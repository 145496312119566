import EngineService from '../core/engine-service';

const ENDPOINT = '/credentials';

export function sendToEngine(credential) {
  return EngineService.post(ENDPOINT, {
    credential,
  }).then((r) => r.credential);
}

export function deleteCredential(credentialId) {
  return EngineService.delete(`${ENDPOINT}/${credentialId}`).then((json) => json);
}

export default {
  sendToEngine,
  deleteCredential,
};
