import { createAsyncThunk } from '@reduxjs/toolkit';

import { isObjectEmpty } from 'helpers/utils';
import { sendEmail } from 'services/support-desk-messages';
import {
  deleteTicket as del,
  getTicket,
  getTicketListViewSettings,
  updateTicketListViewSettings,
  updateTicket as updateTicketRemote,
} from 'services/ticket';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../helpers/errorHelper';
import { serializeForm } from '../../../../helpers/formHelper';
import { replaceTicket } from '../../Tickets/state/data/reducer';

export const fetchSingleTicket = createAsyncThunk(
  'ticket-get',
  async (ticketId, { dispatch, getState }) => {
    try {
      const res = await getTicket(ticketId);
      const isCached = !isObjectEmpty(getState().ticket.tabsOrder);
      let ticketTabs = getState().ticket.tabsOrder;
      if (!isCached) {
        const tabs = await getTicketListViewSettings();
        ticketTabs = tabs;
      }

      return { ...res, ticketTabs };
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const updateTicketTabsOrder = createAsyncThunk(
  'ticket-tabs-order',
  async (tabs, { dispatch }) => {
    const tabsObj = tabs.reduce((acc, currentObj) => {
      const key = Object.keys(currentObj)[0];
      acc[key] = currentObj[key];
      return acc;
    }, {});
    try {
      const res = await updateTicketListViewSettings({ settings: tabsObj });
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const deleteTicket = createAsyncThunk('ticket-delete', async (ticketId, { dispatch }) => {
  try {
    return await del(ticketId);
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const replyOnTicket = createAsyncThunk('ticket-reply', async (payload, { dispatch }) => {
  try {
    return await sendEmail(payload);
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const updateTicket = createAsyncThunk('ticket-update', async (payload, { dispatch }) => {
  try {
    const res = await updateTicketRemote(payload.id, serializeForm(payload.value));
    dispatch(replaceTicket(res));
    return Promise.resolve(res);
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});
