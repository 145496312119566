export const REQUEST_STARTED = 'PIPELINEVIEW: fetch_opportunities_started';
export const REQUEST_SUCCESS = 'PIPELINEVIEW: fetch_opportunities_success';
export const REQUEST_FAILED = 'PIPELINEVIEW: fetch_all_request_failed';
export const REPLACE_OPPORTUNITY = 'PIPELINEVIEW: replace_opportunities';
export const SET_PARAMS = 'PIPELINEVIEW: set_params';
export const RESET_DATA = 'PIPELINEVIEW: reset_opportunities_data';
export const FETCH_SUMS = 'PIPELINEVIEW: fetch_sums';
export const STORE_SUMS = 'PIPELINEVIEW: store_sums';
export const SELECT_PIPELINE = 'PIPELINEVIEW: select_pipeline';
export const STORE_PIPELINES = 'PIPELINEVIEW: store_pipelines';
