import DialogContent from '@mui/material/DialogContent';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import styles from './ModalBody.module.scss';

function ModalBody(props) {
  return (
    <DialogContent {...props} className={classNames([styles.body, props.className])}>
      {props.children}
    </DialogContent>
  );
}

ModalBody.defaultProps = {
  className: '',
};

ModalBody.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default ModalBody;
