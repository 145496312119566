import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { selectUser } from 'app/state/user/selectors';
import Dialpad from 'components/CallDialog/Dialpad/Dialpad';
import {
  selectDisplayDialpad,
  selectIsBadNetwork,
  selectIsNoteScreenVisible,
  selectTwilioConnection,
} from 'components/CallDialog/state/selectors';

import ActiveCallActions from './ActiveCallActions';
import NoteScreen from '../../NoteScreen';
import { setRecording } from '../../state/actions';
import CallerInfo from '../CallerInfo';

import styles from './ActiveCall.module.scss';

export default function ActiveCall() {
  const dispatch = useDispatch();

  const isDialpadDisplayed = useSelector(selectDisplayDialpad);
  const connection = useSelector(selectTwilioConnection);
  const user = useSelector(selectUser);
  const isNoteScreenOpen = useSelector(selectIsNoteScreenVisible);
  const isBadNetwork = useSelector(selectIsBadNetwork);

  useEffect(() => {
    dispatch(setRecording(user.twilioProfile.recording));
  }, []);

  const handleDialpadKeyClick = (digits) => {
    connection.sendDigits(digits);
  };

  return (
    <>
      <div
        className={styles['active-call']}
        style={{ display: isNoteScreenOpen ? 'none' : 'unset' }}
      >
        <div className={styles.info}>
          <CallerInfo
            mainTextColor="orange"
            subTextColor="gray"
            fullInfo={false}
            isBadNetwork={isBadNetwork}
          />
          {isDialpadDisplayed ? (
            <Dialpad onKeyClick={handleDialpadKeyClick} />
          ) : (
            <ActiveCallActions />
          )}
        </div>
      </div>
      <div style={{ display: isNoteScreenOpen ? 'unset' : 'none' }}>
        <NoteScreen />
      </div>
    </>
  );
}
