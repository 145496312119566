import EngineService from '../core/engine-service';

const ENDPOINT = '/data_collectors';

export function getDataCollectors(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function getDataCollector(collectorId) {
  return EngineService.get(`${ENDPOINT}/${collectorId}`).then((json) => json);
}

export function getDataCollectorsImportHistory(params = {}) {
  return EngineService.get('/data_collector_imports', params).then((json) => json);
}

export function createDataCollector(payload) {
  return EngineService.post(ENDPOINT, { data_collector: payload }).then((json) => json);
}

export function updateDataCollector(collectorId, payload) {
  return EngineService.patch(`${ENDPOINT}/${collectorId}`, { data_collector: payload }).then(
    (json) => json
  );
}

export function deleteDataCollector(collectorId) {
  return EngineService.delete(`${ENDPOINT}/${collectorId}`).then((json) => json);
}
