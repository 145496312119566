export const SET_DRIVE_ACCESS_TOKEN = 'SET_DRIVE_ACCESS_TOKEN';
export const SET_DRIVE_RESPONSE = 'SET_DRIVE_RESPONSE';
export const SET_CSV_HEADERS = 'SET_CSV_HEADERS';
export const SET_ROW_COUNT = 'SET_ROW_COUNT';
export const SET_RESOURCE_FIELDS = 'SET_RESOURCE_FIELDS';
export const SET_DISABLED_FORM_SUBMIT = 'SET_DISABLED_FORM_SUBMIT';
export const SET_LOADING = 'SET_LOADING';
export const SET_SELECTED_FILE = 'SET_SELECTED_FILE';
export const SET_CAMPAIGN_VALUE = 'SET_CAMPAIGN_VALUE';
export const SET_OPPORTUNITY_VALUE = 'SET_OPPORTUNITY_VALUE';
export const SET_CONTACT_RESOURCE = 'SET_CONTACT_RESOURCE';
export const CLEAR_IMPORT_STATE = 'CLEAR_IMPORT_STATE';
export const SET_CUSTOM_ENTITY = 'SET_CUSTOM_ENTITY';
export const SET_SELECTED_ITEMS = 'SET_SELECTED_ITEMS';
export const SHOW_IMPORT_DELETE_MODAL = 'SHOW_IMPORT_DELETE_MODAL';
export const SET_PREVIOUS_PATH = 'SET_PREVIOUS_PATH';
export const SET_CURRENT_PATH = 'SET_CURRENT_PATH';
export const SET_MAPPED_VALUE = 'SET_MAPPED_VALUE';
export const REQUEST_SUCCESS = 'REQUEST_SUCCESS';
export const SET_IMPORT_RESOURCE = 'SET_IMPORT_RESOURCE';
export const RESET_IMPORT_DATA = 'RESET_IMPORT_DATA';
export const RESET_SELECTED_ITEMS = 'RESET_SELECTED_ITEMS';
export const SET_COMPONENT_LOADING = 'SET_COMPONENT_LOADING';
export const SET_META = 'SET_META';
export const SET_DOWNLOAD_LOADING = 'SET_DOWNLOAD_LOADING';
export const SET_IMPORTS_HISTORY = 'SET_IMPORTS_HISTORY';
export const SET_LOAD_MORE_HISTORY = 'SET_LOAD_MORE_HISTORY';
export const CLEAR_IMPORTS_HISTORY = 'CLEAR_IMPORTS_HISTORY';
export const SET_DISABLED_BROWSE = 'SET_DISABLED_BROWSE';
export const SET_UPLOAD_PROCESS = 'SET_UPLOAD_PROCESS';
export const SET_DUPLICATES_DOWNLOAD_LOADING = 'SET_DUPLICATES_DOWNLOAD_LOADING';
export const SET_FILE_NAME = 'SET_FILE_NAME';
export const SET_EXTERNAL_ID = 'SET_EXTERNAL_ID';
export const SET_KEY_FOR_EXTERNAL_ID = 'SET_KEY_FOR_EXTERNAL_ID';
export const SET_IMPORT_ERROR = 'SET_IMPORT_ERROR';
