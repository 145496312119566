export const selectInteractions = (state) => state.nestedInteractions.data;
export const selectIsLoading = (state) => state.nestedInteractions.isLoading;
export const selectMeta = (state) => state.nestedInteractions.meta;
export const selectIsRefreshRequired = (state) => state.nestedInteractions.isRefreshRequired;

export function selectModal(state) {
  return state.nestedInteractions.modal;
}

export function selectIsNoteModalVisible(state) {
  const modalState = selectModal(state);
  return modalState.visible && modalState.name === 'NewNoteModal';
}

export function selectIsAttachmentModalVisible(state) {
  const modalState = selectModal(state);
  return modalState.visible && modalState.name === 'NewAttachmentModal';
}

export function selectIsActivityModalVisible(state) {
  const modalState = selectModal(state);
  return modalState.visible && modalState.name === 'NewActivityModal';
}

export const selectTimelineFilter = (state) => state.nestedInteractions.timelineFilter;
