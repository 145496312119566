import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { hideNotificationsSlider } from 'app/state/app-notifications/actions';
import MoreButton from 'lib/MoreButton';

import styles from './NotificationBody.module.scss';

function NotificationBody({ body = [], importData = null }) {
  const dispatch = useDispatch();

  const closeSlider = () => {
    dispatch(hideNotificationsSlider());
  };

  if (importData) {
    const menuConfig = [
      { id: 1, key: 'created_records_csv_id', actionTitle: 'Created records' },
      { id: 2, key: 'failed_records_csv_id', actionTitle: 'Failed records' },
      { id: 3, key: 'updated_records_csv_id', actionTitle: 'Updated records' },
    ];

    const menuItems = menuConfig
      .filter((item) => importData[item.key])
      .map((item) => ({
        id: item.id,
        actionTitle: item.actionTitle,
        onClick: () => {
          window.open(importData[item.key], '_blank', 'noopener,noreferrer');
        },
        divider: false,
      }));

    return (
      <div className={styles['import-wrapper']}>
        <Link
          to={importData.link}
          variant="contained"
          color="primary"
          className={styles['review-button']}
          onClick={closeSlider}
        >
          Review
        </Link>
        <MoreButton
          variant="outlined"
          color="primary"
          menuItems={menuItems}
          disabled={!menuItems.length}
        >
          Download reports
        </MoreButton>
      </div>
    );
  }

  return (
    <div className={styles.body}>
      {body.map((item) => {
        if (item.type === 'link') {
          return (
            <Link key={uuidv4()} to={item.link} className={styles.link}>
              {item.content}
            </Link>
          );
        }
        if (item.type === 'mention') {
          return (
            <span key={uuidv4()} className={styles.mention}>
              {item.content}
            </span>
          );
        }
        return <span key={uuidv4()}>{item.content}</span>;
      })}
    </div>
  );
}

NotificationBody.propTypes = {
  body: PropTypes.array,
  importData: PropTypes.object,
};

export default NotificationBody;
