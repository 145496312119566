export function parseImportsData(data = []) {
  const options = {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  };

  const mapper = (listId, opportunityId) => {
    let contactCreateType = 'Import';

    if (listId) {
      contactCreateType = 'Import contacts to a campaign';
    }
    if (opportunityId) {
      contactCreateType = 'Import contacts to an opportunity';
    }

    return {
      ContactCreate: {
        type: contactCreateType,
        entity: 'Contacts',
      },
      ListContactCreate: {
        type: 'Import',
        entity: 'Contacts',
      },
      ContactUpdate: {
        type: 'Update',
        entity: 'Contacts',
      },
      ContactBatchDelete: {
        type: 'Delete',
        entity: 'Contacts',
      },
      AccountCreate: {
        type: 'Import',
        entity: 'Companies',
      },
      AccountUpdate: {
        type: 'Update',
        entity: 'Companies',
      },
      AccountBatchDelete: {
        type: 'Delete',
        entity: 'Companies',
      },
      OpportunityCreate: {
        type: 'Import',
        entity: 'Opportunities',
      },
      OpportunityUpdate: {
        type: 'Update',
        entity: 'Opportunities',
      },
      ProductCreate: {
        type: 'Import',
        entity: 'Products',
      },
      ProductUpdate: {
        type: 'Update',
        entity: 'Products',
      },
      QuoteCreate: {
        type: 'Import',
        entity: 'Quotes',
      },
      QuoteUpdate: {
        type: 'Update',
        entity: 'Quotes',
      },
      QuoteProductCreate: {
        type: 'Import',
        entity: 'Quote Products',
      },
      QuoteProductUpdate: {
        type: 'Update',
        entity: 'Quote Products',
      },
      UserCreate: {
        type: 'Import',
        entity: 'Users',
      },
      UserUpdate: {
        type: 'Update',
        entity: 'Users',
      },
      OpportunityBatchDelete: {
        type: 'Delete',
        entity: 'Opportunities',
      },
      CustomObjectCreate: {
        type: 'Import',
        entity: 'Custom',
      },
      CustomObjectUpdate: {
        type: 'Update',
        entity: 'Custom',
      },
      CustomObjectBatchDelete: {
        type: 'Delete',
        entity: 'Custom',
      },
      SalesOrderCreate: {
        type: 'Import',
        entity: 'Sales Orders',
      },
      SalesOrderUpdate: {
        type: 'Update',
        entity: 'Sales Orders',
      },
      SalesOrderItemCreate: {
        type: 'Import',
        entity: 'Sales Order Items',
      },
      SalesOrderItemUpdate: {
        type: 'Update',
        entity: 'Sales Order Items',
      },
    };
  };

  const calcStatus = (startedAt, completedAt) => {
    if (!startedAt && !completedAt) return 'Scheduled';
    if (startedAt && !completedAt) return 'Progress';
    if (startedAt && completedAt) return 'Completed';
    return '';
  };

  return data.map((i) => ({
    id: i.id,
    entity: mapper(i.list_id, i.opportunity_id)[i.title].entity,
    entityName: i.entity_name,
    createdCount: i.created_count || 0,
    failedCount: i.failed_count || 0,
    updatedCount: i.updated_count || 0,
    status: calcStatus(i.started_at, i.completed_at),
    dateTime: new Date(i.created_at).toLocaleDateString('en-US', options),
    listName: i.list_name,
    opportunityName: i.opportunity_name,
    errorFile: i.failed_records_csv_id ? 'Download' : '-',
    createdFile: i.created_records_csv_id ? 'Download' : '-',
    updatedFile: i.updated_records_csv_id ? 'Download' : '-',
    totalRecords: i.row_count,
    createdCSVid: i.created_records_csv_id,
    failedCSVid: i.failed_records_csv_id,
    updatedCSVid: i.updated_records_csv_id,
    dataCollectorName: i.data_collector_name || '',
    originalFileName: i.original_file_name || '-',
    csvName: i.csv_name || '',
    percentCompleted: 0,
  }));
}
