export const FETCHING_EMAIL_TEMPLATES = 'mass_email_templates_settings: fetching email templates';
export const EMAIL_TEMPLATES_FETCHED = 'mass_email_templates_settings: email templates fetched';
export const FETCHING_EMAIL_TEMPLATES_FAILED =
  'mass_email_templates_settings: fetching email templates failed';
export const STORE_EMAIL_TEMPLATES = 'mass_email_templates_settings: store email templates';
export const STORE_EMAIL_TEMPLATE = 'mass_email_templates_settings: store email template';
export const REMOVE_EMAIL_TEMPLATE = 'mass_email_templates_settings: remove email template';
export const SELECT_EMAIL_TEMPLATE = 'mass_email_templates_settings: select email template';

export default {
  FETCHING_EMAIL_TEMPLATES,
  EMAIL_TEMPLATES_FETCHED,
  FETCHING_EMAIL_TEMPLATES_FAILED,
  STORE_EMAIL_TEMPLATE,
  STORE_EMAIL_TEMPLATES,
  REMOVE_EMAIL_TEMPLATE,
  SELECT_EMAIL_TEMPLATE,
};
