import { useEffect } from 'react';

import Day from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { getCallStatus } from 'helpers/activities';
import { getPhoneTypeFromNumber } from 'helpers/contacts';
import useHotjar from 'hooks/useHotjar';
import { createActivity } from 'services/twilioService';
import { getActivityDispositionValues } from 'state/custom-fields/selectors';
import { error as errorAlert, success as successAlert } from 'state/notifications/actions';
import { selectUserId } from 'state/user/selectors';

import {
  minimizeCallModal,
  resetTwilioStatePartially,
  setActiveTab,
  setPhoneDialogOpen,
} from '../state/actions';
import {
  selectFormValues,
  selectPhoneNumber,
  selectPreviousCall,
  selectTwilioCallers,
  selectTwilioConnection,
} from '../state/selectors';

export default function useCallDialogModal() {
  const previousCall = useSelector(selectPreviousCall);
  const connection = useSelector(selectTwilioConnection);
  const phoneNumber = useSelector(selectPhoneNumber);
  const { callNotes, custom, disposition, subject } = useSelector(selectFormValues);
  const callers = useSelector(selectTwilioCallers);
  const userId = useSelector(selectUserId);
  const dispositionValues = useSelector(getActivityDispositionValues);
  const { contact, account, callSid, type, caller } = previousCall;
  const dispatch = useDispatch();
  const { logCustomEvent } = useHotjar();

  const generateDefaultText = () => {
    switch (disposition) {
      case 'left_voicemail':
        return 'I left VM for <ContactName>';
      case 'no_connect':
        return `I called <ContactName>, but it didn't connect`;
      default:
        return 'I got <ContactName> on the phone';
    }
  };

  const generateDescription = () => {
    const defaultText = generateDefaultText();
    let description = defaultText.replace(
      '<ContactName>',
      contact ? contact?.firstName : account?.name
    );

    if (phoneNumber && !contact && !account) {
      description = defaultText.replace('<ContactName>', callers[0].name);
      description += `\n\nPhone: ${phoneNumber}`;
    }

    if (phoneNumber && contact) {
      const phoneType = getPhoneTypeFromNumber(phoneNumber, contact);
      description += `\n\n${phoneType.toUpperCase()} Phone: ${phoneNumber}`;
    } else if (phoneNumber && account) {
      description += `\n\nPhone: ${phoneNumber}`;
    }

    if (callNotes) {
      description += `\n\nNotes:\n\n${callNotes}`;
    }

    return description;
  };

  const generateSubject = () => {
    if (!contact && !account) return `Spoke with ${callers[0].name}`;
    switch (disposition) {
      case 'left_voicemail':
        return `Left voicemail for ${contact ? contact.firstName : account.name}`;
      case 'no_connect':
        return `Called ${contact ? contact.firstName : account.name} but didn't connect`;
      case 'inbound_call':
        return `Spoke with ${contact?.firstName || account?.name || callers[0]?.name}`;
      default:
        return `Spoke with ${contact ? contact.firstName : account.name}`;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (
        previousCall.callSid &&
        connection &&
        previousCall.callSid !== connection.parameters.CallSid &&
        previousCall.callers.length > 0
      ) {
        const payload = {
          call_sid: callSid,
          description: generateDescription(),
          disposition: disposition || type,
          subject: subject || generateSubject(),
          owner_type: caller?.resource_type || previousCall.callers[0].resource_type,
          owner_id: caller?.resource_id || previousCall.callers[0].resource_id,
          interacted_at: new Day().format(),
          user_id: userId,
          custom,
          call_status: getCallStatus(dispositionValues, disposition || type),
        };
        try {
          await createActivity(payload);
          dispatch(successAlert('Your notes are being auto-saved.'));
          dispatch(resetTwilioStatePartially());
          dispatch(minimizeCallModal(false));
          dispatch(setPhoneDialogOpen(true));
          logCustomEvent('phone_modal_opened');
          dispatch(setActiveTab('Phone'));
        } catch (error) {
          dispatch(errorAlert(error.statusText));
        }
      }
    };

    fetchData();
  }, [connection]);
}
