import { useState } from 'react';

import LoadingButton from '@mui/lab/LoadingButton';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import UserAvatar from 'components/UserAvatar';
import usePageLeaveWarning from 'hooks/usePageLeaveWarning';
import Button from 'lib/Button';
import ConfirmationModal from 'modals/ConfirmationModal';

import NotesEditor from '../NotesEditor';
import { setRepliedTo } from '../state/reducer';
import { selectRepliedTo } from '../state/selectors';

import styles from './ActiveEditor.module.scss';

function ActiveEditor({
  isActive,
  user = null,
  toggleActiveEditor = () => {},
  createCollaborationNote = () => {},
  editorValue = '',
  onChange = () => {},
  hasFooter = true,
  isLoading = false,
  turnOnPageWarning = false,
}) {
  const [value, setValue] = useState(editorValue);
  const [attachments, setAttachments] = useState([]);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const repliedTo = useSelector(selectRepliedTo);
  const dispatch = useDispatch();

  const handleOnChange = (val) => {
    setValue(val);

    if (onChange) onChange(val);
    if (val.length > 0) {
      setIsSaveDisabled(false);
    } else {
      setIsSaveDisabled(true);
    }
  };

  const toggleConfirmationModal = () => setConfirmationModalOpen(!confirmationModalOpen);

  const clearState = () => {
    setValue('');
    if (onChange) onChange('');
    setAttachments([]);
    setConfirmationModalOpen(false);
    toggleActiveEditor();
  };

  const handleOnCancel = () => {
    if (attachments.length) {
      toggleConfirmationModal();
    } else {
      clearState();
    }
  };

  const onConfirmClick = () => {
    toggleConfirmationModal();
    clearState();
  };

  const handleOnSave = () => {
    createCollaborationNote(value, repliedTo).then(() => {
      clearState();
      dispatch(setRepliedTo(null));
    });
  };

  const onDelete = (newAttachments) => {
    if (newAttachments.length !== attachments.length) {
      setAttachments(newAttachments);
    }
  };

  const handleSetAttachments = (newAttachments) =>
    setAttachments([...attachments, ...newAttachments]);

  if (turnOnPageWarning) {
    usePageLeaveWarning('You have unsaved changes. Do you want to stay on the page?', !!value);
  }

  if (!user) {
    return (
      <div className={styles['editor-without-avatar']}>
        <div className={styles.editor}>
          <NotesEditor
            onChange={handleOnChange}
            value={value}
            isActive={isActive}
            handleSetAttachments={handleSetAttachments}
            onDelete={onDelete}
          />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={isActive ? styles.wrapper : styles['non-active-wrapper']}>
        <UserAvatar user={user} />
        <div className={styles.editor} data-cy="editor">
          <NotesEditor
            onChange={handleOnChange}
            value={value}
            isActive={isActive}
            handleSetAttachments={handleSetAttachments}
            onDelete={onDelete}
          />
          {hasFooter && (
            <div className={styles.footer}>
              <Button onClick={handleOnCancel} data-cy="cancel-btn">
                Cancel
              </Button>
              <LoadingButton
                color="primary"
                variant="outlined"
                disabled={isSaveDisabled}
                onClick={handleOnSave}
                loading={isLoading}
                sx={{ border: '1px solid #5D43CF' }}
                data-cy="save-btn"
              >
                Save
              </LoadingButton>
            </div>
          )}
        </div>
      </div>
      <ConfirmationModal
        visible={confirmationModalOpen}
        title="Discard note?"
        confirmButtonText="Confirm"
        cancelButtonText="Back"
        message="Your note and attachments will be lost."
        onConfirmClick={onConfirmClick}
        onCancelClick={toggleConfirmationModal}
      />
    </>
  );
}

ActiveEditor.propTypes = {
  isActive: PropTypes.bool.isRequired,
  user: PropTypes.object,
  toggleActiveEditor: PropTypes.func,
  createCollaborationNote: PropTypes.func,
  editorValue: PropTypes.string,
  onChange: PropTypes.func,
  hasFooter: PropTypes.bool,
  isLoading: PropTypes.bool,
  turnOnPageWarning: PropTypes.bool,
};

export default ActiveEditor;
