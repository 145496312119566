import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { collaborationNotePath } from 'helpers/paths';
import SanitizedText from 'ui/SanitizedText';

import { ReactComponent as NoteIcon } from '../../../activities/ActivitiesDataGrid/note.svg';
import { useSearch } from '../SearchProvider';

import styles from './SearchResult.module.scss';

function CollaborationNoteResult({ collaborationNote }) {
  const { resourceType, resourceName, resourceId, name, kind, customFieldID } = collaborationNote;
  const { setVisible } = useSearch();

  return (
    <Link
      to={collaborationNotePath(resourceType, resourceId, kind, customFieldID)}
      onClick={() => setVisible(false)}
      className={styles['search-result']}
    >
      <div className={styles['primary-col']}>
        <NoteIcon className={styles.icon} />
        <div>
          <SanitizedText as="span" text={resourceType === 'Account' ? 'Company' : resourceType} />
          :&nbsp;
          <SanitizedText as="span" text={resourceName} />
          <div className={styles['block-secondary-field']}>
            <SanitizedText as="p" text={name} />
          </div>
        </div>
      </div>
    </Link>
  );
}

CollaborationNoteResult.propTypes = {
  collaborationNote: PropTypes.object.isRequired,
};

export default CollaborationNoteResult;
