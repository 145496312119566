import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { nullFilter } from 'helpers/filters';

import { updateTicket } from '../tickets/Ticket/state/actions';
import { fetchTickets } from '../tickets/Tickets/state/data/actions';
import { clearState } from '../tickets/Tickets/state/data/reducer';
import {
  selectIsTicketCreateInProgress,
  selectTickets,
  selectTicketsMeta,
  selectTicketsParams,
} from '../tickets/Tickets/state/data/selectors';
import { setCurrentFilters } from '../tickets/Tickets/state/filters/actions';

export default function useTickets(defaultParams = { per_page: 10 }) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsTicketCreateInProgress);
  const tickets = useSelector(selectTickets);
  const meta = useSelector(selectTicketsMeta);
  const params = useSelector(selectTicketsParams);
  const [searchQuery, setSearchQuery] = useState('');

  const handleFetchTickets = async (requestParams = {}) => {
    dispatch(fetchTickets({ ...defaultParams, ...requestParams, areNestedTickets: true }));
  };

  const handleUpdateTicket = async (id, value) => {
    await dispatch(updateTicket({ id, value }));
  };

  const getPage = useCallback((page) => {
    handleFetchTickets({ page });
  }, []);

  const getRecordsPerPage = useCallback((records) => {
    handleFetchTickets({ per_page: records });
  }, []);

  const sortTickets = useCallback((sortBy, sortOrder) => {
    handleFetchTickets({ sort: sortBy, order: sortOrder, page: 1 });
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleSearchEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        handleFetchTickets({ query: searchQuery, page: 1 });
      }
    },
    [searchQuery]
  );

  const handleSearchClear = useCallback(() => {
    setSearchQuery('');
    handleFetchTickets({ query: '', page: 1 });
  }, []);

  const clearAppliedFilters = useCallback(() => {
    handleFetchTickets({ q: undefined, page: undefined });
    dispatch(setCurrentFilters(nullFilter));
  }, []);

  useEffect(() => {
    handleFetchTickets();
  }, []);

  useEffect(
    () => () => {
      dispatch(clearState());
    },
    []
  );

  return {
    isLoading,
    tickets,
    sortTickets,
    meta,
    params,
    getPage,
    handleUpdateTicket,
    handleSearchChange,
    handleSearchEnterPress,
    handleSearchClear,
    searchQuery,
    handleFetchTickets,
    clearAppliedFilters,
    getRecordsPerPage,
  };
}
