import { HIDE_MODAL, RESET_MODAL_STATE, SHOW_MODAL, STORE_FORM_DATA } from './constants';
// import { serializeCustomFieldValues } from '../../../components/CustomFields/helper';

const initialState = {
  visible: false,
  formData: {
    label: '',
    call_status: 0,
    subject: '',
    body: '',
    index: -1,
  },
};

function showModalActionHandler(state, action) {
  return {
    ...state,
    visible: true,
    formData: action.data ? { ...state.formData, ...action.data } : state.formData,
  };
}

function hideModalActionHandler(state) {
  return { ...state, visible: false };
}

function resetModalActionHandler() {
  return { ...initialState };
}

function storeFormDataActionHandler(state, action) {
  return { ...state, formData: action.data };
}

const ACTION_HANDLERS = {
  [SHOW_MODAL]: showModalActionHandler,
  [HIDE_MODAL]: hideModalActionHandler,
  [RESET_MODAL_STATE]: resetModalActionHandler,
  [STORE_FORM_DATA]: storeFormDataActionHandler,
};

export default function activityTypesFormModalReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
