import { useState } from 'react';

export default function useTabs(defaultValue = 0) {
  const [selectedTab, setSelectedTab] = useState(defaultValue);

  function selectTab(e, newValue) {
    setSelectedTab(newValue);
  }

  return [selectedTab, selectTab];
}
