import EngineService from '../core/engine-service';

const API_ENDPOINT = '/groups';

export async function getGroups(params) {
  try {
    const json = await EngineService.get(API_ENDPOINT, params);
    return json.groups;
  } catch (error) {
    return error;
  }
}

export async function createGroup(data) {
  try {
    const json = await EngineService.post(API_ENDPOINT, { group: data });
    return json;
  } catch (error) {
    return error;
  }
}

export async function updateGroup(ID, data) {
  try {
    const json = await EngineService.patch(`${API_ENDPOINT}/${ID}`, data);
    return json;
  } catch (error) {
    return error;
  }
}

export async function deleteGroup(ID) {
  try {
    return await EngineService.delete(`${API_ENDPOINT}/${ID}`);
  } catch (error) {
    return error;
  }
}
