import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showConfetti: false,
};

export const opportunityCelebrationSlice = createSlice({
  name: 'opportunityCelebration',
  initialState,
  reducers: {
    setShowConfetti: (state, { payload }) => {
      if (
        payload.requestPayload.opportunity_stage_id &&
        payload.response.opportunity_stage.is_won &&
        payload.response.opportunity_stage.is_closed
      ) {
        return { ...state, showConfetti: true };
      }
      return { ...state };
    },
    hideConfetti: (state) => ({ ...state, showConfetti: false }),
  },
});

export const { setShowConfetti, hideConfetti } = opportunityCelebrationSlice.actions;

export default opportunityCelebrationSlice.reducer;
