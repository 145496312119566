import {
  CHANGE_TWILIO_PROFILE_AVAILABILITY,
  FETCHING_ORG_TWILIO_PROFILES,
  SET_TWILIO_VERIFIED_STATUS,
  STORE_ORG_TWILIO_PROFILES,
  STORE_TWILIO_PROFILE,
  TOGGLE_ORG_CALL_RECORDING_AVILABILITY,
  TOGGLE_ORG_CALL_SUMMARY_AVILABILITY,
} from './constants';
import { serialize } from '../../helpers/twilio-profile';
import { getTransferNumbers } from '../../services/twilioService';
import {
  updateTwilioProfile as updateTwilioProfileRemote,
  updateUser as updateUserRemote,
} from '../../services/users';

export function storeTwilioProfile(data) {
  return {
    type: STORE_TWILIO_PROFILE,
    data,
  };
}

export function setTwilioNumberVerificationStatus(data) {
  return {
    type: SET_TWILIO_VERIFIED_STATUS,
    data,
  };
}

export function storeAvailability(available) {
  return {
    type: CHANGE_TWILIO_PROFILE_AVAILABILITY,
    available,
  };
}

export function storeOrgTwilioProfiles(twilioProfiles) {
  return {
    type: STORE_ORG_TWILIO_PROFILES,
    twilioProfiles,
  };
}

export function fetchingOrgTwilioProfilesInProgress() {
  return {
    type: FETCHING_ORG_TWILIO_PROFILES,
  };
}

export function fetchOrgTwilioProfiles() {
  return (dispatch) =>
    getTransferNumbers().then((response) => {
      dispatch(storeOrgTwilioProfiles(response.available_transfer_numbers));
    });
}

export function updateTwilioProfile(data, keys) {
  return (dispatch, getState) => {
    const userId = getState().user.id;

    return updateUserRemote(userId, { twilio_profile: serialize(data, keys) }).then((res) =>
      dispatch(storeTwilioProfile(res.twilio_profile))
    );
  };
}

export function updateTwilioAvailability(available, userId) {
  return (dispatch) => {
    updateTwilioProfileRemote(userId, { available }).then(() =>
      dispatch(storeAvailability(available))
    );
  };
}

export function updateOrganizationCallRecording(data) {
  return {
    type: TOGGLE_ORG_CALL_RECORDING_AVILABILITY,
    data,
  };
}

export function updateOrganizationCallSummaries(data) {
  return {
    type: TOGGLE_ORG_CALL_SUMMARY_AVILABILITY,
    data,
  };
}

export default {
  storeTwilioProfile,
  updateTwilioProfile,
};
