class Opportunity {
  constructor(result) {
    this.id = result.id;
    this.type = result.type;
    this.name = result.highlight.name || result.highlight.raw_name || result.name;
    this.accountName = result.highlight['account.name'] || result.account_name;
    this.opportunityStage = result.opportunity_stage?.name;
    this.description = result.highlight.description;
    this.externalID = result.highlight.external_id;
    this.customText = result.highlight.custom_text_part || '';
  }

  getTitle() {
    return `${this.name} at ${this.accountName}`;
  }

  getDescription() {
    return this.description.join('... ');
  }
}

export default Opportunity;
