import { createAsyncThunk } from '@reduxjs/toolkit';

import { serializeFiltersToString } from 'app/helpers/filters';
import { getAccountsForSummaryView } from 'app/services/accounts';
import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/accounts/actions';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../helpers/errorHelper';

function getParamsFromState(state) {
  const params = state.accountsSummaryView.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.accounts.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.Account;

  const paramsObj = params.sort
    ? defaultParams
    : { ...defaultParams, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export const fetchAccountsForSummaryView = createAsyncThunk(
  'accounts-summary-view-get',
  async (params = {}, { dispatch, getState }) => {
    try {
      dispatch(fetchFilterProperties());
      await dispatch(fetchSavedFilters());
      const stateParams = getParamsFromState(getState());

      const payload = { ...stateParams, ...params };

      const res = await getAccountsForSummaryView(payload);
      return Promise.resolve({ ...res, params: payload });
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
