import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import PropTypes from 'prop-types';

import Button from 'lib/Button';
import Modal from 'ui/Modal';

import styles from './TextTemplatesModal.module.scss';

function TextTemplatesModal({
  onClose,
  onSubmit,
  templates,
  setSelectedTemplate,
  selectedTemplate,
}) {
  const handleOnChange = (e) => {
    setSelectedTemplate(e.target.value);
  };

  const handleOnClose = () => {
    onClose();
    setSelectedTemplate('');
  };

  return (
    <Modal open onClose={handleOnClose} fullWidth maxWidth="sm">
      <Modal.Header>Choose a Text Template</Modal.Header>
      <Modal.Body>
        <FormControl className={styles.radio}>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedTemplate}
            onChange={handleOnChange}
          >
            {templates.map((t) => (
              <FormControlLabel value={t.value} control={<Radio />} label={t.label} />
            ))}
          </RadioGroup>
        </FormControl>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={handleOnClose}>Cancel</Button>
        <Button variant="contained" onClick={onSubmit} disabled={selectedTemplate.length <= 0}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

TextTemplatesModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  templates: PropTypes.array.isRequired,
  selectedTemplate: PropTypes.string.isRequired,
  setSelectedTemplate: PropTypes.func.isRequired,
};

export default TextTemplatesModal;
