import EngineService from '../core/engine-service';

const ENDPOINT = '/custom_fields';

export function get(params) {
  return EngineService.get(ENDPOINT, params);
}

export function create(data) {
  return EngineService.post(ENDPOINT, {
    custom_field: data,
  });
}

export function update(id, data) {
  return EngineService.patch(`${ENDPOINT}/${id}`, {
    custom_field: data,
  });
}

export function del(id, params = {}) {
  return EngineService.delete(`custom_fields/${id}`, params);
}

export default {
  get,
  create,
  update,
  delete: del,
};
