export const GET_CAMPAIGNS_REQUEST_STARTED = 'campaigns_get_campaigns_request_started';
export const GET_CAMPAIGNS_REQUEST_SUCCESS = 'campaigns_get_campaigns_request_success';
export const POST_CAMPAIGN_REQUEST_STARTED = 'campaigns_post_campaign_request_started';
export const POST_CAMPAIGN_REQUEST_SUCCESS = 'campaigns_post_campaign_request_success';
export const POST_CAMPAIGN_REQUEST_FAILED = 'campaigns_post_campaign_request_failed';
export const PATCH_CAMPAIGN_REQUEST_STARTED = 'campaigns_patch_campaign_request_started';
export const PATCH_CAMPAIGN_REQUEST_SUCCESS = 'campaigns_patch_campaign_request_success';
export const PATCH_CAMPAIGN_REQUEST_FAILED = 'campaigns_patch_campaign_request_failed';
export const SET_PARAMS = 'campaings: set_params';
export const REMOVE_CAMPAIGN = 'campaigns_remove_campaign';
export const RESET_STATE = 'campaigns: reset_state';
export const SET_RECORDS_COUNT = 'campaigns: set_records_count';
