import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

import ContactAvatar from 'components/ContactAvatar';

import styles from './VCardPreview.module.scss';

export default function VCardPreview({ vCard, onClear }) {
  const [firstName, lastName] = vCard.name.split(' ');

  return (
    <div className={styles.container}>
      <ContactAvatar
        contact={{ first_name: firstName, last_name: lastName }}
        className={styles.avatar}
      />
      <div className={styles.info}>
        <p className={styles.name}>{vCard.name}</p>
        <p className={styles['company-name']}>{vCard.company_name}</p>
      </div>
      <IconButton onClick={onClear} aria-label="close" className={styles['close-btn']}>
        <CloseIcon sx={{ fontSize: '10px' }} />
      </IconButton>
    </div>
  );
}

VCardPreview.propTypes = {
  vCard: PropTypes.oneOfType([
    PropTypes.shape({
      company_name: PropTypes.string,
      file_url: PropTypes.object,
      name: PropTypes.string,
    }),
    PropTypes.oneOf([null]),
  ]).isRequired,
  onClear: PropTypes.func.isRequired,
};
