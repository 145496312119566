import axios from 'axios';

export function handleDownload(fileId, accessToken) {
  return axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}/export?mimeType=text/csv`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
export function getExportLinksForLargeFiles(fileId, accessToken) {
  return axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?fields=exportLinks`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
export function exportLargeFile(url, accessToken) {
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });
}
