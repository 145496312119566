import EngineService from '../core/engine-service';

const ENDPOINT = '/open_ai_assistant';

export function getDefaultQuestions(params) {
  return EngineService.get(`${ENDPOINT}/default_questions`, params).then((json) => json);
}

export function createAssistantThread(payload) {
  return EngineService.post(`${ENDPOINT}/threads`, payload).then((json) => json);
}

export function createMessageInAssistantThread(threadID, payload) {
  return EngineService.post(`${ENDPOINT}/threads/${threadID}/messages`, payload).then(
    (json) => json
  );
}
