import mapValues from 'lodash/mapValues';
import pick from 'lodash/pick';
import Moment from 'moment';

export function transformValue(value) {
  if (typeof value === 'string') {
    const trimmedValue = value.trim();
    if (trimmedValue.length === 0) return null;
  } else if (Moment.isMoment(value)) {
    // return Moment(value).format();
    return value.format('YYYY-MM-DD');
  }

  return value;
}

export function serializeForm(formData, nestedObject = 'custom') {
  return mapValues(formData, (val, key) => {
    if (key === nestedObject) {
      return mapValues(val, (val2) => transformValue(val2));
    }

    return transformValue(val);
  });
}

export function getFormFieldValue(node) {
  if (!node) {
    throw new Error('Node is not defined');
  }
  // node is DOM node, usually e.target
  if (node.type === 'checkbox') {
    return node.checked;
  }
  return node.value;
}

export function populateDefaultData(defaultData, data) {
  if (!defaultData || defaultData === {}) throw new Error('defaultData is not defined');
  if (!data) return defaultData;

  return { ...defaultData, ...pick(data, Object.keys(defaultData)) };
}

export function fixPhoneInput(text) {
  return text === '+' ? null : text;
}

export default serializeForm;
