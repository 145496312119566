import EngineService from '../core/engine-service';

const ENDPOINT = '/ringcentral_configuration';

export function getConfiguration(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function updateConfiguration(payload) {
  return EngineService.patch(`${ENDPOINT}`, {
    ringcentral_configuration: payload,
  }).then((json) => json);
}

export function deleteConfiguration() {
  return EngineService.delete(ENDPOINT).then((json) => json);
}
