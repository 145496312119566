import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, REMOVE_NOTIFICATION_BY_UID } from './constants';

const initialState = {
  items: [],
};

function addNotificationHandler(state, action) {
  return {
    ...state,
    items: [...state.items, action.notification],
  };
}

function removeNotificationHandler(state, action) {
  return {
    ...state,
    items: [...state.items.slice(0, action.key), ...state.items.slice(action.key + 1)],
  };
}

function removeNotificationByUidHandler(state, action) {
  const key = state.items.findIndex((item) => item.uid === action.uid);

  if (key === undefined || key === -1) {
    return state;
  }

  return {
    ...state,
    items: [...state.items.slice(0, key), ...state.items.slice(key + 1)],
  };
}

const ACTION_HANDLERS = {
  [ADD_NOTIFICATION]: addNotificationHandler,
  [REMOVE_NOTIFICATION]: removeNotificationHandler,
  [REMOVE_NOTIFICATION_BY_UID]: removeNotificationByUidHandler,
};

export default function notificationsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
