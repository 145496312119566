import PropTypes from 'prop-types';

import styles from './TabContent.module.scss';

function TabContent({ children }) {
  return <div className={styles.content}>{children}</div>;
}

TabContent.propTypes = {
  children: PropTypes.object.isRequired,
};

export default TabContent;
