import Button from 'lib/Button';

import styles from './NoResults.module.scss';

export default function NoResults() {
  const handleClick = () => {
    window.open('https://support.spiro.ai/article/386-how-does-search-work', '_blank');
  };

  return (
    <div className={styles['no-results']}>
      <h4 className={styles.text}>No results found</h4>
      <Button onClick={handleClick}>Learn more about searching in Spiro</Button>
    </div>
  );
}
