import AddAlarmOutlinedIcon from '@mui/icons-material/AddAlarmOutlined';
import AlarmOnOutlinedIcon from '@mui/icons-material/AlarmOnOutlined';
import AlarmOutlinedIcon from '@mui/icons-material/AlarmOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import BusinessOutlinedIcon from '@mui/icons-material/BusinessOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CloudDoneOutlinedIcon from '@mui/icons-material/CloudDoneOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import PropTypes from 'prop-types';

import { ReactComponent as SpiritoIcon } from 'images/icons/spirito-outlined-icon.svg';

import styles from './NotificationsAvatar.module.scss';

const avatarTypes = {
  reminder_created: <AddAlarmOutlinedIcon />,
  reminder_updated: <AlarmOutlinedIcon />,
  reminder_completed: <AlarmOnOutlinedIcon />,
  collaboration_note_created: <NoteAddOutlinedIcon />,
  collaboration_note_mention: <AlternateEmailOutlinedIcon />,
  collaboration_note_reaction: <NoteAddOutlinedIcon />,
  collaboration_note_reply: <NoteAddOutlinedIcon />,
  assistant_rule_single_record: <SpiritoIcon />,
  video_meeting_summary_created: <VideocamOutlinedIcon />,
  call_summary_created: <CallOutlinedIcon />,
  scheduled_campaign_sent: <CampaignOutlinedIcon />,
  scheduled_email_sent: <EmailOutlinedIcon />,
  calendar_event_contact_created: <PersonAddOutlinedIcon />,
  calendar_event_multiple_contact_created: <GroupAddOutlinedIcon />,
  company_details_updated: <BusinessOutlinedIcon />,
  import_completed: <CloudDoneOutlinedIcon />,
  external_notification: <SpiritoIcon />,
};

export default function NotificationsAvatar({ type, isRead }) {
  return (
    <div className={styles['icon-wrapper']}>
      {!isRead && <span className={styles.dot} />}
      {avatarTypes[type]}
    </div>
  );
}

NotificationsAvatar.propTypes = {
  type: PropTypes.string.isRequired,
  isRead: PropTypes.bool.isRequired,
};
