class Contact {
  constructor(result) {
    this.id = result.id;
    this.type = result.type;
    this.name = result.name;
    this.email = result.highlight.email || result.highlight['email.raw'] || result.email || '';
    this.description = result.highlight.description;
    this.phone = result.highlight.phone;
    this.mobilePhone = result.highlight.mobile_phone;
    this.externalID = result.highlight.external_id;
    this.fullName = result.name.split(' ');
    this.firstName = result.highlight.first_name || this.fullName[0];
    this.lastName = result.highlight.last_name || this.fullName[1];
    this.accountName = result.highlight['account.name'] || result.account_name;
    this.customText = result.highlight.custom_text_part || '';
  }

  getTitle() {
    return `${this.firstName} ${this.lastName}`;
  }
}

export default Contact;
