import Moment from 'moment';

export default class Interaction {
  constructor(data = {}) {
    this.id = data.id || null;
    this.interactionType = data.interaction_type || null;
    this.interactedAt = new Moment(data.interacted_at);
    this.ownerType = data.owner_type || '';
    this.ownerId = data.owner_id || null;
    this.displayName = data.display_name || '';
    this.subject = data.subject || null;
    this.disposition = data.disposition || '';
    this.kind = data.kind || null;
    this.callDuration = data.call_duration || null;
    this.show = data.show;
    this.edit = data.edit;
    this.destroy = data.destroy;
    this.custom = data.custom || {};
    this.description = data.description || '';
    this.owner = data.owner || {
      id: null,
      name: '',
      account: { id: null, name: '' },
    };
    this.user = data.user
      ? { id: data.user.id, fullName: data.user.full_name }
      : { id: null, fullName: '' };
    this.childInteractions = data.child_interactions || [];
    this.confirmed = data.confirmed || false;
    this.callSid = data.call_sid || null;
  }
}
