import { createSlice } from '@reduxjs/toolkit';

import { nullMeta, parseMetaFromResponse } from 'app/helpers/meta';

import { fetchOpportunitiesForSummaryView } from './actions';

const initialState = {
  data: [],
  isLoading: false,
  params: {
    page: 1,
    q: undefined,
  },
  meta: nullMeta,
};

export const opportunitiesSummaryViewSlice = createSlice({
  name: 'opportunitiesSummaryView',
  initialState,
  reducers: {
    resetState: () => ({ ...initialState }),
  },
  extraReducers: {
    [fetchOpportunitiesForSummaryView.pending]: (state) => ({ ...state, isLoading: true }),
    [fetchOpportunitiesForSummaryView.fulfilled]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      data: payload.opportunities,
      meta: parseMetaFromResponse(payload.meta),
      params: payload.params,
    }),
    [fetchOpportunitiesForSummaryView.rejected]: (state) => ({ ...state, isLoading: false }),
  },
});

export const { resetState } = opportunitiesSummaryViewSlice.actions;

export default opportunitiesSummaryViewSlice.reducer;
