import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { selectUser } from 'state/user/selectors';

export default function useHelpscout(location) {
  const user = useSelector(selectUser);

  useEffect(() => {
    if (window.Beacon) {
      window.Beacon('event', {
        type: 'page-viewed',
        url: document.location.href,
        title: document.title,
      });
      window.Beacon('suggest');
    }
  }, [location]);

  useEffect(() => {
    if (window.Beacon && user.id && process.env.CREATING_HELP_SCOUT_CUSTOMERS_ENABLED === 'true') {
      window.Beacon('identify', {
        name: user.fullName,
        email: user.email,
        'email-address': user.email,
        'first-name': user.firstName,
        'org-name': user.organization?.name,
        status: user.status,
      });
      window.Beacon('open');
      window.Beacon('close');
    }
  }, [user?.id]);
}
