import cs from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as ArrowUp } from '../../../images/icons/v2/activity/arrow-up.svg';

import styles from './LoadMoreButton.module.scss';

function LoadMoreButton({ visible, onClick }) {
  return (
    <div
      className={cs(styles['load-more-button'], { hidden: !visible })}
      role="button"
      tabIndex="0"
      onClick={onClick}
    >
      <ArrowUp />
      Load previous texts
    </div>
  );
}

LoadMoreButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default LoadMoreButton;
