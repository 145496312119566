import { getTimezones } from 'app/services/configurations';
import { isObjectEmpty } from 'helpers/utils';
import {
  destroy as deleteAccountRemote,
  followAccount as followAccountRemote,
  getAccountListViewSettings,
  get as getAccountRemote,
  unfollowAccount as unfollowAccountRemote,
  updateAccountListViewSettings,
  update as updateAccountRemote,
} from 'services/accounts';
import { error as errorAlert } from 'state/notifications/actions';

import constants from './constants';
import { forEachError } from '../../../../helpers/errorHelper';
import { serializeForm } from '../../../../helpers/formHelper';

export function storeAccountLocal(account) {
  return {
    type: constants.STORE_ACCOUNT,
    data: { ...account, parent_id: { label: account?.parent?.name, value: account?.parent?.id } },
  };
}

export function storeAccountTabsOrder(tabs) {
  return {
    type: constants.STORE_ACCOUNT_TABS_ORDER,
    data: tabs,
  };
}

function fetchAccountStarted() {
  return {
    type: constants.FETCH_ACCOUNT_STARTED,
  };
}

export function resetState() {
  return {
    type: constants.RESET_STATE,
  };
}

export function showDeleteModal() {
  return {
    type: constants.SHOW_ACCOUNT_DELETE_MODAL,
  };
}

export function hideDeleteModal() {
  return {
    type: constants.HIDE_ACCOUNT_DELETE_MODAL,
  };
}

export function deleteStarted() {
  return {
    type: constants.ACCOUNT_DELETE_STARTED,
  };
}

export function deleteSuccess() {
  return {
    type: constants.ACCOUNT_DELETE_SUCCESS,
  };
}

export function updateAccountTabsOrder(tabs) {
  return (dispatch) => {
    const tabsObj = tabs.reduce((acc, currentObj) => {
      const key = Object.keys(currentObj)[0];
      acc[key] = currentObj[key];
      return acc;
    }, {});
    updateAccountListViewSettings({ settings: tabsObj })
      .then((r) => {
        dispatch(storeAccountTabsOrder(r));
        return Promise.resolve(r);
      })
      .catch((errors) => {
        const errorMessage = errors.data[Object.keys(errors.data)[0]];
        dispatch(errorAlert(errorMessage));
        return Promise.reject(errors);
      });
  };
}

export function getAccount(accountId, navigate) {
  return async (dispatch, getState) => {
    dispatch(fetchAccountStarted());

    try {
      const { account } = await getAccountRemote(accountId);
      const isCached = !isObjectEmpty(getState().account.tabsOrder);
      if (!isCached) {
        const tabs = await getAccountListViewSettings();
        dispatch(storeAccountTabsOrder(tabs));
      }

      dispatch(storeAccountLocal(account));

      return Promise.resolve(account);
    } catch (e) {
      if (e.status === 404) {
        navigate('/page-not-found');
      } else {
        const errorMessage = e.data[Object.keys(e.data)[0]];
        dispatch(errorAlert(errorMessage));
      }
      return Promise.reject(e.data);
    }
  };
}

export function updateAccount(accountId, data) {
  return (dispatch) =>
    updateAccountRemote(accountId, serializeForm(data))
      .then((r) => {
        dispatch(storeAccountLocal(r.account));
        return Promise.resolve(r.account);
      })
      .catch((errors) => {
        const errorMessage = errors.data[Object.keys(errors.data)[0]];
        dispatch(errorAlert(errorMessage));
        return Promise.reject(errors);
      });
}

export function deleteAccount(accountId) {
  return async (dispatch) => {
    dispatch(deleteStarted());

    try {
      await deleteAccountRemote(accountId);
      dispatch(deleteSuccess());
      return Promise.resolve();
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  };
}

export function followAccount(accountId) {
  return (dispatch, getState) =>
    followAccountRemote(accountId)
      .then((res) => {
        const state = getState();
        const account = { ...state.account.data, following_as_user: true };
        dispatch(storeAccountLocal(account));
        return Promise.resolve(res);
      })
      .catch((errors) => {
        const errorMessage = errors.data[Object.keys(errors.data)[0]];
        dispatch(errorAlert(errorMessage));
        return Promise.reject(errors);
      });
}

export function unfollowAccount(accountId) {
  return (dispatch, getState) =>
    unfollowAccountRemote(accountId)
      .then((res) => {
        const state = getState();
        const account = {
          ...state.account.data,
          following_as_user: false,
          latest_weekly_summary: null,
        };
        dispatch(storeAccountLocal(account));
        return Promise.resolve(res);
      })
      .catch((errors) => {
        const errorMessage = errors.data[Object.keys(errors.data)[0]];
        dispatch(errorAlert(errorMessage));
        return Promise.reject(errors);
      });
}

export function storeTimezones(data) {
  return {
    type: constants.STORE_TIMEZONES,
    data,
  };
}

export function fetchTimezones(params = {}) {
  return (dispatch) =>
    getTimezones(params)
      .then((json) => {
        dispatch(storeTimezones(json));
        return Promise.resolve(json);
      })
      .catch((err) => Promise.reject(err));
}
