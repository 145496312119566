import PropTypes from 'prop-types';

import UserAvatar from 'components/UserAvatar';

import styles from './InactiveEditor.module.scss';

function InactiveEditor({ onClick, user, isActive }) {
  return (
    <div
      className={isActive ? styles['non-active-wrapper'] : styles.wrapper}
      data-cy="non-active-wrapper"
    >
      <UserAvatar user={user} />
      <div
        role="button"
        tabIndex="0"
        onClick={onClick}
        className={styles.message}
        data-cy="add-note-btn"
      >
        Add a note...
      </div>
    </div>
  );
}

InactiveEditor.propTypes = {
  onClick: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default InactiveEditor;
