import EngineService from '../core/engine-service';

const ENDPOINT = '/lists';

// GET: /lists,
export function getTargetLists(params = {}) {
  return EngineService.get(`${ENDPOINT}`, params).then((json) => json);
}

// GET: /lists/:id
export function getTargetList(listId) {
  return EngineService.get(`${ENDPOINT}/${listId}`).then((json) => json);
}

// PATCH: /lists/:id
export function updateTargetList(listId, payload) {
  return EngineService.patch(`${ENDPOINT}/${listId}`, { list: payload }).then((json) => json);
}

// POST: /lists
export function createTargetList(payload) {
  return EngineService.post(`${ENDPOINT}`, { list: payload }).then((json) => json);
}

// DELETE: /lists/:id
export function deleteTargetList(listId) {
  return EngineService.delete(`${ENDPOINT}/${listId}`).then((json) => json);
}

// GET: /lists/:id/contacts
export function getTargetListContacts(listId, params = {}) {
  return EngineService.get(`${ENDPOINT}/${listId}/contacts`, params).then((json) => json);
}

// DELETE: /lists/:id/contacts/:contact_id
export function deleteTargetListContact(listId, contactId) {
  return EngineService.delete(`${ENDPOINT}/${listId}/contacts/${contactId}`).then((json) => json);
}

// GET: /lists/:id/notifications
export async function getListRecommendations(listId, params) {
  const json = await EngineService.get(`${ENDPOINT}/${listId}/notifications`, params);
  return json;
}

export function getCampaingFilterProperties(params = {}) {
  return EngineService.get('/filters/lists/columns', params).then((json) => json);
}

export function getAllLists(params = {}) {
  return EngineService.get(`${ENDPOINT}/all`, params).then((json) => json);
}

export function getCampaignsCount(params = {}) {
  return EngineService.get(`${ENDPOINT}/count`, params);
}

export default {
  getTargetLists,
  getTargetList,
  createTargetList,
  updateTargetList,
  deleteTargetList,
  getTargetListContacts,
  deleteTargetListContact,
  getListRecommendations,
  getCampaingFilterProperties,
  getAllLists,
  getCampaignsCount,
};
