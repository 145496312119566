import { useLocation } from 'react-router-dom';

export default function useQueryParams() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const queryParams = {};

  searchParams.forEach((value, key) => {
    queryParams[key] = value;
  });

  return queryParams;
}
