import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { convertNameToInitials } from 'helpers/utils';
import SanitizedText from 'ui/SanitizedText';

import { useSearch } from '../SearchProvider';

import styles from './SearchResult.module.scss';

export default function ContactResult({ contact }) {
  const { setVisible } = useSearch();

  return (
    <Link
      to={`/contacts/${contact.id}`}
      onClick={() => setVisible(false)}
      className={styles['search-result']}
    >
      <div className={styles['primary-col']}>
        <div className={styles['contact-initials']}>{convertNameToInitials(contact.name)}</div>
        <div>
          <SanitizedText as="p" text={contact.getTitle()} />
          <div>
            {contact.accountName && (
              <SanitizedText
                as="span"
                className={styles['secondary-field']}
                text={contact.accountName}
              />
            )}
            {contact.phone && (
              <div className={styles['secondary-field']}>
                <strong>Phone: </strong>
                <SanitizedText as="span" text={contact.phone} />
              </div>
            )}
            {contact.mobilePhone && (
              <div className={styles['secondary-field']}>
                <strong>Mobile phone: </strong>
                <SanitizedText as="span" text={contact.mobilePhone} />
              </div>
            )}
            {contact.externalID && (
              <div className={styles['secondary-field']}>
                <strong>External ID: </strong>
                <SanitizedText as="span" text={contact.externalID} />
              </div>
            )}
          </div>
          {contact.description && (
            <div>
              <strong>Description: </strong>
              {contact.description.map((matchedFragment) => (
                <SanitizedText
                  as="span"
                  className={styles['description-fragment']}
                  text={matchedFragment}
                />
              ))}
            </div>
          )}
          {contact.customText && (
            <div>
              <strong>Custom: </strong>
              {contact.customText.map((matchedFragment) => (
                <SanitizedText
                  as="span"
                  className={styles['description-fragment']}
                  text={matchedFragment}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {contact.email && <SanitizedText className={styles['secondary-col']} text={contact.email} />}
    </Link>
  );
}

ContactResult.propTypes = {
  contact: PropTypes.object.isRequired,
};
