import EngineService from '../core/engine-service';

const ENDPOINT = '/notifications';

export function getNotifications(params) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function clearAllNotifications() {
  return EngineService.post(`${ENDPOINT}/clear_all`).then((json) => json);
}

export function markNotificationAsRead(id) {
  return EngineService.post(`${ENDPOINT}/${id}/mark_as_read`).then((json) => json);
}

export default {
  getNotifications,
  clearAllNotifications,
  markNotificationAsRead,
};
