import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setResourceDetails } from 'app/assistant/Assistant/state/reducer';
import { assistantRulePath, collaborationNotePath } from 'helpers/paths';
import useHotjar from 'hooks/useHotjar';
import { removePushNotification } from 'state/push-notifications/reducer';

import useNotifications from '../AppNotifications/Notifications/useNotifications';
import {
  resetStatePartially,
  setChatId,
  setShouldRefetch,
} from '../CallDialog/MessagesTab/Conversation/state/reducer';
import {
  minimizeCallModal,
  setActiveTab,
  setConversationScreenOpen,
  setPhoneDialogOpen,
} from '../CallDialog/state/actions';

const SINGULARS = {
  contacts: 'Contact',
  accounts: 'Account',
  opportunities: 'Opportunity',
  tickets: 'Ticket',
  quotes: 'Quote',
  'sales-order': 'SalesOrder',
};

export default function usePushNotification() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { logCustomEvent } = useHotjar();
  const { markAsRead } = useNotifications();

  const assistantNotificationHandler = (
    dismiss,
    uid,
    isEnabledOnAssistantPage,
    ruleId,
    resourceType,
    resourceID
  ) => {
    navigate(assistantRulePath(isEnabledOnAssistantPage, ruleId, resourceType, resourceID));
    dispatch(removePushNotification(uid));
    dispatch(setResourceDetails({ id: resourceID, resource: SINGULARS[resourceType] }));
    dismiss(uid);
  };

  const internalNotificationHandler = (
    dismiss,
    uid,
    link,
    additionalData,
    isExternalNotification
  ) => {
    if (isExternalNotification) {
      window.open(link, '_blank');
      dispatch(removePushNotification(uid));
      dismiss(uid);
    } else if (additionalData) {
      const {
        is_enabled_on_assistant_tab: isEnabledOnAssistantPage,
        assistant_rule_id: ruleId,
        resource_type: resourceType,
        resource_id: resourceID,
      } = additionalData;
      navigate(assistantRulePath(isEnabledOnAssistantPage, ruleId, resourceType, resourceID));
      dispatch(removePushNotification(uid));
      dispatch(setResourceDetails({ id: resourceID, resource: resourceType }));
      dismiss(uid);
    } else {
      const relativeLink = link.replace(`${window.location.origin}/`, '');
      navigate(relativeLink, { state: { refreshValue: uid } });
      dispatch(removePushNotification(uid));
      dismiss(uid);
    }
    markAsRead(uid);
  };

  const inboundTextHandler = (dismiss, uid, threadID) => {
    dispatch(removePushNotification(uid));
    dismiss(uid);
    dispatch(resetStatePartially());
    dispatch(setPhoneDialogOpen(true));
    dispatch(minimizeCallModal(false));
    dispatch(setChatId(threadID));
    dispatch(setShouldRefetch(true));
    dispatch(setActiveTab('Messages'));
    dispatch(setConversationScreenOpen(true));
    logCustomEvent('phone_modal_opened');
  };

  const missedCallHandler = (dismiss, uid) => {
    dispatch(removePushNotification(uid));
    dismiss(uid);
    dispatch(setPhoneDialogOpen(true));
    dispatch(minimizeCallModal(false));
    dispatch(setActiveTab('Recents'));
    logCustomEvent('phone_modal_opened');
  };

  const newContactHandler = (dismiss, uid, contactID) => {
    navigate(`/contacts/${contactID}`);
    dispatch(removePushNotification(uid));
    dismiss(uid);
  };

  const reopenedTicketHandler = (dismiss, uid, ticketID) => {
    navigate(`/tickets/${ticketID}`);
    dispatch(removePushNotification(uid));
    dismiss(uid);
  };

  const failedSMSHandler = (dismiss, uid, contactID) => {
    dispatch(removePushNotification(uid));
    dismiss(uid);
    navigate(`/contacts/${contactID}`);
  };

  const collaborationNoteMentionHandler = (
    dismiss,
    uid,
    resourceType,
    resourceID,
    kind,
    customFieldID
  ) => {
    navigate(collaborationNotePath(resourceType, resourceID, kind, customFieldID));
    dispatch(removePushNotification(uid));
    dismiss(uid);
  };

  const runCorrespondingActionsByType = (notification, dismiss) => {
    const {
      type,
      uid,
      link,
      isEnabledOnAssistantPage,
      ruleId,
      resourceType,
      resourceID,
      threadID,
      contactID,
      ticketID,
      kind,
      customFieldID,
      additionalData,
      isExternalNotification,
    } = notification;

    return {
      assistant_notification: () =>
        assistantNotificationHandler(
          dismiss,
          uid,
          isEnabledOnAssistantPage,
          ruleId,
          resourceType,
          resourceID
        ),
      notification_created: () =>
        internalNotificationHandler(dismiss, uid, link, additionalData, isExternalNotification),
      inbound_text: () => inboundTextHandler(dismiss, uid, threadID),
      missed_call: () => missedCallHandler(dismiss, uid),
      new_contact: () => newContactHandler(dismiss, uid, contactID),
      reopened_ticket: () => reopenedTicketHandler(dismiss, uid, ticketID),
      collaboration_note_mention: () =>
        collaborationNoteMentionHandler(
          dismiss,
          uid,
          resourceType,
          resourceID,
          kind,
          customFieldID
        ),
      collaboration_note_mention_reply: () =>
        collaborationNoteMentionHandler(
          dismiss,
          uid,
          resourceType,
          resourceID,
          kind,
          customFieldID
        ),
      collaboration_note_created: () =>
        collaborationNoteMentionHandler(
          dismiss,
          uid,
          resourceType,
          resourceID,
          kind,
          customFieldID
        ),
      failed_sms_notification: () => failedSMSHandler(dismiss, uid, contactID),
    }[type];
  };

  return { runCorrespondingActionsByType };
}
