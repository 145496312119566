import {
  DELETE_PIPELINE,
  INSERT_PIPELINE,
  REQUEST_FAIL,
  REQUEST_START,
  REQUEST_SUCCESS,
  SELECT_PIPELINE,
  STORE_PIPELINE,
  STORE_PIPELINES,
} from './constants';
import { getSalesStagesFromPipelines, parse, parseAll, serialize } from '../../helpers/pipelines';
import {
  create as createPipelineRemote,
  getAll as getPipelinesRemote,
  update as updatePipelineRemote,
} from '../../services/pipelines';
import { storeAllSalesStages } from '../sales-stages/actions';

export function storePipelines(pipelines) {
  return {
    type: STORE_PIPELINES,
    pipelines,
  };
}

export function storePipeline(pipeline) {
  return {
    type: STORE_PIPELINE,
    pipeline,
  };
}

export function selectPipeline(id) {
  return {
    type: SELECT_PIPELINE,
    id,
  };
}

export function insertPipeline(pipeline) {
  return {
    type: INSERT_PIPELINE,
    pipeline,
  };
}

export function requestStart() {
  return {
    type: REQUEST_START,
  };
}

export function requestSuccess() {
  return {
    type: REQUEST_SUCCESS,
  };
}

export function requestFail() {
  return {
    type: REQUEST_FAIL,
  };
}

export function getPipelines(params) {
  return (dispatch) => {
    dispatch(requestStart());

    return getPipelinesRemote(params).then((res) => {
      const parsed = parseAll(res);
      dispatch(storePipelines(parsed));
      dispatch(storeAllSalesStages(getSalesStagesFromPipelines(parsed)));
      dispatch(requestSuccess());

      return parsed;
    });
  };
}

export function createPipeline(data, params) {
  return (dispatch) => {
    dispatch(requestStart());
    return createPipelineRemote(serialize(data), params).then((res) => {
      dispatch(insertPipeline(parse(res.pipeline)));
      dispatch(requestSuccess());
    });
  };
}

export function updatePipeline(id, data, params) {
  return (dispatch) => {
    dispatch(requestStart());
    return updatePipelineRemote(id, serialize(data), params).then((res) => {
      dispatch(storePipeline(parse(res.pipeline)));
      dispatch(requestSuccess());
    });
  };
}

export function deletePipeline(id, params) {
  return (dispatch) => {
    dispatch(requestStart());
    return updatePipelineRemote(id, { is_active: false }, params).then(() => {
      dispatch({
        type: DELETE_PIPELINE,
        id,
      });
      dispatch(requestSuccess());
    });
  };
}
