import {
  CLEAR_DATA,
  FETCH_ENTITIES_STARTED,
  REPLACE_ENTITY,
  SET_CUSTOM_OBJECT,
  SET_PARAMS,
  SET_RECORDS_COUNT,
  SET_SELECTED_RESOURCE,
  STORE_ENTITIES,
} from './constants';
import { nullMeta } from '../../../helpers/meta';

const initialState = {
  fetching: false,
  items: [],
  meta: nullMeta,
  params: {
    page: undefined,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    q: undefined,
  },
  selectedResource: null,
  recordsCount: 0,
  customObject: null,
};

export const fetchEntitiesStartedHandler = (state) => ({ ...state, fetching: true });

export const storeEntitiesHandler = (state, action) => ({
  ...state,
  fetching: false,
  items: action.data,
  meta: { ...state.meta, ...action.meta },
});

export const setParamsHandler = (state, action) => ({
  ...state,
  params: { ...state.params, ...action.data },
});

export const setSelectedResourceHandler = (state, action) => ({
  ...state,
  selectedResource: action.selectedResource,
  items: [],
});

export const replaceEntityHandler = (state, action) => ({
  ...state,
  items: state.items.map((item) => (item.id === action.entity.id ? action.entity : item)),
  fetching: false,
});

function setRecordsCountHandler(state, action) {
  return { ...state, recordsCount: action.recordsCount };
}

function setCustomObject(state, action) {
  return { ...state, customObject: action.customObject };
}

export const clearDataHandler = () => initialState;

const ACTION_HANDLERS = {
  [FETCH_ENTITIES_STARTED]: fetchEntitiesStartedHandler,
  [STORE_ENTITIES]: storeEntitiesHandler,
  [SET_PARAMS]: setParamsHandler,
  [SET_SELECTED_RESOURCE]: setSelectedResourceHandler,
  [REPLACE_ENTITY]: replaceEntityHandler,
  [CLEAR_DATA]: clearDataHandler,
  [SET_RECORDS_COUNT]: setRecordsCountHandler,
  [SET_CUSTOM_OBJECT]: setCustomObject,
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
