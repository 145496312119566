import { parametrizeUrl } from '../../../helpers/URLHelper';
import { get as getUserSession } from '../../helpers/userSession';

export function singularKey(resourcePlurar) {
  switch (resourcePlurar) {
    case 'contacts':
      return 'contact';
    case 'companies':
      return 'account';
    case 'opportunities':
      return 'opportunity';
    default:
      return '';
  }
}

export const downloadSample = (params) => {
  const accessToken = getUserSession().accessToken;
  const url = parametrizeUrl(
    `${process.env.ENGINE_API_URL}/import_templates.csv?token=${accessToken}`,
    params
  );
  window.open(url, 'Import Template');
};

export const checkRequired = (resourceFieldsValues, setDisabledFormSubmit) => {
  let count = 0;
  let requiredInState = 0;

  resourceFieldsValues.forEach((item) => {
    if (item.required === '1') {
      count += 1;
      if (item.csv_header) {
        requiredInState += 1;
      }
    }
  });

  setDisabledFormSubmit(count !== requiredInState);
};

export const getQueryFromResource = (resName, customEntity) => {
  const mapper = {
    contacts: 'Contact',
    companies: 'Account',
    opportunities: 'Opportunity',
    custom: customEntity.name,
    list: 'ListContact',
    products: 'Product',
    quotes: 'Quote',
    'quote-products': 'QuoteProduct',
    users: 'User',
    'sales-orders': 'SalesOrder',
    'sales-order-items': 'SalesOrderItem',
  };

  return mapper[resName];
};

export const getNounsFromResource = (resName, count = 2) => {
  const mapper = {
    contacts: {
      singular: 'contact',
      plural: 'contacts',
    },
    companies: {
      singular: 'company',
      plural: 'companies',
    },
    opportunities: {
      singular: 'opportunity',
      plural: 'opportunities',
    },
    custom: {
      singular: 'list entity',
      plural: 'list entities',
    },
    products: {
      singular: 'product',
      plural: 'products',
    },
    quotes: {
      singular: 'quote',
      plural: 'quotes',
    },
    'quote-products': {
      singular: 'quote product',
      plural: 'quote products',
    },
    users: {
      singular: 'user',
      plural: 'users',
    },
    'sales-orders': {
      singular: 'sales order',
      plural: 'sales orders',
    },
    'sales-order-items': {
      singular: 'sales order item',
      plural: 'sales order items',
    },
  };

  return mapper[resName][count === 1 ? 'singular' : 'plural'];
};

export const userReadableFlow = (flow) => (flow === 'create' ? 'import' : flow);

export const importType = (resource, flow) => {
  // AccountCreate AccountUpdate ContactCreate ContactUpdate
  // CustomObjectCreate CustomObjectUpdate OpportunityCreate OpportunityUpdate
  const values = {
    contacts: {
      create: 'ContactCreate',
      update: 'ContactUpdate',
    },
    companies: {
      create: 'AccountCreate',
      update: 'AccountUpdate',
    },
    opportunities: {
      create: 'OpportunityCreate',
      update: 'OpportunityUpdate',
    },
    products: {
      create: 'ProductCreate',
      update: 'ProductUpdate',
    },
    quotes: {
      create: 'QuoteCreate',
      update: 'QuoteUpdate',
    },
    'quote-products': {
      create: 'QuoteProductCreate',
      update: 'QuoteProductUpdate',
    },
    users: {
      create: 'UserCreate',
      update: 'UserUpdate',
    },
    custom: {
      create: 'CustomObjectCreate',
      update: 'CustomObjectUpdate',
    },
    list: {
      create: 'ListContactCreate',
    },
    'sales-orders': {
      create: 'SalesOrderCreate',
      update: 'SalesOrderUpdate',
    },
    'sales-order-items': {
      create: 'SalesOrderItemCreate',
      update: 'SalesOrderItemUpdate',
    },
  };
  return values[resource][flow];
};

export const downloadFile = (fileData, name, extension) => {
  const a = document.createElement('a');
  const blob = new Blob([fileData]);
  a.href = URL.createObjectURL(blob);
  a.target = '_blank';
  a.download = `${name}.${extension}`;
  a.click();
};

export function isCsvHeaderNotEmpty(obj) {
  return Object.values(obj).some((nestedObj) => nestedObj.csv_header !== '');
}
