import { parseTokenFromEngine } from 'helpers/tokens';

import { REMOVE_TOKEN, SET_IS_DELETING, STORE_TOKENS } from './constants';

const initialState = {
  items: [],
  isDeleting: false,
};

function storeTokensHandler(state, action) {
  return { ...state, items: action.data.map((item) => parseTokenFromEngine(item)) };
}

function removeTokenHandler(state, action) {
  return { ...state, items: state.items.filter((token) => token.id !== action.tokenId) };
}

function setIsDeletingHandler(state, action) {
  return { ...state, isDeleting: action.status };
}

const ACTION_HANDLERS = {
  [STORE_TOKENS]: storeTokensHandler,
  [REMOVE_TOKEN]: removeTokenHandler,
  [SET_IS_DELETING]: setIsDeletingHandler,
};

export default function tokensReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
