import PropTypes from 'prop-types';

import styles from './DetailsDrawerFooter.module.scss';

function DetailsDrawerFooter({ children }) {
  return <div className={styles.footer}>{children}</div>;
}

DetailsDrawerFooter.propTypes = {
  children: PropTypes.object.isRequired,
};

export default DetailsDrawerFooter;
