import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';

const nullMeta = {
  currentPage: null,
  previousPage: null,
  nextPage: null,
};

// take the key of the first property of render_on object
// we are using this key to match render_on with a value from resource's custom field
function getCustomFieldRenderOnKey(field) {
  return Object.keys(field.options.render_on)[0];
}

function getCustomFieldRenderOnValue(field, key) {
  const value = field.options.render_on[key];
  return Array.isArray(value) ? value : value.split(',');
}

function getResourceValue(key, resource) {
  if (key === 'pipeline_id') return resource.pipeline.id.toString();
  if (key === 'opportunity_stage_id') return resource.opportunity_stage.id.toString();
  if (key === 'support_desk_id') return resource.support_desk.value.toString();
  return resource.custom[key] || null;
}

export function getCustomEntityValues(customObjects, customField) {
  return customObjects.filter((co) => co.custom_field_id === customField.id);
}

export function entityShouldRender(field, resource) {
  if (isEmpty(field.options)) return true;
  if (isEmpty(field.options.render_on)) return true;

  const renderOnKey = getCustomFieldRenderOnKey(field);
  const renderOnValue = getCustomFieldRenderOnValue(field, renderOnKey);
  const resourceValue = getResourceValue(renderOnKey, resource);

  if (!resourceValue) return false;

  return intersection(renderOnValue, resourceValue.split(',')).length > 0;
}

export function parseMetaFromResponse(meta) {
  if (!meta) return nullMeta;
  return {
    currentPage: meta.current_page,
    previousPage: meta.previous_page,
    nextPage: meta.next_page,
  };
}

export function isSectionSchemaType(schemaType) {
  return schemaType === 'section';
}

export function isListSchemaType(schemaType) {
  return schemaType === 'list';
}

export function getPayloadForSectionCreate(customFields) {
  return Object.keys(customFields)
    .filter((field) => field !== 'created_at' && field !== 'created_by')
    .reduce(
      (payload, field) => ({
        ...payload,
        [field]: customFields[field].default || '',
      }),
      {}
    );
}
