export const collaborationNotesNotifications = [
  'collaboration_note_mention',
  'collaboration_note_mention_reply',
  'collaboration_note_created',
];

export function getNoteNotificationText(type) {
  return (
    {
      collaboration_note_mention: 'mentioned you in a note',
      collaboration_note_mention_reply: 'replied to a note you were mentioned',
      collaboration_note_created: 'created a new note',
    }[type] || ''
  );
}
