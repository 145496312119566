export const FETCH_REMINDERS_STARTED = 'NESTED_REMINDERS: fetch_reminders_started';
export const STORE_REMINDERS = 'NESTED_REMINDERS: store_reminders';
export const UPDATE_REMINDER_STARTED = 'NESTED_REMINDERS: update_reminder_started';
export const UPDATE_REMINDER = 'NESTED_REMINDERS: update_reminder';
export const ADD_REMINDER = 'NESTED_REMINDERS: add_reminder';
export const REMOVE_REMINDER = 'NESTED_REMINDERS: remove_reminder';
export const SET_PARAMS = 'NESTED_REMINDERS: set_params';
export const OPEN_EDIT_REMINDER_MODAL = 'NESTED_REMINDERS: open_edit_reminder_modal';
export const CLOSE_EDIT_REMINDER_MODAL = 'NESTED_REMINDERS: close_edit_reminder_modal';
export const OPEN_CREATE_REMINDER_MODAL = 'NESTED_REMINDERS: open_create_reminder_modal';
export const CLOSE_CREATE_REMINDER_MODAL = 'NESTED_REMINDERS: close_create_reminder_modal';
export const CLEAR_STATE = 'NESTED_REMINDERS: clear_state';

export default {
  FETCH_REMINDERS_STARTED,
  STORE_REMINDERS,
  UPDATE_REMINDER_STARTED,
  ADD_REMINDER,
  REMOVE_REMINDER,
  UPDATE_REMINDER,
  SET_PARAMS,
  CLEAR_STATE,
};
