import React from 'react';

import PropTypes from 'prop-types';
import { Device } from 'twilio-client';

import {
  errorCodes,
  getPhoneNumberFromConnection,
  removePhoneNumberFormatting,
} from 'helpers/twilio';

class TwilioDevice extends React.PureComponent {
  componentDidMount() {
    Device.on('connect', (connection) => {
      const phoneNumber = getPhoneNumberFromConnection(connection);

      if (phoneNumber) {
        this.props.searchForContact(removePhoneNumberFormatting(phoneNumber));
      }
    });

    // Listen for incoming connections
    Device.on('incoming', (connection) => {
      this.props.handleIncomingCall(connection, '');
    });

    // Handle call disconnect
    Device.on('disconnect', (connection) => {
      if (!this.props.twilioConnectingInProgress) {
        this.props.hangUpCall(connection);
      }
    });

    // Handle incoming call cancel event
    Device.on('cancel', () => {
      this.props.handleIncomingCallCancel();
    });

    // Handle device errors
    Device.on('error', (error) => {
      if (error.code === errorCodes.JWT_TOKEN_EXPIRED) {
        this.props.createToken((token) => {
          const options = {
            debug: true,
            codecPreferences: ['opus', 'pcmu'],
            fakeLocalDTMF: true,
          };

          Device.setup(token, options);
        });
      }
    });

    // Handle device ready event
    Device.on('ready', () => {
      console.info('Twilio.Device is now ready for connections');
    });
  }

  componentWillUnmount() {
    Device.destroy();
  }

  render() {
    return <div />;
  }
}

TwilioDevice.defaultProps = {
  caller: null,
};

TwilioDevice.propTypes = {
  caller: PropTypes.shape({
    name: PropTypes.string,
    resource_id: PropTypes.number,
    resource_type: PropTypes.string,
  }),
  handleIncomingCall: PropTypes.func.isRequired,
  handleIncomingCallCancel: PropTypes.func.isRequired,
  searchForContact: PropTypes.func.isRequired,
  hangUpCall: PropTypes.func.isRequired,
  createToken: PropTypes.func.isRequired,
  twilioConnectingInProgress: PropTypes.bool.isRequired,
};

export default TwilioDevice;
