import { createSlice } from '@reduxjs/toolkit';

import { nullMeta, parseMetaFromResponse } from 'helpers/meta';

import { fetchTickets, handleCreateTicket } from './actions';

const initialState = {
  data: [],
  fetching: false,
  isCreating: false,
  isModalVisible: false,
  isCreateInProgress: false,
  meta: nullMeta,
  params: {
    page: 1,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    query: undefined,
  },
  recordsCount: 0,
};

export const ticketsSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    setNewTicketModalVisible: (state, { payload }) => ({ ...state, isModalVisible: payload }),
    replaceTicket: (state, { payload }) => {
      const tickets = state.data;
      const index = tickets.map((ticket) => ticket.id).indexOf(payload.ticket.id);

      const newState = tickets
        .slice(0, index)
        .concat(payload.ticket)
        .concat(tickets.slice(index + 1));
      return { ...state, data: newState };
    },
    clearState: (state) => ({ ...initialState, recordsCount: state.recordsCount }),
    setTicketsCount: (state, { payload }) => ({ ...state, recordsCount: payload }),
    replaceTicketInList: (state, { payload }) => {
      const tickets = state.data;
      const index = tickets.map((ticket) => ticket.id).indexOf(payload.id);

      const newState = tickets
        .slice(0, index)
        .concat(payload)
        .concat(tickets.slice(index + 1));
      return { ...state, data: newState };
    },
  },
  extraReducers: {
    [fetchTickets.pending]: (state) => ({ ...state, fetching: true }),
    [fetchTickets.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.tickets,
      fetching: false,
      meta: {
        ...state.meta,
        ...parseMetaFromResponse(payload.meta),
      },
      params: {
        ...state.params,
        ...payload.params,
      },
    }),
    [fetchTickets.rejected]: (state) => ({ ...state, fetching: false }),
    [handleCreateTicket.pending]: (state) => ({ ...state, isCreateInProgress: true }),
    [handleCreateTicket.fulfilled]: (state) => ({
      ...state,
      isCreateInProgress: false,
      isModalVisible: false,
    }),
    [handleCreateTicket.rejected]: (state) => ({ ...state, isCreateInProgress: false }),
  },
});

export const {
  setNewTicketModalVisible,
  replaceTicket,
  clearState,
  setTicketsCount,
  replaceTicketInList,
} = ticketsSlice.actions;

export default ticketsSlice.reducer;
