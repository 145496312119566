import { STORE_ORGANIZATION } from './constants';
import { parseOrganizationFromEngine } from '../../helpers/organization';

const initialState = {
  ...parseOrganizationFromEngine(),
};

function storeOrganizationHandler(state, action) {
  return { ...state, ...parseOrganizationFromEngine(action.data) };
}

const ACTION_HANDLERS = {
  [STORE_ORGANIZATION]: storeOrganizationHandler,
};

export default function organizationReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
