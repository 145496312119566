import Cookies from 'js-cookie';

import { CREATE_USER_SESSION, DESTROY_USER_SESSION } from '../../state/session/constants';

const sessionMiddleware = () => (next) => (action) => {
  if (action.type === CREATE_USER_SESSION) {
    localStorage.setItem('session', JSON.stringify(action.payload));
  } else if (action.type === DESTROY_USER_SESSION) {
    localStorage.removeItem('session');
    Cookies.remove(process.env.APP_ID, { domain: '.spiro.ai', path: '/', secure: true });
  }
  next(action);
};

export default sessionMiddleware;
