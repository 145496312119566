import {
  OPPORTUNITY_CONTACT_ROLES_REQUEST_STARTED,
  OPPORTUNITY_CONTACT_ROLES_REQUEST_SUCCESS,
} from './constants';
import { nullMeta } from '../../helpers/meta';

const initialState = {
  isLoading: false,
  data: [],
  meta: nullMeta,
  params: {
    page: 1,
    per_page: 10,
  },
};

function requestStartedHandler(state) {
  return {
    ...state,
    isLoading: true,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    isLoading: false,
    data: action.data,
    meta: action.meta,
    params: action.params,
  };
}

const ACTION_HANDLERS = {
  [OPPORTUNITY_CONTACT_ROLES_REQUEST_STARTED]: requestStartedHandler,
  [OPPORTUNITY_CONTACT_ROLES_REQUEST_SUCCESS]: requestSuccessHandler,
};

export default function opportunityContactRolesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
