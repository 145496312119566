export const FETCHING_CURRENCIES = 'currencies: fetch_currencies';
export const FETCHING_CURRENCIES_SUCCESS = 'currencies: fetch_currencies_success';
export const FETCHING_CURRENCIES_FAILED = 'currencies: fetch_currencies_failed';
export const STORE_CURRENCIES = 'currencies: store_currencies';

export default {
  FETCHING_CURRENCIES,
  FETCHING_CURRENCIES_SUCCESS,
  FETCHING_CURRENCIES_FAILED,
  STORE_CURRENCIES,
};
