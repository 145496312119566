import { Box, Fade } from '@mui/material';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import { ReactComponent as SpiroLogo } from '../../../images/spiro-logo.svg';

import styles from './ChatWelcomeMessage.module.scss';

export default function ChatWelcomeMessage({ isVisible }) {
  return (
    <Fade in={isVisible} timeout={300}>
      <Box className={styles.container}>
        <SpiroLogo className={styles.logo} />
        <Typography variant="h5" className={styles.message}>
          Ask Spiro about anything
        </Typography>
      </Box>
    </Fade>
  );
}

ChatWelcomeMessage.propTypes = {
  isVisible: PropTypes.bool.isRequired,
};
