import pick from 'lodash/pick';
import sortBy from 'lodash/sortBy';

import { parse as opportunityParse, sortStages } from './opportunity-stages';

export function blank() {
  return {
    id: null,
    name: '',
    isActive: true,
  };
}

function setPipelineIdForStages(stages, pipelineId) {
  if (!stages) return [];
  return sortBy(stages, 'index').map((item) =>
    opportunityParse({
      ...item,
      pipeline_id: pipelineId,
    })
  );
}

export function parse(data = {}) {
  return {
    ...blank(),
    id: data.id,
    name: data.name,
    index: data.index,
    isActive: data.is_active,
    opportunityStages: setPipelineIdForStages(data.opportunity_stages, data.id),
  };
}

export function parseAll(data = []) {
  return data.map((item) => parse(item));
}

export function serialize(data, keys) {
  const s = {
    name: data.name,
    is_active: data.isActive,
  };

  if (keys) {
    return pick(s, keys);
  }
  return s;
}

export function getDefaultPipeline(pipelines) {
  return pipelines.find((pipeline) => pipeline.name === 'default') || pipelines[0];
}

export function mapPipelinesOptions(pipelines) {
  return pipelines.map((pipeline) => ({ value: pipeline.id, label: pipeline.name }));
}

export function getPayloadForUpdate(pipelines, pipelineId) {
  const pipeline = pipelines.find((p) => p.id === parseInt(pipelineId, 10)) || {
    opportunityStages: [],
  };

  return {
    pipeline_id: pipelineId,
    opportunity_stage_id: pipeline.opportunityStages[0] ? pipeline.opportunityStages[0].id : '',
  };
}

export function getSalesStagesFromPipelines(pipelines) {
  const stages = [];
  pipelines.forEach((pipeline) => {
    pipeline.opportunityStages.forEach((stage) => stages.push(stage));
  });

  return sortStages(stages);
}

export function getPipelineOpportunityStages(pipelines, pipelineId) {
  const pipeline = pipelines.find((p) => p.id === pipelineId);
  return pipeline ? pipeline.opportunityStages : [];
}

export default {
  blank,
  parse,
  parseAll,
  serialize,
};
