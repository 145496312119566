import PropTypes from 'prop-types';

import SpiroTabs from 'lib/Tabs';

import styles from './Tabs.module.scss';

function Tabs({ children, ...props }) {
  return (
    <SpiroTabs {...props} className={styles.tabs}>
      {children}
    </SpiroTabs>
  );
}

Tabs.propTypes = {
  children: PropTypes.array.isRequired,
};

export default Tabs;
