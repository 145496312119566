import axios from 'axios';

import { parametrizeUrl } from '../../helpers/URLHelper';

const api = axios.create({
  withCredentials: true,
});

const createParams = (params) => {
  const newParams = {
    back_to: params.redirect_to || '',
    client: process.env.APP_ID,
    device: 'web-edition',
    support_desk_id: params.support_desk_id,
  };
  if (!newParams.support_desk_id) delete newParams.support_desk_id;

  return newParams;
};

function openProviderLogin(relativeURL, params) {
  window.location = parametrizeUrl(
    `${process.env.ENGINE_BASE_URL}${relativeURL}`,
    createParams(params)
  );
}

export function initGoogleAuth(params = {}) {
  openProviderLogin('/login/gmail', params);
}

export function initMicrosoftAuth(params = {}) {
  openProviderLogin('/login/microsoft', params);
}

export function initSlackAuth(params) {
  window.location = parametrizeUrl(`${process.env.ENGINE_BASE_URL}/login/slack`, params);
}

export function openLoginUrl(url, params = {}) {
  window.location = parametrizeUrl(url, createParams(params));
}

export function requestNewTokenFromEngine(payload) {
  return api
    .post(`${process.env.ENGINE_BASE_URL}/oauth/token`, payload, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response));
}

export function requestOneTimeInvitation(token) {
  const payload = {
    invitation: {
      token,
    },
  };
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  return api
    .post(`${process.env.ENGINE_BASE_URL}/one_time_invitations`, payload, { headers })
    .then((res) => res.data)
    .catch((err) => Promise.reject(err.response));
}

export function checkUserPresence(username) {
  return api
    .post(
      `${process.env.ENGINE_BASE_URL}/user_lookup`,
      {
        user: {
          username,
        },
      },
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    )
    .then((res) => Promise.resolve(res.data))
    .catch((err) => Promise.reject(err.response));
}

export function revokeToken(token) {
  const payload = {
    token,
    client_id: process.env.APP_ID,
    client_secret: process.env.APP_SECRET,
  };
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    HTTP_AUTHORIZATION: btoa(`${process.env.APP_ID}:${process.env.APP_SECRET}`),
  };

  return api
    .post(`${process.env.ENGINE_BASE_URL}/oauth/revoke`, payload, { headers })
    .then((response) => response.data)
    .catch((errors) => errors.data);
}

export default {
  initGoogleAuth,
  initMicrosoftAuth,
  requestNewTokenFromEngine,
};
