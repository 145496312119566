import EngineService from '../core/engine-service';

const ENDPOINT = '/currency_options';

// GET: /display_fields,
export function getCurrencies() {
  return EngineService.get(`${ENDPOINT}`).then((json) => json);
}

export default {
  getCurrencies,
};
