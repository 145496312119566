import EngineService from '../core/engine-service';

const apiEndpoint = '/activities';

// POST: /activities
export async function create(payload) {
  const json = await EngineService.post(apiEndpoint, { activity: payload });
  return json;
}

// PATCH: /activities/123
export async function update(activityId, payload) {
  const json = await EngineService.patch(`${apiEndpoint}/${activityId}`, { activity: payload });
  return json;
}

// DELETE: /activities/123
export async function destroy(activityId) {
  const json = await EngineService.delete(`${apiEndpoint}/${activityId}`);
  return json;
}

export default {
  create,
  update,
  delete: destroy,
};
