import EngineService from '../core/engine-service';

export async function loginWithConnectedUser(userId) {
  const payload = {
    user_id: userId,
    client_id: process.env.APP_ID,
  };

  try {
    const json = await EngineService.post('/connected_users_login', payload, null, null, {
      withCredentials: true,
    });
    return Promise.resolve(json);
  } catch (err) {
    return Promise.reject(err);
  }
}

export default {
  loginWithConnectedUser,
};
