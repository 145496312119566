import camelCase from 'lodash/camelCase';
import capitalize from 'lodash/capitalize';
import isObject from 'lodash/isObject';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import orderBy from 'lodash/orderBy';

import { resourceTypes } from './utils';
import { FIELD_TYPES } from '../constants';

export const defaultResourceTypes = [
  resourceTypes.OPPORTUNITY,
  resourceTypes.CONTACT,
  resourceTypes.ACCOUNT,
];

export const spiroEntities = [
  'Activity',
  'Interaction',
  'Notification',
  'Navigation',
  'User',
  'Reminder',
  'Account',
  'Contact',
  'Opportunity',
  'Ticket',
  'Quote',
  'Product',
  'SalesOrderSummary',
  'SalesOrder',
  'SalesOrderItem',
];

const configurationsTypes = {
  unit_type_id: 'unit_types',
  category_id: 'categories',
  billing_frequency_id: 'billing_frequencies',
};

export function formatName(name) {
  if (name.startsWith('custom__')) {
    return name
      .substring(8)
      .split('_')
      .map((word) => capitalize(word))
      .join(' ');
  }

  return name
    .split('_')
    .map((word) => capitalize(word))
    .join(' ');
}

export function isCustomField(displayField) {
  return displayField.startsWith('custom__');
}

export function isReadOnly(displayField) {
  return displayField.readonly;
}

export function isFieldEditable(field, userIsAdmin) {
  if (field.readonly) return false;
  if (userIsAdmin) return true;
  return field.edit;
}

export function isFieldEditableForProducts(field, userIsAdmin, nonAdminsAllowedToEdit) {
  if (isFieldEditable(field, userIsAdmin)) {
    if (userIsAdmin && field.edit) return true;
    return nonAdminsAllowedToEdit;
  }
  return false;
}

export function sortDisplayFields(displayFields, order = 'asc') {
  return orderBy(displayFields, (displayField) => parseInt(displayField.order, 10), [order]);
}

export function filterVisibleDisplayFields(displayFields) {
  return displayFields.filter((displayField) => displayField.visible);
}

export function addIndexProperty(displayFields) {
  return displayFields.map((displayField) => ({
    ...displayField,
    index: parseInt(displayField.order, 10),
  }));
}

export function parse(displayField, name) {
  return {
    ...displayField,
    create: displayField.create === '1',
    details: displayField.details === '1',
    edit: displayField.edit === '1',
    label: displayField.label,
    list: displayField.list === '1',
    order: parseInt(displayField.order, 10),
    readonly: displayField.readonly === '1',
    removable: displayField.removable === '1',
    sortable: displayField.sortable === '1',
    custom: name.startsWith('custom__'),
  };
}

export function parseAll(data = []) {
  return data.map((item) => parse(item));
}

export function displayFieldsToArray(displayFields = {}) {
  if (displayFields && isObject(displayFields)) {
    return Object.entries(displayFields).map((f) => ({
      name: f[0],
      ...f[1],
    }));
  }
  return [];
}

export function isCustomResource(resource) {
  return !defaultResourceTypes.includes(resource);
}

export function displayFieldsToObject(displayFieldsArray = [], selectedResource) {
  return {
    [selectedResource]: keyBy(displayFieldsArray, 'name'),
  };
}

// TODO: Unused => Remove
export function convertValuesToString(displayFields) {
  return mapValues(displayFields, (field) =>
    mapValues(field, (value, property) => {
      if (
        ['create', 'details', 'list', 'readonly', 'removable', 'sortable', 'edit'].includes(
          property
        )
      ) {
        return value ? '1' : '0';
      }
      return value;
    })
  );
}

export function serializeDisplayFields(fields) {
  return fields.reduce((acc, field) => {
    acc[field.name] = {
      id: field.id,
      type: field.type,
      label: field.label,
      order: field.order,
      sortable: field.sortable ? '1' : '0',
      removable: field.removable ? '1' : '0',
      readonly: field.readonly ? '1' : '0',
      create: field.create ? '1' : '0',
      edit: field.edit ? '1' : '0',
      list: field.list ? '1' : '0',
      details: field.details ? '1' : '0',
    };

    return acc;
  }, {});
}

export function displayFieldsToGridColumns(displayFields, config = null) {
  return displayFields.map((df) => ({
    name: df.name,
    label: df.label,
    sortable: config ? config.sortable : df.sortable,
    editable: df.edit,
  }));
}

export function removeNamePrefix(displayFieldName) {
  if (!displayFieldName.startsWith('custom__')) return displayFieldName;
  return displayFieldName.substring(8);
}

export function getValue(displayField, resource) {
  return isCustomField(displayField.name)
    ? resource?.custom[removeNamePrefix(displayField.name)]
    : resource[displayField.name];
}

export function getValueCamelCase(displayField, resource) {
  return isCustomField(displayField.name)
    ? resource?.custom[removeNamePrefix(displayField.name)]
    : resource[camelCase(displayField.name)];
}

function renderOnValueMatchesCondition(renderOnValue, conditionValue) {
  if (!renderOnValue) return false;

  const conditionValueArray = `${conditionValue}`.split(',');
  const renderOnValueArray = renderOnValue.split(',');
  let matched = false;
  conditionValueArray.forEach((value) => {
    if (renderOnValueArray.includes(value)) {
      matched = true;
    }
  });
  return matched;
}

export function getOptions(displayField, customFields, formData = {}, withPrefix = false) {
  if (!isCustomField(displayField.name)) return [];

  const customField = customFields[removeNamePrefix(displayField.name)];

  if (!customField) return [];

  const options = customField.value;

  if (!options) return [];

  const optionsWithConditionMet = options.filter((option) => {
    const renderOn = option.render_on ? Object.keys(option.render_on)[0] : undefined;

    if (!renderOn) return false;

    let renderOnValue = '';
    if (renderOn === 'opportunity_stage_id' || renderOn === 'pipeline_id') {
      renderOnValue = formData[renderOn].toString();
    } else {
      renderOnValue = formData[withPrefix ? `custom__${renderOn}` : renderOn];
    }

    return renderOnValueMatchesCondition(renderOnValue, option.render_on[renderOn]);
  });

  if (optionsWithConditionMet.length > 0) return optionsWithConditionMet;
  return options.filter((option) => !option.render_on);
}

export function getOptionsIncludingProductSelectFields(
  displayField,
  customFields,
  configurations,
  formData = {},
  withPrefix = false
) {
  if (displayField.name === 'taxable') {
    return [
      { value: 'true', label: 'Yes' },
      { value: 'false', label: 'No' },
    ];
  }
  if (displayField.name === 'status') {
    return [
      { value: 'active', label: 'Active' },
      { value: 'inactive', label: 'Inactive' },
    ];
  }
  if (['unit_type_id', 'category_id', 'billing_frequency_id'].includes(displayField.name)) {
    return configurations[configurationsTypes[displayField.name]];
  }
  return getOptions(displayField, customFields, formData, withPrefix);
}

export function getCOOptions(displayField, customFields, formData = {}) {
  const customField = customFields[displayField.name];
  const options = customField.value;

  if (!options) return [];

  const optionsWithConditionMet = options.filter((option) => {
    const renderOn = option.render_on ? Object.keys(option.render_on)[0] : undefined;

    if (!renderOn) return false;

    const renderOnValue = formData[renderOn];
    return renderOnValueMatchesCondition(renderOnValue, option.render_on[renderOn]);
  });

  if (optionsWithConditionMet.length > 0) return optionsWithConditionMet;
  return options.filter((option) => !option.render_on);
}

export function getCFOptions(customField, formData = {}) {
  const options = customField.value;

  if (!options) return [];

  const optionsWithConditionMet = options.filter((option) => {
    const renderOn = option.render_on ? Object.keys(option.render_on)[0] : undefined;

    if (!renderOn) return false;

    const renderOnValue = formData[renderOn];
    return renderOnValueMatchesCondition(renderOnValue, option.render_on[renderOn]);
  });

  if (optionsWithConditionMet.length > 0) return optionsWithConditionMet;
  return options.filter((option) => !option.render_on);
}

export function getPayloadForUpdate(displayField, value) {
  return isCustomField(displayField.name)
    ? { custom: { [removeNamePrefix(displayField.name)]: value } }
    : { [displayField.name]: value };
}

export function selectorValues(displayFields) {
  return Object.keys(displayFields)
    .filter(
      (key) =>
        !['Activity', 'Interaction', 'Notification', 'Navigation', 'User', 'Reminder'].includes(key)
    )
    .sort()
    .map((key) => {
      if (key === 'Account') {
        return {
          value: key,
          label: 'Company',
        };
      }

      return {
        value: key,
        label: key,
      };
    });
}

export function displayFieldsToFormValues(displayFields, cb = undefined) {
  const formData = {};
  displayFields.forEach((df) => {
    if (cb) {
      formData[df.name] = cb(df);
      return;
    }
    formData[df.name] = '';
  });
  return formData;
}

export function getPhoneType(fieldName) {
  return (
    {
      phone: 'work',
      home_phone: 'home',
      mobile_phone: 'mobile',
    }[fieldName] || ''
  );
}

export function isTextOrMultipleSelect(field) {
  return field.type === 'Text' || field.type === 'MultipleSelect';
}

export function isLookup(field) {
  return [
    FIELD_TYPES.AccountLookup,
    FIELD_TYPES.ContactLookup,
    FIELD_TYPES.UserLookup,
    FIELD_TYPES.OpportunityLookup,
    FIELD_TYPES.CustomObjectLookup,
  ].includes(field.type);
}
