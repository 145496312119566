export const FETCHING_SMS_TEMPLATES = 'SMS_TEMPLATES_SETTINGS:FETCHING';
export const SMS_TEMPLATES_FETCHED = 'SMS_TEMPLATES_SETTINGS:FETCHED';
export const FETCHING_SMS_TEMPLATES_FAILED = 'SMS_TEMPLATES_SETTINGS:FETCHING_FAILED';
export const STORE_SMS_TEMPLATES = 'SMS_TEMPLATES_SETTINGS:STORE_TEMPLATES';
export const STORE_SMS_TEMPLATE = 'SMS_TEMPLATES_SETTINGS:STORE_TEMPLATE';
export const REMOVE_SMS_TEMPLATE = 'SMS_TEMPLATES_SETTINGS:REMOVE_TEMPLATE';
export const SELECT_SMS_TEMPLATE = 'SMS_TEMPLATES_SETTINGS:SELECT_TEMPLATE';

export default {
  FETCHING_SMS_TEMPLATES,
  SMS_TEMPLATES_FETCHED,
  FETCHING_SMS_TEMPLATES_FAILED,
  STORE_SMS_TEMPLATE,
  STORE_SMS_TEMPLATES,
  REMOVE_SMS_TEMPLATE,
  SELECT_SMS_TEMPLATE,
};
