import WarningIcon from '@mui/icons-material/Warning';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';

import CallerInfoImage from './CallerInfoImage';
import CallerInfoText from './CallerInfoText';
import CallDurationTimer from '../../CallDurationTimer';

import styles from './CallerInfo.module.scss';

export default function CallerInfo({
  mainTextColor = 'white',
  subTextColor = 'white',
  fullInfo = true,
  isBadNetwork = false,
}) {
  return (
    <>
      <Box className={fullInfo ? styles['full-caller-info'] : styles['caller-info']}>
        <CallerInfoImage />
        <CallerInfoText
          fullInfo={fullInfo}
          mainTextColor={mainTextColor}
          subTextColor={subTextColor}
        />
      </Box>
      {!fullInfo && (
        <Box className={styles.timer}>
          <CallDurationTimer isLarge />
          {isBadNetwork && (
            <Box className={styles['poor-connection']}>
              <WarningIcon fontSize="small" />
              <span>Poor connection...</span>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

CallerInfo.propTypes = {
  mainTextColor: PropTypes.string,
  subTextColor: PropTypes.string,
  fullInfo: PropTypes.bool,
  isBadNetwork: PropTypes.bool,
};
