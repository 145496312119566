import PropTypes from 'prop-types';

import Button from 'lib/Button';

import SearchGroupHeader from '../SearchGroupHeader';
import { useSearch } from '../SearchProvider';
import SearchResult from '../SearchResult';

import styles from './SearchGroup.module.scss';

export default function SearchGroup({ type, items }) {
  const { params, setParams } = useSearch();

  const handleShowMore = () => {
    setParams({ per_page: 50, page: 1, only: type, include: undefined });
  };

  return (
    <div className={styles['search-group']}>
      <SearchGroupHeader title={type} />
      <ul>
        {items.map((item) => (
          <li key={item.id}>
            <SearchResult result={item} />
          </li>
        ))}
      </ul>
      {!params?.only && <Button onClick={handleShowMore}>Show more</Button>}
    </div>
  );
}

SearchGroup.propTypes = {
  type: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
};
