import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectAddons } from 'state/user/selectors';

import UnauthorizedAccess from '../UnauthorizedAccess';

export default function AddonsOnly({ children, addonName, displayFallback }) {
  const addons = useSelector(selectAddons);
  const isAddonEnabled = addons.find((addon) => addon.name === addonName);

  if (isAddonEnabled) return children;

  return displayFallback ? <UnauthorizedAccess /> : null;
}

AddonsOnly.defaultProps = {
  displayFallback: false,
};

AddonsOnly.propTypes = {
  children: PropTypes.node.isRequired,
  displayFallback: PropTypes.bool,
  addonName: PropTypes.string.isRequired,
};
