import AttachmentContextProvider from 'components/AttachmentGridCell/AttachmentContext/AttachmentContextProvider';

import AttachmentField from './AttachmentField';

export default function AttachmentFieldContainer({ ...props }) {
  return (
    <AttachmentContextProvider>
      <AttachmentField {...props} />
    </AttachmentContextProvider>
  );
}
