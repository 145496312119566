import EngineService from '../core/engine-service';

const apiEndpoint = '/interactions';

export function all(params = {}) {
  return EngineService.get(apiEndpoint, params).then((json) => json);
}

export function getInteractionById(id, params = {}) {
  return EngineService.get(`${apiEndpoint}/${id}`, params).then((json) => json);
}

export function getChildInteractions(id, params = {}) {
  return EngineService.get(`${apiEndpoint}/${id}/children`, params).then((json) => json);
}

export function destroy(id) {
  return EngineService.delete(`${apiEndpoint}/${id}`).then((json) => json);
}

export function getTranscriptions(interactionId) {
  return EngineService.get(`${apiEndpoint}/${interactionId}/google_transcriptions`).then(
    (json) => json.google_transcription
  );
}

export function getSummary(interactionId) {
  return EngineService.get(`${apiEndpoint}/${interactionId}/nlp_summaries`).then((json) => json);
}

export function getIntents(interactionId) {
  return EngineService.get(`${apiEndpoint}/${interactionId}/nlp_actions`).then((json) => json);
}

export function updateIntent(actionId, payload) {
  return EngineService.patch(`/nlp_actions/${actionId}`, payload).then((json) => json);
}

export function getFilterProperties(params = {}) {
  return EngineService.get('/filters/interactions/columns', params).then((json) => json);
}

export default {
  all,
  delete: destroy,
};
