import PropTypes from 'prop-types';

import styles from './SearchGroupHeader.module.scss';

export const pluralizeText = (text) =>
  ({
    Account: 'Companies',
    Company: 'Companies',
    Contact: 'Contacts',
    Opportunity: 'Opportunities',
    Interaction: 'Interactions',
    Quote: 'Quotes',
    Ticket: 'Tickets',
    CollaborationNote: 'Collaboration Note',
  })[text] || '';

export default function SearchGroupHeader({ title }) {
  return (
    <div className={styles['search-group-header']}>
      <h6 className={styles.title}>{pluralizeText(title)}</h6>
    </div>
  );
}

SearchGroupHeader.propTypes = {
  title: PropTypes.string.isRequired,
};
