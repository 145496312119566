const initialState = {
  fetching: false,
  failed: false,
  fetched: false,
  callsLastWeekCount: null,
  contactedLastWeekCount: null,
  currentMonthBookingsSum: null,
  currentPipelineSum: null,
  meetingsLastWeekCount: null,
  notContactedLastWeekCount: null,
  openedOpportunitiesCount: null,
  sentEmailsLastWeekCount: null,
  winRate: null,
};

export default function statsReducer(state = initialState, action) {
  switch (action.type) {
    case 'FETCHING_STATS':
      return { ...state, fetching: true, fetched: false };
    case 'STATS_FETCHED':
      return {
        ...state,
        fetching: false,
        failed: false,
        fetched: true,
        currentMonthBookingsSum: parseFloat(action.data.current_month_bookings_sum || 0),
        currentPipelineSum: parseFloat(action.data.current_pipeline_sum || 0),
        monthlyQuota: parseFloat(action.data.monthly_quota || 0),
        newOpportunitiesCurrentMonthCount: parseFloat(
          action.data.new_opportunities_current_month_count || 0
        ),
        openedOpportunitiesCount: parseFloat(action.data.opened_opportunities_count || 0),
        winRate: parseFloat(action.data.win_rate || 0),
        pastDueReminders: action.data.past_due_reminders,
        totalCalls: action.data.today_calls,
        totalConnectedCalls: action.data.today_connected_calls,
        todayEvents: action.data.today_events,
        todayOpenReminders: action.data.today_open_reminders,
        todayTalkTimeMinutes: action.data.today_talk_time_minutes,
      };
    case 'FETCHING_STATS_FAILED':
      return { ...state, fetching: false, failed: true };
    default:
      return state;
  }
}
