import PersonOffOutlinedIcon from '@mui/icons-material/PersonOffOutlined';
import Avatar from '@mui/material/Avatar';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { convertNameToInitials } from 'helpers/utils';

import { selectTwilioCaller } from '../state/selectors';

import styles from './InCallContactDetails.module.scss';

function InCallContactDetails({ title = '', subheader = '', avatarImage = null }) {
  const caller = useSelector(selectTwilioCaller);

  const getInitials = () => {
    if (typeof title !== 'string' && caller) return convertNameToInitials(caller.name);
    if (typeof title !== 'string' && !caller) return <PersonOffOutlinedIcon />;
    return convertNameToInitials(title);
  };

  const avatar = (
    <Avatar className={styles.avatar} src={avatarImage}>
      {getInitials()}
    </Avatar>
  );

  return (
    <div className={styles.container}>
      <Card elevation={0}>
        <CardHeader
          className={styles.header}
          avatar={avatar}
          title={title}
          subheader={subheader}
          titleTypographyProps={{ noWrap: true }}
          classes={{ content: styles.content, title: styles.title }}
        />
      </Card>
    </div>
  );
}

InCallContactDetails.propTypes = {
  title: PropTypes.string,
  subheader: PropTypes.string,
  avatarImage: PropTypes.node,
};

export default InCallContactDetails;
