import EngineService from '../core/engine-service';

const ENDPOINT = '/sales_orders';

export function getOrders(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function getOrder(orderId, params = {}) {
  return EngineService.get(`${ENDPOINT}/${orderId}`, params).then((json) => json);
}

export function deleteOrder(orderId) {
  return EngineService.delete(`${ENDPOINT}/${orderId}`);
}

export function updateOrder(orderId, payload) {
  return EngineService.patch(`${ENDPOINT}/${orderId}`, { sales_order: payload }).then(
    (json) => json
  );
}

export function getOrderFilterProperties(params = {}) {
  return EngineService.get('/filters/sales_orders/columns', params).then((json) => json);
}

export function getOrderItems(orderId, params = {}) {
  return EngineService.get(`${ENDPOINT}/${orderId}/sales_order_items`, params).then((json) => json);
}

export function getOrderItem(orderId, orderItemId, params = {}) {
  return EngineService.get(`${ENDPOINT}/${orderId}/sales_order_items/${orderItemId}`, params).then(
    (json) => json
  );
}

export function updateOrderItem(orderId, orderItemId, payload) {
  return EngineService.patch(`${ENDPOINT}/${orderId}/sales_order_items/${orderItemId}`, {
    sales_order_item: payload,
  }).then((json) => json);
}

export function getOrdersCount(params = {}) {
  return EngineService.get(`${ENDPOINT}/count`, params);
}
