import { createSelector } from '@reduxjs/toolkit';

import { filterExchangeCredential, filterImapCredential } from 'helpers/credentials';

export function selectCredentials(state) {
  return state.credentials.items;
}

export const selectExchangeCredential = createSelector([selectCredentials], (credentials) =>
  filterExchangeCredential(credentials)
);

export const selectImapCredential = createSelector([selectCredentials], (credentials) =>
  filterImapCredential(credentials)
);

export const selectIsDeleting = (state) => state.credentials.isDeleting;
