import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';

import { removePhoneNumberFormatting } from 'helpers/twilio';
import useCall from 'lib/CallButton/useCall';
import Phone from 'lib/Phone';

import Dialpad from '../Dialpad';
import { setPhoneNumber as setPhone } from '../state/actions';

import styles from './MakeACallScreen.module.scss';

export default function MakeACallScreen() {
  const [phoneNumber, setPhoneNumber] = useState('+1');
  const dispatch = useDispatch();
  const startCall = useCall();

  const onChange = (number) => {
    setPhoneNumber(number);
  };

  const onBackspaceClick = () => {
    if (phoneNumber) {
      setPhoneNumber(phoneNumber.slice(0, -1));
    }
  };

  const onKeyClick = (value) => {
    setPhoneNumber(`${phoneNumber}${value}`);
  };

  useEffect(() => {
    dispatch(setPhone(phoneNumber));
  }, [phoneNumber]);

  const startCallOnEnter = (e) => {
    if (e.key === 'Enter') startCall(removePhoneNumberFormatting(phoneNumber));
  };

  return (
    <>
      <div className={styles.header}>
        <Typography variant="h5">Phone</Typography>
      </div>
      <Box className={styles['make-call-dialog']}>
        <Grid container direction="column" alignItems="center" justifyContent="flex-end">
          <Grid item className={styles['phone-input']}>
            <Phone
              value={phoneNumber}
              defaultCountry="us"
              onChange={onChange}
              onKeyPress={startCallOnEnter}
            />
          </Grid>
          <Grid item className={styles.icon}>
            <Dialpad onKeyClick={onKeyClick} handleDelete={onBackspaceClick} />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
