import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    debug: process.env.NODE_ENV === 'development',
    fallbackLng: 'en',
    backend: {
      loadPath: `${process.env.ENGINE_BASE_URL}/localizations/{{lng}}`,
      init: {
        mode: 'no-cors',
        cache: 'default',
      },
    },
    react: {
      wait: true,
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
