import EngineService from '../core/engine-service';

const endpoints = {
  MISSED_CALLS: '/twilio/missed_calls',
};

const twilioService = {
  apiEndpoint: '/twilio',
  messageEndpoint: '/twilio/messages',
  missedCallsEndpoint: '/twilio/missed_calls',
  callersEndpoint: '/twilio/multiple_callers',
  callLogs: '/twilio/call_logs',
  activitiesEndpoint: '/twilio/activities',

  // POST: /twilio/tokens
  createToken() {
    return EngineService.post(`${this.apiEndpoint}/tokens`).then((json) => json);
  },

  // GET: /twilio/missed_calls
  getMissedCalls(params = {}) {
    return EngineService.get(endpoints.MISSED_CALLS, params).then((json) => json);
  },

  // GET: /twilio/call_logs/:sid
  getMissedCallNumber(sid) {
    return EngineService.get(`${this.callLogs}/${sid}`).then((json) => json);
  },

  // PUT: /twilio/missed_calls
  batchUpdateMissedCalls(payload) {
    return EngineService.put(`${endpoints.MISSED_CALLS}/batch_update`, payload).then(
      (json) => json
    );
  },

  // GET: /twilio/messages
  allMessages(params = {}) {
    return EngineService.get(this.messageEndpoint, params).then((json) => json);
  },

  // GET: /twilio/messages/unread
  getUnreadMessages(params = {}) {
    return EngineService.get(`${this.messageEndpoint}/unread`, params).then((json) => json);
  },

  // POST: /twilio/messages
  createMessage(payload) {
    return EngineService.post(this.messageEndpoint, { message: payload }).then((json) => json);
  },

  // PATCH: /twilio/messages/:id
  updateMessage(id, payload) {
    return EngineService.patch(`${this.messageEndpoint}/${id}`, { message: payload }).then(
      (json) => json
    );
  },

  // PUT: /twilio/messages/batch_update
  batchUpdateMessages(payload) {
    return EngineService.put(`${this.messageEndpoint}/batch_update`, payload).then((json) => json);
  },

  verifyPhone(payload) {
    return EngineService.post(`${this.apiEndpoint}/phones`, payload).then((json) => json);
  },

  getCallers(params) {
    return EngineService.get(`${this.callersEndpoint}`, params).then((json) => json);
  },
};

// POST: /twilio/activities
export function createActivity(payload) {
  return EngineService.post('/twilio/activities', { activity: payload }).then((json) => json);
}

export function getBlockedPhones(params) {
  return EngineService.get(`${twilioService.apiEndpoint}/blocked_numbers`, params).then(
    (json) => json
  );
}

export function createBlockedPhone(payload) {
  return EngineService.post(`${twilioService.apiEndpoint}/blocked_numbers`, payload).then(
    (json) => json
  );
}

export function deleteBlockedPhone(phoneNumberId) {
  return EngineService.delete(`${twilioService.apiEndpoint}/blocked_numbers/${phoneNumberId}`).then(
    (json) => json
  );
}

export function getTransferNumbers() {
  return EngineService.get(`${twilioService.apiEndpoint}/available_transfer_numbers`).then(
    (json) => json
  );
}

export function transferCall(payload) {
  return EngineService.post(`${twilioService.apiEndpoint}/transfer_calls`, payload).then(
    (json) => json
  );
}

export function getAvailableNumbers(params = {}) {
  return EngineService.get(`${twilioService.apiEndpoint}/numbers`, params).then((json) => json);
}

export function assignedNumberToUser(payload) {
  return EngineService.post(`${twilioService.apiEndpoint}/numbers`, payload).then((json) => json);
}

export default twilioService;
