import { useCallback, useRef } from 'react';

export default function useInfiniteScroll(loading, meta, fetchData) {
  const observer = useRef();

  const handleRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && meta.nextPage) {
          fetchData({ page: typeof meta.nextPage === 'number' ? meta.nextPage : null });
        }
      });
      if (node) observer.current.observe(node);
    },
    [loading, meta]
  );

  return { handleRef };
}
