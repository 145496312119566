import { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { loadStateFromLocalStorage, saveStateToLocalStorage } from 'app/helpers/utils';
import useTabs from 'hooks/useTabs';
import {
  selectUnreadGeneralNotificationsCount,
  selectUnreadPriorityNotificationsCount,
} from 'state/user/selectors';

const useNotificationsFilters = (fetchNotifications, clearState) => {
  const unreadGeneralNotificationsCount = useSelector(selectUnreadGeneralNotificationsCount);
  const unreadPriorityNotificationsCount = useSelector(selectUnreadPriorityNotificationsCount);

  const [selectedTab, selectTab] = useTabs(loadStateFromLocalStorage('alertsSelectedTab', 0));

  const [priorityChips, setPriorityChips] = useState(() =>
    loadStateFromLocalStorage('alertsPriorityFilters', [
      { id: 1, label: 'Assistant Rule', value: 'assistant_rules', disabled: false },
      { id: 2, label: 'Mentions', value: 'mentions', disabled: false },
      { id: 3, label: 'Reminders', value: 'reminders', disabled: false },
      { id: 4, label: 'Summaries', value: 'summaries', disabled: false },
    ])
  );

  const [generalChips, setGeneralChips] = useState(() =>
    loadStateFromLocalStorage('alertsGeneralFilters', [
      { id: 1, label: 'AI Updates', value: 'ai_updates', disabled: false },
      { id: 2, label: 'Import', value: 'import', disabled: false },
      { id: 3, label: 'Following', value: 'following', disabled: false },
    ])
  );

  const priorityChipsParams = priorityChips
    .filter((chip) => !chip.disabled)
    .map((i) => i.value)
    .join(',');

  const generalChipsParams = generalChips
    .filter((chip) => !chip.disabled)
    .map((i) => i.value)
    .join(',');

  const toggleChipState = (setChips) => (id) => {
    setChips((prevChips) => {
      const enabledChips = prevChips.filter((chip) => !chip.disabled);

      if (enabledChips.length === 1 && enabledChips[0].id === id) {
        return prevChips;
      }

      return prevChips.map((chip) =>
        chip.id === id ? { ...chip, disabled: !chip.disabled } : chip
      );
    });
  };

  const priorityLabel = (
    <span className="notifications-counter-container">
      Priority
      {unreadPriorityNotificationsCount > 0 && (
        <span className="notifications-counter">
          {unreadPriorityNotificationsCount > 99 ? '99+' : unreadPriorityNotificationsCount}
        </span>
      )}
    </span>
  );

  const generalLabel = (
    <span className="notifications-counter-container">
      General
      {unreadGeneralNotificationsCount > 0 && (
        <span className="notifications-counter">
          {unreadGeneralNotificationsCount > 99 ? '99+' : unreadGeneralNotificationsCount}
        </span>
      )}
    </span>
  );

  useEffect(() => {
    clearState();
    fetchNotifications({
      group: selectedTab === 0 ? 'priority' : 'general',
      subgroup: selectedTab === 0 ? priorityChipsParams : generalChipsParams,
      offset: 0,
    });
  }, [selectedTab, priorityChipsParams, generalChipsParams]);

  useEffect(() => {
    saveStateToLocalStorage('alertsPriorityFilters', priorityChips);
  }, [priorityChips]);

  useEffect(() => {
    saveStateToLocalStorage('alertsGeneralFilters', generalChips);
  }, [generalChips]);

  useEffect(() => {
    saveStateToLocalStorage('alertsSelectedTab', selectedTab);
  }, [selectedTab]);

  return {
    selectedTab,
    priorityChips,
    generalChips,
    selectTab,
    toggleChipState,
    setPriorityChips,
    setGeneralChips,
    priorityLabel,
    generalLabel,
  };
};

export default useNotificationsFilters;
