import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  deleteOrder as deleteOrderRemote,
  getOrder,
  updateOrder as updateOrderRemote,
} from 'services/orders';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../helpers/errorHelper';
import { serializeForm } from '../../../../helpers/formHelper';

export const fetchOrder = createAsyncThunk('order-get', async (orderID, { dispatch }) => {
  try {
    const res = await getOrder(orderID);
    return res.sales_order;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const updateOrder = createAsyncThunk('order-update', async (payload, { dispatch }) => {
  try {
    const res = await updateOrderRemote(payload.id, serializeForm(payload.value));
    return res.sales_order;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const deleteOrder = createAsyncThunk('order-delete', async (orderID, { dispatch }) => {
  try {
    return await deleteOrderRemote(orderID);
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});
