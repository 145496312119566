import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function SmartProductsOnly({ children = null, fallback = null }) {
  const userHasSmartProductsAsAddons = useSelector(
    (state) => !!state.user.addons.find((p) => p.name === 'Smart Quotes')
  );

  if (userHasSmartProductsAsAddons) return children;

  return fallback || null;
}

SmartProductsOnly.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.func,
};

export default SmartProductsOnly;
