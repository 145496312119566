import { useState } from 'react';

import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { generateVCard } from 'app/services/vCards';
import { error as errorAlert } from 'state/notifications/actions';

import { forEachError } from '../../../../helpers/errorHelper';
import styles from '../styles/ConversationsButtonStyles.module.scss';

export default function VCardButton({ setVCard, disabled }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const generateMyVCard = async () => {
    try {
      setLoading(true);
      const res = await generateVCard();
      setVCard(res.vcard);
    } catch (error) {
      forEachError(error.data, (err) => dispatch(errorAlert(err)));
    } finally {
      setLoading(false);
    }
  };

  return (
    <IconButton
      className={loading || disabled ? styles['icon-wrapper-disabled'] : styles['icon-wrapper']}
      onClick={generateMyVCard}
      disabled={loading || disabled}
    >
      <Tooltip
        title="Send my vCard"
        arrow
        classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
      >
        <BadgeOutlinedIcon fontSize="medium" />
      </Tooltip>
    </IconButton>
  );
}

VCardButton.propTypes = {
  setVCard: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};
