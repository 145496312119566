import { useDispatch, useSelector } from 'react-redux';
import { Device } from 'twilio-client';

import {
  minimizeCallModal,
  setActiveTab,
  setConversationScreenOpen,
  setPhoneDialogOpen,
  setupTwilioDevice,
} from 'components/CallDialog/state/actions';
import { selectTwilioEnabled } from 'components/CallDialog/state/selectors';
import useHotjar from 'hooks/useHotjar';

import useNewConversation from '../components/CallDialog/MessagesTab/Conversation/NewConversation/useNewConversation';
import { resetStatePartially } from '../components/CallDialog/MessagesTab/Conversation/state/reducer';

const useConversation = () => {
  const dispatch = useDispatch();
  const isTwilioEnabled = useSelector(selectTwilioEnabled);
  const { getData } = useNewConversation();
  const { logCustomEvent } = useHotjar();

  const startConversation = (params, contact) => {
    dispatch(resetStatePartially());
    dispatch(setConversationScreenOpen(true));
    dispatch(setPhoneDialogOpen(true));
    dispatch(minimizeCallModal(false));
    getData(params, contact);
    dispatch(setActiveTab('Messages'));
    logCustomEvent('phone_modal_opened');
  };

  const showConversation = (params, contact = null) => {
    if (isTwilioEnabled) {
      startConversation(params, contact);
    } else {
      dispatch(setupTwilioDevice()).then(() => {
        Device.on('ready', () => {
          startConversation(params, contact);
        });
      });
    }
  };

  return { showConversation };
};

export default useConversation;
