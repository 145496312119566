import mapValues from 'lodash/mapValues';

import {
  FETCHING_DISPLAY_FIELDS_FAILED,
  FETCHING_DISPLAY_FIELDS_STARTED,
  FETCHING_DISPLAY_FIELDS_SUCCESS,
  REORDER_FIELDS,
  SET_SELECTED_ENTITY,
  STORE_ALL_DISPLAY_FIELDS,
  STORE_DISPLAY_FIELDS,
} from './constants';
import { parse } from '../../helpers/display-fields';
import {
  getDisplayFields as getDisplayFieldsRemote,
  updateDisplayField as updateDisplayFieldRemote,
} from '../../services/display-fields';
import { fetchSpiroViews } from '../views/actions';

export function fetching() {
  return {
    type: FETCHING_DISPLAY_FIELDS_STARTED,
  };
}

export function fetchSuccess(data) {
  return {
    type: FETCHING_DISPLAY_FIELDS_SUCCESS,
    data,
  };
}

export function fetchFailed(data) {
  return {
    type: FETCHING_DISPLAY_FIELDS_FAILED,
    data,
  };
}

export function storeDisplayFields(data) {
  return {
    type: STORE_DISPLAY_FIELDS,
    data: mapValues(data, (resource) => mapValues(resource, (field, key) => parse(field, key))),
    items: mapValues(data, (fields) =>
      Object.keys(fields)
        .sort((a, b) => fields[a].order - fields[b].order)
        .map((field) => ({ name: field, ...parse(fields[field], field) }))
    ),
  };
}

export function storeAllDisplayFields(data) {
  return {
    type: STORE_ALL_DISPLAY_FIELDS,
    data: mapValues(data, (resource) => mapValues(resource, (field, key) => parse(field, key))),
    items: mapValues(data, (fields) =>
      Object.keys(fields)
        .sort((a, b) => fields[a].order - fields[b].order)
        .map((field) => ({ name: field, ...parse(fields[field], field) }))
    ),
  };
}

export function setSelectedEntity(entity) {
  return {
    type: SET_SELECTED_ENTITY,
    entity,
  };
}

export function reorderFields(entity, dragIndex, hoverIndex) {
  return {
    type: REORDER_FIELDS,
    entity,
    dragIndex,
    hoverIndex,
  };
}

export function updateDisplayField(displayField) {
  return (dispatch) => {
    dispatch(fetching());

    return updateDisplayFieldRemote(displayField)
      .then((response) => dispatch(storeDisplayFields(response)))
      .then((r) => {
        dispatch(fetchSuccess(r));
        dispatch(fetchSpiroViews());
      })
      .catch((e) => dispatch(fetchFailed(e)));
  };
}

export function getDisplayFields(params = {}) {
  return (dispatch) => {
    getDisplayFieldsRemote(params).then((response) => dispatch(storeAllDisplayFields(response)));
  };
}

export default {
  fetching,
  fetchSuccess,
  fetchFailed,
  updateDisplayField,
};
