import { useDispatch, useSelector } from 'react-redux';

import {
  minimizeCallModal,
  rejectIncomingCall,
  setModalMinimizedByOtherAction,
  setNoteScreenOpen,
  setPhoneDialogOpen,
} from 'components/CallDialog/state/actions';
import {
  selectIsNoteScreenVisible,
  selectTwilioAccount,
  selectTwilioCaller,
  selectTwilioConnection,
  selectTwilioContact,
} from 'components/CallDialog/state/selectors';
import useHotjar from 'hooks/useHotjar';
import { hideModal, showModal } from 'modals/state/actions';
import { createReminder } from 'state/nested-reminders/actions';
import { success as successAlert } from 'state/notifications/actions';

export default function useCallActions() {
  const dispatch = useDispatch();
  const contact = useSelector(selectTwilioContact);
  const account = useSelector(selectTwilioAccount);
  const caller = useSelector(selectTwilioCaller);
  const connection = useSelector(selectTwilioConnection);
  const isNoteScreenOpen = useSelector(selectIsNoteScreenVisible);
  const { logCustomEvent } = useHotjar();

  const handleMinimizeCallModal = () => {
    dispatch(minimizeCallModal(false));
    dispatch(setPhoneDialogOpen(true));
  };

  const handleHideModal = () => {
    dispatch(hideModal());
    handleMinimizeCallModal();
  };

  const handleReminderSubmit = (params) => {
    dispatch(createReminder(params));
    dispatch(successAlert('Reminder saved!'));
    handleMinimizeCallModal();
    return Promise.resolve();
  };

  const rejectIfCallIsPending = () => {
    if (connection?.status() === 'pending') {
      connection.reject(() => dispatch(rejectIncomingCall()));
      connection.reject();
    }
  };

  const setReminder = () => {
    dispatch(minimizeCallModal(true));
    dispatch(setPhoneDialogOpen(false));
    rejectIfCallIsPending();
    dispatch(
      showModal('ADD_REMINDER_MODAL', {
        show: true,
        parent: caller.resource_type === 'Contact' ? contact : account,
        ownerType: caller.resource_type === 'Contact' ? 'Contact' : 'Account',
        ownerId: caller.resource_id,
        onSubmit: handleReminderSubmit,
        onHide: handleHideModal,
      })
    );
    logCustomEvent('set_reminder_web_phone');
  };
  const sendEmail = () => {
    rejectIfCallIsPending();
    dispatch(showModal('SEND_EMAIL_MODAL', { show: true, contact }));
    dispatch(minimizeCallModal(true));
    dispatch(setPhoneDialogOpen(false));
    dispatch(setModalMinimizedByOtherAction(true));
    logCustomEvent('email_modal_opened_web_phone');
  };

  const contactHasPhoneNumber = !!contact?.mobilePhone || !!contact?.phone || !!contact?.home_phone;

  const toggleNoteScreen = () => {
    dispatch(setNoteScreenOpen(!isNoteScreenOpen));
  };

  return {
    setReminder,
    sendEmail,
    contactHasPhoneNumber,
    contact,
    caller,
    toggleNoteScreen,
  };
}
