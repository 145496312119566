class Person {
  constructor(data) {
    this.id = data.id || '';
    this.firstName = data.first_name || '';
    this.lastName = data.last_name || '';
    this.title = data.title || '';
    this.username = data.username || '';
    this.nickname = data.nickname || '';
    this.gender = data.gender || '';
    this.photoUrl = data.photo_url || '';
    this.phone = data.phone || '';
    this.mobilePhone = data.mobile_phone || '';
    this.email = data.email || '';
    this.region = data.region || '';
    this.countryCode = data.country_code || '';
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  getFullTitle() {
    return `${this.title} ${this.countryCode ? `, ${this.countryCode}` : ''}`;
  }

  getFullNameWithTitle() {
    return `${this.getFullName()} ${this.getFullTitle() ? `, ${this.getFullTitle()}` : ''}`;
  }
}

export default Person;
