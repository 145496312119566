import EngineService from '../core/engine-service';

const ENDPOINT = '/custom_objects';

// GET: /custom_objects
export function getCustomObjects(params = {}) {
  return EngineService.get(`${ENDPOINT}`, params).then((json) => json);
}

// GET: /custom_objects/:id
export function getCustomObject(id) {
  return EngineService.get(`${ENDPOINT}/${id}`).then((json) => json);
}

// GET: /custom_objects/columns
export function getCustomObjectsColumns() {
  return EngineService.get(`/filters${ENDPOINT}/columns`).then((json) => json);
}

// POST: /custom_objects
export function createCustomObject(payload) {
  return EngineService.post(`${ENDPOINT}`, { custom_object: payload }).then((json) => json);
}

// PATCH: /custom_objects/:id
export function updateCustomObject(id, payload) {
  return EngineService.patch(`${ENDPOINT}/${id}`, { custom_object: payload }).then((json) => json);
}

// DELETE: /custom_objects/:id
export function deleteCustomObject(id) {
  return EngineService.delete(`${ENDPOINT}/${id}`);
}

export function batchDeleteCustomObjects(customObjects) {
  return EngineService.post('/custom_objects/batch_delete', {
    custom_objects: { ...customObjects },
  }).then((json) => json);
}

export function getCustomObjectsForCustomField(customFieldId, params) {
  return EngineService.get(`${ENDPOINT}?custom_field_id=${customFieldId}`, params).then(
    (json) => json
  );
}

export function getCustomObjectsCount(params = {}) {
  return EngineService.get(`${ENDPOINT}/count`, params).then((json) => json);
}

export default {
  getAll: getCustomObjects,
  create: createCustomObject,
  update: updateCustomObject,
  delete: deleteCustomObject,
};
