import Hotjar from '@hotjar/browser';
import * as Sentry from '@sentry/react';
import { createRoot } from 'react-dom/client';

import 'core-js/stable';
import 'regenerator-runtime/runtime';
import App from '../App';
import '../i18n';
import '../styles/main.scss';

const ingoreEvents = [
  'Non-Error promise rejection captured with keys: message',
  'Non-Error promise rejection captured with keys: error, status',
  'Non-Error promise rejection captured with keys: config, data, headers, request, status',
  'ResizeObserver loop limit exceeded',
  'Invalid or unexpected token',
  'XSS is not defined',
  'This browser does not support audio output selection',
  'Object captured as promise rejection with keys: message',
  'Object captured as promise rejection with keys: config, data, headers, request, status',
];

if (process.env.NODE_ENV === 'production') {
  if (process.env.HOTJAR_SITE_ID) {
    Hotjar.init(process.env.HOTJAR_SITE_ID, process.env.HOTJAR_VERSION);
    Hotjar.stateChange(window.location.pathname);
  }
  Sentry.init({
    dsn: process.env.SENTRY_DSN,
    integrations: [
      new Sentry.Replay({
        maskAllText: true,
        blockAllMedia: true,
      }),
    ],

    tracesSampleRate: 1.0,
    replaysSessionSampleRate: 1.0,
    replaysOnErrorSampleRate: 1.0,

    beforeSend(event, hint) {
      const exception = event.exception && event.exception.values && event.exception.values[0];

      if (exception) {
        const message = exception.value || '';
        const type = exception.type || '';

        if (ingoreEvents.includes(message)) return null;
        if (message.includes('NetworkError') || message.includes('Failed to fetch')) return null;
        if (type === 'TypeError' || type === 'SyntaxError') return null;
      }

      if (hint.originalException?.name === 'ChunkLoadError') return null;
      if (hint.originalException?.message === "Unexpected token '<'") return null;
      if (hint.originalException?.message === 'ResizeObserver loop limit exceeded') return null;
      if (
        hint.originalException?.statusText === 'Unprocessable Entity' ||
        hint.originalException?.statusText === 'Forbidden' ||
        hint.originalException?.statusText === 'Not Found' ||
        hint.originalException?.contact?.matched ||
        hint.originalException?.account?.matched
      )
        return null;

      return event;
    },
  });
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App tab="home" />);
