import { Suspense, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import AppNotifications from 'components/AppNotifications';
import CallDialog from 'components/CallDialog';
import { selectTwilioEnabled } from 'components/CallDialog/state/selectors';
import PushNotifications from 'components/PushNotifications';
import TwilioDevice from 'components/TwilioDevice';
import { shouldDoOnboarding } from 'helpers/user';
import { useHelpscout, useHotjar } from 'hooks';
import { selectUserFetched, selectUserStatus } from 'state/user/selectors';
import PageLoader from 'ui/Loaders/NewPageLoader';

import SideBar from './SideBar';
import TopBar from './TopBar';

import styles from './Main.module.scss';

function Main() {
  const location = useLocation();
  const userFetched = useSelector(selectUserFetched);
  const twilioEnabled = useSelector(selectTwilioEnabled);
  const userStatus = useSelector(selectUserStatus);
  const userShouldDoOnboarding = shouldDoOnboarding(userStatus);
  const navigate = useNavigate();
  useHelpscout(location);
  useHotjar();

  useEffect(() => {
    if (userFetched && userShouldDoOnboarding) {
      navigate('/onboarding');
    }
  }, [userFetched, userShouldDoOnboarding]);

  return (
    <div className={styles['main-layout']}>
      {twilioEnabled && <TwilioDevice />}
      {userFetched && (
        <>
          <CallDialog />
          <PushNotifications />
          <AppNotifications />
          <TopBar />
          <SideBar />
        </>
      )}

      <Suspense fallback={<PageLoader />}>
        <div className={styles['content-container']}>
          <Outlet />
        </div>
      </Suspense>
    </div>
  );
}

export default Main;
