import Moment from 'moment';

import Person from './Person';
import { parseCompanyFullContactData, parsePersonFullContactData } from '../helpers/contacts';

class Contact extends Person {
  constructor(data) {
    super(data);
    this.crmContactId = data.crm_contact_id || '';
    this.followupAt = data.followup_at ? new Moment(data.followup_at) : '';
    this.provider = data.provider || '';
    this.description = data.description || '';
    this.accountId = data.account_id || '';
    this.accountName = data.account_name || '';
    this.company = data.account_name || '';
    this.userId = data.user_id || '';
    this.owner = data.owner || '';
    this.status = data.status || '';
    this.user = data.user || { full_name: '' };
    this.userFullName = data.user ? data.user.full_name || '' : '';
    this.twilioErrors = data.twilio_errors;
    this.custom = data.custom || {};
    this.hasOpportunities = data.has_opportunities;
    this.lastActivityDate = data.last_activity_date ? new Moment(data.last_activity_date) : '';
    this.closestReminderReason = data.closest_reminder_reason || '';
    this.createdAt = data.created_at ? new Moment(data.created_at) : '';
    this.createdBy = data.created_by || '';
    this.fullContactData = parsePersonFullContactData(data.fullcontact_info);
    this.accountFullContactData = parseCompanyFullContactData(data.account_fullcontact_info);
    this.accountPhone = data.account_phone || '';
    this.account_phone = data.account_phone || '';
    this.photoUrl = data.photo_url || undefined;
    this.name = `${this.firstName} ${this.lastName}`;
    this.nextStep = data.next_step || '';
    this.nextStepRecord = data.next_step_record || {};
    this.missedStep = data.missed_step || '';
    this.missedStepRecord = data.missed_step_record || {};
    this.lastInteractionType = data.last_interaction_type || '';
    this.homePhone = data.home_phone || '';
    this.home_phone = data.home_phone || '';
    this.mobile_phone = data.mobile_phone || '';
    this.externalId = data.external_id || '';
    this.campaignStatus = data.campaign_status || '';
    this.emailOptOut = data.email_opt_out;
    this.imageUrl = this.photoUrl || this.fullContactData?.primaryPhoto?.url;
    this.ext_number = this.ext_number || null;
  }

  getInitials() {
    return `${this.firstName.charAt(0).toUpperCase()} ${this.lastName.charAt(0).toUpperCase()}`;
  }

  getDetailsPath() {
    return `/contacts/${this.id}`;
  }

  getEmailTemplates() {
    return this.emailTemplates;
  }

  getFullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  getFirstName() {
    return this.firstName;
  }

  getLastName() {
    return this.lastName;
  }

  getEmail() {
    return this.email;
  }

  getTitle() {
    return this.title;
  }

  getPhone() {
    return this.phone;
  }

  getMobilePhone() {
    return this.mobilePhone;
  }

  getDescription() {
    return this.description;
  }

  getCompanyName() {
    return this.company;
  }

  getCompanyId() {
    return this.accountId;
  }

  getLastActivityDate(dateFormat = 'll') {
    if (this.lastActivityDate) {
      return this.lastActivityDate.format(dateFormat);
    }
    return '';
  }
}

export default Contact;
