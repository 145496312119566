import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

export default function SanitizedText({ as: Element, text, className }) {
  return <Element className={className} dangerouslySetInnerHTML={{ __html: sanitizeHtml(text) }} />;
}

SanitizedText.defaultProps = {
  as: 'div',
  className: '',
};

SanitizedText.propTypes = {
  text: PropTypes.PropTypes.string.isRequired,
  className: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};
