import { useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import useInfiniteScroll from 'app/hooks/useInfiniteScroll';

import useNewConversation from './useNewConversation';
import ConversationContactCard from '../ConversationContactCard';

import styles from './NewConversation.module.scss';

const StyledTextField = styled(TextField)({
  flex: 1,
  '& .MuiOutlinedInput-root': {
    padding: '0px !important',
    '& fieldset': {
      border: 'none',
    },
  },
  '& .MuiOutlinedInput-input': {
    padding: '0px !important',
    marginTop: '-1px',
  },
  '& input::-webkit-search-cancel-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button, & input::-webkit-clear-button':
    {
      display: 'none',
    },
});

function NewConversation() {
  const {
    toggleConversationScreen,
    loading,
    contacts,
    meta,
    handleSearch,
    onSelect,
    handleOnBlur,
    searchTerm,
    handleGetContacts,
    onKeyPress,
  } = useNewConversation();
  const [selectedContact, setSelectedContact] = useState(null);
  const { handleRef } = useInfiniteScroll(loading, meta, handleGetContacts);

  return (
    <>
      <div className={styles.header}>
        <IconButton size="large" onClick={toggleConversationScreen}>
          <ArrowBackIcon />
        </IconButton>
        <h5>Create message</h5>
      </div>
      <div className={styles.search}>
        <span>To</span>
        <StyledTextField
          placeholder="Type a name, phone number..."
          autoComplete="off"
          value={searchTerm}
          InputProps={{
            onBlur: handleOnBlur,
            onChange: (e) => handleSearch(e.target.value),
            onKeyPress,
          }}
        />
      </div>
      <div className={styles.results}>
        {contacts.map((contact, index) => (
          <ConversationContactCard
            key={contact.id}
            contact={contact}
            onClick={onSelect}
            ref={contacts.length - 1 === index ? handleRef : null}
            setSelectedContact={setSelectedContact}
            selectedContact={selectedContact}
          />
        ))}
      </div>
    </>
  );
}

export default NewConversation;
