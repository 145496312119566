import Day from 'dayjs';
import get from 'lodash/get';
import Moment from 'moment';

export default class Reminder {
  constructor(data) {
    this.id = data.id || null;
    this.owner = data.owner || { name: '', user_id: null };
    this.ownerId = data.owner_id || null;
    this.ownerType = data.owner_type || '';
    this.userId = data.user_id || null;
    this.reason = data.reason || '';
    this.description = data.description || '';
    this.remindMeAt = data.remind_me_at ? new Day(data.remind_me_at) : '';
    this.remindMe = data.remind_me || false;
    this.createdAt = data.created_at ? new Moment(data.created_at) : '';
    this.updatedAt = data.updated_at ? new Moment(data.updated_at) : '';
    this.createdBy = get(data, 'created_by.full_name');
    this.user = data.user;
    this.userFullName = get(data, 'user.full_name') || '';
    this.account = data.account;
    this.custom = data.custom || {};
    this.priority = data.priority?.toString() || '1';
    this.mentionedUserIDs = data.mentioned_user_ids || [];
  }
}
