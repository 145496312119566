import pick from 'lodash/pick';

export function blank() {
  return {
    currentPage: 1,
    nextPage: null,
    totalPages: 1,
    perPage: 20,
  };
}

// null meta object for new Pagy pagination
export const nullMeta = {
  currentPage: null,
  previousPage: null,
  nextPage: null,
};

// function for parsing new Pagy meta from response
export function parseMetaFromResponse(meta) {
  if (!meta) return nullMeta;

  return {
    currentPage: meta.current_page,
    previousPage: meta.previous_page,
    nextPage: meta.next_page,
  };
}

export function parseMetaFromSearchEndpointResponse(results, meta) {
  if (!meta) return nullMeta;

  return {
    currentPage: meta.current_page,
    previousPage: meta.previous_page,
    nextPage: results.length >= meta.per_page ? meta.current_page + 1 : meta.next_page,
    contextAccountId: meta.context_account_id || null,
    constrained: meta.constrained || false,
  };
}

export function parse(data, salesStages = []) {
  const meta = {
    ...blank(),
    currentPage: data.current_page,
    nextPage: data.next_page,
    totalPages: data.total_pages,
    perPage: data.per_page,
    totalCount: data.total_count,
  };
  // Assign sums of Opportunities by priority
  if (typeof data.amount_0 !== 'undefined') {
    [0, 1, 2, 3].forEach((key) => {
      meta[`amount_${key}`] = parseFloat(data[`amount_${key}`]);
    });
  }
  // Assign sums of Opportunities by sales stages
  salesStages
    .map((s) => s.id)
    .forEach((id) => {
      meta[id] = parseFloat(data[id] || 0);
    });
  return meta;
}

export function serialize(meta, keys) {
  const s = {
    current_page: meta.currentPage,
    next_page: meta.nextPage,
    total_pages: meta.totalPages,
    per_page: meta.perPage,
  };
  if (keys) {
    return pick(s, keys);
  }
  return s;
}

export default {
  blank,
  parse,
  serialize,
};
