export const TOKEN_STATUS = {
  FALSE: -1,
  CORRECT: 0,
  EXPIRED: 1,
  CONNECTED: 5,
};

export const TOKEN_PROVIDERS = {
  SPIRO: 'spiro',
  SALESFORCE: 'sf',
  HUBSPOT: 'hubspot',
  GOOGLE: 'gmail',
  MICROSOFT: 'microsoft',
};

export function isTokenValid(token) {
  return (
    token.status === TOKEN_STATUS.CORRECT && token.accessToken !== '' && token.refreshToken !== ''
  );
}

export function filterSpiroToken(tokens) {
  const t = tokens.filter((token) => token.provider === TOKEN_PROVIDERS.SPIRO);
  return t[0];
}

export function filterSalesforceToken(tokens) {
  const t = tokens.filter((token) => token.provider === TOKEN_PROVIDERS.SALESFORCE);
  return t[0];
}

export function filterHubspotToken(tokens) {
  const t = tokens.filter((token) => token.provider === TOKEN_PROVIDERS.HUBSPOT);
  return t[0];
}

export function filterGoogleToken(tokens) {
  const t = tokens.filter((token) => token.provider === TOKEN_PROVIDERS.GOOGLE);
  return t[0];
}

export function filterMicrosoftToken(tokens) {
  const t = tokens.filter((token) => token.provider === TOKEN_PROVIDERS.MICROSOFT);
  return t[0];
}

export function parseTokenFromEngine(data = {}) {
  return {
    id: data.id || null,
    userId: data.user_id || '',
    provider: data.provider || '',
    accessToken: data.access_token || '',
    refreshToken: data.refresh_token || '',
    email: data.email || '',
    instanceUrl: data.instance_url || '',
    issuedAt: data.issued_at,
    status: data.status,
  };
}

export default {
  isTokenValid,
  filterSpiroToken,
  filterSalesforceToken,
  filterHubspotToken,
  filterGoogleToken,
  filterMicrosoftToken,
  parseTokenFromEngine,
};
