import EngineService from '../core/engine-service';

const ENDPOINT = '/display_fields';

// GET: /display_fields,
export function getDisplayFields(params) {
  return EngineService.get(`${ENDPOINT}`, params).then((json) => json);
}

// PATCH: /display_fields/:id
export function updateDisplayField(data) {
  return EngineService.post(`${ENDPOINT}`, data).then((json) => json);
}

// POST: /display_fields
export function createDisplayField(payload) {
  return EngineService.post(`${ENDPOINT}`, { mailing_list: payload }).then((json) => json);
}

export default {
  getDisplayFields,
  createDisplayField,
  updateDisplayField,
};
