export function selectMissedCallsCount(state) {
  return state.counters.missedCalls;
}

export function selectUnreadMessagesCount(state) {
  return state.counters.unreadSMS;
}

export function selectUnreadVoicemailsCount(state) {
  return state.counters.voicemails;
}
