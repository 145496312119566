import EngineService from '../core/engine-service';

const ENDPOINT = '/accounts';

export function getAll(params = {}) {
  return EngineService.get(ENDPOINT, params);
}

export function get(accountId, params = {}) {
  return EngineService.get(`${ENDPOINT}/${accountId}`, params);
}

export function update(accountId, payload) {
  return EngineService.patch(`${ENDPOINT}/${accountId}`, { account: payload }).then(
    (response) => response
  );
}

export function destroy(accountId) {
  return EngineService.delete(`${ENDPOINT}/${accountId}`);
}

export function create(payload) {
  return EngineService.post(`${ENDPOINT}`, { account: payload });
}

export function getCustomObjects(id) {
  return EngineService.get(`${ENDPOINT}/${id}/custom_objects`);
}

export function getAccountFilterProperties(params = {}) {
  return EngineService.get('/filters/accounts/columns', params).then((json) => json);
}

export function batchDeleteAccounts(accounts) {
  return EngineService.post('/accounts/batch_delete', { accounts }).then((json) => json);
}
export function followAccount(accountID) {
  return EngineService.post(`${ENDPOINT}/${accountID}/follow`).then((json) => json);
}

export function unfollowAccount(accountID) {
  return EngineService.post(`${ENDPOINT}/${accountID}/unfollow`).then((json) => json);
}

export function getOrderSummary(id, params = {}) {
  return EngineService.get(`${ENDPOINT}/${id}/sales_order_summary`, params).then((json) => json);
}

export function updateOrderSummary(id, payload) {
  return EngineService.patch(`${ENDPOINT}/${id}/sales_order_summary`, {
    sales_order_summary: payload,
  }).then((json) => json);
}

export function getAccountCount(params = {}) {
  return EngineService.get(`${ENDPOINT}/count`, params);
}

export function getAccountListViewSettings(params = {}) {
  return EngineService.get('/Account/list_view_settings', params);
}

export function updateAccountListViewSettings(payload) {
  return EngineService.put('/Account/list_view_settings', payload);
}

export function getAccountsForSummaryView(params = {}) {
  return EngineService.get(`${ENDPOINT}/summary`, params).then((json) => json);
}

export default {
  getAll,
  get,
  update,
  destroy,
  create,
  getAccountFilterProperties,
  batchDeleteAccounts,
  getAccountListViewSettings,
};
