import MuiButton from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const colors = {
  grey: '#999999',
  lightGrey: 'rgba(128, 128, 128, 0.24)',
  white: '#FFFFFF',
  purple: '#5D43CF',
  lightPurple: 'rgba(174, 161, 231, 0.24)',
  lightPurple2: '#BEB4EC',
  pomegranate: '#f44336',
  lightRed: '#FEECEB',
  orange: '#f06223',
  lightOrange: 'rgba(248, 176, 145, 0.16)',
};

const Button = styled(MuiButton)(
  ({ disableTextTransform, disableTextDecoration, noPadding, withBorders }) => ({
    '&.MuiButton-text': {
      color: colors.purple,
      '&:disabled': {
        color: colors.grey,
      },
      '&:hover': {
        backgroundColor: colors.lightPurple,
      },
    },
    '&.MuiButton-textSecondary': {
      color: colors.grey,
      '&:disabled': {
        color: colors.lightGrey,
      },
      '&:hover': {
        backgroundColor: colors.lightPurple,
      },
    },

    '&.MuiButton-outlined': {
      border: `1px solid ${colors.purple}`,
      color: colors.purple,
      backgroundColor: colors.white,
      '&:disabled': {
        color: colors.grey,
        border: `1px solid ${colors.grey}`,
        backgroundColor: 'transparent',
      },
      '&:hover': {
        color: colors.purple,
        border: `1px solid ${colors.purple}`,
        backgroundColor: colors.lightPurple,
      },
    },
    '&.MuiButton-outlinedTransparent': {
      border: `1px solid ${colors.purple}`,
      color: colors.purple,
      backgroundColor: 'transparent',
      '&:disabled': {
        color: colors.grey,
        border: `1px solid ${colors.grey}`,
        backgroundColor: 'transparent',
      },
      '&:hover': {
        backgroundColor: colors.lightPurple,
      },
    },

    '&.MuiButton-contained': {
      padding: '5px 15px',
      backgroundColor: colors.purple,
      border: `1px solid ${colors.purple}`,
      boxShadow: 'none',
      '&:disabled': {
        color: colors.white,
        backgroundColor: colors.purple,
        boxShadow: 'none',
        opacity: '0.5',
      },
      '&:hover': {
        padding: '5px 15px',
        color: colors.purple,
        border: `1px solid ${colors.purple}`,
        backgroundColor: colors.lightPurple,
        boxShadow: 'none',
      },
    },

    '&.MuiButton-warning': {
      color: colors.orange,
      textTransform: disableTextTransform ? 'none' : '',
      textDecoration: disableTextDecoration ? 'none' : '',
      padding: noPadding ? '0px' : '',
      border: withBorders ? `1px solid ${colors.orange}` : 'none',
      '&:disabled': {
        color: colors.grey,
        border: `1px solid ${colors.grey}`,
        '& path': {
          fill: colors.grey,
        },
      },
      '&:hover': {
        backgroundColor: withBorders ? colors.lightOrange : 'transparent',
      },
      '& path': {
        fill: colors.orange,
      },
    },

    '&.MuiButton-danger': {
      color: colors.pomegranate,
      border: 'none',
      '&:disabled': {
        color: colors.grey,
      },
      '&:hover': {
        backgroundColor: colors.lightRed,
      },
    },
  })
);

export default Button;
