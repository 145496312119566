import Day from 'dayjs';

function calculateDaysToAdd(day) {
  return { 5: 3, 6: 2 }[day] || 1;
}

const DateHelper = {
  in3Days() {
    return new Day().add(3, 'day').set('hour', 9).set('minute', 0).set('second', 0);
  },

  dayAfterWithoutWeekends() {
    const date = new Day();
    const daysToAdd = calculateDaysToAdd(date.day());

    return date.add(daysToAdd, 'day').set('hour', 9).set('minute', 0).set('second', 0);
  },

  isToday(date) {
    return Day(date).isSame(Day(), 'day');
  },

  isYesterday(date) {
    return Day(date).isSame(Day().subtract(1, 'day'), 'day');
  },

  formatDateForPhoneApp(date) {
    if (DateHelper.isToday(date)) return Day(date).format('h:mm A');
    if (DateHelper.isYesterday(date)) return `Yesterday, ${Day(date).format('h:mm A')}`;
    return Day(date).format('M/D/YY, h:mm A');
  },

  formatDateForVoicemails(date) {
    if (DateHelper.isToday(date)) return 'Today';
    if (DateHelper.isYesterday(date)) return 'Yesterday';
    return Day(date).format('M/D/YY');
  },

  formatDate(date, format) {
    return Day(date).format(format);
  },

  getCurrentTime() {
    return Day().format('h:mm A');
  },

  calculateTimeUntilExpiration(expirationDate) {
    const today = Day();
    const expiration = Day(expirationDate);

    const diffInMilliseconds = expiration.diff(today);

    if (diffInMilliseconds <= 0) {
      return 'expired';
    }

    const diffInDays = Math.floor(diffInMilliseconds / (1000 * 60 * 60 * 24));
    const diffInHours = Math.floor((diffInMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));

    if (diffInDays > 0) {
      return `${diffInDays} ${diffInDays === 1 ? 'day' : 'days'}`;
    }
    if (diffInHours > 0) {
      return `${diffInHours} ${diffInHours === 1 ? 'hour' : 'hours'}`;
    }
    return 'less than an hour';
  },

  formatDateRange(from, to) {
    const fromDate = Day(from);
    const toDate = Day(to);

    const fromMonth = fromDate.format('MMM');
    const toMonth = toDate.format('MMM');
    const fromDay = fromDate.date();
    const toDay = toDate.date();
    const year = fromDate.year();
    if (fromMonth === toMonth) {
      return `${fromMonth} ${fromDay}–${toDay}, ${year}`;
    }
    return `${fromMonth} ${fromDay}–${toMonth} ${toDay}, ${year}`;
  },
};

export default DateHelper;
