import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import UserAvatar from 'components/UserAvatar';

import styles from './UserInfo.module.scss';

const StyledSkeleton = styled(Skeleton)({
  backgroundColor: 'rgba(253, 239, 233, 0.4)',
  height: '17px',
});

export default function UserInfo({ handleClick, open, user }) {
  return (
    <div className={styles['user-info']}>
      <UserAvatar user={user} />
      <div role="button" tabIndex="0" onClick={handleClick} className={styles.name}>
        {!user.fullName && (
          <div className={styles.skeleton}>
            <StyledSkeleton data-testid="skeleton" variant="text" />
          </div>
        )}
        {user.fullName && <p data-cy="navbar-user-full-name-label">{user.fullName}</p>}
      </div>
      <ExpandMoreIcon className={open ? styles['rotate-icon'] : ''} onClick={handleClick} />
    </div>
  );
}

UserInfo.propTypes = {
  handleClick: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};
