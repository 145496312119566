import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

import Button from 'lib/Button';

import styles from './ConfirmationModal.module.scss';

// TODO: Handle loading={isLoading} prop for confirm button
function ConfirmationModal({
  message = '',
  title = '',
  visible = false,
  confirmButtonText = 'OK',
  isDisabledConfirmButton = false,
  cancelButtonText = 'Cancel',
  isDisabledCancelButton = false,
  // isLoading,
  onConfirmClick = () => {},
  onCancelClick = () => {},
  onHide = () => {},
  cancelButtonVisible = true,
  confirmButtonColor = 'primary',
}) {
  return (
    <Dialog
      open={visible}
      onClose={onHide}
      PaperProps={{ className: styles.modal, 'data-cy': 'confirmation-modal' }}
    >
      <DialogTitle classes={{ root: styles.title }} data-cy="confirmation-modal-title">
        {title}
      </DialogTitle>
      <DialogContent classes={{ root: styles.content }} data-cy="confirmation-modal-content">
        {message && (
          <p
            className={styles.message}
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(message) }}
          />
        )}
      </DialogContent>
      <DialogActions classes={{ root: styles.actions }}>
        {cancelButtonVisible && (
          <Button
            color="secondary"
            onClick={onCancelClick}
            disabled={isDisabledCancelButton}
            data-cy={`${cancelButtonText.toLowerCase().replaceAll(' ', '-')}-btn`}
          >
            {cancelButtonText}
          </Button>
        )}
        <Button
          color="primary"
          variant="contained"
          className={confirmButtonColor === 'warning' ? styles.button : ''}
          disabled={isDisabledConfirmButton}
          onClick={onConfirmClick}
          data-cy={`${confirmButtonText.toLowerCase().replaceAll(' ', '-')}-btn`}
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ConfirmationModal.propTypes = {
  message: PropTypes.string,
  title: PropTypes.string,
  visible: PropTypes.bool,
  confirmButtonText: PropTypes.string,
  isDisabledConfirmButton: PropTypes.bool,
  cancelButtonText: PropTypes.string,
  isDisabledCancelButton: PropTypes.bool,
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  onHide: PropTypes.func,
  cancelButtonVisible: PropTypes.bool,
  confirmButtonColor: PropTypes.string,
};

export default ConfirmationModal;
