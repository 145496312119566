import SearchIcon from '@mui/icons-material/Search';

import SearchModal from 'components/SearchModal';
import { useSearch } from 'components/SearchModal/SearchProvider';
import './NavSearch.scss';

export default function NavSearch() {
  const { visible, setVisible } = useSearch();

  function handleIconClick() {
    setVisible(true);
  }

  return (
    <>
      <SearchIcon color="inherit" onClick={handleIconClick} data-cy="navbar-search-icon" />
      {visible && <SearchModal />}
    </>
  );
}
