import { useSelector } from 'react-redux';

import { selectCurrency, selectCurrencySymbol } from 'state/user/selectors';

export default function useCurrency() {
  const code = useSelector(selectCurrency);
  const symbol = useSelector(selectCurrencySymbol);

  return {
    symbol,
    code,
  };
}
