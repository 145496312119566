import { createAsyncThunk } from '@reduxjs/toolkit';

import { serializeFiltersToString } from 'helpers/filters';
import {
  getOrders,
  getOrdersCount as getOrdersCountRemote,
  updateOrder as updateOrderRemote,
} from 'services/orders';
import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/orders/actions';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../helpers/errorHelper';

function getParamsFromState(state) {
  const params = state.orders.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.orders.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.SalesOrder;

  const paramsObj = params.sort
    ? defaultParams
    : { ...defaultParams, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export const fetchOrders = createAsyncThunk(
  'orders/fetchAll',
  async (params = {}, { dispatch, getState }) => {
    dispatch(fetchFilterProperties());
    return dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      return getOrders(payload)
        .then((json) => Promise.resolve({ ...json, params: { ...stateParams, ...params } }))
        .catch((err) => {
          forEachError(err.data, (e) => dispatch(error(e)));
          return Promise.reject(err);
        });
    });
  }
);

export const getOrdersCount = createAsyncThunk(
  'orders-count',
  async (params = {}, { getState }) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };
    return getOrdersCountRemote(payload)
      .then(() => Promise.resolve())
      .catch((err) => Promise.reject(err));
  }
);

export const updateOrder = createAsyncThunk('orders/update', async (data, { dispatch }) => {
  try {
    const res = await updateOrderRemote(data.id, data.payload);
    return res.sales_order;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});
