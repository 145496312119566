import EngineService from '../core/engine-service';

const ENDPOINT = '/opportunity_stages';

export function getAll(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json.opportunity_stages);
}

export function get(id, params = {}) {
  return EngineService.get(`${ENDPOINT}/${id}`, params).then((json) => json);
}

export function create(payload) {
  return EngineService.post(ENDPOINT, {
    opportunity_stage: payload,
  }).then((json) => json.opportunity_stage);
}

export function update(id, payload) {
  return EngineService.patch(`${ENDPOINT}/${id}`, {
    opportunity_stage: payload,
  }).then((json) => json.opportunity_stage);
}

export function batchUpdate(payload) {
  return EngineService.post(`${ENDPOINT}/batch_update`, {
    opportunity_stages: payload,
  }).then((json) => json);
}

export function del(id) {
  return EngineService.delete(`${ENDPOINT}/${id}`).then((json) => json);
}

export default {
  getAll,
  create,
  update,
  batchUpdate,
  delete: del,
};
