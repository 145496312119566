import MessageOutlinedIcon from '@mui/icons-material/MessageOutlined';
import PropTypes from 'prop-types';

import NotificationIndicator from 'app/layouts/Main/TopBar/TopBarActions/NotificationIndicator';
import { getFullName } from 'helpers/contacts';
import { convertNameToInitials } from 'helpers/utils';

import styles from './MessageCard.module.scss';

function MessageAvatar({ message }) {
  return (
    <div className={styles.avatar}>
      {!message.is_read && <NotificationIndicator />}
      {!message.contact && <MessageOutlinedIcon />}
      {message.contact && !message.contact.photo_url && (
        <span>{convertNameToInitials(getFullName(message.contact))}</span>
      )}
      {message.contact && message.contact.photo_url && (
        <img src={message.contact.photo_url} alt="contact avatar" />
      )}
    </div>
  );
}

MessageAvatar.propTypes = {
  message: PropTypes.object.isRequired,
};

export default MessageAvatar;
