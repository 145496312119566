import {
  FETCHING_CURRENCIES,
  FETCHING_CURRENCIES_FAILED,
  FETCHING_CURRENCIES_SUCCESS,
  STORE_CURRENCIES,
} from './constants';

const initialState = {
  data: [],
  fetching: false,
  fetched: false,
  failed: false,
};

function fetchingCurrenciesSuccess(state) {
  return {
    ...state,
    fetching: false,
    fetched: true,
  };
}

function fetchingCurrencies(state) {
  return {
    ...state,
    fetching: true,
    fetched: false,
  };
}

function storeCurrencies(state, action) {
  return {
    ...state,
    data: [...state.data, ...action.data],
  };
}

function fetchingCurrenciesFailed(state) {
  return {
    ...state,
    fetching: false,
    failed: true,
  };
}

const ACTION_HANDLERS = {
  [FETCHING_CURRENCIES]: fetchingCurrencies,
  [FETCHING_CURRENCIES_SUCCESS]: fetchingCurrenciesSuccess,
  [FETCHING_CURRENCIES_FAILED]: fetchingCurrenciesFailed,
  [STORE_CURRENCIES]: storeCurrencies,
};

export default function currenciesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
