import { createAsyncThunk } from '@reduxjs/toolkit';

import { getRequestedAccesses } from 'app/services/spiro-access';
import { serializeFiltersToString } from 'helpers/filters';
import { serialize } from 'helpers/user';
import { parse } from 'helpers/users';
import { getUsers, updateUser as updateUserRemote } from 'services/users';
import { error as notifyError } from 'state/notifications/actions';

import { forEachError } from '../../../helpers/errorHelper';
import { fetchFilterProperties, fetchSavedFilters } from '../filters/users/actions';

function getParamsFromState(state) {
  const params = state.usersAdministration.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.users.currentFilters;

  return {
    ...defaultParams,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export const fetchUsers = createAsyncThunk(
  'users-get',
  async (params = {}, { dispatch, getState }) => {
    try {
      await dispatch(fetchFilterProperties());
      await dispatch(fetchSavedFilters());
      const stateParams = await getParamsFromState(getState());

      const payload = { ...stateParams, ...params };
      const res = await getUsers(payload);

      return Promise.resolve({ ...res, params: payload });
    } catch (err) {
      return Promise.reject(err);
    }
  }
);

export const updateUser = createAsyncThunk(
  'user-update',
  async (payload, { dispatch, getState }) => {
    try {
      const res = await updateUserRemote(payload.userId, serialize(payload.payload));
      const users = getState().usersAdministration.users;
      const index = users.map((u) => u.id).indexOf(res.id);

      const data = users
        .slice(0, index)
        .concat(parse(res))
        .concat(users.slice(index + 1));
      return Promise.resolve({ data });
    } catch (err) {
      forEachError(err.data, (e) => dispatch(notifyError(e)));
      return Promise.reject(err);
    }
  }
);

export const fetchAccessRequests = createAsyncThunk(
  'access-requests',
  async (params = {}, { getState }) => {
    try {
      const recordsPerPage = getState().spiroViews.recordsPerPage;
      const defaultParams = { ...params, per_page: recordsPerPage };
      const res = await getRequestedAccesses(defaultParams);
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
);
