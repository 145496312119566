import Day from 'dayjs';
import PropTypes from 'prop-types';

import ChatItem from './ChatItem';

export default function GroupList({ messages, ...props }) {
  return (
    <ul className="group-list">
      {messages.map((message) => (
        <ChatItem
          key={message.id}
          contactName={message.name || message.owner?.name}
          userName={message.user.fullName || message.user.full_name}
          text={message.description || message.body}
          mediaUrls={message.mediaUrl || message.media_urls || []}
          inbound={message.smsType === 'inbound' || message.direction === 'inbound'}
          time={Day(message.interacted_at || message.interactedAt).format('hh:mm A')}
          status={message.status}
          {...props}
        />
      ))}
    </ul>
  );
}

GroupList.propTypes = {
  messages: PropTypes.array.isRequired,
};
