import { styled } from '@mui/material/styles';
import MuiTab from '@mui/material/Tab';
import PropTypes from 'prop-types';

const StyledMuiTab = styled(MuiTab)(({ uppercaseText }) => ({
  color: '#999999',

  '&.Mui-selected': {
    color: '#5D43CF !important',
    opacity: 1,
  },

  textTransform: uppercaseText ? 'none' : 'uppercase',
  minWidth: uppercaseText ? '0px' : '160px',
}));

export default function Tab(props) {
  const { uppercaseText = false, ...rest } = props;
  return <StyledMuiTab uppercaseText={uppercaseText} {...rest} />;
}

Tab.propTypes = {
  uppercaseText: PropTypes.bool,
};
