export function getPayload(formData, fileUrls) {
  const payload = formData;

  if (fileUrls.length) {
    payload.message_type = 'mms';
    payload.media_urls = fileUrls.map((url) => url.publicUrl);
  }

  return payload;
}

export function htmlToPlainTextForSms(input) {
  let result = input.replace(/<br\s*\/?>/gi, '\n');
  result = result.replace(/<\/?[^>]+(>|$)/g, '');

  const entitiesMap = {
    '&nbsp;': ' ',
    '&amp;': '&',
    '&quot;': '"',
    '&lt;': '<',
    '&gt;': '>',
    '&rsquo;': '’',
    '&ndash;': '–',
  };

  result = result.replace(/&[a-zA-Z]+;/g, (entity) => entitiesMap[entity] || entity);

  return result;
}
