import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectCallDuration } from '../state/selectors';

function CallDurationTimer({ isLarge = false }) {
  const callDuration = useSelector(selectCallDuration);

  return (
    <Typography variant={isLarge ? 'body1' : 'caption'}>
      {callDuration >= 3600 ? (
        <span>{`0${Math.floor((callDuration / 3600) % 60)}`.slice(-2)}:</span>
      ) : null}
      <span>{`0${Math.floor((callDuration / 60) % 60)}`.slice(-2)}:</span>
      <span>{`0${Math.floor((callDuration / 1) % 60)}`.slice(-2)}</span>
    </Typography>
  );
}

CallDurationTimer.propTypes = {
  isLarge: PropTypes.bool,
};

export default CallDurationTimer;
