export const STORE_SALES_STAGES = 'sales_stages: store_sales_stages';
export const STORE_ALL_SALES_STAGES = 'sales_stages: store_all_sales_stages';
export const STORE_SALES_STAGE = 'sales_stages: store_sales_stage';
export const CREATE_SALES_STAGE = 'sales_stages: create_sales_stage';
export const DELETE_SALES_STAGE = 'sales_stages: delete_sales_stage';
export const SWAP_SALES_STAGES = 'sales_stages: swap_sales_stages';
export const CREATE = 'sales_stages: create opportunity stage';
export const UPDATE = 'sales_stages: update opportunity stage';
export const BATCH_UPDATE = 'sales_stages: batch update opportunity stages';
export const DELETE = 'sales_stages: delete opportunity stage';
export const REQUEST_START = 'sales_stages: request start';
export const REQUEST_SUCCESS = 'sales_stages: request success';
export const REQUEST_FAIL = 'sales_stages: request fail';
export const RESET = 'sales_stages: reset';
export const SET_DIRTY = 'sales_stages: set_dirty';
export const SET_CLEAN = 'sales_stages: set_clean';

export default {
  STORE_SALES_STAGES,
  STORE_SALES_STAGE,
  CREATE_SALES_STAGE,
  DELETE_SALES_STAGE,
  SET_DIRTY,
  SET_CLEAN,
};
