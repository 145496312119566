import AddIcon from '@mui/icons-material/Add';
import IconButton from '@mui/material/IconButton';
import cs from 'classnames';
import Day from 'dayjs';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import DateHelper from '../../../../../helpers/DateHelper';
import {
  minimizeCallModal,
  setModalMinimizedByOtherAction,
  setPhoneDialogOpen,
  setPhoneNumber,
  toggleAddContactModal,
} from '../../state/actions';
import { selectDrafts } from '../Conversation/state/selectors';

import styles from './MessageCard.module.scss';

const displayLatestMessage = (message) => {
  const { body, media_urls: mediaUrls } = message.latest_message;

  if (body === '' && mediaUrls.length > 0) {
    const latestMediaType = mediaUrls[mediaUrls.length - 1].content_type;

    if (latestMediaType && latestMediaType.includes('vcard')) return 'vCard';
    if (latestMediaType && latestMediaType.includes('video')) return 'Video';

    return 'Photo';
  }

  return body;
};

function MessageDetails({ message }) {
  const dispatch = useDispatch();
  const drafts = useSelector(selectDrafts);
  const draft = drafts.find((d) => d.id === message.phone);

  const displayCorrectDateFormat = (date) => {
    if (DateHelper.isToday(date)) return 'Today';
    if (DateHelper.isYesterday(date)) return 'Yesterday';
    return Day(date).format('MMM. D YYYY');
  };

  const handleMinimizeCallModal = () => {
    dispatch(minimizeCallModal(true));
    dispatch(setPhoneDialogOpen(false));
    dispatch(setModalMinimizedByOtherAction(true));
  };

  const openNewContactModal = (e) => {
    e.stopPropagation();
    dispatch(setPhoneNumber(message.phone));
    dispatch(toggleAddContactModal(true));
    handleMinimizeCallModal();
  };

  return (
    <div className={styles['messages-details']}>
      <div className={styles['contact-info']}>
        {message.contact && (
          <span
            className={styles['contact-name-link']}
            role="button"
            tabIndex="0"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <Link to={`/contacts/${message.contact.id}`} className="bold-500">
              {message.contact.full_name}
            </Link>
          </span>
        )}
        {!message.contact && <span className={styles['contact-name']}>{message.phone}</span>}
        <span
          className={cs({
            [styles.draft]: draft,
            [styles.date]: !draft,
            [styles['hide-date']]: !message.contact,
          })}
        >
          {draft ? 'Draft' : displayCorrectDateFormat(message.latest_message.interacted_at)}
        </span>
        {!message.contact && (
          <div className={styles.actions}>
            <IconButton className={styles.icon} onClick={openNewContactModal}>
              <AddIcon className={styles['add-icon']} />
            </IconButton>
          </div>
        )}
      </div>
      <div className={draft ? styles['draft-body'] : styles.body}>
        {draft ? draft.value : displayLatestMessage(message)}
      </div>
    </div>
  );
}

MessageDetails.propTypes = {
  message: PropTypes.object.isRequired,
};

export default MessageDetails;
