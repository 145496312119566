import { createAsyncThunk } from '@reduxjs/toolkit';

import { parseMetaFromResponse } from 'helpers/meta';
import { getEngineRules } from 'services/engine-rules';
import { error } from 'state/notifications/actions';

import { assistantRulesSuccess, setAssistantRulesLoading } from './reducer';
import { forEachError } from '../../../../helpers/errorHelper';

export const getAssistantRules = createAsyncThunk(
  'get-rules',
  async (params, { dispatch, getState }) => {
    const data = getState().assistant.assistantRules;
    try {
      const res = await getEngineRules(params);
      dispatch(
        assistantRulesSuccess({
          assistantRules: [...data, ...res.assistant_rules],
          meta: parseMetaFromResponse(res.meta),
          selectedRule: res.meta.next_page
            ? {}
            : [...data, ...res.assistant_rules]
                .filter((rule) => rule.status === 'on')
                .filter((rule) => rule.enabled_on_assistant_tab)
                .map((rule) => ({
                  label: rule.name,
                  value: rule.id,
                  resource: rule.resource,
                  description: rule.description,
                  order: rule.order,
                  isCurrency: rule.calculation_is_currency,
                  aiDescriptions: rule.ai_descriptions,
                }))
                .sort((rule1, rule2) => rule1.order - rule2.order)[0],
        })
      );

      if (res.meta.next_page) {
        dispatch(getAssistantRules({ ...params, page: res.meta.next_page }));
      } else {
        dispatch(setAssistantRulesLoading());
      }
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
