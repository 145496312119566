import EngineService from '../core/engine-service';

const ENDPOINT = '/vcards';

export function generateVCard() {
  return EngineService.post(`${ENDPOINT}/generate`, {}).then((json) => json);
}

export function extractVCard(url) {
  return EngineService.post(`${ENDPOINT}/extract`, { file_url: url }).then((json) => json);
}
