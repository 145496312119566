import {
  CREATE_USER_REQUEST_FAIL,
  CREATE_USER_REQUEST_STARTED,
  CREATE_USER_REQUEST_SUCCESS,
  GET_ALL_USERS_REQUEST_STARTED,
  GET_ALL_USERS_REQUEST_SUCCESS,
  GET_USERS_REQUEST_STARTED,
  GET_USERS_REQUEST_SUCCESS,
  REQUEST_FAIL,
  REQUEST_START,
  REQUEST_SUCCESS,
  STORE_USER,
  UPDATE,
} from './constants';
import { forEachError } from '../../../helpers/errorHelper';
import { parse as parseUser, parseAll as parseUsers } from '../../helpers/users';
import {
  createUser as createUserRemote,
  getUsers as getUsersRemote,
  updateUser as updateUserRemote,
} from '../../services/users';
import { error as errorAlert } from '../notifications/actions';

export function getUsersRequestStarted() {
  return {
    type: GET_USERS_REQUEST_STARTED,
  };
}

export function getUsersRequestSuccess(users) {
  return {
    type: GET_USERS_REQUEST_SUCCESS,
    users: parseUsers(users),
  };
}

export function createUserRequestStarted() {
  return {
    type: CREATE_USER_REQUEST_STARTED,
  };
}

export function createUserRequestSuccess(user) {
  return {
    type: CREATE_USER_REQUEST_SUCCESS,
    user,
  };
}

export function createUserRequestFail() {
  return {
    type: CREATE_USER_REQUEST_FAIL,
  };
}

export function storeUser(user) {
  return {
    type: STORE_USER,
    user,
  };
}

export function getUsers(params = {}) {
  return (dispatch) => {
    dispatch(getUsersRequestStarted());

    return getUsersRemote(params)
      .then((json) => {
        dispatch(getUsersRequestSuccess(json.users));
        return Promise.resolve(json.users);
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
  };
}

export function createUser(data, params = {}) {
  return (dispatch) => {
    dispatch(createUserRequestStarted());
    return createUserRemote(data, params)
      .then((user) => {
        dispatch(createUserRequestSuccess(parseUser(user)));
      })
      .catch((e) => {
        dispatch(createUserRequestFail());
        return Promise.reject(e.data);
      });
  };
}

export function updateUser(id, params = {}) {
  return (dispatch) => {
    dispatch({ type: REQUEST_START });
    return updateUserRemote(id, params)
      .then((user) => {
        dispatch({
          type: UPDATE,
          id,
          data: parseUser(user),
        });
        dispatch({ type: REQUEST_SUCCESS });
        return Promise.resolve(user);
      })
      .catch((e) => {
        dispatch({ type: REQUEST_FAIL });
        return Promise.reject(e);
      });
  };
}

export function getAllUsersRequestStarted() {
  return {
    type: GET_ALL_USERS_REQUEST_STARTED,
  };
}

export function getAllUsersRequestSuccess(users) {
  return {
    type: GET_ALL_USERS_REQUEST_SUCCESS,
    users: parseUsers(users),
  };
}

export function getAllUsers(params = {}) {
  return (dispatch) => {
    dispatch(getAllUsersRequestStarted());

    return getUsersRemote(params)
      .then((json) => {
        dispatch(getAllUsersRequestSuccess(json.users));
        return Promise.resolve(json.users);
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
  };
}
