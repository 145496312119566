export const STORE_FILTER_PROPERTIES = 'filters/accounts/STORE_FILTER_PROPERTIES';
export const STORE_SAVED_FILTERS = 'filters/accounts/STORE_SAVED_FILTERS';
export const ADD_SAVED_FILTER = 'filters/accounts/ADD_SAVED_FILTER';
export const REMOVE_SAVED_FILTER = 'filters/accounts/REMOVE_SAVED_FILTER';
export const UPDATE_SAVED_FILTER = 'filters/accounts/UPDATE_SAVED_FILTER';
export const SET_CURRENT_FILTERS = 'filters/accounts/SET_CURRENT_FILTERS';
export const RESET_CURRENT_FILTERS = 'filters/accounts/RESET_CURRENT_FILTERS';

export default {
  STORE_FILTER_PROPERTIES,
  STORE_SAVED_FILTERS,
  ADD_SAVED_FILTER,
  REMOVE_SAVED_FILTER,
  UPDATE_SAVED_FILTER,
  SET_CURRENT_FILTERS,
  RESET_CURRENT_FILTERS,
};
