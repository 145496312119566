import { REMOVE_CALENDARS, STORE_CALENDAR, STORE_CALENDARS } from './constants';

export function storeCalendar(data) {
  return {
    type: STORE_CALENDAR,
    data,
  };
}

export function storeCalendars(data) {
  return {
    type: STORE_CALENDARS,
    data,
  };
}

export function removeCalendars() {
  return {
    type: REMOVE_CALENDARS,
  };
}

export default {
  storeCalendar,
  storeCalendars,
  removeCalendars,
};
