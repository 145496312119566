import PropTypes from 'prop-types';

import styles from './NoAvailableRecords.module.scss';

export default function NoAvailableRecords({ title, icon, message }) {
  return (
    <>
      <div className={styles.header}>
        <h5>{title}</h5>
      </div>
      <div className={styles.body}>
        <div className={styles.icon}>{icon}</div>
        <p>{message}</p>
      </div>
    </>
  );
}

NoAvailableRecords.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
};
