import React from 'react';

import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';

import ErrorPage from '../ErrorPage';
import NewVersionPage from '../NewVersionPage';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      hasChunkLoadError: false,
    };
  }

  static getDerivedStateFromError(error) {
    const chunkLoadError = /ChunkLoadError: Loading chunk [\d]+ failed./;
    const cssChunkLoadError = /Error: Loading CSS chunk [\d]+ failed./;

    if (chunkLoadError.test(error) || cssChunkLoadError.test(error)) {
      return { hasChunkLoadError: true };
    }
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    const { componentStack } = errorInfo;

    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      // Sentry.captureException(error);
      Sentry.captureException(error, { contexts: { react: { componentStack } } });
    });
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }
    if (this.state.hasChunkLoadError) {
      return <NewVersionPage />;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
