import { nullMeta } from 'helpers/meta';

import {
  ADD_EMAIL_CAMPAIGN,
  CLEAR_STATE,
  DELETE_EMAIL_CAMPAIGN,
  GET_CAMPAIGN_REQUEST_STARTED,
  GET_CAMPAIGN_REQUEST_SUCCESS,
  GET_CONTACTS_REQUEST_STARTED,
  GET_CONTACTS_REQUEST_SUCCESS,
  GET_DEFAULT_OPT_OUT_SETTING,
  GET_EMAIL_CAMPAIGNS_REQUEST_STARTED,
  GET_EMAIL_CAMPAIGNS_REQUEST_SUCCESS,
  REPLACE_CONTACT,
  REPLACE_EMAIL_CAMPAIGN,
  RESET_CAMPAIGN_CONTACTS,
  RESET_EMAIL_CAMPAIGNS_DATA,
  RESET_SELECTION_MODEL,
  SET_CONTACTS_PARAMS,
  SET_SELECTION_MODEL,
  STORE_CONTACT_LIST_DISPLAY_FIELDS,
  UPDATE_CAMPAIGN_LOCAL,
  UPDATE_EMAIL_CAMPAIGN_LOCAL,
  UPDATE_EMAIL_CAMPAIGN_REQUEST_STARTED,
  UPDATE_EMAIL_CAMPAIGN_REQUEST_SUCCESS,
} from './constants';
import { immutableInsert } from '../../../../helpers/immutableHelper';

const initialState = {
  data: null,
  campaignGetInProgress: false,
  emailCampaigns: [],
  emailCampaignGetInProgress: false,
  emailCampaignsMeta: nullMeta,
  emailCampaignsParams: {
    page: 1,
    order: 'desc',
    sort: 'deliver_at',
    query: undefined,
  },
  campaignContactsGetInProgress: false,
  campaignContacts: [],
  campaignContactsMeta: nullMeta,
  campaignContactsParams: {
    page: 1,
  },
  campaignContactsDisplayFields: [],
  selectionModel: [],
  emailCampaignUpdateInProgress: false,
  hasDefaultOptOutLink: null,
};

function getCampaignRequestStartedHandler(state) {
  return { ...state, campaignGetInProgress: true };
}

function getCampaignRequestSuccessHandler(state, action) {
  const defaultOrder = action.campaign.default_order.split(' ');

  return {
    ...state,
    campaignGetInProgress: false,
    data: action.campaign,
    campaignContactsParams: {
      ...state.campaignContactsParams,
      order: defaultOrder[1],
      sort: defaultOrder[0],
    },
  };
}

function getContactsRequestStartedHandler(state) {
  return { ...state, campaignContactsGetInProgress: true };
}

function getContactsRequestSuccessHandler(state, action) {
  return {
    ...state,
    campaignContactsGetInProgress: false,
    campaignContacts: action.contacts,
    campaignContactsMeta: action.meta,
    data: {
      ...state.data,
      numberOfContacts: action.meta.totalCount,
    },
  };
}

function updateCampaignLocalHandler(state, action) {
  return { ...state, data: action.campaign };
}

function updateEmailCampaignLocalHandler(state, action) {
  const itemId = state.emailCampaigns.findIndex((c) => c.id === action.emailCampaign.id);
  if (itemId === -1) {
    return state;
  }

  return {
    ...state,
    emailCampaigns: immutableInsert(state.emailCampaigns, action.emailCampaign, itemId),
  };
}

function deleteEmailCampaignHandler(state, action) {
  return {
    ...state,
    emailCampaigns: state.emailCampaigns.filter((ec) => ec.id !== action.listId),
  };
}

function replaceEmailCampaignHandler(state, action) {
  const newEC = state.emailCampaigns.map((ec) =>
    ec.id !== action.emailCampaign.id ? ec : action.emailCampaign
  );
  return {
    ...state,
    emailCampaigns: newEC,
  };
}

function getEmailCampaignsRequestStartedHandler(state) {
  return { ...state, emailCampaignGetInProgress: true };
}

function getEmailCampaignsRequestSuccessHandler(state, action) {
  return {
    ...state,
    emailCampaignGetInProgress: false,
    emailCampaigns: action.emailCampaigns,
    emailCampaignsMeta: action.meta,
    emailCampaignsParams: action.params,
  };
}

function addEmailCampaignHandler(state, action) {
  return { ...state, emailCampaigns: [...state.emailCampaigns, action.emailCampaign] };
}

export function setContactsParamsHandler(state, action) {
  return {
    ...state,
    campaignContactsParams: {
      ...state.campaignContactsParams,
      ...action.data,
    },
  };
}

function clearStateHandler(state) {
  return { ...state, ...initialState };
}

function storeContactListDisplayFieldsHandler(state, action) {
  return { ...state, campaignContactsDisplayFields: action.displayFields };
}

function replaceContactHandler(state, action) {
  return {
    ...state,
    campaignContacts: immutableInsert(
      state.campaignContacts,
      action.contact,
      state.campaignContacts.findIndex((c) => c.id === action.contact.id)
    ),
  };
}

function setSelectionModelHandler(state, action) {
  return { ...state, selectionModel: action.selectionModel };
}

function resetCampaignContactsStateHandler(state) {
  return {
    ...state,
    selectionModel: [],
    campaignContactsMeta: initialState.campaignContactsMeta,
    campaignContactsParams: initialState.campaignContactsParams,
    campaignContacts: initialState.campaignContacts,
  };
}

function resetSelectionModelHandler(state) {
  return { ...state, selectionModel: [] };
}

function updateEmailCampaignRequestStartedHandler(state) {
  return { ...state, emailCampaignUpdateInProgress: true };
}

function updateEmailCampaignRequestSuccessHandler(state) {
  return {
    ...state,
    emailCampaignUpdateInProgress: false,
  };
}

function resetEmailCampaignsDataHandler(state) {
  return {
    ...state,
    emailCampaigns: initialState.emailCampaigns,
    emailCampaignsMeta: initialState.emailCampaignsMeta,
    emailCampaignsParams: initialState.emailCampaignsParams,
  };
}

function setDefaultOptOutLinkHandler(state, action) {
  return { ...state, hasDefaultOptOutLink: action.hasDefaultOptOutLink };
}

const ACTION_HANDLERS = {
  [GET_CAMPAIGN_REQUEST_STARTED]: getCampaignRequestStartedHandler,
  [GET_CAMPAIGN_REQUEST_SUCCESS]: getCampaignRequestSuccessHandler,
  [GET_CONTACTS_REQUEST_STARTED]: getContactsRequestStartedHandler,
  [GET_CONTACTS_REQUEST_SUCCESS]: getContactsRequestSuccessHandler,
  [GET_EMAIL_CAMPAIGNS_REQUEST_STARTED]: getEmailCampaignsRequestStartedHandler,
  [GET_EMAIL_CAMPAIGNS_REQUEST_SUCCESS]: getEmailCampaignsRequestSuccessHandler,
  [UPDATE_EMAIL_CAMPAIGN_LOCAL]: updateEmailCampaignLocalHandler,
  [DELETE_EMAIL_CAMPAIGN]: deleteEmailCampaignHandler,
  [REPLACE_EMAIL_CAMPAIGN]: replaceEmailCampaignHandler,
  [UPDATE_CAMPAIGN_LOCAL]: updateCampaignLocalHandler,
  [ADD_EMAIL_CAMPAIGN]: addEmailCampaignHandler,
  [SET_CONTACTS_PARAMS]: setContactsParamsHandler,
  [CLEAR_STATE]: clearStateHandler,
  [STORE_CONTACT_LIST_DISPLAY_FIELDS]: storeContactListDisplayFieldsHandler,
  [REPLACE_CONTACT]: replaceContactHandler,
  [SET_SELECTION_MODEL]: setSelectionModelHandler,
  [RESET_CAMPAIGN_CONTACTS]: resetCampaignContactsStateHandler,
  [RESET_SELECTION_MODEL]: resetSelectionModelHandler,
  [UPDATE_EMAIL_CAMPAIGN_REQUEST_STARTED]: updateEmailCampaignRequestStartedHandler,
  [UPDATE_EMAIL_CAMPAIGN_REQUEST_SUCCESS]: updateEmailCampaignRequestSuccessHandler,
  [RESET_EMAIL_CAMPAIGNS_DATA]: resetEmailCampaignsDataHandler,
  [GET_DEFAULT_OPT_OUT_SETTING]: setDefaultOptOutLinkHandler,
};

export default function campaignReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
