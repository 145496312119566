import { createSlice } from '@reduxjs/toolkit';

import { nullMeta, parseMetaFromResponse } from 'helpers/meta';

import { fetchOrderItems, updateOrderItem } from './actions';
import { immutableSplice } from '../../../../helpers/immutableHelper';

const initialState = {
  fetching: false,
  data: [],
  meta: nullMeta,
  params: {
    page: 1,
    sort: undefined,
    order: undefined,
    query: undefined,
  },
};

export const orderItemsSlice = createSlice({
  name: 'order-items',
  initialState,
  reducers: {
    replaceOrderItemInList: (state, { payload }) => {
      const index = state.data.findIndex((o) => o.id === payload.id);
      return { ...state, data: immutableSplice(state.data, index, 1, payload) };
    },
  },
  extraReducers: {
    [fetchOrderItems.pending]: (state) => ({ ...state, fetching: true }),
    [fetchOrderItems.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.sales_order_items,
      meta: parseMetaFromResponse(payload.meta),
      params: payload.params,
      fetching: false,
    }),
    [fetchOrderItems.rejected]: (state) => ({ ...state, fetching: false }),
    [updateOrderItem.fulfilled]: (state, { payload }) => ({
      ...state,
      data: state.data.map((item) => {
        if (item.id === payload.id) return payload;
        return item;
      }),
    }),
  },
});

export const { replaceOrderItemInList } = orderItemsSlice.actions;

export default orderItemsSlice.reducer;
