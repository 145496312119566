import { createContext, useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import Pusher from 'pusher-js';
import { useSelector } from 'react-redux';

import { selectUserId } from '../state/user/selectors';

const PusherContext = createContext();

export function PusherProvider({ children }) {
  const userId = useSelector(selectUserId);
  const [channel, setChannel] = useState(null);
  const pusher = userId
    ? new Pusher(process.env.PUSHER_APP_KEY, {
        cluster: process.env.PUSHER_APP_CLUSTER,
      })
    : null;

  function subscribeToChannel(channelName) {
    return new Promise((resolve, reject) => {
      const channelInstance = pusher.subscribe(channelName);
      channelInstance.bind('pusher:subscription_succeeded', () => {
        resolve(channelInstance);
      });

      channelInstance.bind('pusher:subscription_error', (status) => {
        reject(new Error(`Failed to subscribe, status: ${status}`));
      });
    });
  }

  const setPusherChannel = async () => {
    if (pusher) {
      try {
        const channel1 = await subscribeToChannel(`spiro_channel_${userId}`);
        setChannel(channel1);
      } catch (error) {
        console.error('Error subscribing to channel:', error);
      }
    }
  };

  const unsubscribe = () => {
    if (channel && pusher) {
      channel.unbind();
      pusher.unsubscribe(channel);
    }

    if (pusher) {
      pusher.disconnect();
    }
  };

  useEffect(() => {
    setPusherChannel();
  }, [userId]);

  if ((!pusher || !channel) && userId) {
    return null;
  }

  return (
    <PusherContext.Provider value={{ pusher, channel, unsubscribe }}>
      {children}
    </PusherContext.Provider>
  );
}

PusherProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { PusherContext };
