import pick from 'lodash/pick';

export const EMAIL_PROVIDERS = ['gmail', 'exchange', 'office365', 'imap'];

export function parseStagesMappingFromEngine(data = {}) {
  return data.map((stage) => ({
    crmStage: stage.crm_stage || '',
    spiroStage: stage.spiro_stage || '',
    forecast: stage.forecast || '',
    sort: stage.sort || '',
  }));
}

export function parseOrganizationFromEngine(data = {}) {
  return {
    id: data.id || '',
    name: data.name || '',
    amount: {
      salesforceColumn: data.amount_column || '',
      label: data.amount_humanized || '',
    },
    stagesMapping: data.stages_mapping ? parseStagesMappingFromEngine(data.stages_mapping) : [],
    emailProvider: data.email_provider || undefined,
    locale: data.locale || 'en',
  };
}

export function serializeOrganizationForEngine(organization, keys) {
  const s = {
    name: organization.name,
    amount_column: organization.amount.salesforceColumn,
    amount_humanized: organization.amount.label,
  };

  if (keys) {
    return pick(s, keys);
  }
  return s;
}

export function spiroToCrmStage(spiroStage, stagesMapping) {
  const s = stagesMapping.find((stage) => stage.spiroStage === spiroStage);

  return s.crmStage;
}

export function crmToSpiroStage(crmStage, stagesMapping) {
  const s = stagesMapping.find((stage) => stage.crmStage === crmStage);

  return s.spiroStage;
}

export function getDropdownList(stagesMapping) {
  return stagesMapping.map((stage) => ({
    value: stage.crmStage,
    label: stage.crmStage,
  }));
}

export function isProvider(name, provider) {
  return name === provider;
}

export default {
  parseOrganizationFromEngine,
  serializeOrganizationForEngine,
  parseStagesMappingFromEngine,
  spiroToCrmStage,
  crmToSpiroStage,
  getDropdownList,
};
