import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';

function parseErrorGroups(errorGroups) {
  const errors = [];

  Object.keys(errorGroups).forEach((eg) => {
    if (isArray(errorGroups[eg])) {
      errorGroups[eg].forEach((err) => {
        errors.push(err);
      });
    }
  });

  return errors;
}

export function parseErrors(engineResponse) {
  if (!engineResponse) return [];
  let errors = [];
  if (engineResponse.message) {
    errors.push(engineResponse.message);
  } else if (engineResponse.error) {
    errors.push(engineResponse.error);
  } else if (engineResponse.errors) {
    errors = errors.concat(parseErrorGroups(engineResponse.errors));
  } else {
    errors = errors.concat(parseErrorGroups(engineResponse));
  }
  return errors;
}

export function forEachError(engineResponse, callback) {
  if (!isFunction(callback)) {
    throw new Error('Callback function is not defined');
  }

  const parsedErrors = parseErrors(engineResponse);

  parsedErrors.forEach((err) => {
    callback(err);
  });
}

export default {
  forEachError,
  parseErrors,
};
