export const REQUEST_STARTED = 'NESTED_OPPORTUNITIES: fetch_all_request_started';
export const REQUEST_SUCCESS = 'NESTED_OPPORTUNITIES: fetch_all_request_success';
export const REQUEST_FAILED = 'NESTED_OPPORTUNITIES: fetch_all_request_failed';
export const SORT_OPPORTUNITIES = 'NESTED_OPPORTUNITIES: sort';
export const REPLACE_OPPORTUNITY = 'NESTED_OPPORTUNITIES: replace_opportunities';
export const SET_PARAMS = 'NESTED_OPPORTUNITIES: set_params';
export const RESET = 'NESTED_OPPORTUNITIES: reset';
export const ADD_OPPORTUNITY_TO_LIST = 'NESTED_OPPORTUNITIES: add_opportunity_to_list';
export const STORE_OPPORTUNITIES = 'NESTED_OPPORTUNITIES: store_opportunities';
export const CREATE_IN_PROGRESS = 'NESTED_OPPORTUNITIES: create_in_progress';
export const CREATE_SUCCESS = 'NESTED_OPPORTUNITIES: create_success';
export const CREATE_FAILURE = 'NESTED_OPPORTUNITIES: create_failure';
export const SET_IS_REFRESH_REQUIRED = 'NESTED_OPPORTUNITIES: SET_IS_REFRESH_REQUIRED';
