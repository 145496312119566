import { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { fetchOrgTwilioProfiles } from 'state/twilio-profile/actions';
import { selectAvailableTwilioProfiles } from 'state/twilio-profile/selectors';

import { toggleTransferCall } from '../state/actions';

export default function useTransferCall() {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const availableTwilioProfiles = useSelector(selectAvailableTwilioProfiles);
  const [filteredProfiles, setFilteredProfiles] = useState({ available: [], unavailable: [] });

  useEffect(() => {
    dispatch(fetchOrgTwilioProfiles());
  }, []);

  const handleSetFilteredProfiles = (profiles) => {
    setFilteredProfiles({
      available: profiles.filter((profile) => profile.available),
      unavailable: profiles.filter((profile) => !profile.available),
    });
  };

  const search = () => {
    handleSetFilteredProfiles(
      availableTwilioProfiles.filter((profile) =>
        profile.full_name.toLowerCase().includes(searchValue.toLowerCase())
      )
    );
  };

  const handleSetSearchValue = (e) => {
    setSearchValue(e.target.value);
  };

  const handleClear = () => {
    setSearchValue('');
  };

  useEffect(() => {
    search();
  }, [searchValue]);

  useEffect(() => {
    handleSetFilteredProfiles(availableTwilioProfiles);
  }, [availableTwilioProfiles]);

  const handleOnClick = () => {
    dispatch(toggleTransferCall(false));
  };

  return {
    handleOnClick,
    handleSetSearchValue,
    handleClear,
    searchValue,
    filteredProfiles,
  };
}
