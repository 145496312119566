import { useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import PropTypes from 'prop-types';

import Checkbox from 'lib/Checkbox';

export default function UnreadNotificationsToggle({ fetchNotifications, isLoading, clearState }) {
  const [isChecked, setIsChecked] = useState(false);

  const handleOnChange = (e) => {
    const value = e.target.checked;
    clearState();

    setIsChecked(value);
    fetchNotifications({ is_read: value ? false : null, offset: 0 });
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          name="unread-notifications-toggle"
          checked={isChecked}
          disabled={isLoading}
          onChange={handleOnChange}
        />
      }
      label="Show only unread"
      sx={{
        '.MuiFormControlLabel-label': {
          fontSize: '14px',
          color: '#666666',
        },
      }}
    />
  );
}

UnreadNotificationsToggle.propTypes = {
  fetchNotifications: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};
