import { useContext, useEffect, useRef, useState } from 'react';

import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useDispatch } from 'react-redux';

import useInfiniteScroll from 'app/hooks/useInfiniteScroll';
import { getMessages } from 'services/spiro-phone';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../../helpers/errorHelper';
import { PusherContext } from '../../../../contexts/PusherContext';
import { parseMetaFromResponse } from '../../../../helpers/meta';
import { setConversationScreenOpen } from '../../state/actions';
import MessageCard from '../MessageCard';
import SearchMessages from './SearchMessages';
import { setChatId } from '../Conversation/state/reducer';

import styles from './MessagesList.module.scss';

function MessagesList() {
  const dispatch = useDispatch();
  const bodyRef = useRef();
  const [data, setData] = useState([]);
  const [meta, setMeta] = useState([]);
  const [loading, setLoading] = useState(true);
  const [createdMessage, setCreatedMessage] = useState();
  const { channel } = useContext(PusherContext);

  channel.bind('text_message_created', (newMessage) => {
    setCreatedMessage(newMessage.object.text_message_threads);
  });

  useEffect(() => {
    if (createdMessage?.id) {
      const messageIndex = data.findIndex((msg) => msg.id === createdMessage.id);
      if (messageIndex >= 0) {
        const newData = [...data];
        newData[messageIndex] = { ...newData[messageIndex], ...createdMessage };
        newData.unshift(newData.splice(messageIndex, 1)[0]);
        setData(newData);
      } else {
        setData([createdMessage, ...data]);
      }
    }
  }, [JSON.stringify(createdMessage)]);

  const fetchData = (params) => {
    setLoading(true);
    getMessages(params)
      .then((res) => {
        const newData =
          res.meta.current_page === 1
            ? res.text_message_threads
            : [...data, ...res.text_message_threads];
        const uniqMessages = [...new Map(newData.map((item) => [item.id, item])).values()];
        setData(uniqMessages);
        setMeta(parseMetaFromResponse(res.meta));
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        forEachError(err.data, (e) => dispatch(error(e)));
      });
  };

  const openConversationScreen = () => {
    dispatch(setConversationScreenOpen(true, null));
    dispatch(setChatId(null));
  };

  const { handleRef } = useInfiniteScroll(loading, meta, fetchData);

  useEffect(() => {
    setData([]);
    if (bodyRef.current) bodyRef.current.scroll(0, 0);
    fetchData({ page: 1 });
  }, []);

  return (
    <>
      <div className={styles.header}>
        <h5>Messages</h5>
        <PostAddIcon className={styles.icon} onClick={openConversationScreen} />
      </div>
      <>
        <SearchMessages fetchData={fetchData} />
        <div className={styles.body} ref={bodyRef}>
          {!loading && !data.length && (
            <div className={styles['no-messages-body']}>
              <div className={styles['no-messages-icon']}>
                <ChatBubbleOutlineOutlinedIcon fontSize="large" />
              </div>
              <p>No messages</p>
            </div>
          )}
          {data.map((item, index) => (
            <MessageCard
              key={item.id}
              message={item}
              ref={data.length - 1 === index ? handleRef : null}
            />
          ))}
        </div>
      </>
    </>
  );
}

export default MessagesList;
