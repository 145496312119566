export const GET_USERS_REQUEST_STARTED = 'users: get_users_request_started';
export const GET_USERS_REQUEST_SUCCESS = 'users: get_users_request_success';
export const CREATE_USER_REQUEST_STARTED = 'users: create_user_request_started';
export const CREATE_USER_REQUEST_SUCCESS = 'users: create_user_request_success';
export const CREATE_USER_REQUEST_FAIL = 'users: create_user_request_fail';
export const STORE_USER = 'users: store_user';
export const REQUEST_START = 'users: request start';
export const REQUEST_SUCCESS = 'users: request success';
export const REQUEST_FAIL = 'users: request fail';
export const UPDATE = 'users: update user';
export const GET_ALL_USERS_REQUEST_STARTED = 'users: get all users';
export const GET_ALL_USERS_REQUEST_SUCCESS = 'users: get all users success';

export default {
  GET_USERS_REQUEST_STARTED,
  GET_USERS_REQUEST_SUCCESS,
  CREATE_USER_REQUEST_STARTED,
  CREATE_USER_REQUEST_SUCCESS,
  CREATE_USER_REQUEST_FAIL,
  STORE_USER,
  REQUEST_START,
  REQUEST_SUCCESS,
  REQUEST_FAIL,
  UPDATE,
  GET_ALL_USERS_REQUEST_STARTED,
  GET_ALL_USERS_REQUEST_SUCCESS,
};
