import { useEffect } from 'react';

import PortalModal from 'ui/PortalModal';

import SearchInput from './SearchInput';
import { useSearch } from './SearchProvider';
import SearchResults from './SearchResults';

export default function SearchModal() {
  const {
    visible,
    debouncedSearchTerm,
    params,
    setCleanState,
    setInitialState,
    fetchSearchResults,
  } = useSearch();

  useEffect(() => {
    if ((debouncedSearchTerm && params?.only) || debouncedSearchTerm) {
      fetchSearchResults();
    } else {
      setCleanState();
    }
  }, [debouncedSearchTerm, params]);

  return (
    <PortalModal show={visible} onHide={setInitialState}>
      <SearchInput />
      <SearchResults />
    </PortalModal>
  );
}
