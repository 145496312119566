import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import CloseIcon from '@mui/icons-material/Close';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';

import styles from './DetailsDrawerHeader.module.scss';

function DetailsDrawerHeader({ onClose, icon = null, title = '', date = '', callDuration = '' }) {
  return (
    <div className={styles.header} data-cy="drawer-header">
      <div className={styles.title}>
        {icon}
        <Typography variant="h5" data-cy="drawer-title" className={date ? styles.heading : ''}>
          {title}
        </Typography>
      </div>
      <div className={styles.info}>
        {date && (
          <div className={styles.date}>
            <CalendarTodayOutlinedIcon fontSize="small" />
            <Typography variant="body" data-cy="drawer-date">
              {date}
            </Typography>
          </div>
        )}
        {callDuration && (
          <div className={styles['call-duration']}>
            <WatchLaterOutlinedIcon fontSize="small" />
            <Typography variant="body" data-cy="drawer-call-duration">
              {callDuration}
            </Typography>
          </div>
        )}
        <IconButton aria-label="close" onClick={onClose} size="large" data-cy="close-btn">
          <CloseIcon />
        </IconButton>
      </div>
    </div>
  );
}

DetailsDrawerHeader.propTypes = {
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.node,
  title: PropTypes.string,
  date: PropTypes.string,
  callDuration: PropTypes.string,
};

export default DetailsDrawerHeader;
