import {
  CREATE_USER_REQUEST_FAIL,
  CREATE_USER_REQUEST_STARTED,
  CREATE_USER_REQUEST_SUCCESS,
  GET_ALL_USERS_REQUEST_STARTED,
  GET_ALL_USERS_REQUEST_SUCCESS,
  GET_USERS_REQUEST_STARTED,
  GET_USERS_REQUEST_SUCCESS,
  REQUEST_FAIL,
  REQUEST_START,
  REQUEST_SUCCESS,
  UPDATE,
} from './constants';
import { immutableInsert } from '../../../helpers/immutableHelper';
import { create as createReducer } from '../../core/redux-utils';

const initialState = {
  data: [],
  usersGetInProgress: false,
  userCreateInProgress: false,
  requestInProgress: false,
  requestSuccess: false,
  requestFail: false,
  allUsers: [],
  getAllUsersInProgress: false,
};

function getUsersRequestStartedHandler(state) {
  return { ...state, usersGetInProgress: true };
}

function getUsersRequestSuccessHandler(state, action) {
  return {
    ...state,
    usersGetInProgress: false,
    data: action.users,
  };
}

function createUserRequestStartedHandler(state) {
  return { ...state, userCreateInProgress: true };
}

function createUserRequestFailHandler(state) {
  return { ...state, userCreateInProgress: false };
}

function createUserRequestSuccessHandler(state, action) {
  return {
    ...state,
    userCreateInProgress: false,
    data: [action.user, ...state.data],
  };
}

function requestStartHandler(state) {
  return {
    ...state,
    requestInProgress: true,
    requestSuccess: false,
    requestFail: false,
  };
}

function requestSuccessHandler(state) {
  return {
    ...state,
    requestInProgress: false,
    requestSuccess: true,
    requestFail: false,
  };
}

function requestFailHandler(state) {
  return {
    ...state,
    requestInProgress: false,
    requestSuccess: false,
    requestFail: true,
  };
}

function updateHandler(state, action) {
  const i = state.data.findIndex((item) => item.id === action.id);
  return { ...state, data: immutableInsert(state.data, action.data, i) };
}

function getAllUsersRequestStartedHandler(state) {
  return { ...state, getAllUsersInProgress: true };
}

function getAllUsersRequestSuccessHandler(state, action) {
  return {
    ...state,
    getAllUsersInProgress: false,
    allUsers: action.users,
  };
}

const actionHandlers = {
  [GET_USERS_REQUEST_STARTED]: getUsersRequestStartedHandler,
  [GET_USERS_REQUEST_SUCCESS]: getUsersRequestSuccessHandler,
  [CREATE_USER_REQUEST_STARTED]: createUserRequestStartedHandler,
  [CREATE_USER_REQUEST_SUCCESS]: createUserRequestSuccessHandler,
  [CREATE_USER_REQUEST_FAIL]: createUserRequestFailHandler,
  [REQUEST_START]: requestStartHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REQUEST_FAIL]: requestFailHandler,
  [UPDATE]: updateHandler,
  [GET_ALL_USERS_REQUEST_STARTED]: getAllUsersRequestStartedHandler,
  [GET_ALL_USERS_REQUEST_SUCCESS]: getAllUsersRequestSuccessHandler,
};

export default createReducer(initialState, actionHandlers);
