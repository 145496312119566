import { createSlice } from '@reduxjs/toolkit';

import { nullMeta } from 'helpers/meta';

const initialState = {
  assistantRules: [],
  assistantRulesMeta: nullMeta,
  assistantRulesLoading: true,
  selectedRule: {},
  resourceDetails: {},
  asistantRulesForDashboard: [],
  assistantRuleManager: { label: 'My Alerts', value: 'current_user' },
};

export const assistantSlice = createSlice({
  name: 'assistant',
  initialState,
  reducers: {
    resetAssistantState: (state) => ({
      ...initialState,
      assistantRules: state.assistantRules,
      assistantRulesLoading: state.assistantRulesLoading,
      assistantRulesMeta: state.assistantRulesMeta,
    }),
    clearAssistantRulesState: () => ({ ...initialState }),
    assistantRulesSuccess: (state, { payload }) => ({
      ...state,
      assistantRules: payload.assistantRules,
      assistantRulesMeta: payload.meta,
      selectedRule: payload.selectedRule,
      asistantRulesForDashboard: payload.assistantRules
        .map((rule) => ({
          title: rule.name,
          id: rule.id,
          resource: rule.resource === 'Account' ? 'Company' : rule.resource,
          resourcesCount: 0,
          iconTitle: rule.resource === 'Account' ? 'company' : rule.resource.toLowerCase(),
          message: rule.description,
          order: rule.order,
          potential: 0,
          fetched: false,
          status: rule.status,
          isCurrency: rule.calculation_is_currency,
          enabledOnDashboard: rule.enabled_on_dashboard,
        }))
        .sort((rule1, rule2) => rule1.order - rule2.order),
    }),
    setAssistantRulesLoading: (state) => ({ ...state, assistantRulesLoading: false }),
    setSelectedRule: (state, { payload }) => ({
      ...state,
      selectedRule: payload,
    }),
    setResourceDetails: (state, { payload }) => ({
      ...state,
      resourceDetails: payload,
    }),
    updateStateForAssistantRules: (state, { payload }) => ({
      ...state,
      assistantRules: payload.assistantRules,
      asistantRulesForDashboard: payload.assistantRules
        .map((rule) => ({
          title: rule.name,
          id: rule.id,
          resource: rule.resource === 'Account' ? 'Company' : rule.resource,
          resourcesCount: 0,
          iconTitle: rule.resource === 'Account' ? 'company' : rule.resource.toLowerCase(),
          message: rule.description,
          order: rule.order,
          potential: 0,
          fetched: false,
          status: rule.status,
          isCurrency: rule.calculation_is_currency,
          enabledOnDashboard: rule.enabled_on_dashboard,
        }))
        .sort((rule1, rule2) => rule1.order - rule2.order),
    }),
    updateStateForAssistantRulesForDashboard: (state, { payload }) => {
      const rule = state.asistantRulesForDashboard.find(
        (r) => r.id === payload.data.assistant_rule_id
      );
      const indexOfRule = state.asistantRulesForDashboard.indexOf(rule);
      const newArrayOfRules = [...state.asistantRulesForDashboard];
      newArrayOfRules[indexOfRule] = {
        ...newArrayOfRules[indexOfRule],
        fetched: true,
        potential: payload.data.total,
        resourcesCount: payload.data.count,
      };

      return {
        ...state,
        asistantRulesForDashboard: newArrayOfRules,
      };
    },
    resetStateForAssistantRulesForDashboard: (state, { payload }) => {
      if (!payload) {
        return {
          ...state,
          asistantRulesForDashboard: state.asistantRulesForDashboard.map((rule) => ({
            ...rule,
            fetched: false,
            potential: 0,
            resourcesCount: 0,
          })),
        };
      }
      return {
        ...state,
        asistantRulesForDashboard: state.asistantRulesForDashboard.map((rule) => {
          if (rule.id === payload.id)
            return { ...rule, fetched: false, potential: 0, resourcesCount: 0 };
          return rule;
        }),
      };
    },
    setAssistantRuleManager: (state, { payload }) => ({
      ...state,
      assistantRuleManager: payload,
    }),
  },
});

export const {
  assistantRulesSuccess,
  resetAssistantState,
  clearAssistantRulesState,
  setSelectedTab,
  setAssistantRulesLoading,
  setSelectedRule,
  updateStateForAssistantRules,
  setResourceDetails,
  updateStateForAssistantRulesForDashboard,
  resetStateForAssistantRulesForDashboard,
  setAssistantRuleManager,
} = assistantSlice.actions;

export default assistantSlice.reducer;
