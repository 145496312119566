import axios from 'axios';

import attachmentService from '../../../services/attachments';

const useNotesEditor = () => {
  function uploadFile(file) {
    return attachmentService
      .presignUrl({
        objectName: file.name,
        contentType: file.type,
        public: true,
      })
      .then((res) =>
        axios
          .put(res.signedUrl, file, {
            headers: {
              Accept: '*/*',
              'Content-Type': file.type,
              'x-amz-acl': 'public-read',
            },
          })
          .then(() => ({ data: { link: res.publicUrl, name: file.name, type: file.type } }))
          .catch((e) => console.error(e))
      )
      .catch((e) => console.error(e));
  }

  function getAttachmentHTML(attachment) {
    if (attachment.type.includes('image')) {
      return `<a class="spiro-attachment" href="${attachment.link}" target="_blank" style="display: inline-block; max-width: 50%;"><img style="width: 100%;" src="${attachment.link}" /></a>`;
    }

    return `<a class="mceNonEditable spiro-attachment" style="color: #F06223; text-decoration: none;" href="${attachment.link}" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" height="18px" viewBox="0 0 24 18" width="24px" fill="#F06223"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M2 12.5C2 9.46 4.46 7 7.5 7H18c2.21 0 4 1.79 4 4s-1.79 4-4 4H9.5C8.12 15 7 13.88 7 12.5S8.12 10 9.5 10H17v2H9.41c-.55 0-.55 1 0 1H18c1.1 0 2-.9 2-2s-.9-2-2-2H7.5C5.57 9 4 10.57 4 12.5S5.57 16 7.5 16H17v2H7.5C4.46 18 2 15.54 2 12.5z"/></svg>${attachment.name}</a>`;
  }

  return {
    uploadFile,
    getAttachmentHTML,
  };
};

export default useNotesEditor;
