import { useState } from 'react';

import Switch from '@mui/material/Switch';
import { useDispatch, useSelector } from 'react-redux';

import { setTwilioDeviceDisabled, setupTwilioDevice } from 'components/CallDialog/state/actions';
import {
  selectActiveCallModalOpen,
  selectIsIncomingCall,
  selectTwilioEnabled,
} from 'components/CallDialog/state/selectors';

function CallAvailabilityToggle() {
  const incomingCall = useSelector(selectIsIncomingCall);
  const isActiveCallModalOpen = useSelector(selectActiveCallModalOpen);
  const callsEnabled = useSelector(selectTwilioEnabled);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const onChange = () => {
    if (callsEnabled) {
      dispatch(setTwilioDeviceDisabled());
    } else {
      setLoading(true);
      dispatch(setupTwilioDevice()).then(() => setLoading(false));
    }
  };
  return (
    <Switch
      checked={callsEnabled}
      onChange={onChange}
      disabled={loading || incomingCall || isActiveCallModalOpen}
      sx={{
        '&.MuiSwitch-root .MuiSwitch-switchBase': {
          color: 'rgba(255, 255, 255, 1)',
        },

        '&.MuiSwitch-root .Mui-checked': {
          color: 'rgba(173, 214, 70, 1)',
        },
        '&.MuiSwitch-root .Mui-checked.Mui-disabled': {
          color: 'rgba(179, 179, 179, 1)',
        },
        '&.MuiSwitch-root .MuiSwitch-track': {
          backgroundColor: 'rgba(179, 179, 179, 1)',
          opacity: 1,
        },
        '&.MuiSwitch-root .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
          backgroundColor: 'rgba(255, 255, 255, 1)',
          opacity: 1,
        },
        '&.MuiSwitch-root .MuiSwitch-switchBase.Mui-disabled+.MuiSwitch-track': {
          opacity: 0.9,
        },
      }}
    />
  );
}

export default CallAvailabilityToggle;
