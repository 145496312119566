import { createAsyncThunk } from '@reduxjs/toolkit';

import { serializeFiltersToString } from 'app/helpers/filters';
import { getOpportunitiesForSummaryView } from 'services/opportunities';
import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/opportunities//actions';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../helpers/errorHelper';

function getParamsFromState(state) {
  const params = state.opportunitiesSummaryView.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.opportunities.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.Opportunity;

  const paramsObj = params.sort
    ? defaultParams
    : { ...defaultParams, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export const fetchOpportunitiesForSummaryView = createAsyncThunk(
  'opportunities-summary-view-get',
  async (params = {}, { dispatch, getState }) => {
    try {
      dispatch(fetchFilterProperties());
      await dispatch(fetchSavedFilters());

      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      const res = await getOpportunitiesForSummaryView(payload);
      return Promise.resolve({ ...res, params: payload });
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
