import { bindActionCreators } from '@reduxjs/toolkit';
import { connect } from 'react-redux';

import PushNotifications from './PushNotifications';
import {
  setTwilioNumberVerificationStatus,
  storeAvailability,
} from '../../state/twilio-profile/actions';
import { storeUnreadMessage } from '../Conversation/state/actions';

function mapStateToProps(state) {
  return {
    app: state.app,
    userId: state.session.userId,
    parentInteraction: state.conversation.parentInteraction,
    alertsDisabled: state.user.webAlertsDisabled,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      setTwilioNumberVerificationStatus,
      storeAvailability,
      storeUnreadMessage,
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PushNotifications);
