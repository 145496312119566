import PropTypes from 'prop-types';

import SupportDeskProductOnly from 'components/SupportDeskProductOnly';

import UnauthorizedAccess from '../UnauthorizedAccess';

function SupportDeskRoute({ children }) {
  return (
    <SupportDeskProductOnly fallback={<UnauthorizedAccess />}>{children}</SupportDeskProductOnly>
  );
}

SupportDeskRoute.propTypes = {
  children: PropTypes.any.isRequired,
};

export default SupportDeskRoute;
