import { EXPIRE_SUBSCRIPTION, SET_SUBSCRIPTION } from './constants';

export function setSubscription(data) {
  return {
    type: SET_SUBSCRIPTION,
    data,
  };
}

export function expireSubscription(data) {
  return {
    type: EXPIRE_SUBSCRIPTION,
    data,
  };
}

export default {
  setSubscription,
  expireSubscription,
};
