import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarMenuCollapsed: false,
  listsMenuVisible: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    toggleSidebarMenu: (state) => {
      localStorage.setItem('sidebarMenuCollapsed', !state.sidebarMenuCollapsed);
      return { ...state, sidebarMenuCollapsed: !state.sidebarMenuCollapsed };
    },
    toggleSidebarMenuWithoutLocalStorage: (state) => ({
      ...state,
      sidebarMenuCollapsed: !state.sidebarMenuCollapsed,
    }),
    openListsMenu: (state) => ({ ...state, listsMenuVisible: true }),
    closeListsMenu: (state) => ({ ...state, listsMenuVisible: false }),
  },
  extraReducers: {},
});

export const {
  toggleSidebarMenu,
  openListsMenu,
  toggleSidebarMenuWithoutLocalStorage,
  closeListsMenu,
} = appSlice.actions;

export default appSlice.reducer;
