import { open } from '../../../../helpers/URLHelper';

export function isLinkActive(linkPathname, locationPathname) {
  if (linkPathname === '/lists') {
    return linkPathname === locationPathname;
  }

  if (locationPathname.includes('/assistant-rules')) return false;

  return locationPathname.startsWith(linkPathname);
}

export function isAssistantLink(displayFieldName) {
  return displayFieldName === 'assistant';
}

function getPathNameForOpportunities() {
  const opportunityView = localStorage.getItem('opportunityView');
  const pathnames = {
    pipeline: '/opportunities/pipeline',
    list: '/opportunities',
    summary: '/opportunities/summary',
  };
  return pathnames[opportunityView || 'list'];
}

function getPathNameForCompanies() {
  const accountView = localStorage.getItem('accountView');
  const pathnames = {
    list: '/companies',
    summary: '/companies/summary',
  };
  return pathnames[accountView || 'list'];
}

export function getPathname(name) {
  return (
    {
      opportunities: getPathNameForOpportunities(),
      contacts: '/contacts',
      companies: getPathNameForCompanies(),
      activities: '/activities',
      campaigns: '/campaigns',
      lists: '/lists',
      reminders: '/reminders',
      tickets: '/tickets',
      assistant: '/assistant',
      quotes: '/quotes',
      orders: '/orders',
    }[name] || '/'
  );
}

export function openReportsUrl(zohoZuid, params) {
  if (params.reportsLink) {
    open(params.reportsLink, '_blank');
    return;
  }

  if (zohoZuid) {
    open(`/reports/signin?email=${params.zohoEmail}`, '_blank');
  } else {
    open(`/reports/signup?email=${params.zohoEmail}`, '_blank');
  }
}
