import { styled } from '@mui/material/styles';
import MuiTabs from '@mui/material/Tabs';

const StyledTabs = styled(MuiTabs)({
  color: 'rgba(153, 153, 153, 1)',
  '& button': {
    padding: '0 24px',
  },
  '.MuiTabs-indicator': {
    backgroundColor: '#5D43CF',
  },
});

export default function Tabs(props) {
  return <StyledTabs {...props} />;
}
