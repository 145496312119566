import { useEffect, useRef, useState } from 'react';

import Divider from '@mui/material/Divider';
import FormGroup from '@mui/material/FormGroup';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { getPhoneTypeFromNumber } from 'helpers/contacts';
import { removePhoneNumberFormatting } from 'helpers/twilio';
import Button from 'lib/Button';
import TextField from 'lib/TextField';
import { error as errorAlert } from 'state/notifications/actions';

import { getPayload, htmlToPlainTextForSms } from './helpers';
import UploadFilesPreview from '../UploadFilesPreview';
import useFileUpload from './useFileUpload';
import { forEachError } from '../../../../helpers/errorHelper';
import { createMessage } from '../../../services/spiro-phone';
import { addNewMessage, setDrafts } from '../../CallDialog/MessagesTab/Conversation/state/reducer';
import { selectDrafts } from '../../CallDialog/MessagesTab/Conversation/state/selectors';
import EmojisButton from '../EmojisButton/EmojisButton';
import TemplateButton from '../TemplateButton/TemplateButton';
import UploadButton from '../UploadButton';
import VCardButton from '../VCardButton';
import VCardPreview from '../VCardPreview/VCardPreview';

import styles from './MessageForm.module.scss';

function MessageForm({ onSuccess, phone, user, contact = null, className = '', maxRows = '1000' }) {
  const dispatch = useDispatch();
  const uploaderRef = useRef(null);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const drafts = useSelector(selectDrafts);
  const draftIndex = drafts.findIndex((draft) => draft.id === phone);

  const [vCard, setVCard] = useState(null);

  const [formData, setFormData] = useState({
    body: draftIndex < 0 ? '' : drafts[draftIndex].value,
    contact_id: contact?.id || null,
    phone_type: 'mobile',
    message_type: 'sms',
    phone,
  });
  const {
    files,
    fileUrls,
    uploading,
    progress,
    uploadingFileName,
    getSignedUrl,
    onUploadStart,
    onUploadProgress,
    onUploadError,
    onUploadFinish,
    removeFile,
    resetUploader,
  } = useFileUpload(uploaderRef);

  useEffect(() => {
    setFormData((prevState) => ({
      ...prevState,
      phone_type: contact ? getPhoneTypeFromNumber(phone, contact) : 'mobile',
    }));
  }, [contact]);

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const clearState = () => {
    setIsLoading(false);
    setSelectedTemplate('');
    setVCard(null);
    setFormData({
      body: '',
      contact_id: contact?.id || null,
      phone_type: contact ? getPhoneTypeFromNumber(phone, contact) : 'mobile',
      message_type: 'sms',
      phone,
    });
    dispatch(setDrafts(drafts.filter((draft) => draft.id !== phone)));
    resetUploader();
  };

  const toggleTemplatesModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const onSendClick = async () => {
    const payload = getPayload(
      {
        ...formData,
        body: formData.body.trim(),
        phone: removePhoneNumberFormatting(formData.phone),
      },
      vCard
        ? [
            ...fileUrls,
            { publicUrl: vCard.file_url.publicUrl, signedUrl: vCard.file_url.signedUrl },
          ]
        : fileUrls
    );

    setIsLoading(true);

    try {
      const res = await createMessage(payload);
      dispatch(addNewMessage({ user, ...res }));
      clearState();
      onSuccess();
    } catch (error) {
      setIsLoading(false);
      forEachError(error.data, (e) => dispatch(errorAlert(e)));
    }
  };

  const saveDrafts = (draftMessage = formData.body) => {
    if (draftIndex < 0 && draftMessage) {
      dispatch(setDrafts([...drafts, { id: phone, value: draftMessage }]));
    } else if (draftIndex >= 0 && draftMessage) {
      const newDrafts = [...drafts];
      newDrafts[draftIndex] = { id: phone, value: draftMessage };
      dispatch(setDrafts(newDrafts));
    } else if (draftIndex >= 0 && !draftMessage) {
      dispatch(setDrafts(drafts.filter((draft) => draft.id !== phone)));
    }
  };

  const onTemplateSelect = () => {
    setFormData((prevState) => {
      const newBody = prevState.body ? `${prevState.body}\n${selectedTemplate}` : selectedTemplate;
      const plainText = htmlToPlainTextForSms(newBody);
      saveDrafts(plainText);
      return { ...prevState, body: plainText };
    });
    toggleTemplatesModal();
    setSelectedTemplate('');
  };

  const handleOnBlur = () => saveDrafts();

  const onKeyDown = (e) => {
    if (e.keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      onSendClick();
    }
  };

  const handleEmojiChange = (emoji) =>
    onChange({ target: { value: `${formData.body} ${emoji}`, name: 'body' } });

  const clearVCard = () => setVCard(null);

  return (
    <>
      <Divider className={styles.divider} />
      <div className={classNames(styles.form, className)}>
        <FormGroup>
          <TextField
            name="body"
            value={formData.body}
            disabled={isLoading}
            onChange={onChange}
            placeholder="Type a new message"
            multiline
            onBlur={handleOnBlur}
            onKeyDown={onKeyDown}
            inputProps={{ maxLength: 1600 }}
            maxRows={maxRows}
          />
        </FormGroup>
        <div>
          {files.length > 0 && (
            <UploadFilesPreview
              files={files}
              onRemove={removeFile}
              isUploading={uploading}
              progress={progress}
              uploadingFileName={uploadingFileName}
            />
          )}
          {vCard && <VCardPreview vCard={vCard} onClear={clearVCard} />}
        </div>
        <footer className={styles.footer}>
          <div className={styles.row}>
            <div className={styles.row}>
              <UploadButton
                uploading={uploading}
                progress={progress}
                isLoading={isLoading}
                getSignedUrl={getSignedUrl}
                onUploadStart={onUploadStart}
                onUploadProgress={onUploadProgress}
                onUploadError={onUploadError}
                onUploadFinish={onUploadFinish}
                ref={uploaderRef}
                disabled={!!vCard}
              />
              <TemplateButton
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                onTemplateSelect={onTemplateSelect}
                toggleTemplatesModal={toggleTemplatesModal}
                isModalVisible={isModalVisible}
                contact={contact}
              />
              <EmojisButton handleOnChange={handleEmojiChange} />
              <VCardButton setVCard={setVCard} disabled={!!files.length} />
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={onSendClick}
              disabled={isLoading || uploading}
            >
              {isLoading ? 'Sending' : 'Send'}
            </Button>
          </div>
        </footer>
      </div>
    </>
  );
}

MessageForm.propTypes = {
  phone: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  contact: PropTypes.object,
  className: PropTypes.string,
  maxRows: PropTypes.string,
};

export default MessageForm;
