class CollaborationNote {
  constructor(result) {
    this.id = result.id;
    this.type = result.type;
    this.name = result.highlight.name || result.highlight.plain_text[0] || result.name;
    this.resourceId = result.resource_id;
    this.resourceName = result.resource_name;
    this.resourceType = result.resource_type;
  }
}

export default CollaborationNote;
