import EngineService from '../core/engine-service';

const ENDPOINT = '/reminders';

export function getAll(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function get(reminderId) {
  return EngineService.get(`${ENDPOINT}/${reminderId}`).then((json) => json);
}

export function create(payload) {
  return EngineService.post(ENDPOINT, { reminder: payload }).then((json) => json);
}

export function update(reminderId, payload) {
  return EngineService.patch(`${ENDPOINT}/${reminderId}`, { reminder: payload }).then(
    (json) => json
  );
}

export function destroy(reminderId) {
  return EngineService.delete(`${ENDPOINT}/${reminderId}`).then((json) => json);
}

export function getRemindersFilterProperties(params = {}) {
  return EngineService.get('/filters/reminders/columns', params).then((json) => json);
}

export function getRemindersCount(params = {}) {
  return EngineService.get(`${ENDPOINT}/count`, params);
}

export default {
  getAll,
  get,
  create,
  update,
  destroy,
  getRemindersFilterProperties,
  getRemindersCount,
};
