import EngineService from '../core/engine-service';

const ENDPOINT = '/contacts';

// GET: /contacts
export function getContacts(params) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

// POST: /contacts
export function createContact(payload) {
  return EngineService.post(ENDPOINT, { contact: payload }).then((json) => json);
}

// GET: /contacts/:id
export function getContact(contactId) {
  return EngineService.get(`${ENDPOINT}/${contactId}`).then((json) => json);
}

// PATCH: /contacts/:id
export function updateContact(contactId, payload) {
  return EngineService.patch(`${ENDPOINT}/${contactId}`, { contact: payload }).then((json) => json);
}

// DELETE: /contacts/:id
export function deleteContact(contactId) {
  return EngineService.delete(`${ENDPOINT}/${contactId}`).then((json) => json);
}

// GET: /contacts/:id/custom_objects
export function getCustomObjects(id) {
  return EngineService.get(`${ENDPOINT}/${id}/custom_objects`).then((json) => json);
}

// GET: /search/contacts/columns
export function getContactFilterProperties(params = {}) {
  return EngineService.get('/filters/contacts/columns', params).then((json) => json);
}

// POST: /contacts/batch_delete
export function batchDeleteContacts(contacts) {
  return EngineService.post('/contacts/batch_delete', { contacts }).then((json) => json);
}

export function getContactsCount(params = {}) {
  return EngineService.get(`${ENDPOINT}/count`, params);
}

export function getContactListViewSettings(params = {}) {
  return EngineService.get('/Contact/list_view_settings', params);
}

export function updateContactListViewSettings(payload) {
  return EngineService.put('/Contact/list_view_settings', payload);
}

export function deleteContactFromMultipleCampaigns(contactID, payload) {
  return EngineService.post(`${ENDPOINT}/${contactID}/lists/batch_delete`, payload);
}

const contactService = {
  getContacts,
  getContact,
  createContact,
  deleteContact,
  updateContact,
  getCustomObjects,
  getContactFilterProperties,
  batchDeleteContacts,
  getContactsCount,
  getContactListViewSettings,
  updateContactListViewSettings,
};

export default contactService;
