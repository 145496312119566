import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isNoteOpened: false,
  repliedTo: null,
};

export const collaborationNotesSlice = createSlice({
  name: 'collaborationNotes',
  initialState,
  reducers: {
    setNoteOpened: (state, { payload }) => ({ ...state, isNoteOpened: payload }),
    setRepliedTo: (state, { payload }) => ({ ...state, repliedTo: payload }),
  },
  extraReducers: {},
});

export const { setNoteOpened, setRepliedTo } = collaborationNotesSlice.actions;

export default collaborationNotesSlice.reducer;
