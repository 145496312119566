import PropTypes from 'prop-types';

import GroupList from './GroupList';

import styles from './ChatGroup.module.scss';

export default function ChatGroup({ name, ...props }) {
  return (
    <div>
      <div className={styles.date}>{name}</div>
      <GroupList {...props} />
    </div>
  );
}

ChatGroup.propTypes = {
  name: PropTypes.string.isRequired,
};
