export const SET_INTERACTION_DESCRIPTION = 'SET_INTERACTION_DESCRIPTION';
export const SET_INTERACTION_PENDING = 'SET_INTERACTION_PENDING';
export const SET_INTERACTION_FULFILLED = 'SET_INTERACTION_FULFILLED';
export const SET_INTERACTION_REJECTED = 'SET_INTERACTION_REJECTED';
export const SET_INTERACTION_SUBJECT = 'SET_INTERACTION_SUBJECT';
export const SET_SELECTED_INTERACTION = 'SET_SELECTED_INTERACTION';
export const SET_COMPOSE_EMAIL_PARAMS = 'SET_COMPOSE_EMAIL_PARAMS';
export const SET_MAIL_ACTION = 'SET_MAIL_ACTION';
export const SET_SELECTED_TEMPLATE = 'SET_SELECTED_TEMPLATE';
export const SET_EMAIL_PARAMS = 'SET_EMAIL_PARAMS';
export const SET_EMAIL_TEMPLATES = 'SET_EMAIL_TEMPLATES';
export const INTERACTION_CHILD_PENDING = 'INTERACTION_CHILD_PENDING';
export const INTERACTION_CHILD_FULFILLED = 'INTERACTION_CHILD_FULFILLED';
export const INTERACTION_CHILD_REJECTED = 'INTERACTION_CHILD_REJECTED';
export const INTERACTION_CHILD_CLEARED = 'INTERACTION_CHILD_CLEARED';
export const SET_INTERACTION_CUSTOM_FIELD = 'SET_INTERACTION_CUSTOM_FIELD';
export const SET_ATTACHMENT_ASSET_URL = 'SET_ATTACHMENT_ASSET_URL';
export const INTERACTION_REMOVE_TRANSCRIPTIONS = 'INTERACTION_REMOVE_TRANSCRIPTIONS';
export const INTERACTION_TRANSCRIPTIONS_PENDING = 'INTERACTION_TRANSCRIPTIONS_PENDING';
export const INTERACTION_TRANSCRIPTIONS_FULFILLED = 'INTERACTION_TRANSCRIPTIONS_FULFILLED';
export const INTERACTION_TRANSCRIPTIONS_REJECTED = 'INTERACTION_TRANSCRIPTIONS_REJECTED';
export const SET_TRANSCRIPTION_SEARCH = 'SET_TRANSCRIPTION_SEARCH';
export const CLEAR_TRANSCRIPTION_SEARCH = 'CLEAR_TRANSCRIPTION_SEARCH';
export const TEXT_INTERACTION_CHILD_PENDING = 'TEXT_INTERACTION_CHILD_PENDING';
export const TEXT_INTERACTION_CHILD_FULFILLED = 'TEXT_INTERACTION_CHILD_FULFILLED';
export const TEXT_INTERACTION_CHILD_REJECTED = 'TEXT_INTERACTION_CHILD_REJECTED';
export const TEXT_INTERACTION_CHILD_CLEARED = 'TEXT_INTERACTION_CHILD_CLEARED';
export const CLEAR_TEXT_INTERACTION_CHILD = 'CLEAR_TEXT_INTERACTION_CHILD';
export const SUMMARY_PENDING = 'SUMMARY_PENDING';
export const SUMMARY_FULFILLED = 'SUMMARY_FULFILLED';
export const SUMMARY_REJECTED = 'SUMMARY_REJECTED';
export const INTENTS_ACTIONS_PENDING = 'INTENTS_ACTIONS_PENDING';
export const INTENTS_ACTIONS_FULFILLED = 'INTENTS_ACTIONS_FULFILLED';
export const INTENTS_ACTIONS_REJECTED = 'INTENTS_ACTIONS_REJECTED';
export const REMOVE_INTENT_AFTER_CREATE = 'REMOVE_INTENT_AFTER_CREATE';
export const UPDATE_TEXT_INTERACTION_CHILD = 'UPDATE_TEXT_INTERACTION_CHILD';
export const MISSED_CALL_NUMBER_PENDING = 'MISSED_CALL_NUMBER_PENDING';
export const MISSED_CALL_NUMBER_FULFILLED = 'MISSED_CALL_NUMBER_FULFILLED';
export const MISSED_CALL_NUMBER_REJECTED = 'MISSED_CALL_NUMBER_REJECTED';
