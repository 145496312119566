import { useSelector } from 'react-redux';

import { selectTwilioConnection } from 'components/CallDialog/state/selectors';

export default function useCallerInfo() {
  const connection = useSelector(selectTwilioConnection);

  const isConnectionPending = () => connection?.status() === 'pending';

  const isConnectionOpen = () => connection?.status() === 'open';

  const getAccountName = (callers) => {
    const firstAccountName = callers[0].account_name;
    const allSame = callers.every((caller) => caller.account_name === firstAccountName);

    return allSame ? firstAccountName : 'Multiple companies';
  };

  return { isConnectionPending, isConnectionOpen, getAccountName };
}
