import { useEffect, useMemo } from 'react';

import PersonAddOutlinedIcon from '@mui/icons-material/PersonAddOutlined';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { setVCardsData } from 'app/components/Conversation/state/actions';
import { selectVCardsData } from 'app/components/Conversation/state/selectors';
import { hideModal, showModal } from 'app/modals/state/actions';
import ContactAvatar from 'components/ContactAvatar';
import { createContact } from 'services/contacts';
import { extractVCard } from 'services/vCards';
import { error as errorAlert } from 'state/notifications/actions';

import { forEachError } from '../../../../helpers/errorHelper';

import styles from './VCard.module.scss';

const BlackTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'black',
    color: 'white',
    marginBottom: '0px !important',
  },
}));

export default function VCard({ url, position, text = '' }) {
  const vCardsData = useSelector(selectVCardsData);
  const dispatch = useDispatch();

  const extractedContact = vCardsData[url];

  const handleExtractVCard = useMemo(
    () => async () => {
      try {
        const res = await extractVCard(url);
        const payload = { [url]: { ...res.contact } };
        dispatch(setVCardsData(payload));
      } catch (err) {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
      }
    },
    [url]
  );

  const handleCreateContact = (payload) =>
    createContact(payload)
      .then((json) => {
        if (json.contact.matched) {
          dispatch(errorAlert('This contact with the same email address already exists'));
          return Promise.reject();
        }
        dispatch(hideModal());
        return Promise.resolve();
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });

  const openNewContactModal = () => {
    dispatch(
      showModal('NEW_CONTACT_MODAL', {
        show: true,
        account: extractedContact?.account_id
          ? { id: extractedContact?.account_id, name: extractedContact?.account_name }
          : undefined,
        firstName: extractedContact?.first_name,
        lastName: extractedContact?.last_name,
        email: extractedContact?.email,
        title: extractedContact?.title,
        mobilePhone: extractedContact?.phone,
        onHide: () => dispatch(hideModal()),
        onSubmit: handleCreateContact,
      })
    );
  };

  useEffect(() => {
    if (!url || vCardsData[url]) return;
    handleExtractVCard();
  }, [url]);

  if (!extractedContact) {
    return <Skeleton variant="rounded" width={258} height={64} sx={{ mt: text ? '16px' : 0 }} />;
  }

  return (
    <div className={styles.container} style={{ marginTop: text ? '16px' : 0 }}>
      <ContactAvatar contact={extractedContact} className={styles.avatar} />
      <div className={styles.info}>
        <p className={styles.name}>
          {extractedContact.first_name} {extractedContact.last_name}
        </p>
        <p className={styles['company-name']}>{extractedContact?.account_name}</p>
      </div>
      {position === 'left' && (
        <BlackTooltip title="Add Contact" placement="top">
          <IconButton
            onClick={openNewContactModal}
            className={styles.button}
            disableRipple
            disableElevation
          >
            <PersonAddOutlinedIcon />
          </IconButton>
        </BlackTooltip>
      )}
    </div>
  );
}

VCard.propTypes = {
  text: PropTypes.string,
  url: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
};
