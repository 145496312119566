import { useSelector } from 'react-redux';

import Conversation from './Conversation';
import MessagesList from './MessagesList';
import { selectIsConversationScreenVisible } from '../state/selectors';

function MessagesTab() {
  const isConversationScreenVisible = useSelector(selectIsConversationScreenVisible);

  if (isConversationScreenVisible) return <Conversation />;

  return <MessagesList />;
}

export default MessagesTab;
