export const STORE_FILTER_PROPERTIES = 'filters/users/STORE_FILTER_PROPERTIES';
export const STORE_SAVED_FILTERS = 'filters/users/STORE_SAVED_FILTERS';
export const ADD_SAVED_FILTER = 'filters/users/ADD_SAVED_FILTER';
export const REMOVE_SAVED_FILTER = 'filters/users/REMOVE_SAVED_FILTER';
export const UPDATE_SAVED_FILTER = 'filters/users/UPDATE_SAVED_FILTER';
export const SET_CURRENT_FILTERS = 'filters/users/SET_CURRENT_FILTERS';
export const RESET_CURRENT_FILTERS = 'filters/users/RESET_CURRENT_FILTERS';

export default {
  STORE_FILTER_PROPERTIES,
  STORE_SAVED_FILTERS,
  ADD_SAVED_FILTER,
  REMOVE_SAVED_FILTER,
  UPDATE_SAVED_FILTER,
  SET_CURRENT_FILTERS,
  RESET_CURRENT_FILTERS,
};
