import { combineReducers } from '@reduxjs/toolkit';

import activityTypesForm from './activity-types-form/reducers';
import addAttachment from './add-attachment/reducers';
import customFieldForm from './custom-field-form/reducers';
import deleteCustomEntity from './delete-custom-entity/reducers';
import deleteCustomField from './delete-custom-field/reducers';
import editCustomEntity from './edit-custom-entity/reducers';
import emailConnectExchange from './email-connect-exchange/reducers';
import emailConnectImap from './email-connect-imap/reducers';
import newCustomEntity from './new-custom-entity/reducers';
import pipeline from './pipeline/reducers';
import userPassword from './user-password-modal/reducers';

export default combineReducers({
  addAttachment,
  userPassword,
  pipeline,
  activityTypesForm,
  emailConnectExchange,
  emailConnectImap,
  newCustomEntity,
  editCustomEntity,
  deleteCustomEntity,
  customFieldForm,
  deleteCustomField,
});
