import { createSlice } from '@reduxjs/toolkit';

import { nullMeta, parseMetaFromResponse } from 'app/helpers/meta';

import { fetchAccountsForSummaryView } from './actions';

const initialState = {
  data: [],
  isLoading: false,
  params: {
    page: 1,
    q: undefined,
  },
  meta: nullMeta,
};

export const accountsSummaryViewSlice = createSlice({
  name: 'accountsSummaryView',
  initialState,
  reducers: {
    resetState: () => ({ ...initialState }),
  },
  extraReducers: {
    [fetchAccountsForSummaryView.pending]: (state) => ({ ...state, isLoading: true }),
    [fetchAccountsForSummaryView.fulfilled]: (state, { payload }) => ({
      ...state,
      isLoading: false,
      data: payload.accounts,
      meta: parseMetaFromResponse(payload.meta),
      params: payload.params,
    }),
    [fetchAccountsForSummaryView.rejected]: (state) => ({ ...state, isLoading: false }),
  },
});

export const { resetState } = accountsSummaryViewSlice.actions;

export default accountsSummaryViewSlice.reducer;
