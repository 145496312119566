export function immutablePush(arr = [], item) {
  return [...arr, item];
}

export function immutablePop(arr) {
  return arr.slice(0, -1);
}

export function immutableShift(arr) {
  return arr.slice(1);
}

export function immutableUnshift(arr, newEntry) {
  return [newEntry, ...arr];
}

export function immutableInsert(arr, newEntry, index) {
  const i = index === undefined || index < 0 ? arr.length : index;
  return [...arr.slice(0, i), newEntry, ...arr.slice(i + 1)];
}

export function immutableSort(arr, compareFunction) {
  return [...arr].sort(compareFunction);
}

export function immutableReverse(arr) {
  return [...arr].reverse();
}

export function immutableSplice(arr, start, deleteCount = 0, ...items) {
  return [...arr.slice(0, start), ...items, ...arr.slice(start + deleteCount)];
}

export function immutableDelete(arr, index) {
  return arr.slice(0, index).concat(arr.slice(index + 1));
}

export function immutableFindAndDelete(arr, predicate) {
  return immutableDelete(arr, arr.findIndex(predicate));
}

export function immutableSwap(arr, firstIndex, secondIndex) {
  return arr.map((element, index) => {
    switch (index) {
      case firstIndex:
        return arr[secondIndex];
      case secondIndex:
        return arr[firstIndex];
      default:
        return element;
    }
  });
}

export default {
  immutablePush,
  immutablePop,
  immutableShift,
  immutableUnshift,
  immutableInsert,
  immutableSort,
  immutableReverse,
  immutableSplice,
  immutableDelete,
  immutableFindAndDelete,
};
