import { createAsyncThunk } from '@reduxjs/toolkit';

import { cloneRule, createEngineRule, getEngineRules } from 'services/engine-rules';
import { error, success as successAlert } from 'state/notifications/actions';

import { forEachError } from '../../../../../helpers/errorHelper';

export const fetchEngineRules = createAsyncThunk(
  'engine-rules-get',
  async (params = {}, { getState }) => {
    const recordsPerPage = getState().spiroViews.recordsPerPage;
    const reqParams = { ...getState().engineRules.params, ...params };
    const defaultParams = { ...reqParams, per_page: recordsPerPage };

    return getEngineRules(defaultParams);
  }
);

export const handleCreateEngineRule = createAsyncThunk(
  'engine-rules-create',
  async (payload, { dispatch }) => {
    try {
      const res = await createEngineRule(payload);
      dispatch(successAlert('Assistant Rule created!'));
      dispatch(fetchEngineRules());
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const handleCloneEngineRule = createAsyncThunk(
  'engine-rules-clone',
  async (payload, { dispatch }) => {
    try {
      const res = await cloneRule(payload);
      dispatch(successAlert('Assistant Rule cloned!'));
      return res.assistant_rule;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
