import { lazy, Suspense } from 'react';

import { createTheme, StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';
import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { DndProvider } from 'react-dnd';
import Backend from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { RouterProvider } from 'react-router-dom';

import ConfettiCelebration from 'components/ConfettiCelebration';
import { parseQueryString } from 'helpers/utils';
import { toggleSidebarMenu } from 'state/app/reducer';
import { error as errorAlert } from 'state/notifications/actions';

import Notifications from './app/components/Notifications';
import { PusherProvider } from './app/contexts/PusherContext';
import EngineService from './app/core/engine-service';
import retry from './app/helpers/retry';
import { parseTokenResponseData } from './app/helpers/session';
import { parseTokenFromEngine } from './app/helpers/tokens';
import { requestOneTimeInvitation } from './app/services/auth';
import { createUserSession, destroyUserSession } from './app/state/session/actions';
import { getUser } from './app/state/user/actions';
import { forEachError } from './helpers/errorHelper';
import localStorage from './helpers/localStorage';
import router from './router';
import store from './store';
import './styles/main.scss';

LicenseInfo.setLicenseKey(process.env.MATERIAL_UI_LICENCE_KEY);

const ModalManager = lazy(() =>
  retry(() => import(/* webpackChunkName: "ModalManager" */ './app/modals/ModalManager'))
);

const parsedQueryString = parseQueryString(window.location.search);
const urlContainsAccessToken = parsedQueryString.access_token;
const invitationToken = parsedQueryString.invitation_token;
const urlContainsJWT = parsedQueryString.t;
const sessionCookie = Cookies.get(process.env.APP_ID) || parsedQueryString.t;
const session = sessionCookie
  ? parseTokenResponseData(jwtDecode(sessionCookie))
  : localStorage.getItem('session');
const isSidebarMenuCollapsed = localStorage.getItem('sidebarMenuCollapsed');

EngineService.config({ store });

// sync redux store session with local storage session
if (session) {
  store.dispatch(createUserSession(session));

  if (!store.getState().user.fetched) {
    store.dispatch(getUser(session.userId, router.navigate));
  }
}

// sync redux store session with query string params
if (urlContainsAccessToken) {
  store.dispatch(createUserSession(parseTokenResponseData(parsedQueryString)));
  store.dispatch(getUser(parsedQueryString.user_id, router.navigate));
  router.navigate('/', { search: '' });
}

if (isSidebarMenuCollapsed) {
  store.dispatch(toggleSidebarMenu());
} else {
  localStorage.saveItem('sidebarMenuCollapsed', false);
}

if (urlContainsJWT) {
  const decodedJWT = jwtDecode(parsedQueryString.t);

  Cookies.set(process.env.APP_ID, parsedQueryString.t, {
    domain: '.spiro.ai',
    path: '/',
    secure: true,
  });
  store.dispatch(createUserSession(parseTokenResponseData(decodedJWT)));
  store.dispatch(getUser(decodedJWT.user_id, router.navigate));
  router.navigate('/', { search: '' });
}

if (invitationToken) {
  store.dispatch(destroyUserSession());

  requestOneTimeInvitation(invitationToken)
    .then((response) => {
      const auth = parseTokenFromEngine(response);
      store.dispatch(createUserSession(auth));
      store.dispatch(getUser(auth.userId, router.navigate));
      router.navigate('/', { search: '' });
    })
    .catch((err) => {
      router.navigate('/login');
      forEachError(err.data, (e) => store.dispatch(errorAlert(e)));
    });
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#5D43CF',
    },
    icons: {
      main: '#999999',
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#B9B9B9',
          minWidth: '160px',
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          color: '#B9B9B9',
          backgroundColor: '#B9B9B9',
          fontSize: '50px',
        },
      },
    },
  },
});
function App() {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <DndProvider backend={Backend}>
          <Provider store={store}>
            <PusherProvider>
              <Suspense fallback={<div />}>
                <RouterProvider router={router} />
                <Notifications navigate={router.navigate} />
                <ModalManager navigate={router.navigate} />
                <ConfettiCelebration />
              </Suspense>
            </PusherProvider>
          </Provider>
        </DndProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
