import { createSlice } from '@reduxjs/toolkit';

import { fetchSupportDesks, handleCreateSupportDesk, handleDeleteSupportDesk } from './actions';

const initialState = {
  fetching: false,
  data: [],
  createInProgress: false,
  addNewSupportDeskModalVisible: false,
};

export const supportDeskSlice = createSlice({
  name: 'support-desks',
  initialState,
  reducers: {
    setAddNewSupportDeskModalVisible: (state, { payload }) => ({
      ...state,
      addNewSupportDeskModalVisible: payload,
    }),
    setSupportDesks: (state, { payload }) => ({ ...state, data: payload }),
  },
  extraReducers: {
    [fetchSupportDesks.pending]: (state) => ({ ...state, fetching: true }),
    [fetchSupportDesks.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.support_desks.map((d) => ({
        ...d,
        connected_email: d.connected_email === null ? '-' : d.connected_email,
      })),
      fetching: false,
    }),
    [fetchSupportDesks.rejected]: (state) => ({ ...state, fetching: false }),
    [handleCreateSupportDesk.pending]: (state) => ({ ...state, isCreateInProgress: true }),
    [handleCreateSupportDesk.fulfilled]: (state) => ({
      ...state,
      isCreateInProgress: false,
      addNewSupportDeskModalVisible: false,
    }),
    [handleCreateSupportDesk.rejected]: (state) => ({ ...state, isCreateInProgress: false }),
    [handleDeleteSupportDesk.pending]: (state) => ({ ...state, fetching: true }),
    [handleDeleteSupportDesk.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.id,
      fetching: false,
    }),
    [handleDeleteSupportDesk.rejected]: (state) => ({ ...state, fetching: false }),
  },
});

export const { setAddNewSupportDeskModalVisible, setSupportDesks } = supportDeskSlice.actions;

export default supportDeskSlice.reducer;
