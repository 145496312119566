import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import ActiveEditor from 'components/CollaborationNotes/ActiveEditor';

import { setFormValues } from '../state/actions';
import { selectFormValues } from '../state/selectors';

function CallNotes() {
  const formValues = useSelector(selectFormValues);
  const [notes, setNotes] = useState(formValues.callNotes);
  const dispatch = useDispatch();

  const handleOnChange = (value) => {
    setNotes(value);
    dispatch(setFormValues({ ...formValues, callNotes: value }));
  };

  return <ActiveEditor editorValue={notes} isActive onChange={handleOnChange} />;
}

export default CallNotes;
