import { nullMeta } from 'helpers/meta';

import {
  FETCH_INTERACTIONS_STARTED,
  SET_PARAMS,
  SET_RECORDS_COUNT,
  STORE_INTERACTIONS,
} from './constants';

const initialState = {
  isLoading: false,
  data: [],
  meta: nullMeta,
  params: {
    page: 1,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    q: undefined,
  },
  recordsCount: 0,
};

const ACTION_HANDLERS = {
  [FETCH_INTERACTIONS_STARTED]: (state) => ({
    ...state,
    isLoading: true,
  }),
  [STORE_INTERACTIONS]: (state, action) => ({
    ...state,
    isLoading: false,
    data: action.data,
    meta: {
      ...state.meta,
      ...action.meta,
    },
  }),
  [SET_PARAMS]: (state, action) => ({
    ...state,
    params: {
      ...state.params,
      ...action.data,
    },
  }),
  [SET_RECORDS_COUNT]: (state, action) => ({
    ...state,
    recordsCount: action.recordsCount,
  }),
};

export default function interactionsDataReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
