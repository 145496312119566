import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import BouncingDots from 'app/UI/Loaders/BouncingDots/BouncingDots';

import ActiveCall from '../CallDialogModal/ActiveCall';
import IncomingCallModal from '../CallDialogModal/IncomingCallModal';
import useCallDialogModal from '../CallDialogModal/useCallDialogModal';
import MakeACallScreen from '../MakeACallScreen';
import { incrementCallDurationBySecond } from '../state/actions';
import {
  selectActiveCallModalOpen,
  selectCallTimer,
  selectIsIncomingCall,
  selectIsNoteScreenVisible,
  selectTwilioConnectingInProgress,
  selectTwilioConnection,
} from '../state/selectors';

import styles from './PhoneTab.module.scss';

function PhoneTab() {
  const connection = useSelector(selectTwilioConnection);
  const incomingCall = useSelector(selectIsIncomingCall);
  const isActiveCallModalOpen = useSelector(selectActiveCallModalOpen);
  const isNoteScreenOpen = useSelector(selectIsNoteScreenVisible);
  const isTimerStarted = useSelector(selectCallTimer);
  const dispatch = useDispatch();
  const twilioConnectingInProgress = useSelector(selectTwilioConnectingInProgress);

  useCallDialogModal();

  useEffect(() => {
    let interval = null;

    if (isTimerStarted) {
      interval = setInterval(() => {
        dispatch(incrementCallDurationBySecond());
      }, 1000);
    } else {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [isTimerStarted]);

  if (twilioConnectingInProgress) {
    return (
      <div className={styles.container}>
        <BouncingDots grey />
        <span className={styles.text}>Connecting...</span>
      </div>
    );
  }

  return (
    <>
      {!incomingCall && !connection && !isNoteScreenOpen && <MakeACallScreen />}
      {incomingCall && connection && <IncomingCallModal />}
      {isActiveCallModalOpen && !incomingCall && <ActiveCall />}
    </>
  );
}
export default PhoneTab;
