import ResourceLookup from '../ResourceLookup/ResourceLookup';

function SupportDeskLookup(props) {
  return (
    <div className="suport-desk-lookup-input">
      <ResourceLookup {...props} resource="support_desk" />
    </div>
  );
}

export default SupportDeskLookup;
