import { ADD_NOTIFICATION, REMOVE_NOTIFICATION, REMOVE_NOTIFICATION_BY_UID } from './constants';

export function add(notification) {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      ...notification,
      uid: Date.now(),
    },
  };
}

export function error(text, data) {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      text,
      type: 'error',
      uid: Date.now(),
      data,
    },
  };
}

export function warning(text) {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      text,
      type: 'warning',
      uid: Date.now(),
    },
  };
}

export function info(text) {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      text,
      type: 'info',
      uid: Date.now(),
    },
  };
}

export function success(text) {
  return {
    type: ADD_NOTIFICATION,
    notification: {
      text,
      type: 'success',
      uid: Date.now(),
    },
  };
}

export function remove(key) {
  return {
    type: REMOVE_NOTIFICATION,
    key,
  };
}

export function removeByUid(uid) {
  return {
    type: REMOVE_NOTIFICATION_BY_UID,
    uid,
  };
}

export default {
  add,
  error,
  warning,
  info,
  success,
  remove,
  removeByUid,
};
