import { connect } from 'react-redux';

import MessageForm from './MessageForm';
import { error as errorAlert } from '../../../state/notifications/actions';
import { createMessage } from '../state/actions';

function mapStateToProps(state, ownProps) {
  return {
    contact: ownProps.contact || state.conversation.contact,
    createInProgress: state.conversation.createInProgress,
    user: state.user,
  };
}

export default connect(mapStateToProps, {
  createMessage,
  errorAlert,
})(MessageForm);
