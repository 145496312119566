import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import WifiCalling3OutlinedIcon from '@mui/icons-material/WifiCalling3Outlined';
import { IconButton } from '@mui/material';
import cs from 'classnames';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import {
  selectMissedCallsCount,
  selectUnreadMessagesCount,
  selectUnreadVoicemailsCount,
} from 'app/state/counters/selectors';
import { ReactComponent as PoorConnectionIcon } from 'images/poor-connection.svg';

import { selectIsBadNetwork } from '../state/selectors';

import styles from './CallStatus.module.scss';

export default function CallStatus({
  onClick,
  isInCall = false,
  isLoggingActivity = false,
  isConnecting = false,
}) {
  const unreadText = useSelector(selectUnreadMessagesCount);
  const missedCalls = useSelector(selectMissedCallsCount);
  const voicemails = useSelector(selectUnreadVoicemailsCount);
  const isBadNetwork = useSelector(selectIsBadNetwork);

  const unreadNotificationsCount = unreadText + missedCalls + voicemails;

  const renderIcon = () => {
    if (isConnecting) return <WifiCalling3OutlinedIcon data-cy="navbar-phone-icon" />;
    if (isBadNetwork) return <PoorConnectionIcon data-cy="navbar-phone-icon" />;
    return <PhoneOutlinedIcon data-cy="navbar-phone-icon" />;
  };

  return (
    <div
      className={cs({
        [styles['phone-wrapper']]: true,
        [styles['is-in-call']]: isInCall && !isConnecting,
        [styles['is-logging-activity']]: isLoggingActivity,
        [styles.connecting]: isConnecting || isBadNetwork,
      })}
    >
      <IconButton
        aria-label="Phone"
        onClick={onClick}
        data-cy="navbar-toggle-twilio-button"
        size="large"
      >
        {renderIcon()}
      </IconButton>
      {isInCall ||
        isLoggingActivity ||
        (!!unreadNotificationsCount && (
          <div className={styles.indicator}>{unreadNotificationsCount}</div>
        ))}
    </div>
  );
}

CallStatus.propTypes = {
  onClick: PropTypes.func.isRequired,
  isInCall: PropTypes.bool,
  isLoggingActivity: PropTypes.bool,
  isConnecting: PropTypes.bool,
};
