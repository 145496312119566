export const FETCHING_CUSTOM_FIELDS = 'fetching_custom_fields';
export const FETCHING_CUSTOM_FIELDS_FAILED = 'fetching_custom_fields_failed';
export const STORE_CUSTOM_FIELDS = 'store_custom_fields';
export const STORE_CUSTOM_FIELD = 'store_custom_field';
export const REPLACE_CUSTOM_ENTITY = 'replace_custom_entity';
export const REMOVE_CUSTOM_FIELD = 'remove_custom_field';
export const REMOVE_CUSTOM_ENTITY = 'remove_custom_entity';
export const SELECT_CUSTOM_FIELDS_RESOURCE = 'select_custom_field_resource';
export const STORE_NEW_CUSTOM_FIELDS_RESOURCE = 'select_new_custom_fields_resource';

export default {
  STORE_CUSTOM_FIELDS,
  FETCHING_CUSTOM_FIELDS,
  FETCHING_CUSTOM_FIELDS_FAILED,
  SELECT_CUSTOM_FIELDS_RESOURCE,
  REMOVE_CUSTOM_FIELD,
  STORE_NEW_CUSTOM_FIELDS_RESOURCE,
};
