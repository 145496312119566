import axios from 'axios';

import EngineService from '../core/engine-service';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

const baseService = axios.create({
  baseURL: process.env.ENGINE_BASE_URL,
  headers,
});

export function requestSpiroAccess(payload) {
  return baseService.post('/request_access', { token: payload }).then((json) => json);
}

export function getRequestedAccesses(params = {}) {
  return EngineService.get('access_requests', params).then((json) => json);
}

export function updateRequestedAccess(id, payload) {
  return EngineService.patch(`access_requests/${id}`, { status: payload }).then((json) => json);
}
