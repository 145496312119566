import { getPhoneNotificationsCounters } from 'services/spiro-phone';

import { COUNTERS_REQUEST_STARTED, COUNTERS_REQUEST_SUCCESS, COUNTERS_UPDATE } from './constants';

export function requestStarted() {
  return {
    type: COUNTERS_REQUEST_STARTED,
  };
}

export function updateCounters(payload) {
  return {
    type: COUNTERS_UPDATE,
    payload,
  };
}

export function requestSuccess(json) {
  return {
    type: COUNTERS_REQUEST_SUCCESS,
    missedCalls: json.counters.calls,
    unreadSMS: json.counters.messages,
    voicemails: json.counters.voicemails,
  };
}

export function getCounters() {
  return async (dispatch) => {
    dispatch(requestStarted());

    const json = await getPhoneNotificationsCounters();
    return dispatch(requestSuccess(json));
  };
}
