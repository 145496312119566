import { setInteractionsCount } from 'app/activities/Activities/state/actions';
import { setCampaignsCount } from 'app/campaigns/Campaigns/state/data/actions';
import { setAccountsCount } from 'app/companies/Accounts/state/actions';
import { setContactsCount } from 'app/contacts/Contacts/state/actions';
import { setCustomObjectsCount } from 'app/lists/ListsPage/state/actions';
import { setOpportunitiesCount } from 'app/opportunities/Opportunities/state/actions';
import { setOrdersCount } from 'app/orders/Orders/state/reducer';
import { setQuotesCount } from 'app/quotes/Quotes/state/data/reducer';
import { setRemindersCount } from 'app/reminders/Reminders/state/data/reducer';
import { setTicketsCount } from 'app/tickets/Tickets/state/data/reducer';

const actionByResource = {
  Account: setAccountsCount,
  Contact: setContactsCount,
  Opportunity: setOpportunitiesCount,
  Quote: setQuotesCount,
  SalesOrder: setOrdersCount,
  List: setCampaignsCount,
  Reminder: setRemindersCount,
  Ticket: setTicketsCount,
  Interaction: setInteractionsCount,
  CustomObject: setCustomObjectsCount,
};

export function useNotificationsForRecordsCount() {
  const getActionByResourceName = (resource) => actionByResource[resource];
  return { getActionByResourceName };
}
