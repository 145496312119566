import {
  CONVERSATION_CLEAR,
  CONVERSATION_CLEAR_UNREAD_MESSAGES,
  CONVERSATION_MESSAGE_CREATE_STARTED,
  CONVERSATION_MESSAGE_CREATE_SUCCESS,
  CONVERSATION_PARENT_REQUEST_STARTED,
  CONVERSATION_PARENT_REQUEST_SUCCESS,
  CONVERSATION_REQUEST_STARTED,
  CONVERSATION_REQUEST_SUCCESS,
  CONVERSATION_SET_CONTACT,
  CONVERSATION_SET_VCARDS_DATA,
  CONVERSATION_STORE_UNREAD_MESSAGE,
} from './constants';
import { forEachError } from '../../../../helpers/errorHelper';
import { parseMetaFromResponse } from '../../../helpers/meta';
import SmsMessage from '../../../models/interactions/SmsMessage';
import {
  getChildInteractions,
  getInteractionById,
  all as getInteractions,
} from '../../../services/interactions';
import { update as updateSmsMessage } from '../../../services/sms-messages';
import twilioService from '../../../services/twilioService';
import { error as errorAlert } from '../../../state/notifications/actions';

export function setConversationContact(contact) {
  return {
    type: CONVERSATION_SET_CONTACT,
    contact,
  };
}

export function requestStarted() {
  return {
    type: CONVERSATION_REQUEST_STARTED,
  };
}

export function requestSuccess(json) {
  return {
    type: CONVERSATION_REQUEST_SUCCESS,
    data: json.interactions.map((msg) => new SmsMessage(msg)),
    meta: parseMetaFromResponse(json.meta),
  };
}

export function messageCreateStarted() {
  return {
    type: CONVERSATION_MESSAGE_CREATE_STARTED,
  };
}

export function messageCreateSuccess(message) {
  return {
    type: CONVERSATION_MESSAGE_CREATE_SUCCESS,
    message: new SmsMessage(message),
  };
}

export function storeUnreadMessage(message) {
  return {
    type: CONVERSATION_STORE_UNREAD_MESSAGE,
    message: new SmsMessage(message),
  };
}

export function clearUnreadMessages() {
  return {
    type: CONVERSATION_CLEAR_UNREAD_MESSAGES,
  };
}

export function requestParentInteraction() {
  return {
    type: CONVERSATION_PARENT_REQUEST_STARTED,
  };
}

export function setParentInteraction(interaction) {
  return {
    type: CONVERSATION_PARENT_REQUEST_SUCCESS,
    interaction,
  };
}

export function setVCardsData(data) {
  return {
    type: CONVERSATION_SET_VCARDS_DATA,
    data,
  };
}

export function createMessage(payload) {
  return (dispatch) => {
    dispatch(messageCreateStarted());

    return twilioService
      .createMessage(payload)
      .then((json) => {
        dispatch(messageCreateSuccess(json.sms_message));
        return Promise.resolve(json.sms_message);
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
  };
}

function getInteraction(interactionId) {
  return (dispatch) =>
    getInteractionById(interactionId)
      .then((json) => {
        dispatch(setParentInteraction(new SmsMessage(json.interaction)));
        return Promise.resolve(json);
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
}

export function getConversation() {
  return (dispatch, getState) => {
    const userID = getState().user.id;
    const contactID = getState().conversation.contact?.id;

    dispatch(requestParentInteraction());

    return getInteractions({
      type: 'SmsMessage',
      user_id: userID,
      contact_id: contactID,
    })
      .then((json) => {
        const responseInteraction = json.interactions[0];

        if (responseInteraction) {
          return dispatch(getInteraction(responseInteraction.id));
        }
        dispatch(setParentInteraction(null));
        return Promise.resolve();
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
  };
}

export function confirmMessage(id) {
  return () => updateSmsMessage(id, { confirmed: true });
}

export function getMessages(parentID, params) {
  return (dispatch) => {
    dispatch(requestStarted());

    return getChildInteractions(parentID, params)
      .then((json) => dispatch(requestSuccess(json)))
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
  };
}

export function clearConversation() {
  return {
    type: CONVERSATION_CLEAR,
  };
}
