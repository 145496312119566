import EngineService from '../core/engine-service';

const ENDPOINT = '/assistant_rules';

export function getEngineRules(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function getEngineRule(ruleId) {
  return EngineService.get(`${ENDPOINT}/${ruleId}`).then((json) => json);
}

export function createEngineRule(payload) {
  return EngineService.post(ENDPOINT, { assistant_rule: payload }).then((json) => json);
}

export function updateEngineRule(ruleId, payload) {
  return EngineService.patch(`${ENDPOINT}/${ruleId}`, { assistant_rule: payload }).then(
    (json) => json
  );
}

export function getConditionsForRule(ruleId, params) {
  return EngineService.get(`${ENDPOINT}/${ruleId}/conditions`, params).then((json) => json);
}

export function getConditionDefinitions(params = {}) {
  return EngineService.get(`${ENDPOINT}/definitions`, params).then((json) => json);
}

export function createRuleCondition(ruleId, payload) {
  return EngineService.post(`${ENDPOINT}/${ruleId}/conditions`, { condition: payload }).then(
    (json) => json
  );
}

export function updateRuleCondition(ruleId, conditionId, payload) {
  return EngineService.put(`${ENDPOINT}/${ruleId}/conditions/${conditionId}`, {
    condition: payload,
  }).then((json) => json);
}

export function deleteRuleCondition(ruleId, conditionId) {
  return EngineService.delete(`${ENDPOINT}/${ruleId}/conditions/${conditionId}`).then(
    (json) => json
  );
}

export function getResourcesForAssistantRule(ruleId, params = {}) {
  return EngineService.get(`${ENDPOINT}/${ruleId}/resources`, params).then((json) => json);
}

export function deleteRule(ruleId) {
  return EngineService.delete(`${ENDPOINT}/${ruleId}`).then((json) => json);
}

export function cloneRule(ruleId) {
  return EngineService.post(`${ENDPOINT}/${ruleId}/clone`).then((json) => json);
}

export function ignoreRule(ruleId, payload) {
  return EngineService.post(`${ENDPOINT}/${ruleId}/ignored_resources`, {
    ignored_resource: payload,
  }).then((json) => json);
}

export function getConfigurations() {
  return EngineService.get(`${ENDPOINT}/configurations`).then((json) => json);
}

export function getCalculationFields(resource) {
  return EngineService.get(`${ENDPOINT}/fields?resource=${resource}`).then((json) => json);
}

export function getAssistantDashboardSummaries(id, params = {}) {
  return EngineService.get(`assistant/dashboard_summaries?user_id=${id}`, params).then(
    (json) => json
  );
}

export function getUserPickerOptionForAssistantRule(resource) {
  return EngineService.get(`${ENDPOINT}/user_picker_options?resource=${resource}`).then(
    (json) => json
  );
}

export function getRuleActionFields(resource) {
  return EngineService.get(`${ENDPOINT}/action_fields?resource=${resource}`).then((json) => json);
}

export function createEngieRulesActions(ruleId, payload) {
  return EngineService.post(`${ENDPOINT}/${ruleId}/actions`, {
    assistant_rule_action: payload,
  }).then((json) => json);
}

export function deleteEngieRulesAction(ruleId, actionId) {
  return EngineService.delete(`${ENDPOINT}/${ruleId}/actions/${actionId}`).then((json) => json);
}

export function updateEngieRulesAction(ruleId, actionId, payload) {
  return EngineService.patch(`${ENDPOINT}/${ruleId}/actions/${actionId}`, {
    assistant_rule_action: payload,
  }).then((json) => json);
}

export function getEmailTemplateFields(ruleId) {
  return EngineService.get(`${ENDPOINT}/${ruleId}/email_template_fields`).then((json) => json);
}
