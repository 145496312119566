import reject from 'lodash/reject';

import {
  FETCHING_EMAIL_TEMPLATES,
  FETCHING_EMAIL_TEMPLATES_FAILED,
  REMOVE_EMAIL_TEMPLATE,
  SELECT_EMAIL_TEMPLATE,
  STORE_EMAIL_TEMPLATE,
  STORE_EMAIL_TEMPLATES,
  STORE_EMAIL_TEMPLATES_ATTRIBUTES,
} from './constants';
import { immutableInsert, immutableSplice } from '../../../../../helpers/immutableHelper';
import emailTemplate from '../../../../components/TemplatesSettings/helper';

function findTemplate(list, id) {
  return list.find((t) => t.id === id);
}

function findTemplateIndex(list, template) {
  return list.findIndex((t) => template.id === t.id);
}

function selectPreviousEmailTemplate(templates, selectedTemplate) {
  const tmp = templates;
  const i = findTemplateIndex(tmp, selectedTemplate);
  const previous = i > 0 ? i - 1 : 1;
  return tmp[previous];
}

function parseEmailTemplates(data) {
  return data.map((item) => emailTemplate.parse(item));
}

function insertTemplate(list, template) {
  const i = findTemplateIndex(list, template);
  return immutableInsert(list, template, i);
}

function removeTemplate(list, template) {
  const i = findTemplateIndex(list, template);
  return immutableSplice(list, i, 1);
}

function filterEditableTemplates(templates) {
  return reject(templates, (l) => l.title === 'Write Your Own');
}

const initialState = {
  fetching: false,
  data: [],
  selectedGroup: 'email_rapport',
  selected: null, // FIXME rename to selectedTemplate
  failed: false,
  fetched: false,
  attributes: {},
};

export default function emailTemplatesReducer(state = initialState, action) {
  switch (action.type) {
    case FETCHING_EMAIL_TEMPLATES:
      return { ...state, fetching: true, fetched: false };
    case STORE_EMAIL_TEMPLATES: {
      const templates = filterEditableTemplates(parseEmailTemplates(action.data.email_templates));
      return {
        ...state,
        fetching: false,
        data: [...templates],
        selected: templates[0],
        failed: false,
        fetched: true,
      };
    }
    case STORE_EMAIL_TEMPLATE:
      return {
        ...state,
        data: insertTemplate(state.data, emailTemplate.parse(action.data)),
        fetching: false,
        fetched: true,
        failed: false,
      };
    case REMOVE_EMAIL_TEMPLATE: {
      const withoutTemplate = removeTemplate(state.data, { id: action.id });
      const nextSelected = selectPreviousEmailTemplate(state.data, state.selected);

      return {
        ...state,
        data: [...withoutTemplate],
        selected: nextSelected ? { ...nextSelected } : null,
        fetching: false,
        fetched: true,
        failed: false,
      };
    }
    case FETCHING_EMAIL_TEMPLATES_FAILED:
      return { ...state, fetching: false, failed: true };
    case SELECT_EMAIL_TEMPLATE: {
      let selected = null;
      if (action.data) {
        selected = findTemplate(state.data, action.data);
      } else {
        selected = state.data[0];
      }
      return { ...state, selected: selected ? { ...selected } : null };
    }
    case STORE_EMAIL_TEMPLATES_ATTRIBUTES:
      return { ...state, attributes: action.data };
    default:
      return state;
  }
}
