import get from 'lodash/get';
import Moment from 'moment';
// import Contact from '../models/Contact';

function nullUser() {
  return {
    id: null,
    fullName: '',
  };
}

function parseUser(user) {
  if (!user) {
    return nullUser();
  }
  return {
    id: user.id,
    fullName: user.full_name,
  };
}

export function blank() {
  return {
    id: null,
    title: '',
    description: '',
    createdAt: null,
    active: false,
    creator: nullUser(),
    user: nullUser(),
  };
}

export function parse(data = {}) {
  if (!data) {
    return null;
  }

  return {
    ...blank(),
    id: data.id,
    title: data.title,
    description: data.description,
    createdAt: data.created_at,
    active: data.active,
    creator: parseUser(data.creator),
    user: parseUser(data.user),
    defaultOrder: data.default_order,
    numberOfContacts: data.contacts_count,
  };
}

export function parseContacts(targetList) {
  if (!get(targetList, 'contacts')) {
    return [];
  }
  return targetList.contacts;
  // return targetList.contacts.map((c) => new Contact(c));
}

export function parseAll(data = []) {
  return data.map((item) => parse(item));
}

export function getActiveStatus(campaign) {
  if (campaign.active) {
    return 'Yes';
  }
  return 'No';
}

export function getCampaignStatus(campaign) {
  if (campaign.active) {
    return 'Active';
  }
  return 'Inactive';
}

export function getCreatedAt(campaign, dateFormat = 'MMM D, YYYY') {
  return Moment(campaign.createdAt).format(dateFormat);
}

export function campaignsPath() {
  return '/campaigns';
}

export function campaignPath(campaignId) {
  return `/campaigns/${campaignId}`;
}

export function campaignManagePath(campaignId) {
  return `/campaigns/${campaignId}/manage`;
}

export default {
  blank,
  parse,
  parseAll,
};
