const ACTIONS = {
  SET_VISIBLE: 'SET_VISIBLE',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  SET_LOADING: 'SET_LOADING',
  SET_RESULTS: 'SET_RESULTS',
  SET_META: 'SET_META',
  SET_PARAMS: 'SET_PARAMS',
  SET_CLEAN_STATE: 'SET_CLEAN_STATE',
  SET_INITIAL_STATE: 'SET_INITIAL_STATE',
};

export default ACTIONS;
