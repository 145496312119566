import PropTypes from 'prop-types';

import styles from './TabFooter.module.scss';

function TabFooter({ children }) {
  return <div className={styles.footer}>{children}</div>;
}

TabFooter.propTypes = {
  children: PropTypes.object.isRequired,
};

export default TabFooter;
