import { createSelector } from '@reduxjs/toolkit';
import sortBy from 'lodash/sortBy';

export const selectUsers = (state) => state.users.data;
export const selectUserCreateInProgress = (state) => state.userCreateInProgress;
export const selectAllUsers = (state) => state.users.allUsers;

export const selectManagers = createSelector([selectUsers], (users) =>
  users.filter((user) => user.isManager)
);

export const selectActiveUsers = (state) =>
  state.users.data.map((user) => ({
    value: user.id,
    label: user.fullName,
  }));

export const selectAllUsersMappedForFilter = (state) =>
  state.users.allUsers.map((user) => ({ label: user.fullName, value: user.id }));

export const selectAllUsersMappedForOwnerField = (state) =>
  sortBy(
    state.users.allUsers.map((user) => ({
      label: user.fullName,
      value: user.id,
      disabled: user.status === 'deactivated' || user.role === 'administration',
    })),
    'disabled'
  );

export default {
  selectUsers,
  selectManagers,
  selectUserCreateInProgress,
  selectAllUsers,
  selectActiveUsers,
};
