import { useCallback, useEffect, useRef, useState } from 'react';

import PauseOutlinedIcon from '@mui/icons-material/PauseOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import VolumeOffOutlinedIcon from '@mui/icons-material/VolumeOffOutlined';
import VolumeUpOutlinedIcon from '@mui/icons-material/VolumeUpOutlined';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';

import styles from './SpiroAudioPlayer.module.scss';

const formatTime = (time) => {
  if (time && !Number.isNaN(time)) {
    const minutes = Math.floor(time / 60);
    const formatMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const seconds = Math.ceil(time % 60);
    const formatSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    return `${formatMinutes}:${formatSeconds}`;
  }
  return '00:00';
};
export default function SpiroAudioPlayer({ url = '' }) {
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [timeProgress, setTimeProgress] = useState(0);
  const [muteVolume, setMuteVolume] = useState(false);
  const audioRef = useRef();
  const progressBarRef = useRef();
  const playAnimationRef = useRef();

  const repeat = useCallback(() => {
    const currentTime = audioRef.current.currentTime;
    setTimeProgress(currentTime);
    progressBarRef.current.value = audioRef.current.currentTime;

    progressBarRef.current.style.setProperty(
      '--range-progress',
      `${(currentTime / duration) * 100}%`
    );

    playAnimationRef.current = requestAnimationFrame(repeat);
  }, [audioRef, duration, progressBarRef, setTimeProgress]);

  const togglePlayPause = () => setIsPlaying((prev) => !prev);
  const toggleMute = () => setMuteVolume((prevState) => !prevState);

  const onEnded = () => {
    setIsPlaying(false);
    cancelAnimationFrame(playAnimationRef.current);
  };

  const handleProgressChange = () => {
    audioRef.current.currentTime = progressBarRef.current.value;
  };

  const onLoadedMetadata = () => {
    const seconds = audioRef.current.duration;
    setDuration(seconds);
    progressBarRef.current.max = seconds;
  };

  useEffect(() => {
    if (isPlaying) {
      audioRef.current.play();
      playAnimationRef.current = requestAnimationFrame(repeat);
    } else {
      audioRef.current.pause();
      cancelAnimationFrame(playAnimationRef.current);
    }
  }, [isPlaying, audioRef]);

  useEffect(() => {
    audioRef.current.muted = muteVolume;
  }, [muteVolume]);

  return (
    <div className={styles.container}>
      <IconButton onClick={toggleMute}>
        {muteVolume && <VolumeOffOutlinedIcon />}
        {!muteVolume && <VolumeUpOutlinedIcon />}
      </IconButton>
      <div className={styles.player}>
        <div>
          <audio src={url} ref={audioRef} onLoadedMetadata={onLoadedMetadata} onEnded={onEnded} />
          <input
            type="range"
            ref={progressBarRef}
            min={0}
            step="any"
            defaultValue={0}
            onChange={handleProgressChange}
            className={styles.range}
          />
        </div>
        <div className={styles.progress}>
          <span>{formatTime(timeProgress)}</span>
          <span>{formatTime(duration)}</span>
        </div>
      </div>

      {!isPlaying && (
        <IconButton onClick={togglePlayPause}>
          <PlayArrowOutlinedIcon />
        </IconButton>
      )}
      {isPlaying && (
        <IconButton onClick={togglePlayPause}>
          <PauseOutlinedIcon />
        </IconButton>
      )}
    </div>
  );
}

SpiroAudioPlayer.propTypes = {
  url: PropTypes.string,
};
