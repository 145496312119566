import { useDispatch } from 'react-redux';

import { error, success } from 'state/notifications/actions';

const useAlerts = () => {
  const dispatch = useDispatch();

  function showSuccessAlert(message) {
    dispatch(success(message));
  }

  function showErrorAlert(message) {
    dispatch(error(message));
  }

  return { showSuccessAlert, showErrorAlert };
};

export default useAlerts;
