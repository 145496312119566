import AlarmIcon from '@mui/icons-material/Alarm';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import BarChartIcon from '@mui/icons-material/BarChart';
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import ListAltIcon from '@mui/icons-material/ListAlt';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import SpiroTooltip from 'components/SpiroTooltip';
import { selectIsSidebarMenuCollapsed } from 'state/app/selectors';

import { getPathname, isLinkActive } from '../helpers';
import { ReactComponent as Assistant } from '../icons/assistant-icon.svg';
import ReportsLink from '../ReportsLink/ReportsLink';

import styles from './SidebarItem.module.scss';

function SidebarItem({ field }) {
  const location = useLocation();
  const { t } = useTranslation();
  const pathname = getPathname(field.name);
  const linkActive = isLinkActive(pathname, location.pathname);
  const isSidebarCollapsed = useSelector(selectIsSidebarMenuCollapsed);

  const icons = {
    opportunities: <StarBorderOutlinedIcon data-cy="sidebar-opportunities-icon" />,
    contacts: <PersonOutlineIcon data-cy="sidebar-contacts-icon" />,
    companies: <HomeWorkOutlinedIcon data-cy="sidebar-companies-icon" />,
    campaigns: <CampaignOutlinedIcon data-cy="sidebar-campaigns-icon" />,
    reports: <BarChartIcon data-cy="sidebar-reports-icon" />,
    lists: <ListAltIcon data-cy="sidebar-lists-icon" />,
    reminders: <AlarmIcon data-cy="sidebar-reminders-icon" />,
    assistant: <Assistant data-cy="sidebar-assistant-icon" />,
    activities: <CheckCircleOutlineIcon data-cy="sidebar-activities-icon" />,
    tickets: <SupportOutlinedIcon data-cy="sidebar-tickets-icon" />,
    quotes: <ContentPasteOutlinedIcon data-cy="sidebar-quotes-icon" />,
    orders: <AttachMoneyIcon data-cy="sidebar-orders-icon" />,
  };

  if (field.name === 'reports') {
    return <ReportsLink label="Analytics" name={field?.name} />;
  }

  const isSpiroIcon = (name) => name === 'assistant';
  const tooltipTitle = () => {
    if (!isSidebarCollapsed) return '';
    if (field.name === 'orders') return 'Sales Orders';
    return t(`localization.navigation.${field.name}`).includes('localization.')
      ? field.name.charAt(0).toUpperCase() + field.name.slice(1)
      : t(`localization.navigation.${field.name}`);
  };

  const getMenuLabel = () => {
    if (field.name === 'orders') return 'Sales Orders';
    return field.name;
  };

  return (
    <>
      {field.divider && <Divider className={styles.devider} />}
      <Link
        key={field.name}
        to={pathname}
        className={linkActive ? styles['sidebar-item-active'] : styles['sidebar-item']}
        data-cy={`sidebar-menu-${field.name.toLowerCase()}-link`}
      >
        <SpiroTooltip title={tooltipTitle()} placement="top" disableInteractive>
          <span
            className={isSpiroIcon(field.name) ? styles['spiro-icon'] : styles.icon}
            data-cy={`sidebar-menu-${field.name.toLowerCase()}-icon`}
          >
            {icons[field.name]}
          </span>
        </SpiroTooltip>
        <Typography
          className={styles.subheading}
          data-cy={`sidebar-menu-${field.name.toLowerCase()}-label`}
        >
          {t(`localization.navigation.${field.name}`).includes('localization.')
            ? getMenuLabel()
            : t(`localization.navigation.${field.name}`)}
        </Typography>
      </Link>
    </>
  );
}

SidebarItem.propTypes = {
  field: PropTypes.object.isRequired,
};

export default SidebarItem;
