import { createSlice } from '@reduxjs/toolkit';

import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import { parseAll } from 'helpers/users';

import { fetchAccessRequests, fetchUsers, updateUser } from './actions';

const initialState = {
  users: [],
  selectedUser: null,
  fetching: false,
  meta: nullMeta,
  params: {
    page: 1,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    q: undefined,
    include_deactivated: true,
  },
  isBuyLicencesModalVisible: false,
  isChangeOwnerModalVisible: false,
  isUserInvitationModalVisible: false,
  isUserEditModalVisible: false,
  isUserReassignModalVisible: false,
  isAssignLicenseModalVisible: false,
  selectedLicenceType: '',
  accessRequests: [],
  accessRequestsMeta: nullMeta,
  selectedAccessRequest: null,
};

export const userAdministrationSlice = createSlice({
  name: 'userAdministration',
  initialState,
  reducers: {
    setSelectedUser: (state, { payload }) => ({ ...state, selectedUser: payload }),
    setBuyLicencesModalVisible: (state, { payload }) => ({
      ...state,
      isBuyLicencesModalVisible: payload,
    }),
    setChangeOwnerModalVisible: (state, { payload }) => ({
      ...state,
      isChangeOwnerModalVisible: payload,
    }),
    setUserInvitationModalVisible: (state, { payload }) => ({
      ...state,
      isUserInvitationModalVisible: payload,
    }),
    setUserEditModalVisible: (state, { payload }) => ({
      ...state,
      isUserEditModalVisible: payload,
    }),
    setUserReassignModalVisible: (state, { payload }) => ({
      ...state,
      isUserReassignModalVisible: payload,
    }),
    setAssignLicenseModalVisible: (state, { payload }) => ({
      ...state,
      isAssignLicenseModalVisible: payload,
    }),
    setSelectedLicenseType: (state, { payload }) => ({ ...state, selectedLicenceType: payload }),
    setSelectedAccessRequest: (state, { payload }) => ({
      ...state,
      selectedAccessRequest: payload,
    }),
  },
  extraReducers: {
    [fetchUsers.pending]: (state) => ({ ...state, fetching: true }),
    [fetchUsers.fulfilled]: (state, { payload }) => ({
      ...state,
      users: parseAll(payload.users),
      fetching: false,
      meta: {
        ...state.meta,
        ...parseMetaFromResponse(payload.meta),
      },
      params: {
        ...state.params,
        ...payload.params,
      },
    }),
    [fetchUsers.rejected]: (state) => ({ ...state, fetching: false }),
    [updateUser.pending]: (state) => ({ ...state, fetching: true }),
    [updateUser.fulfilled]: (state, { payload }) => ({
      ...state,
      users: payload.data,
      fetching: false,
    }),
    [updateUser.rejected]: (state) => ({ ...state, fetching: false }),
    [fetchAccessRequests.fulfilled]: (state, { payload }) => ({
      ...state,
      accessRequests: payload.access_requests,
      accessRequestsMeta: parseMetaFromResponse(payload.meta),
    }),
  },
});

export const {
  setSelectedUser,
  setBuyLicencesModalVisible,
  setChangeOwnerModalVisible,
  setUserInvitationModalVisible,
  setUserEditModalVisible,
  setUserReassignModalVisible,
  setSelectedLicenseType,
  setAssignLicenseModalVisible,
  setSelectedAccessRequest,
} = userAdministrationSlice.actions;

export default userAdministrationSlice.reducer;
