import { COUNTERS_REQUEST_STARTED, COUNTERS_REQUEST_SUCCESS, COUNTERS_UPDATE } from './constants';

const initialState = {
  requestStarted: false,
  missedCalls: 0,
  unreadSMS: 0,
  voicemails: 0,
};

function requestStartedHandler(state) {
  return {
    ...state,
    requestStarted: true,
  };
}

function requestSuccessHandler(state, action) {
  return {
    ...state,
    requestStarted: false,
    missedCalls: action.missedCalls,
    unreadSMS: action.unreadSMS,
    voicemails: action.voicemails,
  };
}

function updateCountersHandler(state, { payload }) {
  return { ...state, ...payload };
}

const ACTION_HANDLERS = {
  [COUNTERS_REQUEST_STARTED]: requestStartedHandler,
  [COUNTERS_REQUEST_SUCCESS]: requestSuccessHandler,
  [COUNTERS_UPDATE]: updateCountersHandler,
};

export default function countersReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
