import { displayFieldsToArray } from '../../helpers/display-fields';

export const ROLES = {
  normal: 'normal',
  manager: 'manager',
  superManager: 'supermanager',
  basic: 'basic',
  basicManager: 'basic_manager',
  partner: 'partner',
};

export function selectUserFetched(state) {
  return state.user.fetched;
}

export function selectUserFetching(state) {
  return state.user.fetching;
}

export function selectUserId(state) {
  return state.user.id;
}

export function selectUserGroupId(state) {
  return state.user.groupId;
}

export function selectIsBasic(state) {
  return state.user.role === 'basic';
}

export function selectIsAdmin(state) {
  return state.user.isAdmin;
}

export function selectIsManager(state) {
  return state.user.isManager;
}

export function selectTwilioOrganization(state) {
  return state.user.twilioOrganization;
}

export function selectTwilioProfile(state) {
  return state.user.twilioProfile;
}

export function selectEnableNotifications(state) {
  return state.user.enableNotifications;
}

export function selectCurrency(state) {
  return state.user.currency;
}

export function selectCurrencySymbol(state) {
  return state.user.currencySymbol;
}

export function selectEnableParentAccount(state) {
  return state.user.enableParentAccount;
}

export function selectUserFullName(state) {
  return state.user.fullName;
}

export function selectUserStatus(state) {
  return state.user.status;
}

export function selectEmailClient(state) {
  return state.user.emailClient;
}

export function selectConnectedUsers(state) {
  return state.user.connectedUsers;
}

export function selectZohoZuid(state) {
  return state.user.zohoZuid;
}

export function selectZohoEmail(state) {
  return state.user.zohoEmail;
}

export function selectReportsLink(state) {
  return state.user.reportsLink;
}

export function selectNavigationDisplayFields(state) {
  return displayFieldsToArray(state.user.navigationDisplayFields)
    .filter((field) => field.visible === '1')
    .sort((a, b) => a.order - b.order);
}

export function selectTrayIOUser(state) {
  return state.user.trayIOUser;
}

export function selectCanDoImport(state) {
  return state.user.canDoImport;
}

export function selectCalendar(state) {
  return state.user.calendar;
}

export function selectLocale(state) {
  return state.user.organization?.locale;
}

export function selectUser(state) {
  return state.user;
}

export const selectAddons = (state) => state.user.addons;

export const selectHasApiEnabled = (state) =>
  state.user.addons.find((product) => product.name === 'API');

export const selectHasIndependentListEnabled = (state) =>
  state.user.addons.find((product) => product.name === 'Independent List');

export const selectHasAssistantRulesEnabled = (state) =>
  state.user.addons.find((product) => product.name === 'Assistant Rules Editor');

export const selectCountryCode = (state) => state.user.countryCode;

export const selectHasSupportDeskEnabled = (state) =>
  !!state.user.addons.find((product) => product.name === 'Support Desk');

export const selectHasSmartProductsEnabled = (state) =>
  !!state.user.addons.find((product) => product.name === 'Smart Quotes');

export const selectDataCollectorAddonEnabled = (state) =>
  state.user.addons.find((product) => product.name === 'Data Collector');

export const selectDemoAddonEnabled = (state) =>
  state.user.addons.find((product) => product.name === 'Demo Features');

export const selectReadOnlyLicenseAddonEnabled = (state) =>
  state.user.addons.find((product) => product.name === 'Read Only License');

export const selectEmailSyncLicenseAddonEnabled = (state) =>
  state.user.addons.find((product) => product.name === 'Email Sync License');

export const selectPhoneOnlyLicenseAddonEnabled = (state) =>
  state.user.addons.find((product) => product.name === 'Phone Only License');

export const selectFollowingFeatureAddonEnabled = (state) =>
  state.user.addons.find((product) => product.name === 'Following Feature');

export const selectDashboardType = (state) => state.user.dashboard;

export const selectIsManagerSummaryModalVisible = (state) =>
  state.user.isManagerSummaryModalVisible;

export const selectMentionableItems = (state) => {
  const users = state.user.mentionableItems.users.map((u) => ({
    id: u.id.toString(),
    name: u.name,
    spanText: u.name,
    type: 'User',
  }));

  const groups = state.user.mentionableItems.groups.map((g) => ({
    name: `${g.name} (Group)`,
    id: g.id.toString(),
    spanText: g.name,
    type: 'Group',
  }));

  return [...groups, ...users];
};

export const selectHasSalesOrdersEnabled = (state) =>
  !!state.user.addons.find((product) => product.name === 'Sales Orders');

export const selectUnreadGeneralNotificationsCount = (state) =>
  state.user.unreadGeneralNotificationsCount;

export const selectUnreadPriorityNotificationsCount = (state) =>
  state.user.unreadPriorityNotificationsCount;
