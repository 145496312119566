function mapFieldsObjectToArray(fields) {
  return Object.keys(fields).map((key) => ({
    name: key,
    ...fields[key],
  }));
}

export const selectViews = (state) => state.spiroViews;
export const selectViewsFetching = (state) => state.spiroViews.fetching;
export const selectRawColumns = (state) => state.spiroViews.rawColumns;

export function getAccountListViews(state) {
  const fields = state.spiroViews.columns.Account;
  return fields
    ? mapFieldsObjectToArray(fields).sort((field1, field2) => field1.order - field2.order)
    : [];
}

export function getContactListViews(state) {
  const fields = state.spiroViews.columns.Contact;
  return fields
    ? mapFieldsObjectToArray(fields).sort((field1, field2) => field1.order - field2.order)
    : [];
}

export function getOpportunityListViews(state) {
  const fields = state.spiroViews.columns.Opportunity;
  return fields
    ? mapFieldsObjectToArray(fields).sort((field1, field2) => field1.order - field2.order)
    : [];
}

export function getCampaignListViews(state) {
  const fields = state.spiroViews.columns.List;
  return fields
    ? mapFieldsObjectToArray(fields).sort((field1, field2) => field1.order - field2.order)
    : [];
}

export function getTicketListViews(state) {
  const fields = state.spiroViews.columns.Ticket;
  return fields
    ? mapFieldsObjectToArray(fields).sort((field1, field2) => field1.order - field2.order)
    : [];
}

export function getReminderListViews(state) {
  const fields = state.spiroViews.columns.Reminder;
  return fields
    ? mapFieldsObjectToArray(fields).sort((field1, field2) => field1.order - field2.order)
    : [];
}

export function getQuoteListViews(state) {
  const fields = state.spiroViews.columns.Quote;
  return fields
    ? mapFieldsObjectToArray(fields).sort((field1, field2) => field1.order - field2.order)
    : [];
}

export function getProductListViews(state) {
  const fields = state.spiroViews.columns.Product;
  return fields
    ? mapFieldsObjectToArray(fields).sort((field1, field2) => field1.order - field2.order)
    : [];
}

export function getSalesOrderListViews(state) {
  const fields = state.spiroViews.columns.SalesOrder;
  return fields
    ? mapFieldsObjectToArray(fields).sort((field1, field2) => field1.order - field2.order)
    : [];
}

export function getSalesOrderItemListViews(state) {
  const fields = state.spiroViews.columns.SalesOrderItem;
  return fields
    ? mapFieldsObjectToArray(fields).sort((field1, field2) => field1.order - field2.order)
    : [];
}

// Default order

export const getAccountViewsDefaultOrder = (state) => state.spiroViews.defaultOrder.Account;
export const getContactViewsDefaultOrder = (state) => state.spiroViews.defaultOrder.Contact;
export const getOpportunityViewsDefaultOrder = (state) => state.spiroViews.defaultOrder.Opportunity;
export const getCampaignViewsDefaultOrder = (state) => state.spiroViews.defaultOrder.List;
export const getTicketViewsDefaultOrder = (state) => state.spiroViews.defaultOrder.Ticket;
export const getReminderViewsDefaultOrder = (state) => state.spiroViews.defaultOrder.Reminder;
export const getQuoteViewsDefaultOrder = (state) => state.spiroViews.defaultOrder.Quote;
export const getProductViewsDefaultOrder = (state) => state.spiroViews.defaultOrder.Product;
export const getSalesOrderViewsDefaultOrder = (state) => state.spiroViews.defaultOrder.SalesOrder;
export const getSalesOrderItemViewsDefaultOrder = (state) =>
  state.spiroViews.defaultOrder.SalesOrderItem;
export const getDefaultOrder = (state) => state.spiroViews.defaultOrder;
export const selectRecordsPerPage = (state) => state.spiroViews.recordsPerPage;
export const selectActiveTab = (state) => state.spiroViews.activeTab;
