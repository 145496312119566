export const FETCHING_EMAIL_TEMPLATES = 'email_templates_settings: fetching email templates';
export const EMAIL_TEMPLATES_FETCHED = 'email_templates_settings: email templates fetched';
export const FETCHING_EMAIL_TEMPLATES_FAILED =
  'email_templates_settings: fetching email templates failed';
export const STORE_EMAIL_TEMPLATES = 'email_templates_settings: store email templates';
export const STORE_EMAIL_TEMPLATE = 'email_templates_settings: store email template';
export const REMOVE_EMAIL_TEMPLATE = 'email_templates_settings: remove email template';
export const SELECT_EMAIL_TEMPLATE = 'email_templates_settings: select email template';
export const STORE_EMAIL_TEMPLATES_ATTRIBUTES =
  'email_templates_settings: store email template attributes';

export default {
  FETCHING_EMAIL_TEMPLATES,
  EMAIL_TEMPLATES_FETCHED,
  FETCHING_EMAIL_TEMPLATES_FAILED,
  STORE_EMAIL_TEMPLATE,
  STORE_EMAIL_TEMPLATES,
  REMOVE_EMAIL_TEMPLATE,
  SELECT_EMAIL_TEMPLATE,
  STORE_EMAIL_TEMPLATES_ATTRIBUTES,
};
