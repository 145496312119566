import EngineService from '../core/engine-service';

const API_ENDPOINT = '/subusers';

export function getSubUsers(params = {}) {
  return EngineService.get(API_ENDPOINT, params).then((json) => json);
}

export default {
  getSubUsers,
};
