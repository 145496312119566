import PropTypes from 'prop-types';

import useCurrency from 'hooks/useCurrency';
import CurrencyTextField from 'lib/CurrencyField';

export default function UserCurrencyTextField({ component: Component, ...props }) {
  const { symbol } = useCurrency();
  return <Component currency={symbol} {...props} />;
}

UserCurrencyTextField.defaultProps = {
  component: CurrencyTextField,
};

UserCurrencyTextField.propTypes = {
  component: PropTypes.func,
};
