import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function TwilioOnly({ children = null }) {
  const userHasTwilioProfile = useSelector((state) => !!state.user.twilioProfile);

  return userHasTwilioProfile ? children : null;
}

TwilioOnly.propTypes = {
  children: PropTypes.node,
};

export default TwilioOnly;
