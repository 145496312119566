import HelpIcon from '@mui/icons-material/Help';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { companyPath, contactPath } from 'app/helpers/paths';
import CallDurationTimer from 'components/CallDialog/CallDurationTimer';
import {
  selectIsRinging,
  selectPhoneNumber,
  selectTwilioCaller,
  selectTwilioCallers,
} from 'components/CallDialog/state/selectors';

import MultipleCallersMenu from '../MultipleCallersMenu';
import useCallerInfo from '../useCallerInfo';

import styles from './CallerInfoText.module.scss';

export default function CallerInfoText({ fullInfo, mainTextColor, subTextColor }) {
  const { isConnectionPending, getAccountName } = useCallerInfo();
  const phoneNumber = useSelector(selectPhoneNumber);
  const callers = useSelector(selectTwilioCallers);
  const caller = useSelector(selectTwilioCaller);
  const isRinging = useSelector(selectIsRinging);

  return (
    <Box className={fullInfo ? styles['text-center'] : styles.text}>
      <Box className={styles['main-text']}>
        <Typography
          className={styles.name}
          aria-controls="multiple-callers-menu"
          aria-haspopup="true"
          noWrap
        >
          {callers.length === 0 && phoneNumber && !caller && (
            <span>{parsePhoneNumberFromString(phoneNumber)?.formatInternational()}</span>
          )}
          {callers.length > 1 && (
            <span className={`text-truncate ${styles[`multiple-contacts`]}`}>
              {isConnectionPending() ? (
                <Tooltip
                  title="This number is associated with multiple contacts."
                  classes={{ tooltip: styles.tooltip }}
                >
                  <span className={styles['tooltip-info']}>
                    {parsePhoneNumberFromString(phoneNumber)?.formatInternational()}
                    <HelpIcon className={styles.icon} />
                  </span>
                </Tooltip>
              ) : (
                <MultipleCallersMenu
                  items={callers}
                  caller={caller}
                  number={parsePhoneNumberFromString(phoneNumber)?.formatInternational()}
                />
              )}
            </span>
          )}
          {callers.length === 1 && caller && (
            <span className={`text-truncate ${styles[`text-${mainTextColor}`]}`}>
              <Link
                to={
                  caller.resource_type === 'Contact'
                    ? contactPath(caller.resource_id)
                    : companyPath(caller.resource_id)
                }
              >
                {caller.name}
              </Link>
            </span>
          )}
        </Typography>
        {callers.length >= 1 && (
          <Typography className={styles.account} noWrap gutterBottom>
            {(caller && caller.account_name) || getAccountName(callers)}
          </Typography>
        )}
        {phoneNumber && callers.length !== 0 && (
          <Typography className={styles.phone}>
            {parsePhoneNumberFromString(phoneNumber)?.formatInternational()}
          </Typography>
        )}
        {caller && caller.ext_number && !isConnectionPending() && (
          <Typography className={styles.phone} noWrap>
            Ext: {caller.ext_number}
          </Typography>
        )}
      </Box>
      <Box className={`${styles['sub-text']} ${styles[`text-${subTextColor}`]}`}>
        {fullInfo && (
          <>
            {isConnectionPending() && <Typography variant="caption">Incoming Call</Typography>}
            {!isConnectionPending() && !isRinging && <CallDurationTimer />}
            {isRinging && (
              <Typography variant="caption" className={styles.dialing}>
                Dialing
                <span>.</span>
                <span>.</span>
                <span>.</span>
              </Typography>
            )}
          </>
        )}
      </Box>
    </Box>
  );
}

CallerInfoText.propTypes = {
  fullInfo: PropTypes.bool.isRequired,
  mainTextColor: PropTypes.string.isRequired,
  subTextColor: PropTypes.string.isRequired,
};
