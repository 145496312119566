import ResourceLookup from '../ResourceLookup';

function ContactLookup(props) {
  return (
    <ResourceLookup
      {...props}
      labelFn={(item) => `${item.name}${item.email ? ` (${item.email})` : ''}`}
      resource="contact"
    />
  );
}

export default ContactLookup;
