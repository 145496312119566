class NullContact {
  constructor() {
    this.firstName = '';
    this.lastName = '';
    this.email = '';
    this.custom = {};
  }

  getFullName() {
    return [this.firstName, this.lastName].join(' ');
  }
}

export default NullContact;
