import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ userLoggedIn, children, redirectPath = '/login' }) {
  if (!userLoggedIn) {
    return <Navigate to={redirectPath} replace />;
  }

  return children;
}

PrivateRoute.propTypes = {
  children: PropTypes.object.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
  redirectPath: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    userLoggedIn: state.session.loggedIn,
  };
}

export default connect(mapStateToProps)(PrivateRoute);
