import { useEffect, useRef } from 'react';

import ClassNames from 'classnames';
import ReactDom from 'react-dom';

import useOnClickOutside from 'hooks/useOnClickOutside';
import './PortalModal.scss';

export default function PortalModal({ show, onHide, children }) {
  const ref = useRef();
  useOnClickOutside(ref, onHide);

  useEffect(() => {
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'visible';
    }

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, [show]);

  return ReactDom.createPortal(
    <>
      <div className={ClassNames('portal-modal-container', { hidden: !show })}>
        <div className="portal-modal">
          <div className="portal-modal-body" ref={ref}>
            {children}
          </div>
        </div>
      </div>
      <div className={ClassNames('portal-modal-overlay', { hidden: !show })} />
    </>,
    document.getElementById('portal')
  );
}
