import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { useDispatch } from 'react-redux';

import { minimizeCallModal, setPhoneDialogOpen } from '../state/actions';

import styles from './PhoneDialogHeader.module.scss';

export default function PhoneDialogHeader() {
  const dispatch = useDispatch();

  const onClick = () => {
    dispatch(setPhoneDialogOpen(false));
    dispatch(minimizeCallModal(true));
  };

  return (
    <section className={styles.header}>
      <IconButton onClick={onClick}>
        <CloseIcon />
      </IconButton>
    </section>
  );
}
