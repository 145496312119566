import {
  CREATE_GROUP_SET_PENDING,
  DELETE_GROUP_SET_MODAL_VISIBLE,
  DELETE_GROUP_SET_PENDING,
  FETCH_GROUPS_SET_PENDING,
  NEW_GROUP_SET_MODAL_VISIBLE,
  SET_DATA,
  SET_SELECTED_GROUP,
  UPDATE_GROUP_SET_PENDING,
} from './constants';

const initialState = {
  fetchPending: false,
  createPending: false,
  updatePending: false,
  deletePending: false,
  deleteModalVisible: false,
  selectedGroup: null,
  data: [],
  isCreateModalVisible: false,
};

export default function groupsReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_GROUPS_SET_PENDING:
      return {
        ...state,
        fetchPending: action.pending,
      };
    case CREATE_GROUP_SET_PENDING:
      return {
        ...state,
        createPending: action.pending,
      };
    case UPDATE_GROUP_SET_PENDING:
      return {
        ...state,
        updatePending: action.pending,
      };
    case DELETE_GROUP_SET_PENDING:
      return {
        ...state,
        deletePending: action.pending,
      };
    case DELETE_GROUP_SET_MODAL_VISIBLE:
      return {
        ...state,
        deleteModalVisible: action.visible,
      };
    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.group,
      };
    case SET_DATA:
      return {
        ...state,
        data: action.data,
      };
    case NEW_GROUP_SET_MODAL_VISIBLE:
      return {
        ...state,
        isCreateModalVisible: action.payload,
      };
    default:
      return state;
  }
}
