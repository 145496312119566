import { CREATE_USER_SESSION, DESTROY_USER_SESSION } from './constants';

const initialState = {
  accessToken: '',
  createdAt: '',
  expiresIn: '',
  instanceUrl: '',
  loggedIn: false,
  refreshToken: '',
  tokenType: '',
  userEmail: '',
  userId: '',
};

function createUserSessionHandler(state, action) {
  return {
    ...state,
    loggedIn: true,
    ...action.payload,
  };
}

function destroyUserSessionHandler() {
  return initialState;
}

const ACTION_HANDLERS = {
  [CREATE_USER_SESSION]: createUserSessionHandler,
  [DESTROY_USER_SESSION]: destroyUserSessionHandler,
};

export default function sessionReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
