export const FETCHING_DISPLAY_FIELDS_STARTED = 'display_fields: fetching_started';
export const FETCHING_DISPLAY_FIELDS_SUCCESS = 'display_fields: fetching_success';
export const FETCHING_DISPLAY_FIELDS_FAILED = 'display_fields: fetching_failed';

export const STORE_DISPLAY_FIELDS = 'display_fields: store_display_field_resource';
export const STORE_ALL_DISPLAY_FIELDS = 'display_fields: store_all_display_field_resource';

export const UPDATE_DISPLAY_FIELDS = 'display_fields: update_display_fields';
export const SET_SELECTED_ENTITY = 'display_fields: set_selected_entity';
export const REORDER_FIELDS = 'display_fields: reorder_fields';

export default {
  FETCHING_DISPLAY_FIELDS_STARTED,
  FETCHING_DISPLAY_FIELDS_SUCCESS,
  FETCHING_DISPLAY_FIELDS_FAILED,
  UPDATE_DISPLAY_FIELDS,
  STORE_DISPLAY_FIELDS,
  STORE_ALL_DISPLAY_FIELDS,
  SET_SELECTED_ENTITY,
  REORDER_FIELDS,
};
