import PropTypes from 'prop-types';

import AccountResult from './AccountResult';
import CollaborationNoteResult from './CollaborationNoteResult';
import ContactResult from './ContactResult';
import InteractionResult from './InteractionResult';
import OpportunityResult from './OpportunityResult';

export default function SearchResult({ result }) {
  if (result.type === 'Account') {
    return <AccountResult account={result} />;
  }

  if (result.type === 'Contact') {
    return <ContactResult contact={result} />;
  }

  if (result.type === 'Opportunity') {
    return <OpportunityResult opportunity={result} />;
  }

  if (result.type === 'Interaction') {
    return <InteractionResult interaction={result} />;
  }

  if (result.type === 'CollaborationNote') {
    return <CollaborationNoteResult collaborationNote={result} />;
  }

  return null;
}

SearchResult.propTypes = {
  result: PropTypes.object.isRequired,
};
