import PropTypes from 'prop-types';

import ResourceLookup from '../ResourceLookup/ResourceLookup';

function SalesOrderLookup(props) {
  return <ResourceLookup {...props} inline={props.inline} resource="sales_order" />;
}

SalesOrderLookup.defaultProps = {
  inline: false,
};

SalesOrderLookup.propTypes = {
  inline: PropTypes.bool,
};

export default SalesOrderLookup;
