import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import UserAvatar from 'components/UserAvatar';
import { ReactComponent as SpiritoAvatar } from 'images/spirito-avatar.svg';
import { selectUser } from 'state/user/selectors';

import styles from './ChatUserInfo.module.scss';

export default function ChatUserInfo({ message }) {
  const user = useSelector(selectUser);
  const isResponse = message.type === 'response';

  return (
    <div className={styles.container}>
      {isResponse ? <SpiritoAvatar /> : <UserAvatar user={user} isMini displayInitials={false} />}
      <p className={isResponse ? styles.spirito : styles.name}>
        {isResponse ? 'Spiro' : user.fullName}
      </p>
    </div>
  );
}

ChatUserInfo.propTypes = {
  message: PropTypes.object.isRequired,
};
