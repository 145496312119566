import PropTypes from 'prop-types';

import styles from './RegularTextMessage.module.scss';

export default function RegularTextMessage({ value }) {
  const { defaultQuestion } = value;
  const isBold = value.type === 'bold';

  return (
    <>
      <p className={isBold ? styles['bold-text'] : styles.text}>{value.text}</p>
      {defaultQuestion && (
        <ul className={styles.list}>
          {defaultQuestion.map((question, index) => (
            <li key={index}>{question}</li>
          ))}
        </ul>
      )}
    </>
  );
}

RegularTextMessage.propTypes = {
  value: PropTypes.object.isRequired,
};
