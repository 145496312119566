import pick from 'lodash/pick';

export function parseCalendarFromEngine(data = {}) {
  return {
    uid: data.uid || '',
    name: data.name || '',
    provider: data.provider || '',
  };
}

export function serializeCalendarForEngine(calendar, keys) {
  const s = {
    uid: calendar.uid,
    name: calendar.name,
  };

  if (keys) {
    return pick(s, keys);
  }
  return s;
}

export default {
  parseCalendarFromEngine,
  serializeCalendarForEngine,
};
