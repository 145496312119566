import { useRef } from 'react';

import isEmpty from 'lodash/isEmpty';

import NoResults from '../NoResults';
import SearchGroup from '../SearchGroup';
import SearchLoader from '../SearchLoader';
import { useSearch } from '../SearchProvider';

import styles from './SearchResults.module.scss';

export default function SearchResults() {
  const ref = useRef(null);
  const { debouncedSearchTerm, meta, params, loading, groupedResults, setParams } = useSearch();
  const resultsEmpty = debouncedSearchTerm && isEmpty(groupedResults);

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = ref.current;
    const scrolledToBottom = scrollTop + clientHeight >= scrollHeight;

    if (params.only && scrolledToBottom && meta.nextPage && !loading) {
      setParams({ page: meta.nextPage });
    }
  };

  return (
    <div className={styles['search-results']} ref={ref} onScroll={handleScroll}>
      {groupedResults.Contact && <SearchGroup type="Contact" items={groupedResults.Contact} />}
      {groupedResults.Account && <SearchGroup type="Account" items={groupedResults.Account} />}
      {groupedResults.Opportunity && (
        <SearchGroup type="Opportunity" items={groupedResults.Opportunity} />
      )}
      {groupedResults.Interaction && (
        <SearchGroup type="Interaction" items={groupedResults.Interaction} />
      )}
      {groupedResults.CollaborationNote && (
        <SearchGroup type="CollaborationNote" items={groupedResults.CollaborationNote} />
      )}
      {loading && <SearchLoader />}
      {!loading && resultsEmpty && <NoResults />}
    </div>
  );
}
