import pick from 'lodash/pick';

export function blank() {
  return {};
}

export function parse(data = {}) {
  return { ...blank(), ...data };
}

export function parseAll(data = []) {
  return data.map((item) => parse(item));
}

export function parseTwilioProfileFromEngine(data = {}) {
  if (data) {
    return {
      id: data.id || '',
      createdAt: data.created_at,
      updatedAt: data.updated_at,
      userId: data.user_id || '',
      number: data.number || '',
      forwardTo: data.forward_to || null,
      recording: data.recording || false,
      available: data.available || false,
      transcriptionsEnabled: data.transcriptions_enabled,
      callRecordingEnabled: data.twilio_account_recording_enabled,
      callSummariesEnabled: data.call_summaries_enabled,
    };
  }
  return data;
}

export function serialize(data, keys) {
  const s = {
    forward_to: data.forwardTo,
    recording: data.recording,
    transcriptions_enabled: data.transcriptionsEnabled,
  };

  if (keys) {
    return pick(s, keys);
  }
  return s;
}

export default {
  blank,
  parse,
  parseAll,
  parseTwilioProfileFromEngine,
  serialize,
};
