import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/campaigns/actions';

import {
  GET_CAMPAIGNS_REQUEST_STARTED,
  GET_CAMPAIGNS_REQUEST_SUCCESS,
  PATCH_CAMPAIGN_REQUEST_FAILED,
  PATCH_CAMPAIGN_REQUEST_STARTED,
  PATCH_CAMPAIGN_REQUEST_SUCCESS,
  POST_CAMPAIGN_REQUEST_FAILED,
  POST_CAMPAIGN_REQUEST_STARTED,
  POST_CAMPAIGN_REQUEST_SUCCESS,
  REMOVE_CAMPAIGN,
  RESET_STATE,
  SET_PARAMS,
  SET_RECORDS_COUNT,
} from './constants';
import { forEachError } from '../../../../../helpers/errorHelper';
import { serializeFiltersToString } from '../../../../helpers/filters';
import { parseMetaFromResponse } from '../../../../helpers/meta';
import { parse } from '../../../../helpers/target-list';
import {
  createTargetList as createCampaignRemote,
  deleteTargetList as deleteCampaignRemote,
  getCampaignsCount as getCampaignsCountRemote,
  getTargetLists as getCampaignsRemote,
  updateTargetList as updateCampaignRemote,
} from '../../../../services/targets';
import { error as errorAlert } from '../../../../state/notifications/actions';

export function getCampaignsRequestStarted() {
  return {
    type: GET_CAMPAIGNS_REQUEST_STARTED,
  };
}

export function getCampaignsRequestSuccess(json) {
  return {
    type: GET_CAMPAIGNS_REQUEST_SUCCESS,
    campaigns: json.lists,
    meta: parseMetaFromResponse(json.meta),
  };
}

export function postCampaignRequestStarted() {
  return {
    type: POST_CAMPAIGN_REQUEST_STARTED,
  };
}

export function postCampaignRequestSuccess(campaign) {
  return {
    type: POST_CAMPAIGN_REQUEST_SUCCESS,
    campaign: parse(campaign),
  };
}

export function postCampaignRequestFailed() {
  return {
    type: POST_CAMPAIGN_REQUEST_FAILED,
  };
}

export function patchCampaignRequestStarted() {
  return {
    type: PATCH_CAMPAIGN_REQUEST_STARTED,
  };
}

export function patchCampaignRequestSuccess(campaign) {
  return {
    type: PATCH_CAMPAIGN_REQUEST_SUCCESS,
    campaign,
  };
}

export function patchCampaignRequestFailed() {
  return {
    type: PATCH_CAMPAIGN_REQUEST_FAILED,
  };
}

export function removeCampaign(campaignId) {
  return {
    type: REMOVE_CAMPAIGN,
    id: campaignId,
  };
}

export function setParams(data) {
  return {
    type: SET_PARAMS,
    data,
  };
}

export function resetState() {
  return {
    type: RESET_STATE,
  };
}

export function setCampaignsCount(recordsCount) {
  return {
    type: SET_RECORDS_COUNT,
    recordsCount,
  };
}

function getParamsFromState(state) {
  const params = state.campaigns.data.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.campaigns.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.List;
  const paramsObj = params.sort
    ? defaultParams
    : { ...defaultParams, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return { ...paramsObj, q: serializeFiltersToString(currentFilters.filters) || undefined };
}

export function getCampaigns(params = {}) {
  return async (dispatch, getState) => {
    dispatch(getCampaignsRequestStarted());

    dispatch(fetchFilterProperties());
    dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      return getCampaignsRemote(payload)
        .then((json) => {
          dispatch(getCampaignsRequestSuccess(json));
          dispatch(setParams(payload));
          return Promise.resolve(json);
        })
        .catch((err) => {
          forEachError(err.data, (e) => dispatch(errorAlert(e)));
          return Promise.reject(err);
        });
    });
  };
}

export function getCampaignsCount(params = {}) {
  return async (_, getState) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };

    return getCampaignsCountRemote(payload)
      .then(() => Promise.resolve())
      .catch((err) => Promise.reject(err));
  };
}

export function createCampaign(payload) {
  return async (dispatch) => {
    dispatch(postCampaignRequestStarted());

    try {
      const json = await createCampaignRemote(payload);
      dispatch(postCampaignRequestSuccess(json.list));
      dispatch(getCampaigns());
      return json.list;
    } catch (err) {
      dispatch(postCampaignRequestFailed());
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  };
}

export function updateCampaign(campaignId, payload) {
  return async (dispatch) => {
    dispatch(patchCampaignRequestStarted());

    try {
      const json = await updateCampaignRemote(campaignId, payload);
      dispatch(patchCampaignRequestSuccess(json.list));
      return json.list;
    } catch (err) {
      dispatch(patchCampaignRequestFailed());
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  };
}

export function deleteCampaign(campaignId) {
  return (dispatch) =>
    deleteCampaignRemote(campaignId).then((json) => {
      dispatch(getCampaigns());
      return json;
    });
}
