import EngineService from '../core/engine-service';

const ENDPOINT = '/attachments';

// POST: /attachments
export async function create(payload) {
  const json = await EngineService.post(ENDPOINT, { attachment: payload });
  return json;
}

// PATCH: /attachments/123
export async function update(id, payload) {
  const json = await EngineService.patch(`${ENDPOINT}/${id}`, { attachment: payload });
  return json;
}

// DELETE: /attachments/123
export async function destroy(id) {
  const json = await EngineService.delete(`${ENDPOINT}/${id}`);
  return json;
}

// POST: /presigned_aws_urls
export async function presignUrl(params) {
  const json = await EngineService.post('/presigned_aws_urls', params);
  return json;
}

export default {
  create,
  update,
  destroy,
  presignUrl,
};
