import isObject from 'lodash/isObject';
import isUndefined from 'lodash/isUndefined';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';

export function parseCustomFieldsFromEngine(data = {}) {
  return {
    notification: data.Notification || {},
    opportunity: data.Opportunity || {},
    account: data.Account || {},
    contact: data.Contact || {},
    activity: data.Activity || {},
  };
}

export function customFieldsToArray(customFields = {}) {
  if (customFields && isObject(customFields)) {
    return Object.entries(customFields).map((f) => ({
      name: f[0],
      ...f[1],
    }));
  }
  return [];
}

export function serializeCustomField(data) {
  return omitBy(
    {
      order: data.order,
      resource: data.resource,
      resource_for: data.resourceFor,
      schema_type: data.schemaType,
      label: data.label,
      name: data.name,
      type: data.type,
      value: data.value,
      expression: data.expression,
      options: data.options,
      default: data.default,
      required: data.required,
      custom_field_id: data.customFieldId,
      picker_key: data.pickerKey,
      custom_entity_id: data.customEntityId,
      visible_on_lists: data.visibleOnLists,
      visible_on_data_collector: data.visibleOnDataCollector,
      demo: data.demo,
      saved_filter_id: data.saved_filter_id,
      visible_on_mobile_app: data.visibleOnMobile,
    },
    isUndefined
  );
}

export function parseCustomFieldValues(str = '') {
  const values = str.split(',');
  return values
    .filter((v) => v.trim().length !== 0)
    .map((value) => {
      const v = value.trim();
      return {
        label: v,
        value: v,
      };
    });
}

export function joinCustomFieldValuesByComma(values = []) {
  return values.map((a) => a.value).join(', ');
}

export function isCustomFieldWithValues(field) {
  return ['List', 'MultipleSelect', 'Calculated'].includes(field.type);
}

export function getCustomFieldValues(field) {
  if (!isCustomFieldWithValues(field)) return '-';
  return joinCustomFieldValuesByComma(field.value);
}

export function getCustomFieldsForResource(fields, resource) {
  const fieldsByResource = fields[resource];

  if (!fieldsByResource) return null;
  if (resource === 'Activity') {
    return omit(fieldsByResource.fields, 'disposition');
  }
  return fieldsByResource.fields;
}

export function parseFieldsFromCustomField(customField) {
  return mapValues(customField.fields, (value) => ({
    label: value.label,
    type: value.type,
    id: customField.id,
    value: value.value,
    default: value.default,
    ...(value.expression && { expression: value.expression }),
  }));
}

export default {
  parseCustomFieldsFromEngine,
  customFieldsToArray,
  serializeCustomField,
  parseCustomFieldValues,
};
