import { useEffect, useState } from 'react';

export function useAutocomplete(
  meta,
  results,
  handleSearch,
  loading,
  value,
  onSelect,
  handleCheckboxChange
) {
  const [isScrollBottom, setIsScrollBottom] = useState(false);
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState(results || []);
  const [typingTimeout, setTypingTimeout] = useState(null);
  const [searchTerm, setSearchTerm] = useState(value);
  const [isChecked, setIsChecked] = useState(true);

  useEffect(() => {
    let data = [];
    if (results.length === 0 || results[0]?.type !== 'User') data = results;

    if (results.length > 0 && results[0]?.type === 'User') {
      data = results.map((user) => ({ ...user, name: user.full_name }));
    }
    const newOptions = meta && meta?.currentPage === 1 ? data : [...options, ...data];
    setOptions(newOptions);
  }, [meta]);

  useEffect(() => {
    setSearchTerm(value || '');
  }, [value]);

  useEffect(() => {
    if (isScrollBottom && meta?.nextPage && !loading) {
      handleSearch(searchTerm, meta?.nextPage, isChecked);
    }
  }, [isScrollBottom]);

  useEffect(() => {
    if (results?.length > 0) setOpen(true);
  }, [results]);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);

    if (typingTimeout) clearTimeout(typingTimeout);

    const timeout = setTimeout(() => {
      handleSearch(e.target.value, undefined, isChecked);
    }, 300);
    setTypingTimeout(timeout);
  };

  const handleSelect = (item) => {
    if (item.type === 'Checkbox') return;

    if (item.type === 'Account') {
      setSearchTerm(`${item.name} (${item.id})`);
    } else {
      setSearchTerm(item.name);
    }

    onSelect(item);
  };

  const onCheckboxChange = () => {
    setIsChecked(!isChecked);
    setTimeout(() => {
      handleCheckboxChange(searchTerm, 1, !isChecked);
    }, 100);
  };

  return {
    setIsScrollBottom,
    setOpen,
    handleChange,
    handleSelect,
    open,
    options,
    searchTerm,
    onCheckboxChange,
    isChecked,
  };
}
