import constants from './constants';

const initialState = {
  fetching: false,
  data: null,
  deleteModalVisible: false,
  deleting: false,
  tabsOrder: {},
  timezones: [],
};

export function fetchAccountStartedHandler(state) {
  return {
    ...state,
    fetching: true,
  };
}

export function storeAccountHandler(state, action) {
  return {
    ...state,
    fetching: false,
    data: action.data,
  };
}

function resetStateHandler(state) {
  return { ...state, ...initialState };
}

function showDeleteModalHandler(state) {
  return { ...state, deleteModalVisible: true };
}

function hideDeleteModalHandler(state) {
  return { ...state, deleteModalVisible: false };
}

function deleteStartedHandler(state) {
  return { ...state, deleting: true };
}

function deleteSuccessHandler(state) {
  return { ...state, deleting: false, deleteModalVisible: false };
}

function storeAccountTabsOrderHandler(state, action) {
  return { ...state, tabsOrder: action.data };
}

function storeTimezones(state, action) {
  return {
    ...state,
    timezones: action.data.timezones,
  };
}

const ACTION_HANDLERS = {
  [constants.FETCH_ACCOUNT_STARTED]: fetchAccountStartedHandler,
  [constants.STORE_ACCOUNT]: storeAccountHandler,
  [constants.RESET_STATE]: resetStateHandler,
  [constants.SHOW_ACCOUNT_DELETE_MODAL]: showDeleteModalHandler,
  [constants.HIDE_ACCOUNT_DELETE_MODAL]: hideDeleteModalHandler,
  [constants.ACCOUNT_DELETE_STARTED]: deleteStartedHandler,
  [constants.ACCOUNT_DELETE_SUCCESS]: deleteSuccessHandler,
  [constants.STORE_ACCOUNT_TABS_ORDER]: storeAccountTabsOrderHandler,
  [constants.STORE_TIMEZONES]: storeTimezones,
};

export default function accountReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
