import { nullMeta, parseMetaFromResponse } from 'helpers/meta';
import SmsMessage from 'models/interactions/SmsMessage';

import {
  CLEAR_TEXT_INTERACTION_CHILD,
  CLEAR_TRANSCRIPTION_SEARCH,
  INTENTS_ACTIONS_FULFILLED,
  INTENTS_ACTIONS_PENDING,
  INTENTS_ACTIONS_REJECTED,
  INTERACTION_CHILD_CLEARED,
  INTERACTION_CHILD_FULFILLED,
  INTERACTION_CHILD_PENDING,
  INTERACTION_CHILD_REJECTED,
  INTERACTION_REMOVE_TRANSCRIPTIONS,
  INTERACTION_TRANSCRIPTIONS_FULFILLED,
  INTERACTION_TRANSCRIPTIONS_PENDING,
  INTERACTION_TRANSCRIPTIONS_REJECTED,
  MISSED_CALL_NUMBER_FULFILLED,
  MISSED_CALL_NUMBER_PENDING,
  MISSED_CALL_NUMBER_REJECTED,
  REMOVE_INTENT_AFTER_CREATE,
  SET_ATTACHMENT_ASSET_URL,
  SET_COMPOSE_EMAIL_PARAMS,
  SET_EMAIL_PARAMS,
  SET_EMAIL_TEMPLATES,
  SET_INTERACTION_CUSTOM_FIELD,
  SET_INTERACTION_DESCRIPTION,
  SET_INTERACTION_FULFILLED,
  SET_INTERACTION_PENDING,
  SET_INTERACTION_REJECTED,
  SET_INTERACTION_SUBJECT,
  SET_MAIL_ACTION,
  SET_SELECTED_INTERACTION,
  SET_SELECTED_TEMPLATE,
  SET_TRANSCRIPTION_SEARCH,
  SUMMARY_FULFILLED,
  SUMMARY_PENDING,
  SUMMARY_REJECTED,
  TEXT_INTERACTION_CHILD_FULFILLED,
  TEXT_INTERACTION_CHILD_PENDING,
  TEXT_INTERACTION_CHILD_REJECTED,
  UPDATE_TEXT_INTERACTION_CHILD,
} from './constants';

const initialState = {
  requestStarted: false,
  data: [],
  meta: nullMeta,
  errors: [],
  requestSuccess: false,
  requestFailed: false,
  addNoteModal: false,
  filters: {
    type: { label: 'All', value: '' },
    date: { label: 'All', value: '' },
    owner: { label: 'All', value: '' },
  },
  params: {
    page: 1,
    per_page: 15,
    user_id: undefined,
    query: undefined,
    account_id: undefined,
    contact_id: undefined,
    opportunity_id: undefined,
  },
  backToLink: null,
  shouldRefresh: false,
  deleteInProgress: false,
  selectedInteraction: null,
  interactionRequestPending: false,
  interactionRequestFulfilled: false,
  interactionRequestRejected: false,
  composeEmailParams: {
    closed: true,
    expanded: false,
    minimized: false,
  },
  mailAction: '',
  selectedEmailTemplate: {},
  emailParams: {
    to: [],
    cc: [],
    subject: '',
    body: '',
  },
  emailTemplates: [],
  selectedInteractionTranscriptions: {},
  interactionTranscriptionsStatus: 'idle',
  interactionTranscriptionsError: null,
  childInteractions: {
    status: 'idle',
    error: null,
    data: [],
  },
  attachmentAssetUrl: '',
  transcriptionSearch: {
    query: '',
    count: 0,
    selectedOccurenceIndex: 0,
    selectedFindingIndex: null,
    selectedPart: null,
    indexInFinding: null,
    findings: [],
  },
  textChildInteractions: {
    interactions: [],
    meta: nullMeta,
  },
  textChildInteractionsPending: false,
  textChildInteractionsRejected: false,
  summaryRequestPending: false,
  summaryRequestFulfilled: false,
  summary: null,
  intentsActionsRequestPending: false,
  intentsActionsRequestFulfilled: false,
  intentsActions: [],
  missedCallNumberRequestPending: false,
  missedCallNumberRequestFulfilled: false,
  missedCallNumber: null,
};

export default function interactionsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INTERACTION_PENDING:
      return {
        ...state,
        interactionRequestPending: true,
      };
    case SET_SELECTED_INTERACTION:
      return {
        ...state,
        selectedInteraction: action.payload,
      };
    case SET_INTERACTION_FULFILLED:
      return {
        ...state,
        interactionRequestPending: false,
        interactionRequestFulfilled: true,
        selectedInteraction: action.payload,
      };
    case SET_INTERACTION_REJECTED:
      return {
        ...state,
        interactionRequestPending: false,
        interactionRequestRejected: true,
      };
    case SET_INTERACTION_DESCRIPTION:
      return {
        ...state,
        selectedInteraction: {
          ...state.selectedInteraction,
          description: action.payload,
        },
      };
    case SET_INTERACTION_SUBJECT:
      return {
        ...state,
        selectedInteraction: {
          ...state.selectedInteraction,
          subject: action.payload,
        },
      };
    case SET_COMPOSE_EMAIL_PARAMS: {
      return {
        ...state,
        composeEmailParams: Object.keys(action.params).length
          ? action.params
          : initialState.composeEmailParams,
      };
    }
    case SET_MAIL_ACTION: {
      return { ...state, mailAction: action.payload };
    }
    case SET_SELECTED_TEMPLATE: {
      return { ...state, selectedEmailTemplate: action.payload };
    }
    case SET_EMAIL_PARAMS: {
      return {
        ...state,
        emailParams: Object.keys(action.payload).length
          ? { ...state.emailParams, ...action.payload }
          : initialState.emailParams,
      };
    }
    case SET_EMAIL_TEMPLATES: {
      return {
        ...state,
        emailTemplates: action.payload,
      };
    }
    case INTERACTION_TRANSCRIPTIONS_PENDING: {
      return {
        ...state,
        interactionTranscriptionsStatus: 'pending',
      };
    }
    case INTERACTION_TRANSCRIPTIONS_FULFILLED: {
      return {
        ...state,
        interactionTranscriptionsStatus: 'fulfilled',
        selectedInteractionTranscriptions: action.payload,
      };
    }
    case INTERACTION_CHILD_PENDING: {
      return {
        ...state,
        childInteractions: {
          ...state.childInteractions,
          status: 'pending',
        },
      };
    }
    case INTERACTION_CHILD_FULFILLED: {
      return {
        ...state,
        childInteractions: {
          ...state.childInteractions,
          status: 'fulfilled',
          data: action.payload,
        },
      };
    }
    case INTERACTION_CHILD_REJECTED: {
      return {
        ...state,
        childInteractions: {
          ...state.childInteractions,
          status: 'rejected',
          error: action.payload,
        },
      };
    }
    case INTERACTION_CHILD_CLEARED: {
      return {
        ...state,
        childInteractions: {
          status: 'idle',
          error: null,
          data: [],
        },
      };
    }
    case SET_INTERACTION_CUSTOM_FIELD: {
      return {
        ...state,
        selectedInteraction: {
          ...state.selectedInteraction,
          custom: {
            ...state.selectedInteraction.custom,
            [action.payload.key]: action.payload.value,
          },
        },
      };
    }
    case SET_ATTACHMENT_ASSET_URL: {
      return {
        ...state,
        attachmentAssetUrl: action.payload,
      };
    }
    case INTERACTION_TRANSCRIPTIONS_REJECTED: {
      return {
        ...state,
        interactionTranscriptionsStatus: 'rejected',
        interactionTranscriptionsError: action.payload,
      };
    }
    case INTERACTION_REMOVE_TRANSCRIPTIONS: {
      return {
        ...state,
        selectedInteractionTranscriptions: {},
        interactionTranscriptionsStatus: 'idle',
        interactionTranscriptionsError: null,
      };
    }
    case CLEAR_TRANSCRIPTION_SEARCH: {
      return {
        ...state,
        transcriptionSearch: initialState.transcriptionSearch,
      };
    }
    case SET_TRANSCRIPTION_SEARCH: {
      if (Array.isArray(action.payload)) {
        let tempTranscriptionSearch = {
          ...state.transcriptionSearch,
        };
        action.payload.forEach((item) => {
          tempTranscriptionSearch = {
            ...tempTranscriptionSearch,
            [item.key]: item.value,
          };
        });
        return {
          ...state,
          transcriptionSearch: tempTranscriptionSearch,
        };
      }
      return {
        ...state,
        transcriptionSearch: {
          ...state.transcriptionSearch,
          [action.payload.key]: action.payload.value,
        },
      };
    }
    case TEXT_INTERACTION_CHILD_FULFILLED: {
      return {
        ...state,
        textChildInteractions: {
          ...state.textChildInteractions,
          interactions: [
            ...action.payload.interactions.map((msg) => new SmsMessage(msg)).reverse(),
            ...state.textChildInteractions.interactions,
          ],
          meta: parseMetaFromResponse(action.payload.meta),
        },
      };
    }
    case TEXT_INTERACTION_CHILD_PENDING:
      return {
        ...state,
        textChildInteractionsPending: true,
        textChildInteractionsRejected: false,
      };
    case TEXT_INTERACTION_CHILD_REJECTED:
      return {
        textChildInteractionsPending: true,
        textChildInteractionsRejected: true,
      };
    case CLEAR_TEXT_INTERACTION_CHILD:
      return {
        ...state,
        textChildInteractionsPending: false,
        textChildInteractionsRejected: false,
        textChildInteractions: {
          interactions: [],
          meta: nullMeta,
        },
      };
    case SUMMARY_PENDING:
      return {
        ...state,
        summaryRequestPending: true,
      };
    case SUMMARY_FULFILLED:
      return {
        ...state,
        summaryRequestPending: false,
        summaryRequestFulfilled: true,
        summary: action.payload.nlp_summary.summary,
      };
    case SUMMARY_REJECTED:
      return {
        ...state,
        summaryRequestPending: false,
        summaryRequestRejected: true,
        summary: null,
      };
    case INTENTS_ACTIONS_PENDING:
      return {
        ...state,
        intentsActionsRequestPending: true,
      };
    case INTENTS_ACTIONS_FULFILLED:
      return {
        ...state,
        intentsActionsRequestPending: false,
        intentsActionsRequestFulfilled: true,
        intentsActions: action.payload.nlp_actions,
      };
    case INTENTS_ACTIONS_REJECTED:
      return {
        ...state,
        intentsActionsRequestPending: false,
        intentsActionsRequestRejected: true,
        intentsActions: [],
      };
    case REMOVE_INTENT_AFTER_CREATE:
      return {
        ...state,
        intentsActions: action.payload,
      };
    case UPDATE_TEXT_INTERACTION_CHILD:
      return {
        ...state,
        textChildInteractions: {
          ...state.textChildInteractions,
          interactions: [...state.textChildInteractions.interactions, action.payload],
        },
      };
    case MISSED_CALL_NUMBER_PENDING:
      return {
        ...state,
        missedCallNumberRequestPending: true,
      };
    case MISSED_CALL_NUMBER_FULFILLED:
      return {
        ...state,
        missedCallNumberRequestPending: false,
        missedCallNumberRequestFulfilled: true,
        missedCallNumber: action.payload.twilio_call_log.from_number,
      };
    case MISSED_CALL_NUMBER_REJECTED:
      return {
        ...state,
        missedCallNumberRequestPending: false,
        missedCallNumberRequestRejected: true,
        missedCallNumber: null,
      };
    default:
      return state;
  }
}
