import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import classNames from 'classnames';
import PropTypes from 'prop-types';

function ModalHeader({ children, divider = false, className = '', ...props }) {
  return (
    <DialogTitle {...props} className={classNames([className])}>
      {children}
      {divider && <Divider sx={{ marginBottom: '10px', marginTop: '15px' }} />}
    </DialogTitle>
  );
}

ModalHeader.propTypes = {
  children: PropTypes.node.isRequired,
  divider: PropTypes.bool,
  className: PropTypes.string,
};

export default ModalHeader;
