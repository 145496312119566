import EngineService from '../core/engine-service';

const ENDPOINT = '/search';

export function get(term, params = {}, include = '') {
  return EngineService.get(ENDPOINT, {
    ...params,
    query: term || '',
    include: include || 'account',
  }).then((json) => json);
}

export function search(params) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function getContacts(params = {}) {
  return EngineService.get(`${ENDPOINT}/contacts`, params).then((json) => json);
}

export function getColumns(resource, params) {
  return EngineService.get(`${ENDPOINT}/${resource}/columns`, params).then((json) => json.columns);
}

export function getContactsColumns(params) {
  return getColumns('contacts', params);
}

export function getAccountsColumns(params) {
  return getColumns('accounts', params);
}

export function getOpportunitiesColumns(params) {
  return getColumns('opportunities', params);
}

export default {
  get,
  getColumns,
  getContacts,
  getContactsColumns,
  getAccountsColumns,
  getOpportunitiesColumns,
};
