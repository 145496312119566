const localStorage = {
  getItem(key) {
    return JSON.parse(window.localStorage.getItem(key));
  },

  saveItem(key, value) {
    window.localStorage.setItem(key, JSON.stringify(value));
  },

  deleteItem(key) {
    window.localStorage.removeItem(key);
  },

  clear() {
    window.localStorage.clear();
  },

  itemExist(key) {
    return !!this.get(key);
  },
};

export default localStorage;
