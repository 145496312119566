import { serializeFiltersToString } from 'helpers/filters';
import { parseMetaFromResponse } from 'helpers/meta';
import activityService from 'services/activities';
import attachmentService from 'services/attachments';
import noteService from 'services/notes';
import {
  fetchFilterProperties,
  fetchSavedFilters,
} from 'state/filters/nested-interactions/actions';

import {
  CLEAR_INTERACTIONS,
  FETCH_INTERACTIONS_STARTED,
  HIDE_MODAL,
  SET_IS_REFRESH_REQUIRED,
  SET_PARAMS,
  SET_TIMELINE_FILTER_AND_LABEL,
  SHOW_MODAL,
  STORE_INTERACTIONS,
} from './constants';
import { forEachError } from '../../../helpers/errorHelper';
import http from '../../core/engine-service';
import { error as errorAlert } from '../notifications/actions';

export function storeInteractions(interactions, meta) {
  return {
    type: STORE_INTERACTIONS,
    data: interactions,
    meta: parseMetaFromResponse(meta),
  };
}

export function setParams(data) {
  return {
    type: SET_PARAMS,
    data,
  };
}

export function setIsRefreshRequired(isRefreshRequired) {
  return {
    type: SET_IS_REFRESH_REQUIRED,
    isRefreshRequired,
  };
}

export function fetchInteractionsStarted() {
  return {
    type: FETCH_INTERACTIONS_STARTED,
  };
}

export function clearInteractions() {
  return {
    type: CLEAR_INTERACTIONS,
  };
}

function getParamsFromState(state) {
  const params = state.nestedInteractions.params;
  const currentFilters = state.filters.nestedInteractions.currentFilters;

  return {
    ...params,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export function getInteractions(params = {}) {
  return async (dispatch, getState) => {
    dispatch(fetchInteractionsStarted());
    dispatch(fetchFilterProperties());
    dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const recordsPerPage = getState().spiroViews.recordsPerPage;
      const defaultParams = { ...stateParams, per_page: recordsPerPage };
      const payload = { ...defaultParams, ...params };

      return http
        .get('/interactions', payload)
        .then((json) => {
          dispatch(storeInteractions(json.interactions, json.meta));
          dispatch(setParams(payload));
          return Promise.resolve(json);
        })
        .catch((err) => Promise.reject(err));
    });
  };
}

export function deleteInteraction(interactionID, successFunction) {
  return (dispatch) => {
    const handleSuccess = () => {
      dispatch(getInteractions());
      if (successFunction) successFunction();
    };
    const handleError = (err) => forEachError(err.data, (e) => dispatch(errorAlert(e)));

    return http.delete(`/interactions/${interactionID}`).then(handleSuccess).catch(handleError);
  };
}

export function showModal(modalName) {
  return {
    type: SHOW_MODAL,
    modalName,
  };
}

export function hideModal() {
  return {
    type: HIDE_MODAL,
  };
}

function handleCreateSuccess(json, dispatch) {
  dispatch(getInteractions());
  return Promise.resolve(json);
}

function handleCreateFailure(err, dispatch) {
  if (err.status !== 422) {
    forEachError(err.data, (e) => dispatch(errorAlert(e)));
  }
  return Promise.reject(err);
}

export function createNote(payload) {
  return (dispatch) =>
    noteService
      .create(payload)
      .then((json) => {
        handleCreateSuccess(json, dispatch);
      })
      .catch((err) => handleCreateFailure(err, dispatch));
}

export function createAttachment(payload) {
  return (dispatch) =>
    attachmentService
      .create(payload)
      .then((json) => {
        handleCreateSuccess(json, dispatch);
      })
      .catch((err) => handleCreateFailure(err, dispatch));
}

export function createActivity(payload) {
  return (dispatch) =>
    activityService
      .create(payload)
      .then((json) => {
        handleCreateSuccess(json, dispatch);
      })
      .catch((err) => handleCreateFailure(err, dispatch));
}

export function setTimelineFilterAndLabel(data) {
  return {
    type: SET_TIMELINE_FILTER_AND_LABEL,
    data,
  };
}
