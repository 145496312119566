import {
  FETCHING_DISPLAY_FIELDS_FAILED,
  FETCHING_DISPLAY_FIELDS_STARTED,
  FETCHING_DISPLAY_FIELDS_SUCCESS,
  REORDER_FIELDS,
  SET_SELECTED_ENTITY,
  STORE_ALL_DISPLAY_FIELDS,
  STORE_DISPLAY_FIELDS,
  UPDATE_DISPLAY_FIELDS,
} from './constants';
import { immutableSwap } from '../../../helpers/immutableHelper';

const initialState = {
  fields: {},
  items: {},
  selectedResource: 'Opportunity',
  fetching: false,
  fetched: false,
  failed: false,
};

function fetchingDisplayFieldsSuccess(state) {
  return {
    ...state,
    fetching: false,
    fetched: true,
  };
}

function fetchingDisplayFields(state) {
  return {
    ...state,
    fetching: true,
    fetched: false,
  };
}

function updateDisplayFields(state) {
  return {
    ...state,
    fetching: true,
    fetched: false,
  };
}

function storeDisplayFields(state, action) {
  return {
    ...state,
    fields: Object.assign(state.fields, action.data),
    items: {
      ...state.items,
      ...action.items,
    },
  };
}

function storeAllDisplayFields(state, action) {
  return {
    ...state,
    fetched: true,
    fields: action.data,
    items: action.items,
  };
}

function fetchingDisplayFieldsFailed(state) {
  return {
    ...state,
    fetching: false,
    failed: true,
  };
}

function setSelectedEntity(state, action) {
  return {
    ...state,
    selectedResource: action.entity,
  };
}

function reorderFields(state, action) {
  const fields = state.items[action.entity];

  return {
    ...state,
    items: {
      ...state.items,
      [action.entity]: immutableSwap(fields, action.dragIndex, action.hoverIndex).map(
        (field, index) => ({ ...field, order: index })
      ),
    },
  };
}

const ACTION_HANDLERS = {
  [FETCHING_DISPLAY_FIELDS_STARTED]: fetchingDisplayFields,
  [FETCHING_DISPLAY_FIELDS_SUCCESS]: fetchingDisplayFieldsSuccess,
  [FETCHING_DISPLAY_FIELDS_FAILED]: fetchingDisplayFieldsFailed,
  [UPDATE_DISPLAY_FIELDS]: updateDisplayFields,
  [STORE_DISPLAY_FIELDS]: storeDisplayFields,
  [STORE_ALL_DISPLAY_FIELDS]: storeAllDisplayFields,
  [SET_SELECTED_ENTITY]: setSelectedEntity,
  [REORDER_FIELDS]: reorderFields,
};

export default function displayFieldsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
