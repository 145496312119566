export const STORE_TWILIO_PROFILE = 'twilio_profile: store_twilio_profile';
export const SET_TWILIO_VERIFIED_STATUS = 'twilio_profile: set_twilio_verified_status';
export const CHANGE_TWILIO_PROFILE_AVAILABILITY = 'twilio_profile: change_availability';
export const STORE_ORG_TWILIO_PROFILES = 'twilio_profile: store_org_twilio_profiles';
export const FETCHING_ORG_TWILIO_PROFILES = 'twilio_profile: fetching_org_twilio_profiles';
export const TOGGLE_ORG_CALL_RECORDING_AVILABILITY =
  'twilio_profile: toggle_org_call_recording_avilability';
export const TOGGLE_ORG_CALL_SUMMARY_AVILABILITY =
  'twilio_profile: toggle_org_call_summary_avilability';

export default {
  STORE_TWILIO_PROFILE,
  SET_TWILIO_VERIFIED_STATUS,
  CHANGE_TWILIO_PROFILE_AVAILABILITY,
  STORE_ORG_TWILIO_PROFILES,
  FETCHING_ORG_TWILIO_PROFILES,
  TOGGLE_ORG_CALL_RECORDING_AVILABILITY,
  TOGGLE_ORG_CALL_SUMMARY_AVILABILITY,
};
