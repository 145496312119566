import EngineService from '../core/engine-service';

const ENDPOINT = '/sms_messages';

export async function update(id, payload) {
  const json = await EngineService.patch(`${ENDPOINT}/${id}`, { sms_message: payload });
  return json;
}

export async function destroy(id) {
  const json = await EngineService.delete(`${ENDPOINT}/${id}`);
  return json;
}

export default {
  update,
  delete: destroy,
};
