export const REQUEST_STARTED = 'NESTED_CONTACTS: fetch_all_request_started';
export const REQUEST_SUCCESS = 'NESTED_CONTACTS: fetch_all_request_success';
export const REQUEST_FAILED = 'NESTED_CONTACTS: fetch_all_request_failed';
export const SORT_CONTACTS = 'NESTED_CONTACTS: sort';
export const REPLACE_CONTACT = 'NESTED_CONTACTS: replace_contact';
export const STORE_CONTACTS = 'NESTED_CONTACTS: store_contacts';
export const SET_PARAMS = 'NESTED_CONTACTS: set_params';
export const RESET_DATA = 'NESTED_CONTACTS: reset_contacts_data';
export const ADD_CONTACT_TO_LIST = 'NESTED_CONTACTS: add_contact_to_list';
export const CREATE_IN_PROGRESS = 'NESTED_CONTACTS: create_in_progress';
export const CREATE_SUCCESS = 'NESTED_CONTACTS: create_success';
export const CREATE_FAILURE = 'NESTED_CONTACTS: create_failure';
