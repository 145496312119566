import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import useHotjar from 'hooks/useHotjar';
import { removePushNotification } from 'state/push-notifications/reducer';

import NotificationBody from './NotificationBody';
import usePushNotification from './usePushNotification';

function PushNotification({ notifications = [] }) {
  const dispatch = useDispatch();
  const [isClosedManually, setIsClosedManually] = useState(false);
  const { runCorrespondingActionsByType } = usePushNotification();
  const { logCustomEvent } = useHotjar();

  const drawNotifications = (data) => {
    data.forEach((notification) => {
      toast(<NotificationBody notification={notification} />, {
        toastId: notification.uid,
        style: {
          boxShadow: '0px 16px 16px rgba(0, 0, 0, 0.16)',
          height: '72px',
          width: '450px',
          right: '143px',
        },
        closeOnClick: false,
        onClose: () => {
          if (!isClosedManually) {
            dispatch(removePushNotification(notification.uid));
          } else {
            setIsClosedManually(false);
          }
        },
        closeButton: (
          <CloseIcon
            sx={{ cursor: 'pointer' }}
            fontSize="small"
            onClick={(e) => {
              toast.dismiss(notification.uid);
              dispatch(removePushNotification(notification.uid));
              e.stopPropagation();
              setIsClosedManually(true);
            }}
          />
        ),
        onClick: () => {
          runCorrespondingActionsByType(notification, toast.dismiss)();
          setIsClosedManually(true);
        },
        autoClose: 5000,
        position: 'top-right',
        draggable: false,
        pauseOnHover: true,
        hideProgressBar: true,
      });
    });
  };

  useEffect(() => {
    if (notifications.length) {
      drawNotifications(notifications);
      logCustomEvent('notification_popup_created');
    }
  }, [notifications]);

  return null;
}

PushNotification.propTypes = {
  notifications: PropTypes.array,
};

export default PushNotification;
