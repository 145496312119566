import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import MuiCheckbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';

const StyledCheckbox = styled(MuiCheckbox)({
  '&.MuiCheckbox-root': {
    color: '#999999',
  },
  '&.Mui-checked': {
    color: '#5d43cf',
  },
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-checked:hover': {
    backgroundColor: 'rgba(146, 88, 231, 0.08)',
  },
  '&.Mui-checked:focus': {
    backgroundColor: 'rgba(146, 88, 231, 0.16)',
  },
  '&.Mui-checked:active': {
    backgroundColor: 'rgba(146, 88, 231, 0.24)',
  },
  '&.Mui-checked.Mui-disabled': {
    color: 'rgba(174, 161, 231, 0.48)',
  },
});

export default function Checkbox(props) {
  return <StyledCheckbox checkedIcon={<CheckBoxOutlinedIcon />} {...props} />;
}
