import { useState } from 'react';

import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setCallInfo } from 'app/components/CallDialog/state/actions';
import { convertNameToInitials } from 'app/helpers/utils';

import styles from './MultipleCallersMenu.module.scss';

export default function MultipleCallersMenu({ items, number, caller = null, isShrinked = false }) {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (item) => {
    handleClose();
    dispatch(setCallInfo(items, item));
  };

  return (
    <>
      <span
        onClick={handleClick}
        tabIndex="0"
        role="button"
        size="small"
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        className={styles.button}
      >
        <span className={isShrinked ? styles['shrinked-button'] : styles.name}>
          {caller?.name || 'Multiple Contacts'}
        </span>
        <ArrowDropDownOutlinedIcon />
      </span>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              background: '#ffffff',
              boxShadow: '0px 12px 12px rgba(0, 0, 0, 0.08)',
              borderRadius: '8px',
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              '& .MuiList-root': {
                padding: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        {items.map((item) => (
          <MenuItem
            key={item.resource_id}
            onClick={() => handleSelect(item)}
            className={styles.item}
          >
            <Avatar src={item.photo_url}>{convertNameToInitials(item.name)}</Avatar>
            <div>
              <p className={styles.heading}>{item.name}</p>
              <p className={styles.subheading}>
                {number} ({item.account_name})
              </p>
            </div>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

MultipleCallersMenu.propTypes = {
  items: PropTypes.array.isRequired,
  number: PropTypes.string.isRequired,
  caller: PropTypes.shape({
    account_name: PropTypes.string,
    name: PropTypes.string,
    resource_type: PropTypes.string,
    resource_id: PropTypes.number,
    ext_number: PropTypes.object,
    photo_url: PropTypes.object,
  }),
  isShrinked: PropTypes.bool,
};
