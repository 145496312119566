import { useEffect } from 'react';

import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListAltIcon from '@mui/icons-material/ListAlt';
import Typography from '@mui/material/Typography';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import SpiroTooltip from 'components/SpiroTooltip';
import { closeListsMenu, openListsMenu } from 'state/app/reducer';
import { selectIsListMenuVisible, selectIsSidebarMenuCollapsed } from 'state/app/selectors';

import styles from './ListsMenu.module.scss';

export default function ListsMenu() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const pathnameMatchesLists = pathname.startsWith('/lists/');
  const listsMenuVisible = useSelector(selectIsListMenuVisible);
  const isSidebarCollapsed = useSelector(selectIsSidebarMenuCollapsed);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!listsMenuVisible && pathnameMatchesLists) {
      dispatch(openListsMenu());
    }
    if (listsMenuVisible && !pathnameMatchesLists) {
      dispatch(closeListsMenu());
    }
  }, [pathname]);

  const toggleMenu = () => {
    if (listsMenuVisible) return dispatch(closeListsMenu());

    return dispatch(openListsMenu());
  };

  return (
    <div
      role="button"
      tabIndex="0"
      onClick={toggleMenu}
      className={classNames(styles.toggle, { [styles.open]: pathnameMatchesLists })}
    >
      <SpiroTooltip
        title={isSidebarCollapsed ? t(`localization.navigation.lists`) : ''}
        placement="top"
        disableInteractive
      >
        <ListAltIcon className={styles.icon} />
      </SpiroTooltip>
      <div className={styles.conteiner}>
        <Typography className={styles.title}>{t(`localization.navigation.lists`)}</Typography>
        <ChevronRightIcon className={styles.icon} />
      </div>
    </div>
  );
}
