import {
  BATCH_UPDATE,
  CREATE,
  CREATE_SALES_STAGE,
  REQUEST_FAIL,
  REQUEST_START,
  REQUEST_SUCCESS,
  RESET,
  SET_CLEAN,
  SET_DIRTY,
  STORE_ALL_SALES_STAGES,
  STORE_SALES_STAGE,
  STORE_SALES_STAGES,
  UPDATE,
} from './constants';
import { immutableInsert, immutablePush } from '../../../helpers/immutableHelper';
import { blank, repairSortValues } from '../../helpers/opportunity-stages';

const initialState = {
  items: [],
  allItems: [],
  dirty: false,
  requestInProgress: false,
  requestSuccess: false,
  requestFail: false,
};

function requestStartHandler(state) {
  return {
    ...state,
    requestInProgress: true,
    requestSuccess: false,
    requestFail: false,
  };
}

function requestSuccessHandler(state) {
  return {
    ...state,
    requestInProgress: false,
    requestSuccess: true,
    requestFail: false,
  };
}

function requestFailHandler(state) {
  return {
    ...state,
    requestInProgress: false,
    requestSuccess: false,
    requestFail: true,
  };
}

export function storeSalesStagesHandler(state, action) {
  return {
    ...state,
    items: [...action.data],
    dirty: false,
  };
}

export function storeAllSalesStagesHandler(state, action) {
  return {
    ...state,
    allItems: [...action.data],
    dirty: false,
  };
}

export function storeSalesStageHandler(state, action) {
  return {
    ...state,
    items: immutableInsert(state.items, action.data, action.key),
    dirty: true,
  };
}

export function createSalesStageHandler(state) {
  return {
    ...state,
    items: immutablePush(state.items, {
      ...blank(),
      spiroStage: state.stageValues[0].value,
      value: state.stageValues[0].value,
      sort: state.items.length + 1,
    }),
    dirty: true,
  };
}

export function createHandler(state, action) {
  return {
    ...state,
    items: [...state.items, action.data],
  };
}

export function updateHandler(state, action) {
  const i = state.items.findIndex((item) => item.id === action.id);
  return { ...state, items: repairSortValues(immutableInsert(state.items, action.data, i)) };
}

export function batchUpdateHandler(state, action) {
  return { ...state, items: [...action.data] };
}

export function setDirtyHandler(state) {
  return { ...state, dirty: true };
}

export function setCleanHandler(state) {
  return { ...state, dirty: false };
}

export function resetHandler() {
  return initialState;
}

const ACTION_HANDLERS = {
  [STORE_SALES_STAGES]: storeSalesStagesHandler,
  [STORE_ALL_SALES_STAGES]: storeAllSalesStagesHandler,
  [STORE_SALES_STAGE]: storeSalesStageHandler,
  [CREATE_SALES_STAGE]: createSalesStageHandler,
  [SET_CLEAN]: setCleanHandler,
  [SET_DIRTY]: setDirtyHandler,
  [REQUEST_START]: requestStartHandler,
  [REQUEST_SUCCESS]: requestSuccessHandler,
  [REQUEST_FAIL]: requestFailHandler,
  [CREATE]: createHandler,
  [UPDATE]: updateHandler,
  [BATCH_UPDATE]: batchUpdateHandler,
  [RESET]: requestStartHandler,
};

export default function salesStagesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
