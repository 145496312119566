import PropTypes from 'prop-types';

import ResourceLookup from '../ResourceLookup/ResourceLookup';

function OpportunityLookup(props) {
  return <ResourceLookup {...props} inline={props.inline} resource="opportunity" />;
}

OpportunityLookup.defaultProps = {
  inline: false,
};

OpportunityLookup.propTypes = {
  inline: PropTypes.bool,
};

export default OpportunityLookup;
