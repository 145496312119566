import { HIDE_MODAL_DIALOG, MINIMIZE_EMAIL_DIALOG, SHOW_MODAL_DIALOG } from './constants';

const initialState = {
  name: '',
  visible: '',
  props: null,
  isEmailModalMinimized: false,
};

function showModalHandler(state, action) {
  return {
    ...state,
    name: action.name,
    visible: true,
    props: action.props,
  };
}

function hideModalHandler(state) {
  return { ...state, ...initialState };
}

function toggleMinimizeEmailModal(state) {
  return { ...state, isEmailModalMinimized: !state.isEmailModalMinimized };
}
const ACTION_HANDLERS = {
  [SHOW_MODAL_DIALOG]: showModalHandler,
  [HIDE_MODAL_DIALOG]: hideModalHandler,
  [MINIMIZE_EMAIL_DIALOG]: toggleMinimizeEmailModal,
};

export default function contactsReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
