import { revokeToken } from 'services/auth';

import { CREATE_USER_SESSION, DESTROY_USER_SESSION } from './constants';
import localStorage from '../../../helpers/localStorage';

export function createUserSession(payload) {
  return {
    type: CREATE_USER_SESSION,
    payload,
  };
}

export function destroyUserSessionLocally() {
  return {
    type: DESTROY_USER_SESSION,
  };
}
export function destroyUserSession() {
  const session = localStorage.getItem('session');

  return (dispatch) =>
    revokeToken(session?.accessToken).then(() => {
      dispatch({
        type: DESTROY_USER_SESSION,
      });
    });
}

export default {
  createUserSession,
  destroyUserSession,
};
