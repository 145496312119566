import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ReactPhoneInput from './ReactPhoneInput';
import './Phone.scss';

function Phone({
  value,
  ref,
  name,
  className = 'spiro-phone-input',
  autoFocus = false,
  defaultCountry = 'us',
  disabled = false,
  onBlur = undefined,
  onKeyPress = () => {},
  onChange = () => {},
}) {
  return (
    <div className={className}>
      <ReactPhoneInput
        ref={ref}
        autoFocus={autoFocus}
        defaultCountry={defaultCountry}
        value={value}
        name={name}
        disabled={disabled}
        onBlur={onBlur}
        onEnterKeyPress={onKeyPress}
        onChange={onChange}
      />
    </div>
  );
}

Phone.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  defaultCountry: PropTypes.string,
  disabled: PropTypes.bool,
  onBlur: PropTypes.func,
  onKeyPress: PropTypes.func,
  onChange: PropTypes.func,
};

function mapStateToProps(state) {
  return {
    defaultCountry: state.user.countryCode.toLowerCase(),
  };
}

export default connect(mapStateToProps)(Phone);
