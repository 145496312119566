import { ATTACHMENT_CREATE_STARTED, ATTACHMENT_CREATED } from './constants';

const initialState = {
  isSubmitting: false,
  isOpen: false,
};

const attachmentCreateStarted = (state) => ({ ...state, isSubmitting: true });
const attachmentCreated = (state) => ({ ...state, isSubmitting: false });

const ACTION_HANDLERS = {
  [ATTACHMENT_CREATE_STARTED]: attachmentCreateStarted,
  [ATTACHMENT_CREATED]: attachmentCreated,
};

export default function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
