import { fetchFilterProperties, fetchSavedFilters } from 'app/state/filters/nested-filters/actions';

import {
  ADD_REMINDER,
  CLEAR_STATE,
  CLOSE_CREATE_REMINDER_MODAL,
  CLOSE_EDIT_REMINDER_MODAL,
  FETCH_REMINDERS_STARTED,
  OPEN_CREATE_REMINDER_MODAL,
  OPEN_EDIT_REMINDER_MODAL,
  REMOVE_REMINDER,
  SET_PARAMS,
  STORE_REMINDERS,
  UPDATE_REMINDER,
  UPDATE_REMINDER_STARTED,
} from './constants';
import { forEachError } from '../../../helpers/errorHelper';
import http from '../../core/engine-service';
import { parseMetaFromResponse } from '../../helpers/meta';
import Reminder from '../../models/Reminder';
import {
  create as createReminderRemote,
  destroy as destroyReminderRemote,
  get as getReminderRemote,
  update as updateReminderRemote,
} from '../../services/reminders';
import { error as errorAlert } from '../notifications/actions';

export function fetchRemindersStarted() {
  return {
    type: FETCH_REMINDERS_STARTED,
  };
}

export function storeReminders(reminders, meta) {
  return {
    type: STORE_REMINDERS,
    data: reminders.map((item) => new Reminder(item)),
    meta: parseMetaFromResponse(meta),
  };
}

export function setParams(data) {
  return {
    type: SET_PARAMS,
    data,
  };
}

export function getReminders(params = {}) {
  return async (dispatch, getState) => {
    dispatch(fetchFilterProperties('Reminder'));
    dispatch(fetchSavedFilters('Reminder')).then(() => {
      const stateParams = getState().nestedReminders.params;
      const recordsPerPage = getState().spiroViews.recordsPerPage;
      const defaultParams = { ...stateParams, per_page: recordsPerPage };
      const payload = {
        ...defaultParams,
        ...params,
      };

      return http
        .get('/reminders', payload)
        .then(({ reminders, meta }) => {
          dispatch(storeReminders(reminders, meta));
          dispatch(setParams(payload));

          return Promise.resolve(reminders);
        })
        .catch((err) => Promise.reject(err));
    });
  };
}

export function fetchReminder(reminderId) {
  return (dispatch) => {
    dispatch(fetchRemindersStarted());
    return getReminderRemote(reminderId);
  };
}

export function updateReminderStarted() {
  return {
    type: UPDATE_REMINDER_STARTED,
  };
}

export function updateReminderLocal(data) {
  return {
    type: UPDATE_REMINDER,
    data,
  };
}

export function updateReminder(reminder, data) {
  return async (dispatch) => {
    dispatch(updateReminderStarted());

    try {
      const r = await updateReminderRemote(reminder.id, data);
      dispatch(updateReminderLocal(new Reminder(r.reminder)));
      return Promise.resolve(r);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(errorAlert(e)));
      return Promise.reject(err);
    }
  };
}

// TODO: remove and use updateReminder instead
export function doneReminder(reminder, data) {
  return (dispatch) =>
    updateReminderRemote(reminder.id, data).then((r) => {
      dispatch(updateReminderLocal(new Reminder(r.reminder)));
    });
}

export function addReminder(data) {
  return {
    type: ADD_REMINDER,
    data,
  };
}

export function removeReminder(reminderId) {
  return {
    type: REMOVE_REMINDER,
    reminderId,
  };
}

export function createReminder(data) {
  return (dispatch) =>
    createReminderRemote(data)
      .then((r) => {
        dispatch(addReminder(new Reminder(r.reminder)));
        return Promise.resolve(r);
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
}

export function deleteReminder(reminderId) {
  return (dispatch) =>
    destroyReminderRemote(reminderId).then((r) => {
      dispatch(removeReminder(reminderId));
      return r;
    });
}

export function openEditModal(reminder) {
  return {
    type: OPEN_EDIT_REMINDER_MODAL,
    reminder,
  };
}

export function closeEditModal() {
  return {
    type: CLOSE_EDIT_REMINDER_MODAL,
  };
}

export function openCreateModal() {
  return {
    type: OPEN_CREATE_REMINDER_MODAL,
  };
}

export function closeCreateModal() {
  return {
    type: CLOSE_CREATE_REMINDER_MODAL,
  };
}

export function clearState() {
  return {
    type: CLEAR_STATE,
  };
}

const remindersActions = {
  getReminders,
  doneReminder,
  updateReminder,
  openEditModal,
  closeEditModal,
  openCreateModal,
  closeCreateModal,
  clearState,
};

export default remindersActions;
