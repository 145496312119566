export const STORE_PIPELINES = 'pipelines: store pipelines';
export const STORE_PIPELINE = 'pipelines: store pipeline';
export const SELECT_PIPELINE = 'pipelines: select pipeline';
export const INSERT_PIPELINE = 'pipelines: insert pipeline';
export const DELETE_PIPELINE = 'pipelines: delete pipeline';
export const REQUEST_START = 'pipelines: request start';
export const REQUEST_SUCCESS = 'pipelines: request success';
export const REQUEST_FAIL = 'pipelines: request fail';

export default {
  STORE_PIPELINES,
  STORE_PIPELINE,
  INSERT_PIPELINE,
  DELETE_PIPELINE,
  REQUEST_START,
  REQUEST_SUCCESS,
  REQUEST_FAIL,
  SELECT_PIPELINE,
};
