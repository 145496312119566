import { useSelector } from 'react-redux';

import { selectTwilioProfile } from 'state/user/selectors';

const useNotificationPermission = () => {
  const twilioProfile = useSelector(selectTwilioProfile);
  if ('Notification' in window) {
    const permission = Notification.permission;

    if (twilioProfile && permission === 'default') {
      Notification.requestPermission();
    }
  }
};

export default useNotificationPermission;
