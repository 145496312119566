import EngineService from '../core/engine-service';

const ENDPOINT = '/support_desks';

export async function getSupportDesks(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export async function createSupportDesk(payload) {
  return EngineService.post(ENDPOINT, { support_desk: payload }).then((json) => json);
}

export function deleteDesk(deskId) {
  return EngineService.delete(`${ENDPOINT}/${deskId}`).then((json) => json);
}

export function updateSupportDesk(supportDeskId, payload) {
  return EngineService.patch(`${ENDPOINT}/${supportDeskId}`, {
    support_desk: payload,
  }).then((json) => json);
}

export async function disconnectMailFromSupportDesk(deskId) {
  return EngineService.post(`${ENDPOINT}/${deskId}/disconnect_token`, {}).then((json) => json);
}

export function getSupportDesk(deskId) {
  return EngineService.get(`${ENDPOINT}/${deskId}`).then((json) => json);
}
