import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import {
  selectIsIncomingCall,
  selectIsPhoneDialogOpen,
  selectTwilioCaller,
} from 'components/CallDialog/state/selectors';
import useHotjar from 'hooks/useHotjar';
import Contact from 'models/Contact';
import { get as getAccount } from 'services/accounts';
import { getContact } from 'services/contacts';

import PhoneDialog from './PhoneDialog';
import {
  setActiveTab,
  setPhoneDialogOpen,
  setTwilioAccount,
  setTwilioContact,
} from './state/actions';

export default function CallDialog() {
  const caller = useSelector(selectTwilioCaller);
  const isPhoneDialogOpen = useSelector(selectIsPhoneDialogOpen);
  const incomingCall = useSelector(selectIsIncomingCall);
  const { logCustomEvent } = useHotjar();

  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      if (caller) {
        if (caller.resource_type === 'Contact') {
          const res = await getContact(caller.resource_id);
          dispatch(setTwilioContact(new Contact(res.contact)));
          dispatch(setTwilioAccount(null));
        } else {
          const res = await getAccount(caller.resource_id);
          dispatch(setTwilioAccount(res.account));
          dispatch(setTwilioContact(null));
        }
      }
    };

    fetchData();
  }, [caller]);

  useEffect(() => {
    if (incomingCall && !isPhoneDialogOpen) {
      dispatch(setActiveTab('Phone'));
      dispatch(setPhoneDialogOpen(true));
      logCustomEvent('inbound_call_recieved');
    }
    if (incomingCall && isPhoneDialogOpen) {
      dispatch(setActiveTab('Phone'));
      logCustomEvent('inbound_call_recieved');
    }
  }, [incomingCall]);

  return isPhoneDialogOpen && <PhoneDialog />;
}
