import Contact from './Contact';

class ContactRole {
  constructor(data = {}) {
    this.id = data.id || '';
    this.contactId = data.contact_id || '';
    this.keyContact = data.key_contact || false;
    this.opportunityId = data.opportunity_id || '';
    this.source = data.source || null;
    this.contact = new Contact(data.contact) || {};
    this.icon = data.key_contact ? 'tick-blue-ui-icon' : 'tick-grey-negative-ui-icon';
  }
}

export default ContactRole;
