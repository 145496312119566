import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Button from 'lib/Button';

import { ReactComponent as UnauthorizedAccessImage } from '../../../images/unauthorized-access.svg';

import styles from './UnauthorizedAccess.module.scss';

function UnauthorizedAccess({ message = "You don't have the permissions to see this page." }) {
  return (
    <Grid container justifyContent="center" className="text-center">
      <Grid item xs={12}>
        <Paper elevation={0} square className={styles.wrapper}>
          <UnauthorizedAccessImage />
          <Typography variant="h6" className={styles.heading}>
            Unauthorized Access!
          </Typography>
          <Typography variant="body1">{message}</Typography>
          <Button
            component={Link}
            to="/"
            variant="contained"
            color="primary"
            className={styles.button}
          >
            Return home
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

UnauthorizedAccess.propTypes = {
  message: PropTypes.string,
};

export default UnauthorizedAccess;
