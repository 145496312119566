export function parseTokenResponseData(response) {
  return {
    accessToken: response.access_token,
    createdAt: response.created_at,
    expiresIn: response.expires_in,
    instanceUrl: response.instance_url,
    refreshToken: response.refresh_token,
    tokenType: response.token_type,
    userEmail: response.user_email,
    userId: response.user_id,
  };
}
