import DoneIcon from '@mui/icons-material/Done';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import Chip from 'lib/Chip';

import styles from './ChipList.module.scss';

function ChipList({ chips, onChipClick, isLoading }) {
  const enabledChips = chips.filter((chip) => !chip.disabled);

  return (
    <div className={styles.wrapper}>
      {chips.map((chip) => {
        const isLastEnabled = enabledChips.length === 1 && !chip.disabled;
        return (
          <Tooltip
            key={chip.id}
            title={
              isLastEnabled
                ? 'Cannot hide all notifications. Please keep at least one type visible.'
                : ''
            }
            disableHoverListener={!isLastEnabled}
            arrow={false}
            classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
          >
            <div>
              <Chip
                label={chip.label}
                disabled={isLoading}
                onClick={() => onChipClick(chip.id)}
                icon={chip.disabled ? <VisibilityOffOutlinedIcon /> : <DoneIcon />}
                sx={{
                  backgroundColor: chip.disabled
                    ? 'rgba(128, 128, 128, 0.08)'
                    : 'rgba(174, 161, 231, 0.24)',
                  color: chip.disabled ? '#4D4D4D' : '#5D43CF',
                  '& .MuiSvgIcon-root, & svg': {
                    color: chip.disabled ? '#999999' : '#5D43CF',
                    fontSize: '16px',
                  },
                  border: chip.disabled ? null : '1px solid #5D43CF',
                  fontSize: '14px',
                }}
                hoverable={false}
              />
            </div>
          </Tooltip>
        );
      })}
    </div>
  );
}

ChipList.propTypes = {
  chips: PropTypes.array.isRequired,
  onChipClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default ChipList;
