import Moment from 'moment';

class Interaction {
  constructor(result) {
    this.id = result.id;
    this.type = result.type;
    this.accountName = result.account_name;
    this.interactionType = result.interaction_type;
    this.subject = result.highlight.subject;
    this.description = result.highlight.description;
    this.transcription = result.highlight.transcription?.transcription;
    this.interactedAt = new Moment(result.interacted_at).format('MMM D, YYYY');
    this.kind = result.kind;
  }

  getTitle() {
    return this.accountName;
  }

  getDescription() {
    if (Array.isArray(this.description)) {
      return this.description.join('');
    }
    return this.description;
  }

  getTranscription() {
    if (Array.isArray(this.transcription)) {
      return this.transcription.join('');
    }
    return this.transcription;
  }
}

export default Interaction;
