import { createSlice } from '@reduxjs/toolkit';

import { nullMeta, parseMetaFromResponse } from 'helpers/meta';

import {
  createRuleActionFields,
  fetchCalculationFields,
  fetchConditionDefinitions,
  fetchEmailTemplateFields,
  fetchEngineRule,
  fetchEngineRuleActionFields,
  fetchGroups,
  fetchRuleConditions,
  fetchUserFieldOptionsForAssistantRule,
  handleCreateRuleCondition,
  handleUpdateEngieRulesAction,
  handleUpdateEngineRule,
  handleUpdateRuleCondition,
} from './actions';
import { mapToLabelValue, parseActionFields } from '../EngineRuleActions/helper';
import { parseCalculationFields } from '../EngineRuleCalculation/helper';

const initialState = {
  fetching: false,
  fetchingGroups: false,
  data: null,
  deleteModalVisible: false,
  deleting: false,
  criteriaFields: [],
  conditions: [],
  fetchingConditions: false,
  groups: [],
  conditionDefinitions: null,
  fetchingConditionDefenitions: false,
  creatingRuleCondition: false,
  modalVisible: false,
  formData: { name: '', note: '', params: {} },
  selectedCriteriaId: null,
  deleteCriteriaModalVisible: false,
  conditionsMeta: nullMeta,
  conditionsParams: {
    page: 1,
  },
  configurations: {},
  calculationFields: [],
  userPickerFieldOptions: [],
  actionFields: [],
  actions: [],
  emailTemplateFields: [],
};

export const engineRuleSlice = createSlice({
  name: 'engine-rule',
  initialState,
  reducers: {
    setCriteriaFields: (state, { payload }) => ({ ...state, criteriaFields: payload }),
    setModalVisible: (state, { payload }) => ({
      ...state,
      modalVisible: payload,
      formData: payload ? state.formData : initialState.formData,
      selectedCriteriaId: payload ? state.selectedCriteriaId : null,
    }),
    setFormData: (state, { payload }) => ({ ...state, formData: payload }),
    setSelectedCriteriaId: (state, { payload }) => ({ ...state, selectedCriteriaId: payload }),
    toggleDeleteCriteriaModal: (state, { payload }) => ({
      ...state,
      deleteCriteriaModalVisible: payload,
    }),
    setRuleConditions: (state, { payload }) => ({ ...state, conditions: payload }),
    setEngineRule: (state, { payload }) => ({ ...state, data: payload }),
    clearConditionDefinitionsState: (state) => ({ ...state, conditionDefinitions: null }),
    addRuleAction: (state) => ({
      ...state,
      actions: [...state.actions, { id: state.actions.length + 1, field: {}, payload: {} }],
    }),
    setRuleActions: (state, { payload }) => ({ ...state, actions: payload }),
  },
  extraReducers: {
    [fetchEngineRule.pending]: (state) => ({
      ...state,
      fetching: true,
      conditions: [],
      criteriaFields: [],
    }),
    [fetchEngineRule.fulfilled]: (state, { payload }) => ({
      ...state,
      fetching: false,
      data: payload.assistant_rule,
      actions: payload.assistant_rule.assistant_rule_actions,
    }),
    [fetchEngineRule.rejected]: (state) => ({ ...state, fetching: false }),
    [fetchRuleConditions.pending]: (state) => ({ ...state, fetchingConditions: true }),
    [fetchRuleConditions.fulfilled]: (state, { payload }) => ({
      ...state,
      fetchingConditions: false,
      conditions: payload.conditions,
      conditionsMeta: parseMetaFromResponse(payload.meta),
    }),
    [fetchRuleConditions.rejected]: (state) => ({ ...state, fetchingConditions: false }),
    [fetchGroups.pending]: (state) => ({ ...state, fetchingGroups: true }),
    [fetchGroups.fulfilled]: (state, { payload }) => ({
      ...state,
      groups: payload,
      fetchingGroups: false,
    }),
    [fetchGroups.rejected]: (state) => ({ ...state, fetchingGroups: false }),
    [fetchConditionDefinitions.pending]: (state) => ({
      ...state,
      fetchingConditionDefenitions: true,
    }),
    [fetchConditionDefinitions.fulfilled]: (state, { payload }) => ({
      ...state,
      conditionDefinitions: payload,
      fetchingConditionDefenitions: false,
    }),
    [fetchConditionDefinitions.rejected]: (state) => ({
      ...state,
      fetchingConditionDefenitions: false,
    }),
    [handleCreateRuleCondition.pending]: (state) => ({ ...state, creatingRuleCondition: true }),
    [handleCreateRuleCondition.fulfilled]: (state) => ({ ...state, creatingRuleCondition: false }),
    [handleCreateRuleCondition.rejected]: (state) => ({ ...state, creatingRuleCondition: false }),
    [handleUpdateRuleCondition.pending]: (state) => ({ ...state, creatingRuleCondition: true }),
    [handleUpdateRuleCondition.fulfilled]: (state) => ({ ...state, creatingRuleCondition: false }),
    [handleUpdateRuleCondition.rejected]: (state) => ({ ...state, creatingRuleCondition: false }),
    [handleUpdateEngineRule.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.assistant_rule,
    }),
    [fetchCalculationFields.fulfilled]: (state, { payload }) => ({
      ...state,
      calculationFields: parseCalculationFields(payload),
    }),
    [fetchUserFieldOptionsForAssistantRule.fulfilled]: (state, { payload }) => {
      const keys = Object.keys(payload);
      const options = keys.map((key) => ({ label: payload[key], value: key }));
      return { ...state, userPickerFieldOptions: options };
    },
    [fetchEngineRuleActionFields.fulfilled]: (state, { payload }) => ({
      ...state,
      actionFields: parseActionFields(payload),
    }),
    [createRuleActionFields.fulfilled]: (state, { payload }) => ({
      ...state,
      actions: [
        ...state.actions,
        { definition: payload.definition, id: payload.id, kind: payload.kind },
      ],
    }),
    [handleUpdateEngieRulesAction.fulfilled]: (state, { payload }) => {
      const actions = state.actions;
      const index = state.actions.findIndex((item) => item.id === payload.id);

      const newState = actions
        .slice(0, index)
        .concat({ definition: payload.definition, id: payload.id, kind: payload.kind })
        .concat(actions.slice(index + 1));

      return {
        ...state,
        actions: [...newState],
      };
    },
    [fetchEmailTemplateFields.fulfilled]: (state, { payload }) => ({
      ...state,
      emailTemplateFields: mapToLabelValue(payload),
    }),
  },
});

export const {
  setCriteriaFields,
  setModalVisible,
  setFormData,
  setSelectedCriteriaId,
  toggleDeleteCriteriaModal,
  setRuleConditions,
  setEngineRule,
  clearConditionDefinitionsState,
  setRuleActions,
  addRuleAction,
} = engineRuleSlice.actions;
export default engineRuleSlice.reducer;
