import { useState } from 'react';

import { useDispatch } from 'react-redux';

import { groupItemsByDate } from 'app/helpers/utils';
import { getNotifications, markNotificationAsRead } from 'services/notifications';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../helpers/errorHelper';

export default function useNotifications() {
  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [meta, setMeta] = useState({ nextPage: false });
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
  });

  const dispatch = useDispatch();

  const fetchNotifications = async (requestParams = {}) => {
    const offset = notifications.length;
    const newParams = { ...params, offset, ...requestParams };

    try {
      setIsLoading(true);
      const res = await getNotifications(newParams);
      setNotifications((prevNotifications) => [...prevNotifications, ...res.notifications]);
      setParams(newParams);
      setMeta({ nextPage: res.meta.next_page });
      setIsLoading(false);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
    }
  };

  const clearState = () => {
    setNotifications([]);
    setParams({
      limit: 10,
      offset: 0,
    });
    setMeta({ nextPage: false });
  };

  const markAsRead = async (id) => {
    try {
      const res = await markNotificationAsRead(id);
      Promise.resolve(res);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
    }
  };

  return {
    isLoading,
    notifications: groupItemsByDate(notifications),
    fetchNotifications,
    meta,
    clearState,
    markAsRead,
  };
}
