import { createAsyncThunk } from '@reduxjs/toolkit';

import { createSupportDesk, deleteDesk, getSupportDesks } from 'services/support-desks';
import { error, success as successAlert } from 'state/notifications/actions';

import { forEachError } from '../../../../../helpers/errorHelper';

export const fetchSupportDesks = createAsyncThunk('support-desks-get', async (params = {}) => {
  const res = await getSupportDesks(params);
  return res;
});

export const handleCreateSupportDesk = createAsyncThunk(
  'support-desks-create',
  async (payload, { dispatch }) => {
    try {
      const res = await createSupportDesk(payload);
      dispatch(successAlert('Support Desk created!'));
      return res.tickets;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const handleDeleteSupportDesk = createAsyncThunk(
  'support-desks-delete',
  async (deskId, { dispatch }) => {
    try {
      return await deleteDesk(deskId);
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
