export const REQUEST_STARTED = 'custom_entities_fetch_all_request_started';
export const REQUEST_SUCCESS = 'custom_entities_fetch_all_request_success';
export const STORE_CUSTOM_OBJECT = 'custom_entities_store_custom_object';
export const SET_PARAMS = 'custom_entities_set_params';
export const ADD_CONTACT_TO_LIST = 'custom_entities_add_contact_to_list';
export const CREATE_IN_PROGRESS = 'custom_entities_create_in_progress';
export const CREATE_SUCCESS = 'custom_entities_create_success';

export const CUSTOM_OBJECTS_SHOW_MODAL = 'custom_objects_show_modal';
export const CUSTOM_OBJECTS_HIDE_MODAL = 'custom_objects_hide_modal';
export const CUSTOM_OBJECTS_SET_SELECTED_ENTITY = 'custom_objects_set_selected_entity';
export const CUSTOM_OBJECTS_CLEAR_DATA = 'custom_objects_clear_data';
