import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import MuiSelect from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';

import styles from './Select.module.scss';

const StyledFormControl = styled(FormControl)({
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent',
    position: 'relative',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    '&:hover fieldset': {
      borderColor: '#5D43CF',
    },
  },
});

const menuProps = {
  PaperProps: {
    sx: {
      filter: 'drop-shadow(-2px 0px 8px rgba(0, 0, 0, 0.10))',
    },
  },
};

export default function Select({
  className,
  fullWidth,
  label,
  value,
  options,
  removable,
  loading,
  variant,
  required,
  hasGrayLabel,
  ...props
}) {
  return (
    <StyledFormControl
      className={className}
      variant={variant}
      fullWidth={fullWidth}
      required={required}
      data-cy={`${props.name}-select-input`}
    >
      <InputLabel className={hasGrayLabel ? styles['gray-label'] : ''}>{label}</InputLabel>
      <MuiSelect value={value || ''} MenuProps={menuProps} {...props}>
        {removable && (
          <MenuItem value="" data-cy="option-none">
            <em>None</em>
          </MenuItem>
        )}
        {options.length === 1 && !options[0].value && (
          <MenuItem value={value} data-cy="option-1">
            <em>{options[0].label}</em>
          </MenuItem>
        )}
        {options.length >= 1 &&
          options[0].value?.toString() &&
          options.map((option, index) => (
            <MenuItem
              key={option.value}
              value={option.value}
              disabled={option.disabled}
              data-cy={`option-${index + 1}`}
            >
              {option.label}
            </MenuItem>
          ))}
      </MuiSelect>
      {loading && (
        <div className={styles.loader}>
          <CircularProgress size={20} />
        </div>
      )}
    </StyledFormControl>
  );
}

Select.defaultProps = {
  value: '',
  label: '',
  name: '',
  options: [],
  removable: true,
  fullWidth: true,
  className: '',
  loading: null,
  variant: 'filled',
  required: false,
  hasGrayLabel: false,
};

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  removable: PropTypes.bool,
  fullWidth: PropTypes.bool,
  className: PropTypes.string,
  variant: PropTypes.string,
  loading: PropTypes.bool,
  required: PropTypes.bool,
  hasGrayLabel: PropTypes.bool,
};
