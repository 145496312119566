import { useState } from 'react';

import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';
import { ClickAwayListener } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EmojiPicker from 'emoji-picker-react';
import PropTypes from 'prop-types';

import styles from '../styles/ConversationsButtonStyles.module.scss';

export default function EmojisButton({ handleOnChange }) {
  const [isEmojiModalVisible, setIsEmojiModalVisible] = useState(false);

  const [defaultSkinTone, setDefaultSkinTone] = useState(
    localStorage.getItem('defaultSkinTone') || 'neutral'
  );

  const onSkinToneChange = (skinTone) => {
    setDefaultSkinTone(skinTone);
    localStorage.setItem('defaultSkinTone', skinTone);
  };

  const onEmojiModalToggle = () => setIsEmojiModalVisible((prev) => !prev);

  return (
    <>
      <IconButton className={styles['icon-wrapper']} onClick={onEmojiModalToggle}>
        <Tooltip title="Emojis" arrow classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}>
          <EmojiEmotionsOutlinedIcon fontSize="medium" />
        </Tooltip>
      </IconButton>
      {isEmojiModalVisible && (
        <ClickAwayListener onClickAway={onEmojiModalToggle}>
          <div>
            <EmojiPicker
              width={330}
              height={419}
              defaultSkinTone={defaultSkinTone}
              style={{ position: 'absolute', bottom: '128px', right: '6px' }}
              onSkinToneChange={onSkinToneChange}
              onEmojiClick={(e) => handleOnChange(e.emoji)}
            />
          </div>
        </ClickAwayListener>
      )}
    </>
  );
}

EmojisButton.propTypes = {
  handleOnChange: PropTypes.func.isRequired,
};
