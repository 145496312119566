import EngineService from '../core/engine-service';

export function createNote(resource, id, payload) {
  return EngineService.post(`/${resource}/${id}/collaboration_notes`, {
    text: payload.text,
    replied_to: payload.repliedTo,
  });
}

export function getCollaborationNotes(resource, resourceID, params) {
  return EngineService.get(`${resource}/${resourceID}/collaboration_notes`, params).then(
    (json) => json
  );
}

export function getCollaborationNote(resource, resourceID, id, params = {}) {
  return EngineService.get(`${resource}/${resourceID}/collaboration_notes/${id}`, params).then(
    (json) => json
  );
}

export function getMentionableUsers(params = {}) {
  return EngineService.get('/collaboration/mentionable_users', params).then((json) => json);
}

export function deleteCollaborationNote(resource, resourceID, id) {
  return EngineService.delete(`${resource}/${resourceID}/collaboration_notes/${id}`).then(
    (json) => json
  );
}

export function updateCollaborationNote(resource, resourceID, id, payload) {
  return EngineService.put(`${resource}/${resourceID}/collaboration_notes/${id}`, payload).then(
    (json) => json
  );
}

export function addReaction(payload) {
  return EngineService.post('/collaboration/reactions', payload);
}

export function deleteReaction(reactionID) {
  return EngineService.delete(`/collaboration/reactions/${reactionID}`).then((json) => json);
}

export function getCollaborationNotesSubscription(resource, resourceID) {
  return EngineService.get(`${resource}/${resourceID}/resource_collaboration_subscriptions`).then(
    (json) => json
  );
}

export function updateCollaborationNotesSubscription(resource, resourceID, payload) {
  return EngineService.patch(
    `${resource}/${resourceID}/resource_collaboration_subscriptions`,
    payload
  ).then((json) => json);
}
