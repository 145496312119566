import { useDispatch, useSelector } from 'react-redux';
import { Device } from 'twilio-client';

import {
  minimizeCallModal,
  resetTwilioState,
  searchForContact,
  setActiveTab,
  setConnectingInProgress,
  setPhoneDialogOpen,
  setRinging,
  setTwilioCaller,
  setTwilioNetworkIssues,
  setupTwilioDevice,
  startCall,
} from 'components/CallDialog/state/actions';
import { selectTwilioEnabled } from 'components/CallDialog/state/selectors';
import {
  mapCallerFromAccount,
  mapCallerFromContact,
  mapCallerFromTicket,
  removePhoneNumberFormatting,
} from 'helpers/twilio';
import useHotjar from 'hooks/useHotjar';

import { error as errorAlert } from '../../state/notifications/actions';

export default function useCall() {
  const dispatch = useDispatch();
  const twilioEnabled = useSelector(selectTwilioEnabled);
  const { logCustomEvent } = useHotjar();

  const attemptConnection = (params, retries = 5) => {
    try {
      const connection = Device.connect(params);
      dispatch(startCall(connection, params));
      dispatch(setActiveTab('Phone'));

      connection.on('error', (error) => {
        if (retries > 0 && error.code === 31000) {
          setTimeout(() => attemptConnection(params, retries - 1), 2000);
        } else {
          dispatch(errorAlert('Connection failed due to network issues.'));
          setTimeout(() => {
            dispatch(resetTwilioState());
          }, 500);
        }
      });

      connection.on('ringing', () => {
        dispatch(setRinging(true));
        dispatch(searchForContact(params.phoneNumber));
      });

      connection.on('accept', () => {
        dispatch(setRinging(false));
      });

      connection.on('reject', () => {
        dispatch(setRinging(false));
      });

      connection.on('warning', () => {
        dispatch(setTwilioNetworkIssues(true));
      });

      connection.on('warning-cleared', () => {
        dispatch(setTwilioNetworkIssues(false));
      });
    } catch (error) {
      dispatch(errorAlert('Error while connecting!'));
    }
  };

  const makeCall = (phoneNumber, resource) => {
    const params = { phoneNumber };
    if (resource?.type === 'Contact')
      dispatch(setTwilioCaller(mapCallerFromContact(resource.value)));
    if (resource?.type === 'Ticket') dispatch(setTwilioCaller(mapCallerFromTicket(resource.value)));
    if (resource?.type === 'Account')
      dispatch(setTwilioCaller(mapCallerFromAccount(resource.value)));
    dispatch(setConnectingInProgress(true));
    attemptConnection(params);
  };

  const openPhoneDialog = () => {
    dispatch(setPhoneDialogOpen(true));
    dispatch(minimizeCallModal(false));
    logCustomEvent('phone_modal_opened');
  };

  const setupCall = (phoneNumber, resource) => {
    if (twilioEnabled) {
      makeCall(removePhoneNumberFormatting(phoneNumber), resource);
      openPhoneDialog();
    } else {
      dispatch(setupTwilioDevice()).then(() => {
        setTimeout(() => {
          openPhoneDialog();
          makeCall(removePhoneNumberFormatting(phoneNumber), resource);
        }, 1000);
      });
    }
  };

  return setupCall;
}
