import Day from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';

import { createPhoneLog } from 'app/services/spiro-phone';
import { resetTwilioState } from 'components/CallDialog/state/actions';
import {
  selectCallDuration,
  selectFormValues,
  selectIsRecordingEnabled,
  selectPhoneNumber,
  selectPreviousCall,
  selectTicketId,
} from 'components/CallDialog/state/selectors';
import { getCallStatus, serializeData } from 'helpers/activities';
import { createActivity as createTwilioActivity } from 'services/twilioService';
import { getActivityDispositionValues } from 'state/custom-fields/selectors';
import { setIsRefreshRequired } from 'state/nested-interactions/actions';
import { error as errorAlert } from 'state/notifications/actions';
import { selectTwilioProfile, selectUserId } from 'state/user/selectors';

export default function useLogActiviti() {
  const phoneNumber = useSelector(selectPhoneNumber);
  const previousCall = useSelector(selectPreviousCall);
  const userId = useSelector(selectUserId);
  const dispositionValues = useSelector(getActivityDispositionValues);
  const duration = useSelector(selectCallDuration);
  const twilioProfile = useSelector(selectTwilioProfile);
  const { callNotes, disposition, custom, subject, mentionsIDs } = useSelector(selectFormValues);
  const ticketId = useSelector(selectTicketId);
  const isRecordingEnabled = useSelector(selectIsRecordingEnabled);
  const dispatch = useDispatch();

  const createActivity = async (payload) => {
    try {
      const activityPayload = serializeData(payload);
      if (!isRecordingEnabled) activityPayload.disable_recordings = '1';
      await createTwilioActivity(activityPayload);
      dispatch(resetTwilioState());
    } catch (error) {
      dispatch(errorAlert(error.statusText));
    }
  };

  const generateDescription = (name, dispositionValue) => {
    let description = dispositionValues.find((d) => d.value === dispositionValue).body;
    description = description.replace('<ContactName>', name);

    if (!callNotes) return description;

    return `${callNotes}\n\n${description}`;
  };

  const generateSubject = (name, dispositionValue) => {
    if (subject) return subject;
    const activitySubject = dispositionValues.find((d) => d.value === dispositionValue).subject;
    return activitySubject.replace('<ContactName>', name);
  };

  const handleCallLog = async (payload, kind, fromNumber, toNumber) => {
    if (previousCall.callers.length > 1) {
      const payloadForCallLog = {
        call_sid: payload.call_sid,
        from_number: fromNumber,
        to_number: toNumber,
        status: payload.callStatus,
        duration,
        kind,
        confirmed: true,
        interacted_at: payload.interactedAt,
      };

      if (payload.ownerType === 'Contact') {
        payloadForCallLog.contact_id = payload.ownerId;
      }

      if (payload.ownerType === 'Account') {
        payloadForCallLog.account_id = payload.ownerId;
      }
      if (payloadForCallLog.contact_id || payloadForCallLog.account_id) {
        await createPhoneLog({
          phone_call_log: { ...payloadForCallLog },
        });
      }
    }
  };

  const handleLeftVoicemail = async () => {
    const payload = {
      userId,
      ownerId: !ticketId ? previousCall.caller.resource_id : ticketId,
      ownerType: !ticketId ? previousCall.caller.resource_type : 'Ticket',
      subject: generateSubject(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'left_voicemail'
      ),
      description: generateDescription(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'left_voicemail'
      ),
      mentionsIDs,
      result: 'result_none',
      disposition: 'left_voicemail',
      callStatus: getCallStatus(dispositionValues, 'left_voicemail'),
      interactedAt: new Day().format(),
      call_sid: previousCall.callSid,
      custom,
    };
    handleCallLog(payload, 'outbound_call', twilioProfile.number, phoneNumber);
    await createActivity(payload);
    dispatch(setIsRefreshRequired(true));
  };

  const handleConnected = async () => {
    const payload = {
      userId,
      ownerId: !ticketId ? previousCall.caller.resource_id : ticketId,
      ownerType: !ticketId ? previousCall.caller.resource_type : 'Ticket',
      subject: generateSubject(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'outbound_call'
      ),
      description: generateDescription(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'outbound_call'
      ),
      mentionsIDs,
      result: 'result_none',
      disposition: 'outbound_call',
      callStatus: getCallStatus(dispositionValues, 'outbound_call'),
      interactedAt: new Day().format(),
      call_sid: previousCall.callSid,
      custom,
    };
    handleCallLog(payload, 'outbound_call', twilioProfile.number, phoneNumber);
    await createActivity(payload);
    dispatch(setIsRefreshRequired(true));
  };

  const handleNotConnected = async () => {
    const payload = {
      userId,
      ownerId: !ticketId ? previousCall.caller.resource_id : ticketId,
      ownerType: !ticketId ? previousCall.caller.resource_type : 'Ticket',
      subject: generateSubject(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'no_connect'
      ),
      description: generateDescription(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'no_connect'
      ),
      mentionsIDs,
      result: 'result_none',
      callStatus: getCallStatus(dispositionValues, 'no_connect'),
      disposition: 'no_connect',
      interactedAt: new Day().format(),
      call_sid: previousCall.callSid,
      custom,
    };

    handleCallLog(payload, 'outbound_call', twilioProfile.number, phoneNumber);
    await createActivity(payload);
    dispatch(setIsRefreshRequired(true));
  };

  const handleIncomingCall = async () => {
    const payload = {
      userId,
      ownerId: previousCall.caller.resource_id,
      ownerType: previousCall.caller.resource_type,
      subject: generateSubject(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'inbound_call'
      ),
      description: generateDescription(
        previousCall.contact ? previousCall.contact.firstName : previousCall.account.name,
        'inbound_call'
      ),
      mentionsIDs,
      result: 'result_none',
      callStatus: getCallStatus(dispositionValues, 'inbound_call'),
      disposition: 'inbound_call',
      interactedAt: new Day().format(),
      call_sid: previousCall.callSid,
      custom,
    };
    handleCallLog(payload, 'inbound_call', phoneNumber, twilioProfile.number);
    await createActivity(payload);
    dispatch(setIsRefreshRequired(true));
  };

  const logActivity = async () => {
    switch (disposition) {
      case 'left_voicemail':
        await handleLeftVoicemail();
        break;
      case 'no_connect':
        await handleNotConnected();
        break;
      case 'outbound_call':
        await handleConnected();
        break;
      default:
        await handleIncomingCall();
    }
  };

  return { logActivity };
}
