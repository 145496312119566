import { HIDE_MODAL, SHOW_MODAL } from './constants';
import { create as createReducer } from '../../../core/redux-utils';

const initialState = {
  visible: false,
  customField: null,
};

function showModal(state, action) {
  return { ...state, customField: action.customField, visible: true };
}

function hideModal(state) {
  return { ...state, visible: false };
}

const actionHandlers = {
  [SHOW_MODAL]: showModal,
  [HIDE_MODAL]: hideModal,
};

export default createReducer(initialState, actionHandlers);
