import { createAsyncThunk } from '@reduxjs/toolkit';

import { resourceFields } from 'services/imports';

export const fetchResourceFields = createAsyncThunk(
  'data-collector-get-resource-fields',
  async (params = {}) => {
    try {
      const res = await resourceFields(params);
      return Promise.resolve(res);
    } catch (err) {
      return Promise.reject(err);
    }
  }
);
