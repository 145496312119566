import { styled } from '@mui/material/styles';
import MuiPhoneNumber from 'mui-phone-number';
import PropTypes from 'prop-types';

const StyledPhoneNumber = styled(MuiPhoneNumber)({
  '& .MuiFilledInput-root': {
    backgroundColor: 'transparent !important',
  },
  '& .MuiPhoneNumber-flagButton': {
    padding: '6px',
    borderRadius: '4px',
    width: 'min-content',
  },
});

export default function Phone({
  className = '',
  variant = 'filled',
  fullWidth = true,
  defaultCountry = 'us',
  ...props
}) {
  return (
    <StyledPhoneNumber
      {...props}
      fullWidth={fullWidth}
      defaultCountry={defaultCountry}
      variant={variant}
      autoComplete="off"
      disableAreaCodes
      className={className}
      localization={{ Swaziland: 'Eswatini' }}
    />
  );
}

Phone.propTypes = {
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  className: PropTypes.string,
  defaultCountry: PropTypes.string,
};
