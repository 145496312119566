import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import { IconButton } from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { removePhoneNumberFormatting } from 'helpers/twilio';
import useCall from 'lib/CallButton/useCall';

import { acceptIncomingCall, setActiveCallModalOpen } from '../state/actions';
import { selectPhoneNumber, selectTwilioConnection } from '../state/selectors';

import styles from './AcceptCall.module.scss';

function AcceptCall({ iconSize = 'large' }) {
  const dispatch = useDispatch();
  const phoneNumber = useSelector(selectPhoneNumber);
  const connection = useSelector(selectTwilioConnection);
  const startCall = useCall();

  const acceptCall = () => {
    if (connection) {
      connection.accept((conn) => dispatch(acceptIncomingCall(conn)));
      connection.accept();
      dispatch(setActiveCallModalOpen(true));
    } else {
      startCall(removePhoneNumberFormatting(phoneNumber));
    }
  };

  return (
    <Box className={styles.container}>
      <IconButton
        className={styles[`accept-call-${iconSize}`]}
        aria-label="Answer"
        onClick={acceptCall}
        size="large"
      >
        <PhoneOutlinedIcon className={styles.icon} />
      </IconButton>
    </Box>
  );
}

AcceptCall.propTypes = {
  iconSize: PropTypes.string,
};

export default AcceptCall;
