import { serializeFiltersToString } from 'helpers/filters';
import { parseMetaFromResponse } from 'helpers/meta';
import {
  createCustomObject as createCustomObjectRemote,
  deleteCustomObject as deleteCustomObjectRemote,
  getCustomObjects,
  getCustomObjectsCount as getCustomObjectsCountRemote,
  updateCustomObject as updateCustomObjectRemote,
} from 'services/custom-objects';
import { error as errorAlert } from 'state/notifications/actions';

import {
  CLEAR_DATA,
  FETCH_ENTITIES_STARTED,
  REPLACE_ENTITY,
  SET_CUSTOM_OBJECT,
  SET_PARAMS,
  SET_RECORDS_COUNT,
  SET_SELECTED_RESOURCE,
  STORE_ENTITIES,
} from './constants';
import { forEachError } from '../../../../helpers/errorHelper';

export function storeEntities(data, meta) {
  return {
    type: STORE_ENTITIES,
    data,
    meta,
  };
}

export function setParams(data) {
  return {
    type: SET_PARAMS,
    data,
  };
}

export function setSelectedResource(selectedResource) {
  return {
    type: SET_SELECTED_RESOURCE,
    selectedResource,
  };
}

export function fetchEntitiesStarted() {
  return {
    type: FETCH_ENTITIES_STARTED,
  };
}

export function clearData() {
  return {
    type: CLEAR_DATA,
  };
}

export function setCustomObjectsCount(recordsCount) {
  return {
    type: SET_RECORDS_COUNT,
    recordsCount,
  };
}

function getParamsFromState(state, entity) {
  const params = state.entitiesView.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.customObjects.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder[entity?.resource];

  const paramsObj = params.sort
    ? defaultParams
    : {
        ...defaultParams,
        sort: `custom__${paramsFromViews.order_by}`,
        order: paramsFromViews.order_direction,
      };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export function fetchCustomObjects(entity, params = {}) {
  return (dispatch, getState) => {
    dispatch(fetchEntitiesStarted());

    const stateParams = getParamsFromState(getState(), entity);
    const payload = { ...stateParams, ...params, custom_field_id: entity?.id };

    return getCustomObjects(payload)
      .then((json) => {
        dispatch(storeEntities(json.custom_objects, parseMetaFromResponse(json.meta)));
        dispatch(setParams(payload));
        return Promise.resolve(json);
      })
      .catch((err) => Promise.reject(err));
  };
}

export function getCustomObjectsCount(params = {}) {
  return async (_, getState) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };

    return getCustomObjectsCountRemote(payload)
      .then(() => Promise.resolve())
      .catch((err) => Promise.reject(err));
  };
}

function handleFailedRequest(err) {
  return (dispatch) => {
    forEachError(err.data, (e) => dispatch(errorAlert(e)));
  };
}

export function replaceCustomObject(entity) {
  return {
    type: REPLACE_ENTITY,
    entity,
  };
}

export function createCustomObject(payload) {
  return (dispatch) =>
    createCustomObjectRemote(payload)
      .then((json) => json.custom_object)
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
}

export function updateCustomObject(id, payload) {
  return (dispatch) => {
    dispatch(fetchEntitiesStarted());

    return updateCustomObjectRemote(id, { custom: payload })
      .then((response) => {
        dispatch(replaceCustomObject(response.custom_object));
      })
      .catch((err) => {
        dispatch(handleFailedRequest(err));
      });
  };
}

export function deleteCustomObject(id) {
  return (dispatch) =>
    deleteCustomObjectRemote(id)
      .then(() =>
        // dispatch(fetchCustomObjects());
        Promise.resolve()
      )
      .catch(() => dispatch(handleFailedRequest()));
}

export function setCustomObject(customObject) {
  return {
    type: SET_CUSTOM_OBJECT,
    customObject,
  };
}
