import { nullMeta } from 'helpers/meta';

import {
  ADD_ACCOUNT_TO_LIST,
  CREATE_FAILURE,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  FETCH_ACCOUNTS_STARTED,
  REMOVE_ACCOUNT,
  SET_PARAMS,
  SET_RECORDS_COUNT,
  STORE_ACCOUNTS,
  UPDATE_ACCOUNT,
} from './constants';
import { immutableFindAndDelete, immutableInsert } from '../../../../helpers/immutableHelper';

const initialState = {
  fetching: false,
  items: [],
  meta: nullMeta,
  createInProgress: false,
  cached: false,
  params: {
    page: 1,
    sort: undefined,
    order: undefined,
    user_id: undefined,
    q: undefined,
  },
  recordsCount: 0,
};

export function fetchAccountsStartedHandler(state) {
  return {
    ...state,
    fetching: true,
  };
}

export function storeAccountsHandler(state, action) {
  return {
    ...state,
    fetching: false,
    items: action.data,
    meta: {
      ...state.meta,
      ...action.meta,
    },
    cached: true,
  };
}

export function removeAccountHandler(state, action) {
  return immutableFindAndDelete(state.items, (acc) => acc.id === action.id);
}

export function updateAccountHandler(state, action) {
  const itemId = state.items.findIndex((acc) => acc.id === action.data.id);
  if (itemId === -1) {
    return state;
  }

  return {
    ...state,
    items: immutableInsert(state.items, action.data, itemId),
  };
}

export function createInProgressHandler(state) {
  return {
    ...state,
    createInProgress: true,
  };
}

export function createSuccessHandler(state) {
  return {
    ...state,
    createInProgress: false,
    cached: false,
  };
}

export function createFailureHandler(state) {
  return {
    ...state,
    createInProgress: false,
  };
}

export function setParamsHandler(state, action) {
  return {
    ...state,
    params: {
      ...state.params,
      ...action.data,
    },
  };
}

function addAccountToListHandler(state, action) {
  return {
    ...state,
    items: [action.account, ...state.items],
  };
}

function setRecordsCountHandler(state, action) {
  return { ...state, recordsCount: action.recordsCount };
}

const ACTION_HANDLERS = {
  [FETCH_ACCOUNTS_STARTED]: fetchAccountsStartedHandler,
  [STORE_ACCOUNTS]: storeAccountsHandler,
  [UPDATE_ACCOUNT]: updateAccountHandler,
  [REMOVE_ACCOUNT]: removeAccountHandler,
  [SET_PARAMS]: setParamsHandler,
  [CREATE_IN_PROGRESS]: createInProgressHandler,
  [CREATE_SUCCESS]: createSuccessHandler,
  [CREATE_FAILURE]: createFailureHandler,
  [ADD_ACCOUNT_TO_LIST]: addAccountToListHandler,
  [SET_RECORDS_COUNT]: setRecordsCountHandler,
};

export default function accountsDataReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
