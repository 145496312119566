import {
  FETCHING_SMS_TEMPLATES,
  FETCHING_SMS_TEMPLATES_FAILED,
  REMOVE_SMS_TEMPLATE,
  SELECT_SMS_TEMPLATE,
  STORE_SMS_TEMPLATE,
  STORE_SMS_TEMPLATES,
} from './constants';
import { immutableInsert } from '../../../../../helpers/immutableHelper';

const initialState = {
  fetching: false,
  data: [],
  selected: null,
  failed: false,
  fetched: false,
};
function findTemplateIndex(list, template) {
  return list.findIndex((t) => template.id === t.id);
}

function selectPreviousEmailTemplate(templates, selectedTemplate) {
  const tmp = templates;
  const i = findTemplateIndex(tmp, selectedTemplate);
  const previous = i > 0 ? i - 1 : 1;
  return tmp[previous];
}

function fetchSmsTemplatesStartedHandler(state) {
  return {
    ...state,
    fetching: true,
    fetched: false,
  };
}

function storeSmsTemplatesHandler(state, action) {
  return {
    ...state,
    fetching: false,
    data: [...action.data],
    selected: action.data[0],
    failed: false,
    fetched: true,
  };
}

function storeSmsTemplateHandler(state, action) {
  const index = state.data.findIndex((rem) => rem.id === action.data.id);
  return {
    ...state,
    data:
      index >= 0 ? immutableInsert(state.data, action.data, index) : [...state.data, action.data],
    fetching: false,
    fetched: true,
    failed: false,
  };
}

function removeSmsTemplateHandler(state, action) {
  const nextSelected = selectPreviousEmailTemplate(state.data, state.selected);
  return {
    ...state,
    data: [...state.data.filter((t) => t.id !== action.id)],
    selected: nextSelected ? { ...nextSelected } : null,
    fetching: false,
    fetched: true,
    failed: false,
  };
}

function fetchSmsTemplatesFailedHandler(state) {
  return {
    ...state,
    fetching: false,
    failed: true,
  };
}

function selectSmsTemplateHandler(state, action) {
  return {
    ...state,
    selected: state.data.find((t) => t.id === action.data) || state.data[0] || null,
  };
}

const ACTION_HANDLERS = {
  [FETCHING_SMS_TEMPLATES]: fetchSmsTemplatesStartedHandler,
  [STORE_SMS_TEMPLATES]: storeSmsTemplatesHandler,
  [STORE_SMS_TEMPLATE]: storeSmsTemplateHandler,
  [REMOVE_SMS_TEMPLATE]: removeSmsTemplateHandler,
  [FETCHING_SMS_TEMPLATES_FAILED]: fetchSmsTemplatesFailedHandler,
  [SELECT_SMS_TEMPLATE]: selectSmsTemplateHandler,
};

export default function smsTemplatesReducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];
  return handler ? handler(state, action) : state;
}
