import { useEffect } from 'react';

import { useBlocker, useNavigate } from 'react-router-dom';

const usePageLeaveWarning = (message, dependency) => {
  const blocker = useBlocker(dependency);
  const navigate = useNavigate();

  useEffect(() => {
    if (blocker.state === 'blocked') {
      const confirmation = window.confirm(message);

      if (confirmation) blocker.reset();
      else blocker.proceed();
    }
    if (blocker.state === 'proceeding') {
      navigate(blocker.location.pathname);
    }
  }, [blocker?.state]);

  useEffect(() => {
    const handlePageLeave = (event) => {
      if (dependency) {
        const confirmation = window.confirm(message);
        if (!confirmation) {
          event.preventDefault();
        }
      }
    };

    const beforeUnloadHandler = (event) => {
      handlePageLeave(event);
    };
    if (dependency) window.addEventListener('beforeunload', beforeUnloadHandler);

    return () => {
      window.removeEventListener('beforeunload', beforeUnloadHandler);
    };
  }, [dependency, message]);
};

export default usePageLeaveWarning;
