import EngineService from '../core/engine-service';

const ENDPOINT = '/support_desk_messages';

export function sendEmail(payload) {
  return EngineService.post(`${ENDPOINT}`, payload).then((json) => json);
}

export default {
  sendEmail,
};
