import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import Button from 'lib/Button';

import { ReactComponent as ErrorImage } from '../../../images/error-illustration.svg';

import styles from './ErrorPage.module.scss';

function ErrorPage() {
  const getBack = () => {
    window.location.assign('/');
  };
  return (
    <Grid container justifyContent="center" className="text-center">
      <Grid item xs={12}>
        <Paper elevation={0} square className={styles.wrapper}>
          <ErrorImage />
          <Typography variant="h6" className={styles.heading}>
            Oops, something went wrong!
          </Typography>
          <Typography variant="body1">
            Please contact support@spiro.ai if this continues.
          </Typography>
          <Button onClick={getBack} variant="contained" color="primary" className={styles.button}>
            Return home
          </Button>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default ErrorPage;
