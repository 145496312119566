import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { customFieldsToArray } from 'helpers/custom-fields';
import { selectCustomFields } from 'state/custom-fields/selectors';
import { selectDemoAddonEnabled } from 'state/user/selectors';

import styles from './ListsMenuItems.module.scss';

export default function ListsMenuItems() {
  const demoAddonEnabled = useSelector(selectDemoAddonEnabled);
  const entities = customFieldsToArray(useSelector(selectCustomFields)).filter(
    (entity) =>
      ![
        'Notification',
        'Activity',
        '.Team',
        'Reminder',
        'User',
        'Contact',
        'Ticket',
        'Opportunity',
        'Interaction',
        'Account',
      ].includes(entity.name) &&
      entity.schema_type === 'list' &&
      entity.visible_on_lists &&
      (!demoAddonEnabled || !entity.demo)
  );

  return (
    <>
      <div className={styles.title}>
        <h6>Lists</h6>
      </div>
      <ul>
        {entities.map((entity) => (
          <li key={entity.id}>
            <NavLink
              to={`/lists/${entity.id}`}
              className={styles.listItem}
              activeClassName={styles.activeLink}
            >
              {entity.name}
            </NavLink>
          </li>
        ))}
      </ul>
    </>
  );
}
