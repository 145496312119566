import { createAsyncThunk } from '@reduxjs/toolkit';

import { serializeFiltersToString } from 'helpers/filters';
import {
  create as createReminderRemote,
  getRemindersCount as getRemindersCountRemote,
  getAll as getRemindersRemote,
  update as updateReminderRemote,
} from 'services/reminders';
import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/reminders/actions';
import { error } from 'state/notifications/actions';

import { forEachError } from '../../../../../helpers/errorHelper';

export const getParamsFromState = (state) => {
  const params = state.reminders.data.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.reminders.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.Reminder;

  const paramsObj = params.sort
    ? defaultParams
    : { ...defaultParams, sort: paramsFromViews.order_by, order: paramsFromViews.order_direction };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
};

export const getReminders = createAsyncThunk(
  'reminders/get',
  async (params = {}, { dispatch, getState }) => {
    try {
      const isCached = getState().filters.reminders.filterProperties.length;

      if (!isCached) {
        dispatch(fetchFilterProperties('Reminder'));
        await dispatch(fetchSavedFilters('Reminder'));
      }

      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };
      const res = await getRemindersRemote(payload);

      return {
        reminders: res.reminders,
        meta: res.meta,
        params: { ...stateParams, ...params },
      };
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const getRemindersCount = createAsyncThunk(
  'reminders-count',
  async (params = {}, { getState }) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };
    return getRemindersCountRemote(payload)
      .then(() => Promise.resolve())
      .catch((err) => Promise.reject(err));
  }
);

export const updateReminder = createAsyncThunk('reminders/update', async (data, { dispatch }) => {
  try {
    const res = await updateReminderRemote(data.id, data.payload);
    return res;
  } catch (err) {
    forEachError(err.data, (e) => dispatch(error(e)));
    return Promise.reject(err);
  }
});

export const markReminderAsDone = createAsyncThunk(
  'reminders/mark-as-done',
  async (data, { dispatch, getState }) => {
    const meta = getState().reminders.data.meta;

    try {
      await updateReminderRemote(data.id, data.payload);
      await dispatch(getReminders({ page: meta.currentPage || 1 }));
      return Promise.resolve();
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);

export const createReminder = createAsyncThunk(
  'reminders/create',
  async (payload, { dispatch }) => {
    try {
      const res = await createReminderRemote(payload);
      dispatch(getReminders({ page: 1, sort: undefined, order: undefined, rule_id: undefined }));
      return res;
    } catch (err) {
      forEachError(err.data, (e) => dispatch(error(e)));
      return Promise.reject(err);
    }
  }
);
