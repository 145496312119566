import EngineService from '../core/engine-service';

const ENDPOINT = '/spiro_views';

export function getViews(params) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function updateViews(userID, payload) {
  return EngineService.patch(`${ENDPOINT}/${userID}`, {
    ...payload,
  }).then((json) => json);
}

export function deleteView(userID, resource) {
  return EngineService.delete(`${ENDPOINT}/${userID}?resource=${resource}`).then((json) => json);
}
