import { useContext, useEffect, useState } from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { PusherContext } from 'app/contexts/PusherContext';
import { selectUser, selectUserId } from 'app/state/user/selectors';
import { getMessageThread } from 'services/spiro-phone';
import RingLoader from 'ui/Loaders/RingLoader';

import ChatWindow from './ChatWindow';
import NewConversation from './NewConversation';
import { fetchSingleMessage } from './state/actions';
import {
  addNewMessage,
  resetStatePartially,
  setContact,
  setPhone,
  setShouldRefetch,
} from './state/reducer';
import {
  selectAreThreadsFetching,
  selectChatId,
  selectContact,
  selectIsLoading,
  selectMessages,
  selectPhone,
  selectShouldRefetch,
} from './state/selectors';
import { setConversationScreenOpen } from '../../state/actions';

import styles from './Conversation.module.scss';

function Conversation() {
  const dispatch = useDispatch();
  const chatId = useSelector(selectChatId);
  const contact = useSelector(selectContact);
  const messages = useSelector(selectMessages);
  const phone = useSelector(selectPhone);
  const isLoading = useSelector(selectIsLoading);
  const areThreadsFetching = useSelector(selectAreThreadsFetching);
  const shouldRefetch = useSelector(selectShouldRefetch);
  const { channel } = useContext(PusherContext);
  const userID = useSelector(selectUserId);
  const user = useSelector(selectUser);
  const [displayUnreadMessagesButton, setDisplayUnreadMessagesButton] = useState(false);

  const fetchMessageThread = (id, params) => {
    dispatch(fetchSingleMessage({ id, params }));
  };

  const toggleConversationScreen = () => {
    dispatch(setConversationScreenOpen(false));
    dispatch(resetStatePartially());
  };

  useEffect(() => {
    channel.bind(`new_inbound_text_message_user_${userID}_thread_${chatId}`, (newMessage) => {
      const payload = { text_message: newMessage.object.text_message, user };
      dispatch(addNewMessage(payload));
      setDisplayUnreadMessagesButton(true);
    });
    return () => {
      channel.unbind(`new_inbound_text_message_user_${userID}_thread_${chatId}`);
    };
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if ((chatId || shouldRefetch) && !contact) {
        const res = await getMessageThread(chatId);
        dispatch(setContact(res.text_message_threads.contact));
        if (!phone) dispatch(setPhone(res.text_message_threads.phone));
      }
      if (chatId && chatId !== 'unknown') fetchMessageThread(chatId, { offset: 0 });
      dispatch(setShouldRefetch(false));
    };
    if (!isLoading) fetchData();
  }, [chatId, shouldRefetch]);

  if ((isLoading || areThreadsFetching) && !messages.length)
    return (
      <Box className={styles.loader}>
        <RingLoader variant="purple" />
      </Box>
    );

  if (!chatId && !isLoading) return <NewConversation />;

  return (
    <div>
      <div className={styles.header}>
        <IconButton size="large" onClick={toggleConversationScreen}>
          <ArrowBackIcon />
        </IconButton>
        <h5>
          {contact ? (
            <Link to={`/contacts/${contact.id}`} className={styles.link}>
              {contact?.full_name}
            </Link>
          ) : (
            phone
          )}
        </h5>
      </div>
      {chatId && (
        <ChatWindow
          data={messages}
          contact={contact}
          phone={phone}
          displayUnreadMessagesButton={displayUnreadMessagesButton}
          setDisplayUnreadMessagesButton={setDisplayUnreadMessagesButton}
        />
      )}
    </div>
  );
}

export default Conversation;
