import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import SanitizedText from 'ui/SanitizedText';

import ActivityIcon from '../../../activities/ActivitiesDataGrid/ActivityIcon';
import { useSearch } from '../SearchProvider';

import styles from './SearchResult.module.scss';

export default function OpportunityResult({ interaction }) {
  const { setVisible } = useSearch();

  return (
    <Link
      to={`/activities?activityID=${interaction.id}&kind=${interaction.kind}`}
      onClick={() => setVisible(false)}
      className={styles['search-result']}
    >
      <div className={styles['primary-col']}>
        <div className={styles.icon}>
          <ActivityIcon kind={interaction.kind} />
        </div>
        <div>
          <p className={styles['interaction-title']}>{interaction.getTitle()}</p>
          {interaction.subject && (
            <SanitizedText text={interaction.subject} className={styles['interaction-subject']} />
          )}
          {interaction.description && (
            <SanitizedText
              className={styles['interaction-text']}
              text={interaction.getDescription()}
            />
          )}
          {interaction.transcription && (
            <SanitizedText
              className={styles['interaction-text']}
              text={interaction.getTranscription()}
            />
          )}
        </div>
      </div>
      <div className={styles['secondary-col']}>{interaction.interactedAt}</div>
    </Link>
  );
}

OpportunityResult.propTypes = {
  interaction: PropTypes.object.isRequired,
};
