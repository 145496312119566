import React, { useMemo } from 'react';

import CallEndIcon from '@mui/icons-material/CallEnd';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import DialpadOutlinedIcon from '@mui/icons-material/DialpadOutlined';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import VoicemailOutlinedIcon from '@mui/icons-material/VoicemailOutlined';
import WatchLaterOutlinedIcon from '@mui/icons-material/WatchLaterOutlined';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { ReactComponent as PoorConnectionIcon } from 'images/poor-connection.svg';
import {
  selectMissedCallsCount,
  selectUnreadMessagesCount,
  selectUnreadVoicemailsCount,
} from 'state/counters/selectors';

import AvailabilityIndicator from './AvailabilityIndicator';
import { setActiveTab } from '../state/actions';
import {
  selectCallTimer,
  selectIsBadNetwork,
  selectIsCallLogged,
  selectIsIncomingCall,
  selectTwilioConnectingInProgress,
} from '../state/selectors';

import styles from './PhoneDialogSidebar.module.scss';

export default function PhoneDialogSidebar({ activeItem }) {
  const dispatch = useDispatch();
  const unreadTextCount = useSelector(selectUnreadMessagesCount);
  const missedCallsCount = useSelector(selectMissedCallsCount);
  const missedVoicemailsCount = useSelector(selectUnreadVoicemailsCount);
  const isTimerStarted = useSelector(selectCallTimer);
  const incomingCall = useSelector(selectIsIncomingCall);
  const isCallLogged = useSelector(selectIsCallLogged);
  const twilioConnectingInProgress = useSelector(selectTwilioConnectingInProgress);
  const isBadNetwork = useSelector(selectIsBadNetwork);

  const getIconStyles = (item) => {
    if (item.title === 'Phone' && (twilioConnectingInProgress || isBadNetwork))
      return styles.connecting;
    if (item.title === 'Phone' && (incomingCall || isTimerStarted)) return styles['call-active'];
    if (item.title === 'Phone' && isCallLogged) return styles['call-not-logged'];
    if (item.title === activeItem && (!incomingCall || !isTimerStarted)) return styles.active;
    return '';
  };

  const togglePhoneIcon = () => {
    if (isBadNetwork && (incomingCall || isTimerStarted)) return <PoorConnectionIcon />;
    if (incomingCall || isTimerStarted || twilioConnectingInProgress) return <PhoneInTalkIcon />;
    if (isCallLogged) return <CallEndIcon />;
    return <DialpadOutlinedIcon />;
  };

  const toggleTabs = (item) => {
    dispatch(setActiveTab(item));
  };

  const menuItems = useMemo(
    () => [
      {
        id: 1,
        title: 'Messages',
        count: unreadTextCount,
        icon: <ChatBubbleOutlineOutlinedIcon />,
        onClick: () => toggleTabs('Messages'),
      },
      {
        id: 2,
        title: 'Recents',
        count: missedCallsCount,
        icon: <WatchLaterOutlinedIcon />,
        onClick: () => toggleTabs('Recents'),
      },
      {
        id: 3,
        title: 'Phone',
        count: 0,
        icon: <DialpadOutlinedIcon />,
        onClick: () => toggleTabs('Phone'),
      },
      {
        id: 4,
        title: 'Voicemail',
        count: missedVoicemailsCount,
        icon: <VoicemailOutlinedIcon />,
        onClick: () => toggleTabs('Voicemail'),
      },
    ],
    [unreadTextCount, missedCallsCount, missedVoicemailsCount]
  );

  return (
    <div className={styles.container}>
      {menuItems.map((item) => (
        <React.Fragment key={item.id}>
          <button
            type="button"
            onClick={item.onClick}
            className={`${styles['menu-item']} ${getIconStyles(item)}`}
          >
            <div className={styles.icon}>
              {item.title === 'Phone' ? togglePhoneIcon() : item.icon}
              {item.title !== activeItem && !!item.count && (
                <div className={styles.indicator}>{item.count}</div>
              )}
            </div>
            {item.title}
          </button>
        </React.Fragment>
      ))}
      <AvailabilityIndicator />
    </div>
  );
}

PhoneDialogSidebar.propTypes = {
  activeItem: PropTypes.string.isRequired,
};
