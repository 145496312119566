import ClassNames from 'classnames';
import PropTypes from 'prop-types';

import Drawer from 'lib/Drawer';

import styles from './DetailsDrawer.module.scss';

function DetailsDrawer({ children, className = '', ...props }) {
  return (
    <Drawer anchor="right" className={ClassNames(styles.drawer, className)} {...props}>
      {children}
    </Drawer>
  );
}

DetailsDrawer.propTypes = {
  children: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export default DetailsDrawer;
