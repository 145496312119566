import { STORE_ORGANIZATION } from './constants';

export function storeOrganization(data) {
  return {
    type: STORE_ORGANIZATION,
    data,
  };
}

export default {
  storeOrganization,
};
