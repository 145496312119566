import EngineService from '../core/engine-service';

const ENDPOINT = '/saved_filters';

export function create(payload) {
  return EngineService.post(ENDPOINT, { saved_filter: payload }).then((json) => json);
}

export function getAll(params) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function get(id) {
  return EngineService.get(`${ENDPOINT}/${id}`).then((json) => json);
}

export function update(id, payload) {
  return EngineService.patch(`${ENDPOINT}/${id}`, payload).then((json) => json);
}

export function del(id) {
  return EngineService.delete(`${ENDPOINT}/${id}`).then((json) => json);
}

export default {
  create,
  getAll,
  get,
  update,
  delete: del,
};
