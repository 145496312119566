import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import IconButton from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import IconButtonWithLabel from 'lib/IconButtonWithLabel';

import { muteCall } from '../state/actions';
import { selectCallMuted, selectTwilioConnection } from '../state/selectors';

import styles from './MuteCallButton.module.scss';

function MuteCallButton({ smallSize = false }) {
  const [muteToastId, setMuteToastId] = useState(null);
  const dispatch = useDispatch();

  const toastSettings = {
    type: 'success',
    autoClose: 5000,
    style: {
      width: '400px',
      backgroundColor: '#EDF7ED',
    },
    position: 'top-center',
    onClose: () => setMuteToastId(null),
    closeButton: (
      <CloseIcon
        fontSize="small"
        className={styles.icon}
        onClick={() => {
          toast.dismiss();
        }}
      />
    ),
  };

  const connection = useSelector(selectTwilioConnection);
  const callMuted = useSelector(selectCallMuted);

  const toggleMute = () => {
    connection.mute(!callMuted);
    dispatch(muteCall(!callMuted));

    const message = callMuted ? 'Call unmuted.' : 'Call muted.';

    if (muteToastId) {
      toast.update(muteToastId, {
        ...toastSettings,
        render: message,
      });
    } else {
      const id = toast.success(message, {
        ...toastSettings,
      });
      setMuteToastId(id);
    }
  };

  const showLabel = () => (callMuted ? 'Muted' : 'Mute');

  const toggleColor = () => (callMuted ? 'dark-purple' : 'purple');

  if (smallSize) {
    return (
      <IconButton
        onClick={toggleMute}
        className={`${styles['container-small']} ${callMuted ? styles['call-muted'] : ''}`}
      >
        <MicOffOutlinedIcon fontSize="small" />
      </IconButton>
    );
  }

  return (
    <div className={styles.container}>
      <IconButtonWithLabel
        icon={<MicOffOutlinedIcon />}
        classes={styles.button}
        color={toggleColor()}
        iconSize="large"
        handleOnClick={toggleMute}
        label={showLabel()}
      />
    </div>
  );
}

MuteCallButton.propTypes = {
  smallSize: PropTypes.bool,
};

export default MuteCallButton;
