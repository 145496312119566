import { serializeFiltersToString } from 'helpers/filters';
import { parseMetaFromResponse } from 'helpers/meta';
import {
  create as createAccountRemote,
  destroy as destroyAccountRemote,
  getAccountCount as getAccountCountRemote,
  getAll as getAllAccounts,
  update as updateAccountRemote,
} from 'services/accounts';
import { fetchFilterProperties, fetchSavedFilters } from 'state/filters/accounts/actions';
import { error as errorAlert } from 'state/notifications/actions';

import {
  ADD_ACCOUNT_TO_LIST,
  CREATE_FAILURE,
  CREATE_IN_PROGRESS,
  CREATE_SUCCESS,
  FETCH_ACCOUNTS_STARTED,
  REMOVE_ACCOUNT,
  SET_PARAMS,
  SET_RECORDS_COUNT,
  STORE_ACCOUNTS,
  UPDATE_ACCOUNT,
} from './constants';
import { forEachError } from '../../../../helpers/errorHelper';
import { serializeForm } from '../../../../helpers/formHelper';

export function storeAccounts(accounts, meta) {
  return {
    type: STORE_ACCOUNTS,
    data: accounts,
    meta: parseMetaFromResponse(meta),
  };
}

export function updateAccountLocal(data) {
  return {
    type: UPDATE_ACCOUNT,
    data,
  };
}

function removeAccount(id) {
  return {
    type: REMOVE_ACCOUNT,
    id,
  };
}

export function setParams(data) {
  return {
    type: SET_PARAMS,
    data,
  };
}

export function fetchAccountsStarted() {
  return {
    type: FETCH_ACCOUNTS_STARTED,
  };
}

export function createInProgress() {
  return {
    type: CREATE_IN_PROGRESS,
  };
}

export function createSuccess() {
  return {
    type: CREATE_SUCCESS,
  };
}

export function createFailure() {
  return {
    type: CREATE_FAILURE,
  };
}

export function setAccountsCount(recordsCount) {
  return {
    type: SET_RECORDS_COUNT,
    recordsCount,
  };
}

function getParamsFromState(state) {
  const params = state.accounts.params;
  const recordsPerPage = state.spiroViews.recordsPerPage;
  const defaultParams = { ...params, per_page: recordsPerPage };
  const currentFilters = state.filters.accounts.currentFilters;
  const paramsFromViews = state.spiroViews.defaultOrder.Account;

  const paramsObj = params.sort
    ? defaultParams
    : {
        ...defaultParams,
        sort: paramsFromViews.order_by,
        order: paramsFromViews.order_direction,
      };

  return {
    ...paramsObj,
    q: serializeFiltersToString(currentFilters.filters),
  };
}

export function getAccounts(params = {}) {
  return async (dispatch, getState) => {
    dispatch(fetchAccountsStarted());
    dispatch(fetchFilterProperties());
    return dispatch(fetchSavedFilters()).then(() => {
      const stateParams = getParamsFromState(getState());
      const payload = { ...stateParams, ...params };

      return getAllAccounts(payload)
        .then((json) => {
          dispatch(storeAccounts(json.accounts, json.meta));
          dispatch(setParams(payload));
          return Promise.resolve(json);
        })
        .catch((err) => Promise.reject(err));
    });
  };
}

export function getAccountsCount(params = {}) {
  return async (dispatch, getState) => {
    const stateParams = getParamsFromState(getState());
    const payload = { ...stateParams, ...params };

    return getAccountCountRemote(payload)
      .then(() => Promise.resolve())
      .catch((err) => Promise.reject(err));
  };
}

export function createAccount(payload) {
  return (dispatch) => {
    dispatch(createInProgress());

    return createAccountRemote(payload)
      .then((json) => {
        if (json.account.matched) {
          dispatch(createFailure());
          dispatch(errorAlert('This company with the same name and website already exists'));
          return Promise.reject(json);
        }

        dispatch(createSuccess());
        return json.account;
      })
      .catch((err) => {
        dispatch(createFailure());
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
  };
}

export function addAccountToList(account) {
  return {
    type: ADD_ACCOUNT_TO_LIST,
    account,
  };
}

export function updateAccount(accountId, data) {
  return (dispatch) =>
    updateAccountRemote(accountId, serializeForm(data))
      .then((r) => {
        dispatch(updateAccountLocal(r.account));
        return Promise.resolve(r.account);
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
}

export function deleteAccount(accountId) {
  return (dispatch) =>
    destroyAccountRemote(accountId)
      .then(() => {
        dispatch(removeAccount(accountId));
        return Promise.resolve();
      })
      .catch((err) => {
        forEachError(err.data, (e) => dispatch(errorAlert(e)));
        return Promise.reject(err);
      });
}
