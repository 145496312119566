import { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import TextField from 'lib/TextField';

import { setFormValues } from '../state/actions';
import { selectFormValues } from '../state/selectors';

export default function CallSubject() {
  const formValues = useSelector(selectFormValues);
  const dispatch = useDispatch();
  const [subject, setSubject] = useState(formValues.subject);

  const handleOnBlur = () => {
    dispatch(setFormValues({ ...formValues, subject }));
  };

  const handleOnChange = (e) => setSubject(e.target.value);

  return (
    <TextField label="Subject" value={subject} onChange={handleOnChange} onBlur={handleOnBlur} />
  );
}
