import { useState } from 'react';

import { Box, styled } from '@mui/material';

import ChatHeader from './ChatHeader';
import ChatHome from './ChatHome/ChatHome';

const ResponsiveBox = styled(Box)(({ theme }) => ({
  height: '100vh',
  marginLeft: '16px',
  marginRight: '16px',
  [theme.breakpoints.up('sm')]: {
    marginLeft: '40px',
    marginRight: '40px',
  },
}));

const MainContainer = styled(Box)({
  height: 'calc(100% - 80px)',
  overflow: 'hidden',
});

function ChatApp() {
  const [shouldClearState, setShouldClearState] = useState(false);

  return (
    <ResponsiveBox>
      <ChatHeader setShouldClearState={setShouldClearState} />
      <MainContainer>
        <ChatHome shouldClearState={shouldClearState} setShouldClearState={setShouldClearState} />
      </MainContainer>
    </ResponsiveBox>
  );
}

export default ChatApp;
