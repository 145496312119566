import { useEffect, useState } from 'react';

import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

import { getAll as getTemplates } from 'services/sms-templates';

import TextTemplatesModal from '../../../modals/TextTemplatesModal';
import styles from '../styles/ConversationsButtonStyles.module.scss';

export default function TemplateButton({
  selectedTemplate,
  setSelectedTemplate,
  onTemplateSelect,
  isModalVisible,
  toggleTemplatesModal,
  contact = null,
}) {
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (contact) {
      getTemplates({ contact_id: contact.id }).then((json) => {
        setTemplates(json.sms_templates);
      });
    }
  }, [contact]);

  return (
    <>
      <IconButton
        className={
          !contact || templates.length <= 0
            ? styles['icon-wrapper-disabled']
            : styles['icon-wrapper']
        }
        onClick={toggleTemplatesModal}
        disabled={!contact || templates.length <= 0}
      >
        <Tooltip
          title="Choose Template"
          arrow
          classes={{ tooltip: styles.tooltip, arrow: styles.arrow }}
        >
          <NoteAddOutlinedIcon fontSize="medium" />
        </Tooltip>
      </IconButton>

      {isModalVisible && (
        <TextTemplatesModal
          onClose={toggleTemplatesModal}
          templates={templates.map((t) => ({
            label: t.title,
            value: t.html_body ? t.html_body : t.body,
          }))}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          onSubmit={onTemplateSelect}
        />
      )}
    </>
  );
}

TemplateButton.propTypes = {
  contact: PropTypes.object,
  selectedTemplate: PropTypes.object.isRequired,
  setSelectedTemplate: PropTypes.func.isRequired,
  onTemplateSelect: PropTypes.func.isRequired,
  toggleTemplatesModal: PropTypes.func.isRequired,
  isModalVisible: PropTypes.bool.isRequired,
};
