import { useCallback, useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { nullFilter } from 'helpers/filters';
import { selectCurrentFilter } from 'state/filters/nested-filters/selectors';
import opportunitiesActions, {
  createOpportunity as handleCreateOpportunity,
} from 'state/nested-opportunities/actions';
import {
  selectIsLoading,
  selectIsRefreshRequired,
  selectMeta,
  selectOpportunities,
  selectParams,
} from 'state/nested-opportunities/selectors';
import { getPipelines } from 'state/pipelines/actions';

import { selectRecordsPerPage } from '../state/views/selectors';

export default function useOpportunities(defaultParams) {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const opportunities = useSelector(selectOpportunities);
  const meta = useSelector(selectMeta);
  const params = useSelector(selectParams);
  const isRefreshRequired = useSelector(selectIsRefreshRequired);
  const [searchQuery, setSearchQuery] = useState('');
  const recordsPerPage = useSelector(selectRecordsPerPage);

  const fetchOpportunities = (requestParams = {}) =>
    dispatch(
      opportunitiesActions.getOpportunities({
        ...defaultParams,
        ...requestParams,
        per_page: recordsPerPage,
      })
    );

  const createOpportunity = (payload) => dispatch(handleCreateOpportunity(payload));

  const updateOpportunity = (id, payload) =>
    dispatch(opportunitiesActions.updateOpportunity(id, payload));

  const getPage = useCallback((page) => {
    fetchOpportunities({ page });
  }, []);

  const getRecordsPerPage = useCallback((records) => {
    fetchOpportunities({ per_page: records });
  }, []);

  const sortOpportunities = useCallback((sortBy, sortOrder) => {
    fetchOpportunities({ sort: sortBy, order: sortOrder, page: 1 });
  }, []);

  const handleSearchChange = useCallback((e) => {
    setSearchQuery(e.target.value);
  }, []);

  const handleSearchEnterPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        fetchOpportunities({ query: searchQuery, page: 1 });
      }
    },
    [searchQuery]
  );

  const handleSearchClear = useCallback(() => {
    setSearchQuery('');
    fetchOpportunities({ query: '', page: 1 });
  }, []);

  const clearAppliedFilters = useCallback(() => {
    fetchOpportunities({ q: undefined, page: undefined });
    dispatch(selectCurrentFilter(nullFilter));
  }, []);

  useEffect(() => {
    dispatch(getPipelines());
  }, []);

  useEffect(() => {
    fetchOpportunities();
  }, []);

  useEffect(() => {
    if (isRefreshRequired) {
      fetchOpportunities();
    }
  }, [isRefreshRequired]);

  useEffect(
    () => () => {
      dispatch(opportunitiesActions.reset());
    },
    []
  );

  return {
    isLoading,
    opportunities,
    sortOpportunities,
    meta,
    params,
    getPage,
    updateOpportunity,
    handleSearchChange,
    handleSearchEnterPress,
    handleSearchClear,
    searchQuery,
    fetchOpportunities,
    clearAppliedFilters,
    createOpportunity,
    getRecordsPerPage,
  };
}
