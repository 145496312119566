import { InputAdornment } from '@mui/material';

import TextField from 'lib/TextField';

export default function PercentageField({ ...props }) {
  return (
    <TextField
      type="number"
      {...props}
      InputProps={{
        endAdornment: <InputAdornment position="start">%</InputAdornment>,
      }}
    />
  );
}
