import PropTypes from 'prop-types';

import { Tabs } from 'components/DetailsDrawer';
import Tab from 'lib/Tab';

import ChipList from './ChipList';
import useNotificationsFilters from './useNotificationsFilters';

function NotificationsFilters({ fetchNotifications, isLoading, clearState }) {
  const {
    selectedTab,
    priorityChips,
    generalChips,
    selectTab,
    toggleChipState,
    setPriorityChips,
    setGeneralChips,
    priorityLabel,
    generalLabel,
  } = useNotificationsFilters(fetchNotifications, clearState);

  return (
    <>
      <Tabs value={selectedTab} onChange={selectTab}>
        <Tab label={priorityLabel} uppercaseText />
        <Tab label={generalLabel} uppercaseText />
      </Tabs>
      {selectedTab === 0 && (
        <ChipList
          chips={priorityChips}
          onChipClick={toggleChipState(setPriorityChips)}
          isLoading={isLoading}
        />
      )}
      {selectedTab === 1 && (
        <ChipList
          chips={generalChips}
          onChipClick={toggleChipState(setGeneralChips)}
          isLoading={isLoading}
        />
      )}
    </>
  );
}

NotificationsFilters.propTypes = {
  fetchNotifications: PropTypes.func.isRequired,
  clearState: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

export default NotificationsFilters;
