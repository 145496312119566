export function opportunityPath(opportunityId) {
  return `/opportunities/${opportunityId}`;
}

export function contactPath(contactId) {
  return `/contacts/${contactId}`;
}

export function companyPath(companyId) {
  return `/companies/${companyId}`;
}

export function targetListsPath() {
  return '/lists';
}

export function targetListPath(targetListId) {
  return `/lists/${targetListId}`;
}

export function targetListManagePath(targetListId) {
  return `/lists/${targetListId}/manage`;
}

export function campaignsPath() {
  return '/campaigns';
}

export function campaignPath(campaignId) {
  return `/campaigns/${campaignId}`;
}

export function campaignManagePath(campaignId) {
  return `/campaigns/${campaignId}/manage`;
}

export function ticketPath(ticketId) {
  return `/tickets/${ticketId}`;
}

export function collaborationNotePath(resourceType, id, kind, customFieldID, orderID = null) {
  return {
    Account: `/companies/${id}#collaboration`,
    Contact: `/contacts/${id}#collaboration`,
    Opportunity: `/opportunities/${id}#collaboration`,
    Quote: `/quotes/${id}#collaboration`,
    Ticket: `/tickets/${id}#collaboration`,
    CustomObject: `/lists/${customFieldID}/record/${id}#collaboration`,
    Reminder: `/reminders?reminderID=${id}#collaboration`,
    Activity: `/activities?activityID=${id}&kind=${kind}#collaboration`,
    Interaction: `/activities?activityID=${id}&kind=${kind}#collaboration`,
    SalesOrder: `/orders/${id}#collaboration`,
    SalesOrderItem: `/orders/${orderID}/order-items/${id}#collaboration`,
    List: `/campaigns/${id}#collaboration`,
  }[resourceType];
}

export function assistantRulePath(isEnabledOnAssistantPage, ruleId, resourceType, resourceID) {
  if (isEnabledOnAssistantPage) return `/assistant?assistant_rule_id=${ruleId}`;
  return {
    accounts: `/companies/${resourceID}`,
    contacts: `/contacts/${resourceID}`,
    opportunities: `/opportunities/${resourceID}`,
    quotes: `/quotes/${resourceID}`,
    tickets: `/tickets/${resourceID}`,
    sales_orders: `/orders/${resourceID}`,
  }[resourceType];
}

export default {
  HOME: '/',
  OPPORTUNITIES: '/opportunities',
  OPPORTUNITIES_PRIORITY_VIEW: '/opportunities/priority',
  CONTACTS: '/contacts',
  COMPANIES: '/companies',
  TICKETS: '/tickets',
};
