import { createSlice } from '@reduxjs/toolkit';

import { nullMeta, parseMetaFromResponse } from 'helpers/meta';

import { fetchEngineRules, handleCreateEngineRule } from './actions';

const initialState = {
  fetching: false,
  data: [],
  meta: nullMeta,
  createInProgress: false,
  addNewRuleModalVisible: false,
  params: {
    page: 1,
  },
};

export const engineRulesSlice = createSlice({
  name: 'engine-rules',
  initialState,
  reducers: {
    setAddNewRuleModalVisible: (state, { payload }) => ({
      ...state,
      addNewRuleModalVisible: payload,
    }),
    setEngineRules: (state, { payload }) => ({ ...state, data: payload }),
  },
  extraReducers: {
    [fetchEngineRules.pending]: (state) => ({ ...state, fetching: true }),
    [fetchEngineRules.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload.assistant_rules,
      fetching: false,
      meta: {
        ...state.meta,
        ...parseMetaFromResponse(payload.meta),
      },
    }),
    [fetchEngineRules.rejected]: (state) => ({ ...state, fetching: false }),
    [handleCreateEngineRule.pending]: (state) => ({ ...state, createInProgress: true }),
    [handleCreateEngineRule.fulfilled]: (state) => ({
      ...state,
      createInProgress: false,
      addNewRuleModalVisible: false,
    }),
    [handleCreateEngineRule.rejected]: (state) => ({ ...state, createInProgress: false }),
  },
});

export const { setAddNewRuleModalVisible, setEngineRules } = engineRulesSlice.actions;

export default engineRulesSlice.reducer;
