import PropTypes from 'prop-types';

import AddonsOnly from 'components/AddonsOnly';

import UnauthorizedAccess from '../UnauthorizedAccess';

function AddonsRoute({ children, addonName }) {
  return (
    <AddonsOnly addonName={addonName} displayFallback fallback={<UnauthorizedAccess />}>
      {children}
    </AddonsOnly>
  );
}

AddonsRoute.propTypes = {
  children: PropTypes.any.isRequired,
  addonName: PropTypes.string.isRequired,
};

export default AddonsRoute;
