import MuiDrawer from '@mui/material/Drawer';
import { styled } from '@mui/material/styles';

const StyledDrawer = styled(MuiDrawer)({
  '& .MuiDrawer-paper': {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
});

export default function Drawer(props) {
  return <StyledDrawer {...props} disableEnforceFocus />;
}
