import pick from 'lodash/pick';

export const CREDENTIAL_PROVIDERS = {
  EXCHANGE: 'exchange',
  IMAP: 'imap',
};

function filterCredentialsFor(provider, credentials) {
  return credentials.filter((credential) => credential.provider === provider);
}

export function filterExchangeCredential(credentials) {
  const credential = filterCredentialsFor(CREDENTIAL_PROVIDERS.EXCHANGE, credentials);
  return credential[0];
}

export function filterImapCredential(credentials) {
  const credential = filterCredentialsFor(CREDENTIAL_PROVIDERS.IMAP, credentials);
  return credential[0];
}

export function parseCredential(data) {
  return {
    id: data.id || '',
    provider: data.provider || '',
    email: data.email || '',
    username: data.username || '',
    password: data.password || '',
    server: data.options.server || '',
    port: data.options.port || null,
  };
}

export function serializeCredential(credential, keys) {
  const s = {
    provider: credential.provider,
    username: credential.username || credential.email,
    password: credential.password,
    email: credential.email,
    options: {
      server: credential.server,
      port: credential.port ? parseInt(credential.port, 10) : null,
      use_ssl: credential.useSSL,
    },
  };

  if (keys) {
    return pick(s, keys);
  }
  return s;
}

export default {
  filterExchangeCredential,
  parseCredential,
  serializeCredential,
};
