import Typography from '@mui/material/Typography';

import { ReactComponent as NoNotificationsImage } from '../no-notifications.svg';

import styles from './NoNotifications.module.scss';

export default function NoNotifications() {
  return (
    <div className={styles.wrapper}>
      <NoNotificationsImage />
      <Typography variant="h6">No Notifications</Typography>
    </div>
  );
}
