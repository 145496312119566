import EngineService from '../core/engine-service';

const ENDPOINT = '/smart_quotes/products';

export function getSmartProducts(params = {}) {
  return EngineService.get(ENDPOINT, params).then((json) => json);
}

export function createSmartProduct(payload) {
  return EngineService.post(ENDPOINT, { product: payload }).then((json) => json);
}

export function updateSmartProduct(productId, payload) {
  return EngineService.patch(`${ENDPOINT}/${productId}`, { product: payload }).then((json) => json);
}

export function deleteSmartProduct(productId) {
  return EngineService.delete(`${ENDPOINT}/${productId}`);
}

export function getSmartProductsConfigurations(params = {}) {
  return EngineService.get('/smart_quotes/configurations', params).then((json) => json);
}

export function getProductFilterProperties(params = {}) {
  return EngineService.get('/filters/products/columns', params).then((json) => json);
}
