import { createSlice } from '@reduxjs/toolkit';

import { fetchOrder, updateOrder } from './actions';

const initialState = {
  data: null,
  fetching: false,
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    resetOrderState: () => ({ ...initialState }),
    replaceOrder: (state, { payload }) => ({
      ...state,
      data: payload,
      fetching: false,
    }),
  },
  extraReducers: {
    [fetchOrder.pending]: (state) => ({ ...state, fetching: true }),
    [fetchOrder.fulfilled]: (state, { payload }) => ({
      ...state,
      data: payload,
      fetching: false,
    }),
    [fetchOrder.rejected]: (state) => ({ ...state, fetching: false }),
    [updateOrder.fulfilled]: (state, { payload }) => ({ ...state, data: payload }),
  },
});

export const { resetOrderState, replaceOrder } = orderSlice.actions;

export default orderSlice.reducer;
