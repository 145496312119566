import PropTypes from 'prop-types';

import { ReactComponent as Icon } from '../../../images/icons/v2/arrow-up-icon.svg';

import styles from './UnreadMessagesButton.module.scss';

function UnreadMessagesButton({ visible, onClick }) {
  if (!visible) return null;

  return (
    <button type="button" className={styles['unread-messages']} onClick={onClick}>
      <Icon width={8} height={8} className={styles.icon} />
      <span>Unread messages</span>
    </button>
  );
}

UnreadMessagesButton.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default UnreadMessagesButton;
