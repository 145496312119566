import {
  FETCHING_USER,
  SET_NOTIFICATIONS_STATUS,
  STORE_MENTIONABLE_USERS,
  STORE_USER,
  TOGGLE_MANAGER_SUMMARY_MODAL,
  UPDATE_ENABLE_PARENT_ACCOUNT,
  UPDATE_FAIL,
  UPDATE_IN_PROGRESS,
  UPDATE_SUCCESS,
  UPDATE_USER,
} from './constants';
import { create as createReducer } from '../../core/redux-utils';
import { parseUserFromEngine } from '../../helpers/user';

const initialState = {
  ...parseUserFromEngine(),
  fetching: false,
  fetched: false,
  updateInProgress: false,
  updateSuccess: false,
  updateFail: false,
  isManagerSummaryModalVisible: false,
  mentionableItems: {
    users: [],
    groups: [],
  },
};

export function fetchingUserHandler(state) {
  return { ...state, fetching: true };
}

export function storeUserHandler(state, action) {
  return {
    ...state,
    ...action.data,
    fetching: false,
    fetched: true,
  };
}

export function setNotificationStatusHandler(state, action) {
  return { ...state, enableNotifications: action.status };
}

export function updateUserInProgressHandler(state) {
  return {
    ...state,
    updateInProgress: true,
    updateSuccess: false,
    updateFail: false,
  };
}

export function updateUserSuccessHandler(state) {
  return {
    ...state,
    updateInProgress: false,
    updateSuccess: true,
    updateFail: false,
  };
}

export function updateUserFailHandler(state) {
  return {
    ...state,
    updateInProgress: false,
    updateSuccess: false,
    updateFail: true,
  };
}

export function updateUserHandler(state, action) {
  return {
    ...state,
    ...action.data,
    fetching: false,
    fetched: true,
  };
}

export function updateEnableParentAccountHandler(state, action) {
  return {
    ...state,
    ...action.data,
  };
}

export function toggleManagerSummaryModalHandler(state, action) {
  return {
    ...state,
    isManagerSummaryModalVisible: action.data,
  };
}

export function storeMentionableUsers(state, action) {
  return {
    ...state,
    mentionableItems: action.data,
  };
}

const actionHandlers = {
  [FETCHING_USER]: fetchingUserHandler,
  [STORE_USER]: storeUserHandler,
  [SET_NOTIFICATIONS_STATUS]: setNotificationStatusHandler,
  [UPDATE_IN_PROGRESS]: updateUserInProgressHandler,
  [UPDATE_SUCCESS]: updateUserSuccessHandler,
  [UPDATE_FAIL]: updateUserFailHandler,
  [UPDATE_USER]: updateUserHandler,
  [UPDATE_ENABLE_PARENT_ACCOUNT]: updateEnableParentAccountHandler,
  [TOGGLE_MANAGER_SUMMARY_MODAL]: toggleManagerSummaryModalHandler,
  [STORE_MENTIONABLE_USERS]: storeMentionableUsers,
};

export default createReducer(initialState, actionHandlers);
