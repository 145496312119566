import EngineService from '../core/engine-service';

const ENDPOINT = '/import';

// POST: /imports
export function imports(params = {}) {
  return EngineService.post('imports', params).then((json) => json);
}

export const getImportHistory = (params) =>
  EngineService.get('/imports', params).then((json) => json);

export const getImportHistoryById = (id) =>
  EngineService.get(`/imports/${id}`).then((json) => json);

// GET: /imports/:id
export function pool(importId) {
  return EngineService.get(`${ENDPOINT}/${importId}`).then((json) => json);
}

export function csvHeaders(payload) {
  return EngineService.post(`${ENDPOINT}/csv_headers`, payload).then((json) => json);
}

export function resourceFields(params) {
  return EngineService.get(`${ENDPOINT}/resource_fields`, params).then((json) => json);
}

export function getDriveAccessToken() {
  return EngineService.get(`${ENDPOINT}/access_tokens`).then((json) => json);
}

export function getImportTemplate(params) {
  return EngineService.get(`${ENDPOINT}/templates`, params, {
    Accept: 'text/csv',
  }).then((json) => json);
}

export function uploadFileToAWS(payload) {
  return EngineService.post('/import_presigned_aws_urls', payload).then((json) => json);
}

export function externalIdValidation(payload) {
  return EngineService.post(`${ENDPOINT}/external_id_validation`, payload).then((json) => json);
}

export default {
  imports,
  pool,
  csvHeaders,
  resourceFields,
  getDriveAccessToken,
};
