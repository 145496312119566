import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { selectCanDoImport, selectIsAdmin } from 'state/user/selectors';

function ImportAllowedOnly({ children, fallback = null }) {
  const userIsAdmin = useSelector(selectIsAdmin);
  const userCanDoImport = useSelector(selectCanDoImport);

  if (userIsAdmin || userCanDoImport) return children;

  return fallback || null;
}

ImportAllowedOnly.propTypes = {
  children: PropTypes.any.isRequired,
  fallback: PropTypes.func,
};

export default ImportAllowedOnly;
